.trialWarningBox {
  text-align: center;

  @include breakpoint($tablet) {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
  }

  span svg {
    fill: $pink;
  }

  p.no-margin {
    margin: 0;
  }

  svg {
    height: 15px;
    width: 16px;
    margin-right: 5px;
    vertical-align: middle;
  }

  a {
    color: black;
    text-decoration: underline;
    margin: 0 0 0 2px;
  }
}

