.routing {
}

.accordionBorder {
  padding: 5px 10px 30px !important;
}

.assignTruckDrawerContainer {
  width: 50% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 15px 10px 10px;
  float: left;

  @media (max-width: $tablet) {
    width: 100% !important;
  }

  .drawer.open.withForm {
    background-color: #1e3e58 !important;
  }

  .drawerForm {
    .actions {
      width: 290px;
      padding: 0 5px;
    }
  }

  #assignToTruckHeader {
    justify-content: flex-start;
    align-items: center;
  }

  .header {
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    color: white;

    #routeStatistics {
      font-size: 10px;
      font-weight: 300;
      opacity: 0.5;
    }

    span {
      display: flex;
      justify-content: space-between;
      margin-right: 10px;
    }

    a {
      font-size: 10px;
      color: white !important;
      opacity: 0.7;
    }

    svg {
      height: 25px !important;
      width: 24px !important;
      transform: scaleX(-1);
      margin-right: 1px;
    }
  }

  td {
    border-style: hidden !important;
  }

  .actions {
    bottom: 20px;
    position: fixed;
    width: 240px !important;

    .cancel {
      color: white !important;
    }
  }
}

.breadcrumbs {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.mapRentals,
.mapRoutes {
  .breadcrumbs {
    padding: 5px 0 10px 0;
  }

  .filter {
    padding-left: 20px;

    @media (max-width: 769px) {
      span {
        font-weight: bold;
      }

      select {
        position: absolute;
        right: 5px;
      }

      div > a {
        margin: 0 0 5px 0 !important;
        padding: 0 !important;
      }
    }
  }

  .mapDrawer {
    @include breakpoint($tablet) {
      grid-column: 1;
      width: 70%;
      z-index: 1;
    }
  }

  .map {
    height: calc(100% - 244px);
    left: 0;
    position: absolute;
    z-index: 0;

    @media (max-width: 769px) {
      max-height: 80vh;
    }
  }
}

.scheduleListDayHeader {
  font-weight: bold;
}

.markerActionBox {
  background-color: $white;
  display: flex;
  width: 200px;
  opacity: 2 !important;
  flex-direction: column;
  align-items: center;

  .markerHeader {
    display: flex;
    padding-bottom: 10px;

    span {
      color: $darkgrey;
      font-size: 12px;
      text-decoration: underline;
    }
  }

  .markerBody {
    display: flex;
    justify-content: left;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      color: #000;
      font-size: 12px;
    }
  }

  .markerAddress {
    display: flex;
    justify-content: left;
    margin-top: 5px;

    span {
      color: $darkgrey;
      font-size: 10px;
    }
  }

  .markerBtn {
    width: 50px !important;
  }
}

.markerActions {
  display: flex;
  justify-content: right;
  padding-top: 10px;
  padding-bottom: 10px;

  :nth-child(1) {
    margin-right: 5px;
  }

  .markerIcon {
    position: inherit !important;
    width: 30px !important;
    height: 30px !important;
  }
}

.dragAndDropBox {
  border-style: dashed;
  border-width: thin;
  padding: 20px 2px 20px 10px;
}

#addDeliveryTeam {
  margin: 0;

  .assignTruckDrawer {
    margin: 0;
  }

  .actions {
    bottom: 20px;
    position: fixed;
    width: 240px !important;
    margin-left: 15px;

    .cancel {
      color: white !important;
    }
  }

  @media (max-width: $tablet) {
    .drawer {
      width: 400px !important;
    }
  }
}

.assignTruckDrawer {
  .header {
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    color: white;
  }

  #deliveryTeamContainer {
    margin-left: -15px;
    width: 400px;

    @media (max-width: $tablet) {
      overflow-x: hidden;
    }
  }

  .assignFieldContainer {
    margin-bottom: 10px;

    a {
      margin-top: 5px !important;
    }
  }

  #truckAssignmentContainer {
    svg {
      position: fixed;
      margin: 15px 0 0 -20px;
      fill: white;

      @media (max-width: $tablet) {
        margin: 18px 0 0 -20px;
      }
    }
  }

  .togglePrimary {
    background-color: $darkblue !important;
    color: $lightgrey !important;
    margin-top: 0px !important;
  }

  #assignTruckSelection {
    margin-top: 5px !important;
    color: white;
    font-weight: 300;
  }

  #selectRoleContainer {
    svg {
      margin-left: -20px;
      margin-top: 5px;
      fill: $blue;
    }
  }

  .assignTruckDropdown {
    margin-top: 5px !important;
    font-weight: 300;
    background-color: #0e2c44;

    option {
      color: black;
      background-color: $lightgrey;
    }
  }

  .additionalTruckSelector {
    display: flex;

    span {
      padding-left: 5px;
    }
  }

  .mapSideDrawerInfo {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    .deliveryTime {
      color: $white;
      margin-bottom: 10px;
    }

    .addressInfo {
      color: $white;
    }

    .rentalInfo {
      color: $darkgrey;
    }
  }

  .labelBlue {
    color: $blue;
    padding-bottom: 5px;
    font-weight: bold;
  }

  .checkList {
    margin: 0;

    .checkListItem {
      margin: 0 !important;

      .check {
        display: none;
      }
    }
  }

  .checkListItem:has(> .check.on) {
    border-left: 5px solid $blue;
    font-weight: bold;

    div:last-child {
      display: flex;
      margin-left: -5px;
    }
  }
}

.addTruckDropdown {
  background-color: $meddarkgrey;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-left: 5px;
  height: 40px;

  .btnLink.unselectedBtnLink.add svg {
    fill: #466B88;
  }
}

.w85 {
  width: 85% !important;
}

.unassignedStopHeader {
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 9fr;
  height: 100%;

  span {
    color: $white;
  }

  svg {
    margin-left: 5px;
    margin-right: 5px;
    height: 35px;
  }

  & span {
    height: 35px;

    &:nth-child(1) {
      font-weight: bold;
    }

    &:nth-child(2) {
      font-weight: bold;
    }

    &:nth-child(3) {
      display: flex !important;
      justify-content: space-between;
      font-size: 11px;
      margin-left: 55%;
    }
  }
}

.deliveryTeamRoleSelectionTable, .additionalTruckSelector {
  .removeCircleContainer {
    svg {
      opacity: 0;
    }
  }

  &:hover {
    .removeCircleContainer {
      cursor: pointer;

      svg {
        opacity: 1;
        fill: #ca2135 !important;
      }
    }
  }
}

.deliveryTeamRoleSelectionTable {
  & td {
    &:nth-child(1) {
      color: white;
      width: 160px;
    }

    &:nth-child(2) {
      width: 170px;
    }

    &:nth-child(3) {
      padding-left: 7px;
    }
  }

  tr {
    display: flex;
    padding-left: 22px;

    td {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
  }

  tr:not(:first-child) {
    border-top: 1px solid #254a67;
  }
}

.mobileRoutingDropdown {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .dropdownContainer {
    background-color: $white;
    width: 100%;
    margin-left: 0px;
  }
}

.mapRoutesHeader {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;

  .print {
    cursor: pointer;
  }

  #routingHeader {
    display: flex;
    justify-content: space-between;
    height: 40px;
    width: 100%;
    border-bottom: 1px solid #c1cdd7;
    margin-bottom: 10px;

    .headerNav {
      padding-left: 0;
      margin-left: 0;
      border-left: 0;

      a {
        @media only screen and (min-width: $desktop) {
          &.selected {
            border-bottom: 5px solid #278ada;
            height: 38px;
          }
        }
      }
    }
  }
}

.relativeMap {
  position: relative;
}

#collapseMap {
  position: absolute;
  right: 0;
  z-index: 4;
  margin-top: 10px;
  padding-left: 4px;
  margin-right: 15px;
}

#routeDetailsMap {
  display: flex;
  width: 70%;

  .btnCollapse,
  .btnExpand {
    svg {
      fill: #278ada !important;
    }
  }

  .mapContainer {
    .map {
      position: absolute;
      height: 100vh;
    }
  }

  @media (max-width: 769px) {
    height: unset;
    display: flex;
    flex-direction: column-reverse;

    .routeDrawer {
      z-index: 1 !important;
    }

    #routeAdjustmentsContainer {
      .start {
        height: 35px;
      }
    }

    .rentalTransportActions {
      left: -8px;
      display: flex !important;
      flex-direction: column;
      align-items: flex-start !important;
      margin-top: 40px;
      visibility: visible;
    }

    .truckHolder {
      .header {
        height: 120px;
      }

      .cell-container {
        height: 80px;
        display: flex;
        justify-content: space-between !important;
      }

      #headerRentalTokenContainer {
        height: 29px !important;
      }
    }
  }
}

.mapWrapper {
  display: grid;

  @media (max-width: 769px) {
    display: flex;
    flex-direction: column-reverse;
  }

  &.rentalsWrapper {
    display: block;
  }

  .mapDrawer {
    background-color: $white;
    z-index: 2;

    .drawer.open {
      background-color: #1e3e58 !important;
    }

    .drawer.open.withForm {
      .drawerForm {
        label {
          color: white !important;
        }
      }
    }

    @include breakpoint($tablet) {
      grid-column: 1;

      & + .mapContainer {
        grid-column: 2;
      }
    }

    #noOrders {
      height: 20vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 10px;

      svg {
        height: 50px !important;
        width: 50px !important;
        fill: #c1cdd7;
      }

      text {
        color: #c1cdd7;
      }
    }

    #truckRouteHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .truck-heading {
        margin-bottom: 0 !important;
        padding: 10px;
        display: flex;
      }

      #routeStatistics {
        color: white;
        font-size: 10px;
        opacity: 0.5;
        padding: 10px;
      }
    }

    .contents > div > a.btn {
      width: 35%;

      @media (max-width: 769px) {
        font-size: 16px;
      }
    }

    .mapContainer {
      @include breakpoint($tablet) {
        grid-column: 1 / span 2;
      }

      &.stickyTop {
        position: sticky;
        z-index: 10;
      }
    }
  }

  .mapContainer {
    @media (max-width: $tablet) {
      height: 500px;
    }
  }

  .accordian {
    .accordian {
      .content {
        #iconContainer {
          top: -22px;
          position: relative;
        }

        @media (max-width: $tablet) {
          height: unset !important;

          .accordian {
            .header {
              padding-bottom: 30px !important;

              @media (max-width: 600px) {
                .main {
                  flex-direction: column;
                  height: 60px;
                  gap: 2px;
                }
              }

              .tablet-mt-md {
                margin-top: 16px;
              }

              .tablet-mt-lg {
                margin-top: 24px;
              }

              .dragGrid {
                height: fit-content;
              }

              .cell-container {
                height: fit-content;
              }

              .cell-unit {
                display: flex;
                font-size: 13px;

                @media screen and (max-width: 503px) {
                  font-size: 12px;
                }
              }

              @media screen and (max-width: 600px) {
                span.cell-unit {
                  max-width: 200px;
                  text-wrap: pretty;
                  line-height: 16px;
                  margin-bottom: 4px;
                }
              }

              @media screen and (max-width: 450px) {
                span.cell-unit {
                  max-width: 180px;
                }
              }

              .rentalTransportActions {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                @media screen and (max-width: 600px) {
                  align-items: flex-start;
                }
                position: relative;
                top: 16px;
                left: 40%;

                @media (max-width: 640px) {
                  left: 20%;
                }

                @media (max-width: 570px) {
                  left: 0%;
                }

                @media (max-width: 500px) {
                  left: -10%;
                }

                @media (max-width: 450px) {
                  left: -32%;
                }

                top: -25px;
                height: 65px;
                justify-content: space-around;
              }
            }
          }
        }
      }
    }
  }
}

.mapDrawer {
  & > .header {
    display: flex;
    background-color: $blue;
    line-height: 40px;
    color: $white;

    .heading {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;

      label {
        float: left;
        margin-left: 10px;
        font-weight: 500;
      }

      .btn.close {
        background-color: #e6e8e9;
        color: #374d61;
        padding-right: 30px !important;
        padding-left: 30px !important;
        float: right;
        margin-top: 5px;
      }
    }

    label {
      float: left;
      margin-left: 14px;
    }

    & > * {
      height: 40px;
      width: 50%;
      margin: 0;
      line-height: 40px;
      color: $white;

      &:nth-child(2) {
        font-weight: $regular;
        text-transform: none;
        text-align: right;
        margin-right: 14px;

        svg {
          height: 20px !important;
          width: 20px !important;
          margin-top: 0;
          fill: $darkgrey;
        }
      }
    }
  }

  .routeHeader > div {
    line-height: 40px;
    color: $white;
    text-align: center;
    display: flex;

    @media (max-width: 769px) {
      display: grid;
      grid-template-columns: 1fr;
      padding: 15px 10px;
      margin-top: -1px;
    }

    a {
      color: rgba(255, 255, 255, 0.5);
      font-weight: $regular;
      text-transform: none;
      width: fit-content !important;
      margin: 0 20px;

      &.active {
        font-weight: $bold;
        color: $white;
        border-bottom: 5px solid white;
      }
    }
  }

  .contents {
    h2 {
      color: #2c455a;
      font-weight: bold;

      svg {
        height: 30px !important;
        width: 30px !important;
      }
    }

    .driver {
      line-height: 3.4;

      & > svg {
        height: 14px;
        width: 14px;
        margin-right: 5px;
        fill: $blue;
        vertical-align: middle;
      }

      select {
        margin-top: 5px !important;
        max-width: 100px;
        margin: 0px;
        float: right;
        min-width: 100px;
      }

      span {
        text-align: right;
        float: right;
        margin-right: 10px;
        min-width: 100px;
      }

      &:last-of-type() {
        margin-bottom: 5px !important;
      }
    }

    .toggle {
      margin-top: 10px;
      width: 100%;
      background-color: $lightgrey;
      color: $darkblue;
    }

    select,
    .btn,
    .btnIcon {
      width: 100%;
    }

    select,
    input[type='text'] {
      background-color: $lightgrey;
    }

    .details {
      .btn {
        margin-bottom: 10px;
      }

      .items .item {
        font-size: 13px;
      }

      & > div {
        margin-bottom: 10px;
        font-size: 11px;
      }

      label {
        display: inline-block;
        width: 30px;
      }

      .indent {
        padding-left: 30px;
      }
    }

    .actions {
      width: 100%;
      margin-left: 0;
    }

    .truckInfoSection {
      margin-bottom: 10px;

      .headingDisplay {
        display: flex;
        justify-content: space-between;

        .truck-heading {
          margin-bottom: 0px !important;
        }

        .reassignTrucksLink {
          font-size: 10px;
          text-decoration: underline;
        }
      }
    }

    #routeAdjustmentsContainer {
      display: flex;
      flex-direction: row-reverse;
      height: 30px;

      a {
        width: 45%;
        border: 0px solid white;
        display: flex;
        color: black;
        background-color: #e5e8e9;
        border-radius: 2px;
        align-items: center;
        font-weight: 300;

        svg {
          height: 30px !important;
          width: 30px !important;
          display: flex;
          padding: 7px;
          fill: white;
          border-radius: 2px;
          margin-right: 10px;
        }
      }

      div {
        width: 55%;
        margin: 0;
        background-color: #e5e8e9;
        margin-right: 5px;
        border-radius: 2px;
        display: flex;

        @media (max-width: $tablet) {
          width: 60%;
        }
      }
    }

    .stopDrawer.stopDrawerForm.stopDrawerOpen {
      transform: none !important;
      width: 425px !important;
      background-color: #1e3e58 !important;

      @media (max-width: 769px) {
        padding: 10vh 0vw 0vh 4vw;
        height: 100%;
      }

      #drawerHeader {
        display: flex;
        margin-left: 15px;
        margin-top: 70px;

        h2 {
          font-size: 16px;
          color: white;
          font-weight: bold;
          text-transform: none;
          height: 70px;
          margin: 12px 0px 15px 12px;
        }

        svg {
          @media (max-width: 769px) {
            margin-top: 10px;
          }
        }
      }

      #drawerContainer {
        margin: 0px 15px;

        #stopDateTime {
          display: flex;
          flex-direction: row;
          gap: 6px;
          margin-bottom: 20px;

          #stopDateLabel {
            color: $white;
            padding: 7px 0;
          }
        }

        #routeDetails {
          color: white;
          margin-bottom: 50px;

          #truckInfo {
            display: flex;
            justify-content: space-between;

            #truckName {
              font-weight: bold;
            }

            #routeStatistics {
              font-size: 10px;
              opacity: 0.5;
            }
          }

          #ordersCount {
            opacity: 0.5;
          }
        }

        .fields {
          display: flex;

          div,
          select {
            margin-bottom: 7px;
          }

          input,
          select {
            background-color: white;
            color: black;
          }

          #stopTimeContainer {
            display: flex;
            justify-content: space-between;

            #stopName {
              width: 65%;
            }

            #stopTime {
              width: 33%;
            }
          }

          #addressAndApt {
            display: flex;
            justify-content: space-between;

            #streetAddress1 {
              width: 65%;
            }

            #streetAddress2 {
              width: 33%;
            }
          }

          #cityStatePostal {
            display: flex;
            justify-content: space-between;

            #city {
              width: 39%;
            }

            #state {
              width: 24%;
            }

            #postal {
              width: 33%;
            }
          }
        }
      }

      .drawerFooter {
        bottom: 0;
        left: 0;
        position: absolute;
        margin: 15px;
        display: flex;

        @media (max-width: 769px) {
          margin-left: 6vw;
        }

        .submit {
          width: 66% !important;
        }

        .cancel {
          width: 34% !important;
          color: white;
        }
      }
    }
  }
}

.w25 {
  width: 25%;
}

.w40 {
  width: 40%;
}

.pl5 {
  padding-left: 5px;
}

.accordionTransportHeader {
  padding-left: 10px;
  padding-top: 6px;
}

.mapContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  @include breakpoint($tablet) {
    display: block;
  }

  .mapHeader {
    order: 2;
    @include container;
    width: 100%;
    background-color: $white;
    line-height: 40px;

    .check {
      margin-left: 10px;
    }

    .trucks {
      float: right;

      & > div {
        position: relative;
        display: inline-block;
        margin-left: 5px;

        svg {
          height: 20px !important;
          width: 27px !important;
          vertical-align: middle;
        }

        label {
          position: absolute;
          top: 9px;
          left: 11px;
          right: 0px;
          font-size: 10px;
          font-weight: $bold;
          line-height: 14px;
          color: $white;
          text-align: center;
        }

        &.dropOff {
          label {
            left: 0px;
            right: 11px;
          }
        }
      }
    }

    .legend {
      @include breakpoint($tablet) {
        float: right;
        margin-right: 20px;
      }

      svg {
        height: 23px !important;
        width: 15px !important;
        margin: 0 5px 0 15px;
        fill: $purple;
        vertical-align: middle;

        &:nth-of-type(2) {
          fill: $orange;
        }

        &:last-of-type() {
          fill: $blue;
        }
      }
    }
  }

  .map {
    order: 1;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;

    @media (max-width: $tablet) {
      height: 500px !important;
    }

    @media (min-width: 1020px) {
      width: calc(100% - (100% - 1020px));
      left: calc(0px + (50% - 510px));
    }

    .marker {
      transform: translate(-50%, -100%);
      line-height: 18px;
      cursor: pointer;
    }
  }

  .smallMap {
    .marker {
      transform: translate(-50%, -265%);
    }
  }
}

.scheduleDrawer {
  max-width: 100% !important;

  .contents {
    .deliveryTimeIcon {
      right: 25px;
      position: absolute;
    }

    .rentalTransportDeliveryTimeIcon {
      right: 25px;
      top: 2px;
      position: absolute;
    }

    .trucks-lists {
      @include breakpoint($tablet) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 39px;
      }
    }

    .assigned {
      h2 {
        margin: 0;
      }

      a {
        border-top: 1px solid $meddarkgrey;
        color: $darkblue;

        .marker {
          grid-row: span 2;
        }

        strong label {
          margin-left: 5px;
          color: $darkblue;
        }

        .rental-name {
          margin-left: 25px;
        }
      }
    }

    .unassigned {
      #innerAccordian:first-child {
        border-top: 0px solid rgba(193, 205, 215, 0.5);
      }

      #innerAccordian {
        cursor: pointer;
        min-width: 100%;
        border-top: 1px solid rgba(193, 205, 215, 0.5);

        .header {
          position: relative;
          height: auto;
          padding: 10px 5px 10px 0;
          line-height: 18px;

          & > div {
            text-overflow: initial;
            padding-top: 10px;
          }
        }

        .btnExpand,
        .btnCollapse {
          &:first-of-type {
            svg:first-child {
              fill: $darkgrey !important;
            }
          }
        }
      }

      .accordian {
        position: relative;

        .content {
          @media screen and (max-width: $tablet) {
            height: 100px;
          }
        }

        .btnExpand,
        .btnCollapse {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          margin: 7px;

          svg:first-child {
            fill: $white;
          }
        }
      }

      h2 {
        margin: 0;
      }

      a {
        color: $darkblue;

        .marker {
          grid-row: span 2;
        }

        strong label {
          &:first-child {
            color: $orange;
          }

          margin-left: 5px;
          color: $darkblue;
        }

        .rental-name {
          margin-left: 25px;
        }
      }
    }

    .assigned-truck {
      h2 {
        margin: 0;
      }

      strong {
        display: grid;
        grid-template-columns: 2fr 2fr 1fr 2fr;
      }

      a {
        color: $darkblue;

        &:first-of-type {
          border-top: 0px;
        }

        .marker {
          grid-row: span 2;
        }

        strong label {
          margin-left: 5px;
          color: $darkblue;
        }

        .rental-name {
          margin-left: 25px;
        }
      }

      .rentalTransportTruckContent {
        div:first-of-type {
          #innerAccordian {
            border-top: 0px solid rgba(193, 205, 215, 0.5);
          }
        }
      }

      #innerAccordian {
        min-width: 100%;
        border-top: 1px solid rgba(193, 205, 215, 0.5);

        .header {
          position: relative;
          height: auto;
          padding: 10px 5px 10px 0;
          line-height: 18px;

          & > div {
            text-overflow: initial;
            padding-top: 10px;
          }
        }

        .btnExpand,
        .btnCollapse {
          &:first-of-type {
            svg:first-child {
              fill: $darkgrey !important;
            }
          }
        }
      }

      .accordian {
        position: relative;

        .content {
          @media screen and (max-width: $tablet) {
            height: 150px;
          }
        }

        .btnExpand,
        .btnCollapse {
          float: none;
          position: absolute;
          right: 0;
          top: 0;
          display: block !important;
          margin: 10px;

          svg:first-child {
            fill: $white;
          }
        }
      }
    }

    .rentalTransHeaderWrapper {
      display: flex;
      flex-direction: row;
      @media screen and (max-width: $tablet) {
        height: fit-content;
      }
      align-items: flex-start;

      #paper-clip-icon {
        position: absolute;
        right: 24px;
        top: 36px;

        @media screen and (max-width: 480px) {
          top: 8px;
          right: 36px;
        }
      }
    }

    .carratAcc {
      display: inline-flex !important;
      flex-wrap: wrap;
      width: 100% !important;

      #strikeThrough > * {
        text-decoration: line-through;

        span {
          text-decoration: line-through;
        }
      }

      .cell-container {
        display: flex;
        flex-direction: column;
        padding-left: 10px;

        @media (max-width: $tablet) {
          justify-content: space-evenly;
        }

        .cell {
          display: flex;
          line-height: 12px;
          @media screen and (max-width: 600px) {
            &:nth-last-child(2) {
              text-overflow: ellipsis;
            }
          }

          .cell-unit {
            margin-right: 0;
            display: flex;
            margin-top: 5px;

            .rtInfo {
              white-space: normal;
            }

            .rentalInfoHeader {
              color: $darkgrey;
              width: 300px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-right: 5px;
              overflow-wrap: break-word;

              .rentalInfo {
                display: flex;
                flex-direction: column;
                line-height: 1.5em;
                color: $darkgrey;
              }

              .rentalInfoToken {
                display: flex !important;
                gap: 2px;
                color: $darkgrey;
                align-items: center;
                line-height: 20px;

                &:hover {
                  color: $blue;
                  text-decoration: underline;
                  transition: color 0.2s;
                }
              }
            }

            a {
              display: inline;
              text-decoration: underline;
              margin-right: 5px;
            }

            &.type-label {
              color: $orange;
              margin-right: 15px;
            }

            .rentalTransportDeliveryTimeIcon {
              margin-left: 10px;
              top: 20px;
              right: unset;
            }

            &.additionalTruck {
              font-weight: 500;
            }
          }

          .margin-right {
            margin-right: 5px;
          }

          &.main {
            font-weight: bold;
            margin-top: 2px;
            margin-bottom: 2px;
          }
        }

        div.cell {
          position: relative;
          left: -5px;
        }

        .driveApproximations {
          opacity: 0.5;
          margin-top: 5px;
          font-size: 10px;

          @media (max-width: $tablet) {
            opacity: 0.9;
            font-size: 13px;
          }
        }
      }

      & > svg {
        width: 30px;
      }

      link {
        margin-top: -20px;
        margin-left: -35px;
      }

      strong {
        vertical-align: top !important;
        margin-left: -39px;
        margin-top: -20px;

        a {
          margin-top: -27px;
          margin-left: 5px;
          color: black;
          font-weight: normal;
          text-decoration: none;
        }
      }
    }

    .rentalTransportActions {
      @media screen and (max-width: 1020px) and (min-width: $tablet) {
        position: relative;
        left: -8px;
      }
    }

    #routeDetailsActions {
      right: 0;
      position: absolute;
      height: 80%;
      display: flex !important;
      align-items: center;
      margin-left: 5%;

      a:first-child() {
        margin-right: 5px;

        svg {
          #Color-Blue-0001-0002 {
            transform: translate(5px, 5px);
          }
        }
      }
    }

    .listContent {
      line-height: 2.5;
      margin-left: 5%;
      margin-top: 5px;

      span {
        font-weight: bold;
        margin-left: 5%;
        display: inline-block;
        margin-top: 10px;
      }

      .setByInfo {
        margin: 0 0 20px 20px;

        label:first-child() {
          width: 55px;
          display: inline-block;
        }

        & > strong {
          display: block;
          line-height: 18px;
          margin-bottom: 2px;

          span {
            margin-top: 0;
            margin-left: 5px;
          }
        }

        & > div {
          line-height: 18px;
        }
      }
    }

    .bundleContent {
      margin-left: 10%;
      line-height: 2;

      span {
        font-weight: bold;
        display: inline-block;
        margin-top: 0 !important;
      }
    }

    .routeItem {
      display: flex;

      label:last-child {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .fixLabelSize {
      width: 35px;
      max-width: 35px;
      min-width: 35px;
      display: inline-block;
      font-weight: bold;
    }

    .bold-line {
      border-top: 2px solid;
    }

    .truckHolder {
      .float-left {
        float: left;
      }

      margin-bottom: 5px;

      .teamMembers {
        .teamMemberBadge {
          display: flex;
          align-items: center;

          .icon {
            margin-right: 8px;
          }
        }

        &:last-child {
          margin-bottom: 16px;
        }

        border-bottom: 1px;
        border-color: lightgray;
        border-style: solid;
      }

      .rentalTransportActions {
        @media screen and (min-width: $desktop) {
          margin-left: 5%;
          visibility: hidden;
        }
      }

      &:hover .rentalTransportActions {
        visibility: visible;
      }
    }

    .truck-heading {
      margin-bottom: 0;
      text-decoration-color: white !important;

      .gear-icon {
        display: none;
      }

      &:hover .gear-icon {
        display: block;
      }
    }

    .assigned {
      h2 {
        color: $blue;
      }

      a {
        &:first-of-type {
          border-top: 2px solid $blue;
        }

        strong {
          color: $blue;
        }
      }
    }

    .assigned-truck {
      h2 {
        color: $blue;
      }

      a {
        strong {
          color: $blue;
        }
      }
    }

    .unassigned {
      h4 {
        color: $orange;
        border-bottom: 2px solid $orange;
      }

      h2 {
        color: $orange;
      }

      a {
        strong {
          color: $orange;
        }
      }
    }
  }
}

#finalStop {
  .details {
    top: -5px;
  }
}

.rentalDrawer {
  & > .header {
    &.orange {
      background-color: $orange;
    }

    &.blue {
      background-color: $blue;
    }
  }

  .contents {
    .routingAssignment {
      & > div {
        margin-top: 20px;
        border-top: 1px solid $meddarkgrey;
        // @include breakpoint($tablet) {
        //   padding-left: 10px;
        // }

        .title {
          line-height: 40px;
          font-weight: $bold;

          .time-range {
            float: right;

            span {
              font-size: small;
            }
          }

          svg {
            margin: 0 5px;
            vertical-align: middle;
          }

          span {
            font-size: 10px;
          }
        }

        .truck {
          margin-bottom: 20px;

          .truck-info {
            margin-left: 10px;

            .truck-svg {
              vertical-align: middle;
              margin-right: 7px;
              height: 20px !important;
              width: 20px !important;
            }

            .truck-name {
              font-weight: bold;
            }
          }

          & > div {
            line-height: 30px;

            a:not(.btnLink) {
              display: inline-block;
              margin-left: 5px;
              font-size: 10px;
              text-decoration: underline;
            }
          }

          .driver {
            line-height: 3.4;
            margin-left: 7px;

            & > svg {
              height: 14px;
              width: 14px;
              margin-right: 5px;
              fill: $blue;
              vertical-align: middle;
            }

            select {
              margin-top: 5px !important;
              max-width: 100px;
              right: -30px;
              margin: 0px;
              float: right;
            }

            &:last-of-type() {
              margin-bottom: 5px !important;
            }
          }

          .toggle {
            width: 100%;
            background-color: $lightgrey;
            color: $darkblue;
          }
        }
      }
    }

    .setByTime {
      margin-bottom: 20px;

      label:nth-child(2) {
        width: auto;
        margin-left: 33px;
      }

      label:nth-child(3) {
        width: auto;
        margin-left: 19px;
      }
    }
  }
}

.routeDrawer {
  .contents {
    padding: 15px;
    border-top: 0px solid white !important;

    .btnIcon svg {
      fill: $green;
    }

    .start {
      margin: 46px 0px 10px 0px;

      label {
        display: inline-block;
        line-height: 21px;
        font-size: 10px;
        opacity: 0.6;
        width: 20%;
        margin: 5px 15px 0 10px;

      }

      .rc-time-picker {
        width: 66%;
      }
    }

    .accordian:not(:first-child) {
      border-top: 0px solid rgba(193, 205, 215, 0.5);
    }

    .accordian {
      min-width: 100%;
      border-top: 1px solid rgba(193, 205, 215, 0.5);

      .btnExpand,
      .btnCollapse,
      & > .btnLink {
        position: relative;
        cursor: pointer;
        z-index: 1;
      }

      .btnExpand,
      .btnCollapse {
        margin-right: 2px;
      }

      & > .btnLink {
        margin-top: 12px;
      }

      .header {
        position: relative;
        height: auto;
        padding: 10px 5px 10px 10px;
        line-height: 18px;

        & > div {
          text-overflow: initial;
          padding-top: 10px;
          padding-left: 5px;
        }

        .sortArrows {
          position: absolute;
          top: 0;
          left: -15px;
          height: 40px;
          width: 12px;

          .sortUp,
          .sortDown {
            position: absolute;
            border: 6px solid transparent;
            cursor: pointer;
            height: 12px;
            width: 12px;
            text-indent: -1000px;

            svg {
              position: absolute;
              fill: $darkblue;
            }

            &:hover svg {
              fill: $darkblueHover;
            }
          }

          .sortUp {
            top: 6px;
            border-bottom: 6px solid $darkblue;
          }

          .sortDown {
            bottom: 2px;
            border-top: 6px solid $darkblue;
          }
        }

        .marker {
          height: 27px !important;
          width: 15px !important;
          margin-right: 5px;
          font-size: 9px;
          line-height: 15px;
          vertical-align: top;

          svg {
            height: 30px !important;
            width: 30px !important;
            top: -3px;
            left: -7px;
          }

          &.stop {
            height: 35px !important;
            width: 30px !important;

            svg {
              height: 35px !important;
              width: 30px !important;
            }
          }
        }

        .details {
          display: inline-block;
          position: relative;

          strong,
          label {
            display: block;
            width: auto;
            font-size: 10px;
            color: $darkblue;
          }

          strong {
            font-size: 12px !important;
            font-weight: 400;
            margin-left: 15px;
          }

          label {
            color: black;
            opacity: 0.5;
            margin-left: 5px;
            font-size: 10px;
          }

          .highlight {
            color: black;
            margin: 5px 5px 0 10px;
            font-size: 10px;
          }

          .routeTerminal {
            display: flex;
            margin-left: 34px;
          }

          .routeTerminalType {
            margin-left: 12px;
            font-size: 10px;
          }

          .routeTimeArea {
            margin-left: 34px;
          }
        }
      }

      .content {
        padding: 0 0 10px 10px;

        .details {
          @include breakpoint($tablet) {
            display: grid;
            grid-template-columns: 3fr 2fr;

            h4 {
              grid-column: 1;
            }

            .btnLink {
              grid-column: 2;
              place-self: start end;
            }

            .routeContact {
              h4 {
                margin-bottom: 0;
              }

              .caption {
                color: $blue;
                margin-left: 0;
              }
            }

            .routeContact,
            .routeItems {
              grid-column: span 2;
              margin-top: 25px;
            }

            .rentalName {
              height: 29px;
              font-size: 16px;
              line-height: 24px;
            }

            .routeSetByTime {
              grid-column: span 2;
              display: grid;
              margin-bottom: 20px;

              label:nth-child(2) {
                width: auto;
                margin-left: 33px;
              }

              label:nth-child(3) {
                width: auto;
                margin-left: 19px;
              }
            }
          }
        }

        .setup {
          select {
            margin-right: 5px;
            margin-left: 10px;
            width: 150px;
          }

          label {
            font-size: 10px;
            line-height: 30px;
            display: inline-block;
            background-color: #e5e8e9;
            width: 80px;
            padding-left: 10px;
            color: gray;
          }

          .btnLink {
            font-size: 10px;
            font-weight: $regular;
            color: $darkgrey;
            text-transform: none;
          }

          .transportTime {
            margin: 12px;
          }

          .transportType {
            margin: 43px;
          }
        }

        .actions {
          width: 100%;
        }

        #ordersHeaderContainer {
          display: flex;
          line-height: 1em;

          #headerStopsContainer {
            width: 30%;
            display: flex;
            justify-content: flex-end;
          }

          #headerRentalTokenContainer {
            text-decoration: underline;
            margin-right: 5px;
          }
        }

        .rentalInfoToken {
          display: flex;
          align-items: center;

          &:hover {
            color: $blue;
          }
        }

        #ordersContentLeftColumn {
          display: flex;

          #companyContainer {
            margin-left: 30px;
          }
        }

        #allCustomersContainer, #companyContainer {
          width: 100%;
          .rentalCustomer {
            width: 90%;
            word-wrap: break-word;
          }
        }
        #customerContainer,
        #companyContainer {
          display: flex;
          flex-direction: column;
          color: #466b88;
          margin-bottom: 20px;

          div:first-child() {
            font-weight: bold;
            color: black;
          }

          div {
            width: 50%;
            height: auto;
            line-height: normal;
            word-wrap: break-word;
          }
        }

        #removeStop {
          font-weight: 400;
          font-size: 10px;
          opacity: 0.4;

          &:hover svg {
            opacity: 1;
            fill: crimson;
          }
        }

        #removeStop:hover {
          opacity: 1;
        }
      }
    }

    .carratAcc {
      &.wrap {
        @media screen and (max-width: $tablet) {
          &.cell-container {
            height: 120px;
          }
        }
      }

      display: flex;

      svg {
        margin-right: 5px;
      }

      .cell.main {
        display: flex;
        font-weight: 600;

        div {
          margin-right: 5px;
        }

        div:not(:first-child) {
          color: black;
        }
      }
    }

    table {
      margin-bottom: 30px;
      margin-left: 10px;

      tr {
        display: flex;
        margin-bottom: 10px;
      }

      .driver {
        .userIcon {
          width: 13px;
        }

        #deliveryRole {
          font-weight: bold;
          padding-left: 10px;
          width: 90px;

          @media (max-width: $tablet) {
            width: 150px;
          }
        }
      }
    }
  }

  .drawer.open.withForm {
    padding-top: 200px;

    .linkBack {
      top: -250px;
      left: -10px;
      position: relative;
      color: $blue;
    }

    .assignFieldContainer {
      display: flex;
      flex-direction: column;
    }

    .assignTruckDrawerContainer {
      width: 49% !important;
    }

    .drawerForm {
      width: 0 !important;
    }

    .drawer {
      border-radius: 0px;
      top: -20px;
      height: calc(100% - 99.7px);
      width: 375px;
      position: fixed;
      left: 375px;
      overflow: auto;
      transform: translate(0px, 0px);
      margin: 120px 10px 0 0;

      @media (max-width: $tablet) {
        height: calc(100% - 119.7px) !important;
      }
    }

    @media (max-width: $tablet) {
      right: 0px !important;
      padding: 200px 0 0 20px;
      width: 100% !important;

      .linkBack {
        top: -280px;
      }

      .actions {
        width: calc(100% - 40px);
      }

      .assignTruckDrawerContainer {
        width: 100% !important;
      }

      .drawer {
        width: 100% !important;
        // padding-left: 20px;
      }
    }
  }

  #truckAssignmentContainer {
    svg {
      margin: 40px 0 0 335px;

      @media (max-width: $tablet) {
        margin: 43px 0 0 338px;
      }
    }
  }
}

.directionsDrawer {
  @media (max-width: 769px) {
    z-index: 1;
  }

  & > .header {
    & > * {
      &:nth-child(2) {
        background-color: $blue;
        color: $white;

        svg {
          fill: $white;
        }
      }
    }
  }

  .contents {
    padding: 0;

    .scrollarea {
      height: 460px;
      padding-right: 0;
    }
  }

  .drawer.open.withForm {
    padding-top: 200px;
    right: 400px !important;
    width: 425px !important;

    .linkBack {
      top: -180px;
      left: -10px;
      position: relative;
    }

    .assignFieldContainer {
      display: flex;
      flex-direction: column;
    }

    .assignTruckDrawerContainer {
      width: 100% !important;
    }

    @media (max-width: $tablet) {
      right: 0px !important;
      padding: 200px 0 0 20px;

      .linkBack {
        top: -280px;
      }

      .actions {
        width: calc(100% - 40px);
      }
    }
  }
}

.printDrawer {
  @media (max-width: 769px) {
    z-index: 1;
  }

  .contents {
    padding: 20px;

    .printLinks {
      padding-left: 10px;

      & > div {
        @include container;
        padding-bottom: 10px;
        height: 40px;
      }

      div:not(:last-child) {
        border-bottom: 1px solid $meddarkgrey;

        * {
          border-bottom: 0px solid transparent;
        }
      }

      label {
        font-weight: $bold;
        line-height: 30px;
      }

      .btn {
        float: right;
        width: auto;
        padding: 0 10px;
        margin: 0;
      }

      .checkListItem {
        padding-left: 0 !important;
        display: flex !important;
        font-size: 12px !important;
        font-weight: 400;

        @media (max-width: 769px) {
          white-space: initial;
        }

        .check {
          margin-right: 5px;
        }

        div {
          @media (max-width: 769px) {
            font-size: 16px;
          }
        }
      }
    }

    .custom-stop {
      display: grid;
      grid-template-columns: 1fr 2fr 5fr;
      border-top: 1px solid #c1cdd7;
      padding-left: 10px;
      font-weight: $bold;
      line-height: 37px;

      svg {
        margin-top: 4px;
        margin-bottom: 4px;
      }
    }

    .accordian {
      border-top: 1px solid $meddarkgrey;

      .header-title {
        display: grid;
        grid-template-columns: 1fr 2fr 5fr;
        margin-top: 4px;
      }

      .header {
        padding-left: 10px;
        font-weight: $bold;
      }

      .content {
        padding: 0 0 0 10px;

        .printLinks {
          padding-left: 0;
        }
      }
    }

    .truck-routes {
      .header-title {
        display: grid;
        grid-template-columns: 1fr 1fr 6fr;
        margin-top: 4px;
      }
    }
  }

  .drawer.open.withForm {
    padding-top: 200px;
    right: 400px !important;
    width: 425px !important;

    .linkBack {
      top: -180px;
      left: -10px;
      position: relative;
    }

    .assignFieldContainer {
      display: flex;
      flex-direction: column;
    }

    .assignTruckDrawerContainer {
      width: 100% !important;
    }

    @media (max-width: $tablet) {
      right: 0px !important;
      padding: 200px 0 0 20px;

      .linkBack {
        top: -280px;
      }

      .actions {
        width: calc(100% - 40px);
      }
    }
  }
}

.locationDetailsArea {
  display: flex;
  margin-bottom: 15px;

  .addressDetails {
    margin-left: 15px;
    margin-top: 27px;
  }
}

.directions {
  padding: 20px;

  .header {
    padding: 0 !important;
  }

  li {
    .start,
    .end {
      position: relative;
      padding: 10px 0 10px 30px;
      font-size: 16px;
      line-height: 20px;

      .marker {
        position: absolute;
        top: 5px;
        left: 0;
      }
    }

    .start {
      margin: 0 0 10px;
      border-bottom: 1px solid $meddarkgrey;
    }

    .end {
    }

    .details {
      position: absolute;
      bottom: -8px;
      left: 0;
      padding-right: 5px;
      background-color: $white;
      font-size: 10px;
      line-height: 14px;
      color: $darkgrey;
    }

    ul {
      li {
        position: relative;
        padding: 10px 0;
        margin: 0 0 10px 30px;

        .instructions {
          & > div {
            font-size: 10px !important;
            color: $darkgrey;
          }
        }

        .details {
        }

        .icon {
          position: absolute;
          top: 7px;
          left: -30px;
          height: 16px;
          width: 16px;
          background: transparent url('../../../images/icons/maneuvers.png')
            no-repeat left top;

          &.noManeuver {
            background-image: none;
          }

          &.ferry {
            background-position: 0 -614px;
          }

          &.ferry-train {
            background-position: 0 -566px;
          }

          &.merge {
            background-position: 0 -143px;
          }

          &.straight {
            background-position: 0 -534px;
          }

          &.fork-left {
            background-position: 0 -550px;
          }

          &.ramp-left {
            background-position: 0 -598px;
          }

          &.roundabout-left {
            background-position: 0 -197px;
          }

          &.turn-left,
          &.keep-left {
            background-position: 0 -413px;
          }

          &.turn-sharp-left {
            background-position: 0 0;
          }

          &.turn-slight-left {
            background-position: 0 -378px;
          }

          &.uturn-left {
            background-position: 0 -305px;
          }

          &.fork-right {
            background-position: 0 -499px;
          }

          &.ramp-right {
            background-position: 0 -429px;
          }

          &.roundabout-right {
            background-position: 0 -232px;
          }

          &.turn-right,
          &.keep-right {
            background-position: 0 -483px;
          }

          &.turn-sharp-right {
            background-position: 0 -582px;
          }

          &.turn-slight-right {
            background-position: 0 -51px;
          }

          &.uturn-right {
            background-position: 0 -35px;
          }
        }
      }
    }
  }

  .content {
    padding: 0px !important;

    hr {
      margin: 0 -20px;
    }
  }

  .totalDistance {
    margin: 0px !important;
  }

  .directionsInstructions {
    margin-top: 22px;
    text-align: left;
    height: 48px;
    width: 311px;
    line-height: 12px;
    font-size: 9px;
    color: #7194ac;
  }

  .directionLabel {
    margin-top: 40px;
  }

  .accordian {
    .btnExpand,
    .btnCollapse {
      right: 0;
      position: absolute;
      margin-right: 10px;
    }
  }
}

.printDirectionAccordionBtn {
  .btnExpand,
  .btnCollapse {
    float: left !important;
  }
}

.marker {
  position: relative;
  display: inline-block;
  height: 30px;
  width: 20px;
  font-size: 10px;
  font-weight: $bold;
  line-height: 20px;
  color: $white;
  text-align: center;
  z-index: 0;
  margin-right: 10px;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px !important;
    width: 21px !important;
    fill: $orange;
    z-index: -1;
  }

  &.assigned svg {
    fill: $blue;
  }

  &.assigned-truck svg {
    fill: $blue;
  }

  &.warehouse svg {
    fill: $purple;
  }

  &.route svg {
    fill: $darkblue;
  }

  &.stop {
    height: 20px;

    svg {
      height: 20px;
      fill: $red;
    }
  }

  &.overlapped {
    width: 32px;
    padding-right: 12px;

    svg {
      width: 32px;
    }
  }

  &.unassignedMarker {
    z-index: 10 !important;
  }
}

.multi-map-marker {
  width: 25px;
  height: 25px;
}

.stopModal {
  p:first-of-type() {
    font-weight: $bold;
  }

  label {
    display: inline-block;
    width: 60px;
  }
}

@media print {
  @page {
    margin: 5mm;
    size: auto;
  }

  .routingPrintWrapper {
    background-color: white;
    width: 100%;
    height: auto !important;
    margin: 0 !important;
    padding: 0 !important;
    page-break-before: auto;
    page-break-after: auto;

    float: none;
    position: static;
    overflow: visible;
  }

  .stopContainer {
    page-break-inside: avoid;
  }
}

.routingPrintWrapper {
  background-color: $white;
  height: fit-content !important;

  .content {
    padding: 40px span(1 of 20) 200px;
    display: flex;
    flex-direction: column;
  }

  .mapHeader {
    display: none;
  }

  .mapDrawer {
    .contents {
      @include push(1 of 9);
      @include span(7 of 9);
      @include post(1 of 9);
      display: contents;
      font-size: 15px;
    }
  }

  .routeDrawer .contents {
    .btnExpand,
    .btnCollapse,
    .btnLink {
      display: none;
    }
  }

  .directionsDrawer .contents {
    .scrollarea {
      height: auto;
    }
  }

  .mapContainer {
    width: 100%;

    .map {
      position: inherit;
      height: 800px;
      width: 100%;
    }

    * {
      display: block !important;
    }
  }
}

.scheduleDrawer {
  .accordian {
    .header {
      padding: 0;

      & > div {
        span {
          @media screen and (min-width: $desktop) {
            display: inline-block;
          }
        }
      }

      @include breakpoint($phone) {
        .drop-off {
          display: none;
        }
      }

      @include breakpoint($tablet) {
        .drop-off {
          display: inline-block;
        }
      }
    }
  }

  .detailsContents {
    padding: 0;

    .dragHandle {
      position: relative;
      top: -13px;
      left: -8px;
    }
  }
}

.routingOrdersOrTrucksRentalTokenLink {
  text-decoration: underline;
}

.dispatchSummaryPrintWrapper {
  background-color: $white;

  .content {
    @include breakpoint($tablet) {
      padding: 40px span(1 of 20) 200px;
    }
  }

  .header {
    width: 50%;

    h1 {
      text-transform: none;
      font-size: 2.5rem;
    }
  }

  .dispatchTruckDetails {
    margin-top: 60px;

    h2 {
      font-size: 1.5rem;
    }

    span {
      float: right;
    }

    .dispatchSummaryTable {
      margin-bottom: 60px;

      table {
        margin-top: 30px;
        margin-bottom: 90px;
        width: 100%;

        .dispatchTransport {
          line-height: 4rem;
          margin-bottom: 12px;
          color: #000;

          th {
            font-size: 1.5rem;
            font-weight: bold;
          }
        }

        .dispatchHeaders {
          border-bottom: 2px solid lightgrey;
          line-height: 1.5rem;

          th {
            font-size: 1.1rem;
          }
        }
      }

      .dispatchRow {
        border-bottom: 1px solid lightgrey;
        height: 2rem;

        td {
          font-size: 1rem;
          line-height: 20px;

          &.filled {
            padding-right: 10px;
          }

          &.empty {
            border-left: 1px solid lightgrey;
          }
        }
      }
    }

    .printSignature {
      width: 100%;
      font-size: 1.2rem;

      label {
        display: inline-block;
      }

      p {
        color: lightgrey;
        font-weight: lighter;
        font-size: 0.9rem;
        margin-top: 8px;
      }

      .signatureTitle {
        margin-right: 20px;
        font-weight: bold;
        color: $blue;
        font-size: 1.4rem;
        vertical-align: text-top;
        width: 10%;
      }

      .signatureLine {
        border-top: 3px solid #278ada;
        margin-right: 20px;
        line-height: 2rem;
      }
    }
  }
}

.deliveryManifestPrintWrapper {
  .drivers,
  .routes {
    color: $blue;
    font-family: Heebo;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;

    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid $blue;

    ul {
      display: inline-grid;
    }
  }

  .routes {
    margin-top: 60px;
  }

  .content {
    width: 794px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint($tablet) {
      padding: 40px 70px 200px;
    }
  }

  .header {
    width: 50%;

    h1 {
      text-transform: none;
      font-size: 2.5rem;
    }
  }

  table {
    width: 100%;
    color: $blue;
    font-family: Heebo;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
  }

  table.driverTable tr td:nth-child(odd) {
    padding-left: 20px;
    width: 10%;
  }

  table.driverTable tr td:first-of-type {
    padding-left: 0;
    width: 20%;
  }

  .noteRow {
    td {
      padding-bottom: 10px;
    }
  }

  .blueFont {
    color: $blue !important;
  }

  .mt-15 {
    padding-top: 15px;
  }

  tr.blueFont td {
    color: $blue;
  }

  tr.mt-27 td {
    padding-top: 27px;
  }

  tr.va-bottom td {
    color: $blue;
  }

  .blankSpace {
    width: 100px;
    border-bottom: 1px solid black;
  }

  .va-bottom {
    vertical-align: bottom;
  }

  .va-top {
    vertical-align: top;
  }

  tr.routesHeader td {
    padding: 10px 0px;
    border-bottom: 1px solid #c1cdd7;
  }

  .grayRuler {
    border-bottom: 1px solid #c1cdd7;
  }

  .bodyPadding tr:first-child td {
    padding: 10px 5px;
  }

  .bodyPadding tr:last-child td {
    padding-bottom: 24px;
  }

  .routesCell tr td {
    color: #0e2c44;
    font-family: Heebo;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
  }
}

.radioRow {
  display: flex;
  flex-direction: row;
}

.sortRow {
  color: $darkblue;
  font-family: Heebo;
  font-size: 9px;
  letter-spacing: 0;
  line-height: 13px;
  padding-left: 10px;
}

.alignLeft {
  display: flex;
  justify-content: flex-start;
}

.alignLeft .radio div div {
  margin: 2px 0px 0px 12px !important;
}

.routingHeader {
  display: flex;
  justify-content: space-between;
  height: 40px;

  @media only screen and (min-width: $desktop) {
    border-bottom: 1px solid $meddarkgrey;
  }

  margin-bottom: 10px;

  .print {
    cursor: pointer;
  }
}

.full-expand {
  width: 100% !important;
}

.dispatchDeliveryManifestWrapper {
  background-color: $white;
  max-width: 1000px;
  margin-left: 25%;

  .bold {
    font-weight: bold;
  }

  .blue {
    color: $blue;
  }

  .content {
    @include breakpoint($tablet) {
      padding: 40px span(1 of 20) 200px;
    }
  }

  h2.rentalName {
    color: $darkblue;
    font-family: Heebo;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 27px;
    padding-top: 23px;
  }

  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    h1 {
      text-transform: none;
      font-size: 2.5rem;
    }

    .routeDate {
      color: $darkblue;
      font-family: Heebo;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 14px;
    }
  }

  .subHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #00744c;

    p {
      color: #00744c;
      font-family: Heebo;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
      display: flex;
      align-items: center;
    }
  }

  .deliveryInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;

    color: $darkblue;
    font-family: Heebo;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
  }

  .fs-12 {
    color: #7d98b0;
    font-family: Heebo;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .pt-10 {
    padding-top: 10px;
  }

  .itemsTable td.lp-20 {
    padding-left: 20px;
  }

  .itemsTable {
    padding-bottom: 23px;
  }

  .newClass {
    color: $darkblue;
    font-family: Heebo;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
  }

  .dispatchTruckDetails {
    margin-top: 60px;

    h2 {
      font-size: 1.5rem;
    }

    span {
      float: right;
    }

    .dispatchSummaryTable {
      margin-bottom: 60px;

      table {
        margin-top: 30px;
        margin-bottom: 90px;
        width: 100%;

        .dispatchTransport {
          line-height: 4rem;
          margin-bottom: 12px;
          color: #000;

          th {
            font-size: 1.5rem;
            font-weight: bold;
          }
        }

        .dispatchHeaders {
          border-bottom: 2px solid lightgrey;
          line-height: 1.5rem;

          th {
            font-size: 1.1rem;
          }
        }
      }

      .dispatchRow {
        border-bottom: 1px solid lightgrey;
        height: 2rem;

        td {
          font-size: 1rem;
          line-height: 20px;

          &.filled {
            padding-right: 10px;
          }

          &.empty {
            border-left: 1px solid lightgrey;
          }
        }
      }
    }

    .printSignature {
      width: 100%;
      font-size: 1.2rem;

      label {
        display: inline-block;
      }

      p {
        color: lightgrey;
        font-weight: lighter;
        font-size: 0.9rem;
        margin-top: 8px;
      }

      .signatureTitle {
        margin-right: 20px;
        font-weight: bold;
        color: $blue;
        font-size: 1.4rem;
        vertical-align: text-top;
        width: 10%;
      }

      .signatureLine {
        border-top: 3px solid #278ada;
        margin-right: 20px;
        line-height: 2rem;
      }
    }
  }
}

.eventRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bottomLine {
  border-bottom: 1px solid #7d98b0;
}

.whiteBackground {
  background-color: white;
  margin-left: 0px;
}

.revenueDay {
  background-color: $white;
  padding: 15px;
}

.mapActions {
  @media only screen and (max-width: $tablet) {
    display: inline-flex !important;
  }

  .icons {
    display: inline-flex;
  }
}

.routeDetailsLink {
  float: right;
  font-size: 12px;

  a {
    color: $white !important;
  }
}

.routeDetailsMiles {
  float: right;
  color: $white;
  opacity: 50%;
}
