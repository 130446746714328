.notifications {
  display: grid;
  @include breakpoint($tablet) {
    grid-template: 40px 1fr / 1fr 1fr;
    grid-row-gap: 40px;
  }

  .subrentals_all h1 {
    display: none;
  }

  h1 {
    margin: 0;

    .headerNav {
      @include breakpoint($tablet) {
        border-left: 1px solid $cobalt;
      }

      a {
        color: $darkgrey;

        .dot {
          display: inline-block;
          padding: 0 5px;
          margin-right: 5px;
          background-color: $blue;
          @include border-radius(12px);
          font-size: 10px;
          font-weight: $regular;
          line-height: 16px;
          color: $white;
          text-align: center;
          vertical-align: middle;
        }

        &.selected {
          color: $white;
        }
      }
    }
  }
  .drawerFooter.actions {
    display: grid;
  }
  .actions {
    display: none;
    @include breakpoint($tablet) {
      place-self: center end;
      display: flex;
    }

    .btnIcon {
      width: 110px;
      margin: 0 0 0 5px;
      color: $white;

      svg {
        height: 17px !important;
        width: 18px !important;
      }

      &.outline {
        background-color: transparent;
        border: 2px solid $blue;
        color: $blue;
        line-height: 26px;

        &:hover {
          background-color: $blue;
          color: $white;
        }
      }
      &:not(.add) svg {
        fill: $white;
      }

      &.small {
        width: 30px;

        svg {
          margin: 0;
        }
      }
      &.disabled {
        cursor: not-allowed;
        filter: opacity(0.3);
        user-select: none;
      }
    }
    .divider {
      place-self: center;
      width: 2px;
      height: 30px;
      margin: 0 14px 0 19px;
      background-color: $blue;
    }
  }
  .events,
  .inventory,
  .payments {
    @include breakpoint($tablet) {
      grid-column: 1 / span 2;
      display: grid;
      grid-template-columns: 2fr 10fr;
    }
  }
  @include breakpoint($tablet) {
    .inventory {
      .legend {
        .callout {
          grid-column: 1 / span 2;
        }
      }
    }
  }

  .nav {
    @include breakpoint($tablet) {
      grid-column: 1;
      grid-row: 1;
    }

    .itemList {
      margin: 0;

      li {
        height: 40px;
        border-top: none;
        border-bottom: none;

        a {
          padding-left: 5px;
          color: $darkgrey;

          .dot {
            position: absolute;
            right: 20px;
          }
        }

        &.selected {
          background-color: transparent;
          font-weight: $bold;

          a {
            color: $white;
          }
        }
        &:last-of-type {
          margin: 0 0 40px;
        }
      }
    }
    select {
      width: 100%;
      background-color: $navyblue;
      background-image: url('../../../images/icons/ArrowDown-Blue.png');
      color: $darkgrey;
    }
  }
  .details,
  .detailsNoCal,
  .rentalList,
  .productList {
    @include breakpoint($tablet) {
      grid-column: 2;
      align-self: start;
    }

    .btnLink {
      display: none;
    }
    .filter .btnLink {
      display: inline-block;
    }
    .paymentMethod {
      white-space: break-spaces;
    }

    .recurringPaymentFailureAlert {
      margin: 20px;

      svg {
        height: 16px;
        width: 21px;
        fill: $orange;
      }
    }

    section {
      display: flex;
      flex-direction: column;
      @include breakpoint($tablet) {
        min-height: 450px;
        width: 100%;
      }

      .gridWrapper,
      .spinner {
        flex: 1;
      }
    }
  }
  .detailsNoCal {
    .documentFilter {
      .btnIcon,
      .numberSelect {
        float: right;
      }
      .flexDrawer {
        background-color: #0e2c44 !important;
        h2 {
          color: #278ada;
          display: inline;
        }
        .dateSelect {
          margin-top: 20px;
        }
        .accordian {
          padding: 0 10px;
          background-color: #0e2c44 !important;
          .btnExpand {
            svg {
              fill: #278ada;
            }
          }
          .content {
            color: #ffffff;
            padding: 0 0 0 30px;
            .check {
              display: block;
              padding-top: 20px;
            }
          }
          .header {
            div {
              label {
                color: #ffffff;
              }
            }
          }
          .check {
            label {
              margin-left: 10px;
            }
            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
  .details {
    display: grid;
    @include breakpoint($tablet) {
      grid-template: minmax(40px, auto) auto / 3fr minmax(0, 8fr);
    }

    .filter {
      display: none;
      @include breakpoint($tablet) {
        grid-column: 2;
        grid-row: 1;
        display: block;
      }
    }
    .calendar {
      @include breakpoint($tablet) {
        grid-column: 1;
        grid-row: 1 / span 2;
        align-self: start;
      }
    }
    .items,
    .rentalList {
      @include breakpoint($tablet) {
        grid-column: 2;
        grid-row: 2;
      }
    }
  }

  .calendarToggle {
    padding: 20px 10px;
    margin-bottom: 20px;
    background-color: $navyblue;
    font-size: 20px;
    line-height: 20px;
    color: $white;

    svg {
      fill: $blue;
      vertical-align: middle;

      &:first-of-type() {
        height: 20px !important;
        width: 20px !important;
        margin-right: 10px;
      }
      &:last-of-type() {
        float: right;
        margin-top: 5px;
      }
    }

    & + .calendar {
      margin-top: -20px;
    }
  }
  .calendar {
    padding: 0 20px 20px;
    background-color: $navyblue;
    @include breakpoint($tablet) {
      height: 340px;
      padding: 0 30px 0 25px;
    }

    .r-calendar {
      min-width: auto;

      .r-head {
        margin-bottom: 10px;
        line-height: 40px;

        .r-prev,
        .r-next {
          @include span(1 of 7);
          margin: 0;

          svg {
            fill: $white;
            vertical-align: middle;
          }
          &:focus {
            outline: none;
          }
        }
        .r-prev {
          padding-right: span(1 of 54);
        }
        .r-next {
          padding-left: span(1 of 54);
        }
        .r-title {
          @include span(5 of 7);
          margin: 0;
          font-size: 12px;
          color: $blue;
          text-transform: uppercase;
        }
      }
      .r-weekdays {
        line-height: 22px;
        color: $blue;
      }
      .r-dates {
        font-size: 10px;
        line-height: 10px;
      }
      .r-cell {
        width: span(1 of 9);
        margin-left: span(1 of 54);
        margin-right: span(1 of 54);

        &.r-date.r-in-out {
          border: 2px solid $blue;
        }
      }
    }
  }
  .pagerWrapper .pager {
    li {
      svg {
        fill: $blue;
      }
      &.disabled {
        svg {
          fill: $cobalt;
        }
      }
    }
    div {
      color: $darkgrey;
    }
    input[type='text'] {
      background-color: $blue !important;
    }
    span {
      color: $white;
    }
  }
  .documentList {
    .grid {
      .rTableRow {
        .rTableCell {
          vertical-align: middle !important;
          border-top: 1px solid #c1cdd7 !important;
          height: 40px !important;
          &.documentExpired {
            &:before {
              content: ' ';
              width: 6px;
              height: 40px;
              position: absolute;
              background-color: $orange;
              top: 0;
              left: -26px;
            }
            overflow: visible;
          }
        }
      }
    }
  }
  .items {
    h1 {
      display: none;
    }
    .grid {
      .rTableRow,
      tr {
        &.new,
        &.alert,
        &.ready,
        &.expiring {
          .rTableCell:first-child(),
          td:first-child() {
            position: relative;
            overflow: visible;

            &:before {
              content: ' ';
              position: absolute;
              top: 0;
              bottom: 0;
              left: -23px;
              right: calc(100% + 20px);
              cursor: default;
            }
          }
        }
        &.new {
          .rTableCell:first-child():before,
          td:first-child():before {
            background-color: $pink;
          }
        }
        &.ready {
          .rTableCell:first-child():before,
          td:first-child():before {
            background-color: $green;
          }
        }
        &.expiring {
          .rTableCell:first-child():before,
          td:first-child():before {
            background-color: $red;
          }
        }
        &.alert {
          .rTableCell:first-child():before,
          td:first-child():before {
            background-color: $orange;
          }
        }

        .pickUp svg {
          fill: #8fa9be;
        }
        .Full,
        .Fulfilled {
          color: $green;
        }
        .Partial {
          color: $orange;
        }
        .None,
        .Unfulfilled {
          color: $red;
        }
      }
    }

    .alerts,
    .checking_out,
    .checking_in {
      .rTableCell,
      td {
        &.highlight {
          color: $purple !important;

          svg {
            height: 10px !important;
            width: 10px !important;
            margin-right: 5px;
            fill: $purple !important;
            vertical-align: middle;
          }
        }

        &.alert,
        &.none,
        &.partial,
        &.full {
          padding-left: 0 !important;
          text-align: center;

          svg {
            height: 18px !important;
            width: 19px !important;
          }
        }
        &.alert svg {
          fill: $orange;
        }
        &.none svg {
          color: $lightgrey;
          fill: $lightgrey;
        }
        &.partial svg {
          color: $lightgrey;
          fill: $purple;
        }
        &.full svg {
          color: $green;
          fill: $green;
        }
      }
    }
  }
  .legend {
    display: none;
    @include breakpoint($tablet) {
      grid-column: 1;
      grid-row: 1;
      place-self: start stretch;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 5px;
      padding-right: 20px;
    }

    .callout {
      background-color: $pink;
      @include border-radius(10px);
      font-size: 10px;
      line-height: 20px;
      color: $white;
      text-align: center;

      &.alert {
        background-color: $orange;
      }
      &.ready {
        background-color: $green;
      }
      &.expiring {
        background-color: $red;
      }
    }
    .key {
      grid-column: 1 / span 2;
      font-size: 12px;
      line-height: 30px;
      color: $darkgrey;

      svg {
        height: 15px !important;
        width: 15px !important;
        margin-right: 5px;
        fill: $orange;
        vertical-align: middle;
      }

      &.none svg {
        color: $cobalt;
        fill: $cobalt;
      }
      &.partial svg {
        color: $cobalt;
        fill: $purple;
      }
      &.full svg {
        color: $green;
        fill: $green;
      }
    }
  }
}

#storefrontPlusAd {
  display: table-row-group;
  position: absolute;
  bottom: 10px;
  width: calc(100% - 40px);
  img {
    width: 100%;
    height: 100%;
  }
  @include breakpoint($phone $tablet) {
    display: none !important;
  }
}

#championsAd,
#barcodeAd,
#capitalAd,
.adCarousel {
  @include breakpoint($phone $tablet) {
    display: none !important;
  }
}

#championsAd {
  margin-top: 10px;
  display: inline-block;
}

#barcodeAd {
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
}

#upgradeSfAd {
  display: block;
  width: 162px;
  background: #071827;
  text-align: left;
  padding: 20px 0;

  img {
    width: 100%;
  }

  div {
    padding: 0 10px;
    margin: 10px 0;
    font-size: 11px;

    &.header {
      color: #278ada;
      font-size: 20px;
      font-weight: bold;
      line-height: 22px;
    }
    color: #ffffff;
    a {
      text-decoration: underline;
    }
  }
}

#capitalAd {
  margin-top: 10px;
  display: inline-block;
}

.adCarousel {
  .slider-wrapper {
    li {
      background: transparent !important;

      img {
        object-fit: contain;
        margin-bottom: 35px;
      }
    }
  }
  padding: 10px 10px 10px 0;
}
