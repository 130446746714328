.disableOverlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.25;
  background-color: $darkblue;
  pointer-events: none;
  cursor: none;
  z-index: 25;
}

.disableOverlayContent {
  pointer-events: none !important;
  cursor: none !important;
}
