#StatementPaymentHistory {
  padding: 9px;
  .accorion {
    .header {
      border-bottom: 1px solid $cobalt;
    }
    .content {
      .transaction-history-box {
        background: $white;
        padding: 15px;
        margin-bottom: 6px;
        border-radius: 8px;
        .payment-breakdown-box {
          padding: 9px;
          border: 1px solid $cobalt;
        }
        .overpayment-breakdown-box {
          margin-top: 6px;
          padding: 9px;
          border: 1px solid $red;
          background-color: rgba(255, 0, 0, 0.25) !important;
        }
      }
    }
  }
}
