.npsReport {
	.gauge {
		position: relative;
		float: left;
		background: transparent url('../../../images/nps/nps_gauge.png') no-repeat left top;
		@include breakpoint($phone) {
			height: 139px;
			width: 280px;
			background-size: 280px 139px;
		}
		@include breakpoint($tablet) {
			@include push(1 of 17);
			height: 169px;
			width: 340px;
			background-size: 340px 169px;
		}

		label {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 0);
			font-weight: $bold;
			@include breakpoint($phone) {
				font-size: 36px;
				line-height: 30px;
			}
			@include breakpoint($tablet) {
				font-size: 48px;
				line-height: 30px;
			}
		}
		.needle {
			position: absolute;
			left: -3px;
			transform-origin: right;
			@include breakpoint($phone) {
				bottom: -4px;
				height: 8px;
				width: 143px;
			}
			@include breakpoint($tablet) {
				bottom: -5px;
				height: 10px;
				width: 173px;
			}

			&:after {
				content: " ";
				position: absolute;
				background-color: $darkblue;
				@include breakpoint($phone) {
					height: 8px;
					width: 62px;
				}
				@include breakpoint($tablet) {
					height: 10px;
					width: 73px;
				}
			}
		}
	}
	.counts {
		padding-top: 25px;
		font-size: 12px;
		line-height: 15px;
		@include breakpoint($phone) {
			@include span(full);
		}
		@include breakpoint($tablet) {
			@include span(8 of 17);
		}

		label {
			@include push(1 of 4);
			@include span(1 of 4);
			padding: 11px 0;

			&.caption {
				padding: 15px 0 0;
				font-size: 10px;
				line-height: 11px;
				color: $darkgrey;
				@include breakpoint($phone) {
					@include push(1 of 8);
				}
				@include breakpoint($tablet) {
					@include push(0 of 4);
					@include span(3 of 4);
					@include post(1 of 4);
					text-align: right;
				}
			}
		}
		div {
			@include span(1 of 4);
			@include post(1 of 4);
			height: 35px;
			padding: 11px 0;
			margin-bottom: 5px;
			@include border-radius(2px);
			font-size: 18px;
			color: white;
			text-align: center;

			&.promoters {
				background-color: #54C06E;
			}
			&.passives {
				background-color: #FF886D;
			}
			&.detractors {
				background-color: #D3347F;
			}
		}
	}
	.chartWrapper {
		@include span(full);
		height: 150px;

		.barChart {
			padding: 0;

			.npsLabels {
				margin-top: -25px;
				margin-left: 43px;

				label {
					display: inline-block;
					height: 60px;
					width: 47.7px;
					padding-top: 25px;
					margin-right: 7px;
					background-repeat: no-repeat;
					background-size: 20px 20px;
					background-position: center top;
					text-align: center;

					&.nps0 {
						background-image: url('../../../images/nps/nps_00.png');
					}
					&.nps1 {
						background-image: url('../../../images/nps/nps_01.png');
					}
					&.nps2 {
						background-image: url('../../../images/nps/nps_02.png');
					}
					&.nps3 {
						background-image: url('../../../images/nps/nps_03.png');
					}
					&.nps4 {
						background-image: url('../../../images/nps/nps_04.png');
					}
					&.nps5 {
						background-image: url('../../../images/nps/nps_05.png');
					}
					&.nps6 {
						background-image: url('../../../images/nps/nps_06.png');
					}
					&.nps7 {
						background-image: url('../../../images/nps/nps_07.png');
					}
					&.nps8 {
						background-image: url('../../../images/nps/nps_08.png');
					}
					&.nps9 {
						background-image: url('../../../images/nps/nps_09.png');
					}
					&.nps10 {
						background-image: url('../../../images/nps/nps_10.png');
					}
				}
			}
		}
	}
	.grid {
		.rTableBody .rTableRow {
			.rTableCell {
				&.nps0, &.nps1, &.nps2, &.nps3, &.nps4, &.nps5,
				&.nps6, &.nps7, &.nps8, &.nps9, &.nps10 {
					font-size: 16px;
				}

				&.nps0 {
					color: #D3347F;
				}
				&.nps1 {
					color: #D83553;
				}
				&.nps2 {
					color: #D63744;
				}
				&.nps3 {
					color: #DB4740;
				}
				&.nps4 {
					color: #E55E4A;
				}
				&.nps5 {
					color: #FF886D;
				}
				&.nps6 {
					color: #FB9161;
				}
				&.nps7 {
					color: #FFA96B;
				}
				&.nps8 {
					color: #D7B36F;
				}
				&.nps9 {
					color: #9BBA70;
				}
				&.nps10 {
					color: #54C06E;
				}
			}

			.subGrid .rTableCell {
				width: 600px;
				padding: 20px 0 20px 263px;
				border-top: none;
				color: $cobalt;
				white-space: normal;
			}
		}
	}
}
