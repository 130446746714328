.getTheLook {
	display: grid;
	@include breakpoint($tablet) {
		grid-template-columns: 1fr 1fr !important;
		grid-column-gap: 20px;
	}

	h1 {
		grid-column: span 1 !important;
	}
	.btnIcon {
		margin: 5px 0 10px;
		@include breakpoint($tablet) {
			place-self: start end;
			margin: 5px 0 0;
			padding: 0 40px;
		}
	}
	section {
		figure {
			@include maintain-aspect-ratio(16, 9);
			margin-bottom: 20px;
		}
		.approved {
			color: $blue;
		}
		.pending {
			color: $orange;
		}
		.denied {
			color: $red;
		}
		a:not(.sectionEdit) {
			margin-left: 10px;
			text-decoration: underline;
		}
	}
	.noItems {
		@include breakpoint($tablet) {
			grid-column: 1 / span 2;
		}

		p {
			@include breakpoint($tablet) {
				width: span(10 of 17);
			}
		}
		figure {
			@include maintain-aspect-ratio(5, 3);
			margin-top: 20px;
			background: transparent url("../../../images/marketing/Marketplace.png") no-repeat top left;
			background-size: cover;
		}
	}
}

.textareaGetTheLook{
	width: 100%;
}


.getTheLookForm {
	@include breakpoint($tablet) {
		display: grid;
		grid-template-columns: 11fr 6fr;

		h1 {
			grid-column: 1 / span 2;
		}

		aside {
			margin-left: 20px;
		}

		.spinner {
			display: block;
		}


	}

	.uploadZone {
		.drop {
			@include maintain-aspect-ratio(16, 9);
			margin-bottom: 5px;

			.placeholder svg {
				margin-top: 25%;
			}
		}
		li {
			@include maintain-aspect-ratio(16, 9);
		}
	}
	.credits {
		grid-column: span 4;
		display: grid;
		grid-template-columns: repeat(4, 2fr) 1fr;
		grid-column-gap: 5px;

		.btnLink {
			place-self: center;
			float: none;

			svg {
				margin: 0;
			}
		}
	}
	.showcase {
		h3 {
			margin-bottom: 5px;
		}
		.btn {
			width: 100%;
			margin: 0 0 10px;
		}
		.dragGrid {
			grid-auto-rows: 40px;
		}
		.grid td {
			border-top: none;
		}
	}

	aside {
		align-self: start;

		header {
			padding: 5px 20px;
			background-color: $navyblue;
			line-height: 30px;
			color: $white;

			.viewToggle {
				margin: 0;

				label {
					margin-right: auto;
				}
				& > div {
					margin: 0;
				}

				&.active label {
					color: $white;
				}
			}
			p {
				margin: 10px 0;
				color: $orange;
				line-height: initial;
			}
		}
		.actions {
			grid-template-columns: 1fr;
			padding: 20px 20px 10px;
			margin-bottom: 20px;
			background-color: $darkblue;

			.btnIcon {
				width: 100%;
				margin-bottom: 10px;
				grid-column: span 2 !important;
			}

			.btn {
				grid-column: span 2 !important;
				margin: 0 0 5px;
			}
			hr {
				height: 1px;
				margin: 25px 0;
				background-color: $darkgrey;
				border: none;
			}
			textarea {
				height: 70px;
			}
			p {
				color: $meddarkgrey;

				&.caption {
					text-align: center;
				}
			}
		}
	}
}
