.detailsRow {
  .iconStyle {
    margin-left: 200px;
  }
  .div1 {
    width: 150px;
    height: 30px;
    background-color: #e581b1;
    margin-right: -100px;

    .span1 {
      display: flex;
      text-align: left;
      margin-left: 10px;

      .div2 {
        margin-top: 7px;
        color: #ffffff;
      }
    }
  }

  .div3 {
    width: 150px;
    height: 30px;
    background-color: #e5e8e9;
    margin-right: -100px;

    .span2 {
      display: flex;
      text-align: left;
      margin-left: 10px;

      .div4 {
        margin-top: 7px;
        color: #0e2c44;
      }
    }
  }

  .div5 {
    width: 150px;
    height: 30px;
    background-color: #54c06e;
    margin-right: -100px;

    .span3 {
      display: flex;
      text-align: left;
      margin-left: 10px;

      .div6 {
        margin-top: 7px;
        color: #ffffff;
      }
    }
  }

  .div7 {
    width: 150px;
    height: 30px;
    background-color: #966cb8;
    margin-right: -100px;

    .span4 {
      display: flex;
      text-align: left;
      margin-left: 10px;

      .div8 {
        margin-top: 7px;
        color: #ffffff;
      }
    }
  }
}
