#CreditDebitMemoDetails {
  .white-box{
    background: #fff;
    padding: 12px 20px;
    .blue {
      color: #278ADA;
      margin: 0;
    }
    .flex-between {
      display: flex;
      justify-content: space-between;
      .company-deets {
        margin-top: 8px;
        .no-margin {
          margin: 0;
        }
      }
      .other-deets {
        margin-right: 80px;
      }
      .glAccount {
        display: flex;
        margin-bottom: 10px;



        .btnLink svg {
          margin-left: 15px;
          height: 15px !important;
          width: 15px !important;
        }
      }
    }
    .title-bar {
      border-bottom: 1px solid #CED8E3;
      margin-bottom: 13px;
      a {
        background-color: #D3DCE4;
        height: 30px;
        width: 78px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        color: initial;
      }
    }
    .statementAppliedLine {
      p {
        margin: 0;
      }
      cursor: pointer;
      padding: 4px 12px;
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      &:hover {
        background: #f5f5f4;
      }
      .divider {
        margin: 0 6px;
      }
    }
    .flex {
      display: flex;
    }
    .statement-mini-link {
      padding: 2px 4px;
      cursor: pointer;
      &:hover {
        background: #f5f5f4;
      }
    }
    h2 {
      color: initial;
    }
  }
}

.editGlAccountModal {
  select {
    margin: 0;
  }
}
