.schedule {
  .breadcrumbs {
    padding: 5px 0 10px 0;
  }
  .scheduleHeader {
    @include container;
    background-color: $white;
    line-height: 40px;

    .check {
      margin-left: 10px;
    }
    .legend {
      float: right;
      padding: 8px 0 7px;
      margin-right: 20px;
      font-size: 10px;
      line-height: 25px;

      div {
        display: inline-block;
        margin-left: 5px;
      }
      svg {
        height: 20px !important;
        width: 27px !important;
        fill: $darkblue;
        vertical-align: middle;
      }

      .assigned,
      .unassigned {
        padding: 0 8px;
        @include border-radius(13px);
        color: $white;
      }
      .assigned {
        background-color: $blue;
      }
      .unassigned {
        background-color: $orange;
      }
      .dropOff svg {
        margin-left: 5px;
      }
      .pickUp svg {
        margin-right: 5px;
      }
    }
  }
  .scheduleMonth {
    .dayNames {
      display: flex;
      font-size: 9px;
      line-height: 40px;
      color: $blue;

      label {
        width: span(1 of 7);
      }
    }
    .Month {
      .taller {
        height: 120px;
      }
      .Day:hover {
        background-color: $lightgrey;
      }
      .Day__container {
        padding: 5px 0;
        font-size: 10px;
        line-height: 20px;
        color: $white;

        div {
          display: inline-block;
          cursor: pointer;

          * {
            cursor: pointer;
          }
        }
        svg {
          height: 40px !important;
          width: 29px !important;
          fill: $darkblue;
          vertical-align: middle;
          padding-left: 2.5px;
          padding-right: 2.5px;
        }

        #customStop {
          height: 34px !important;
          width: 23px !important;
        }

        .assigned,
        .unassigned {
          width: 20px;
          margin-right: 2px;
          @include border-radius(50%);
          text-align: center;
        }
        .assigned {
          background-color: $blue;
        }
        .unassigned {
          background-color: $orange;
        }
        .dropOff,
        .pickUp {
          position: relative;
          margin-right: 2px;
          font-weight: $bold;
          line-height: 14px;

          label {
            position: absolute;
            top: 0;
            text-align: center;
          }
        }
        .dropOff label {
          left: 0;
          right: 11px;
        }
        .pickUp label {
          left: 11px;
          right: 0;
        }
      }
    }
  }
  .r-calendar {
    min-width: auto;
    padding: 0 10px 20px;
    background-color: $white;

    .r-head {
      display: none;
    }
    .r-weekdays {
      display: block;
      padding-top: 16px;
      color: $blue;
    }
    .r-cell {
      &.r-date {
        color: $darkgrey;

        &.r-highlighted {
          background-color: $blue;
          color: $white;
        }
      }
    }
  }

  .full-expand {
    width: 100%;
  }
}

.rentalTransportContainer {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
}

.scheduleList {
  .assigned,
  .unassigned {
    @include breakpoint($tablet) {
      float: left;
    }

    svg {
      height: 17px !important;
      width: 23px !important;
      margin-right: 10px;
      vertical-align: middle;
    }
    a {
      display: block;
      padding-bottom: 20px;
      color: $darkblue;
      cursor: pointer;
      overflow: auto;
      @include breakpoint($tablet) {
        padding-bottom: 0;
      }

      &:hover label {
        text-decoration: underline;
      }
    }
    strong {
      line-height: 30px;
    }
    label {
      display: block;
      cursor: pointer;
      @include breakpoint($tablet) {
        display: inline-block;
        float: left;
        width: 120px;
        font-size: 11px;
        line-height: 17px;
      }

      &:last-of-type() {
        overflow: hidden;
        text-overflow: ellipsis;
        @include breakpoint($tablet) {
          width: calc(100% - 120px);
        }
      }
    }
  }
  .assigned {
    @include breakpoint($tablet) {
      width: calc(#{span(1 of 2)} - 10px);
      margin-right: 20px;
    }

    h4 {
      svg {
        fill: $blue;
      }
    }
  }
  .unassigned {
    @include breakpoint($tablet) {
      width: calc(#{span(1 of 2)} - 10px);
    }

    h4 {
      color: $orange;

      svg {
        fill: $orange;
      }
    }
  }
}

.icon-box {
  background-color: $blue;
  padding-left: 10px;
  padding-right: 10px;

  & > svg {
    grid-column: 1;
    place-self: start;
    margin-top: 8px;
    fill: $white;
  }
}
