.statement-payments-modal {
  #statement-payments-container {
    width: 768px;
    display: flex;
    section {
      flex: 1 1;
    }
    max-height: 75vh;
    overflow-y: auto;
    background: $white;
    .actions {
      padding-bottom: 30px !important;
    }
  }
  .content {
    padding: 0px;
  }

  section {
    margin-bottom: 0px;
  }

  .payment {
    .customerSelection {
      border-bottom: 1px solid #ced8e3;
      border-top: 1px solid #ced8e3;
      padding-bottom: 10px;
      padding-top: 10px;
    }

    .paymentType {
      padding-top: 10px;
      padding-bottom: 10px;

      .fields {
        padding: 10px 0px;

        .react-datepicker-component {
          padding: 5px 0px;
        }
      }
    }
  }

  .orders {
    width: 120%;

    .amount-remaining {
      display: flex;
      justify-content: space-between;
      margin-top: 32px;
    }

    .orders-list {
      max-height: 410px;
      background-color: #e5e8e9;
      padding: 8px;
      margin-top: 10px;
      .checkbox {
        padding-right: 0px;
      }
    }
  }
}

.thinner-modal {
  .content,
  .body {
    max-width: 350px !important;
  }
}
