.filterDialog {
	z-index: 100 !important;

	.content {
		& > div {
			background-color: $darkblue !important;

			& > h3 {
				display: block;
				height: $mobileVertical;
				padding: 0 !important;
				background: transparent url('../../../images/logos/logo_blue.png') no-repeat no-repeat 10px 11px;
				background-size: 150px 25px;
				text-indent: -10000px;
			}
		}
		.body {
			.linkAction {
				padding-right: $mobilePadding;
			}
			h2 {
				padding-left: $mobilePadding;
			}

			.scrollarea {
				height: calc(100% - 20px);
			}
			.geofence {
				margin: 20px 0;
				padding: 0 10px;

				input[type="text"] {
					width: 100%;
				}
				label {
					display: inline-block;
					width: span(1 of 4);
					color: $meddarkgrey;
					line-height: 22px;
				}
				select {
					width: calc(#{span(3 of 4)} - 35px);
					margin-right: 5px;
				}
				.btnIcon {
					height: 35px;
					width: 30px;
					margin: 0 0 5px;
					background-color: $blue;
					line-height: 35px;
					vertical-align: bottom;

					svg {
						height: 20px !important;
						width: 21px !important;
						margin-right: 0;
						fill: $white;
					}
				}
			}
			.checkList {
				.checkListItem {
					margin-left: $mobilePadding !important;

					& + div {
						.checkListItem {
							margin-left: 30px !important;
						}
					}
				}
			}

		}
	}
}

.mobileFilter {
	@include clearfix;
	position: fixed;
	bottom: 0;
	left: span(1 of 8);
	height: $mobileVertical;
	width: 100%;
	background-color: $navyblue;
	z-index: 99;

	& > a {
		float: left;
		height: $mobileVertical;
		width: $mobileVertical;
		padding: 11px 11px 10px;

		svg {
			height: 22px !important;
			width: 22px !important;
			fill: $blue;
		}
	}
	.chipWrapper {
		float: left;
		height: $mobileVertical;
		width: calc(100% - 45px);
		overflow: auto;
		white-space: nowrap;

		.chip {
			display: inline-block !important;
			padding: 13px 10px 12px !important;
			background-color: transparent !important;
			border-radius: 0 !important;

			a {
				margin-right: 4px;

				svg {
					height: 15px !important;
					width: 16px !important;
					margin-bottom: -2px;
					fill: $red;
				}
			}
			span {
				padding: 0 !important;
				font-size: 12px !important;
				line-height: 12px !important;
				color: $meddarkgrey !important;
			}
		}
	}
}

.productList {
	.noData {
		margin-top: 40px;

		& > div {
			padding-left: span(4 of 34);
			padding-right: span(5 of 34);

			.logo {
				background-image: url('../../../images/icons/noData_Items.png');

				@media (max-width: 769px) {
					width: 100px;
					height: 100px;
					background-size: 80px;
				}
			}

			&:first-of-type() {
				padding-left: span(5 of 34);
				padding-right: span(4 of 34);

				.logo {
					background-image: url('../../../images/icons/noData_Item.png');

					@media (max-width: 769px) {
						width: 100px;
						height: 100px;
						background-size: 80px;
					}
				}
			}
		}

		&.bundles {
			& > div {
				width: 100%;
				padding-left: span(13 of 34);
				padding-right: span(13 of 34);
				border-right: none;

				.logo {
					background-image: url('../../../images/icons/noData_Bundles.png');
				}
			}
		}
	}
	.maintenancePrompt {
		padding: 40px 0;
		text-align: center;
		@include breakpoint($tablet) {
			padding: 40px;
		}

		.btn {
			width: 100%;
			margin: 0;
			@include breakpoint($tablet) {
				width: auto;
			}
		}
	}
	.grid {
		.medLg {
			width: 150px;
		}
		.rTableRow, tr {
			.info {
				float: none;
			}

			th:first-of-type {
				text-align: left;
			}
			.rTableCell, td {
				.btn.cancel {
					padding-left: 20px;
					padding-right: 20px;
				}
				.actions .btn {
					width: 80px;

					&.cancel {
						width: 60px;
						margin-right: 0;
					}
				}
				&.shared {
					svg {
						margin: 0;
					}

					&.none svg {
						display: none !important;
					}
					&.custom svg{
						fill: $meddarkgrey;
					}
					&.all svg {
						fill: $blue;
					}
				}
				&.positive {
					color: $blue;
				}
				&.negative {
					color: $orange;
				}
			}
		}
	}

	.maintenance {
		.grid {
			// th:last-of-type {
			// 	width: 170px;
			// }
			.xs {
				width: 40px !important;
			}

			svg {
				width: auto;
				margin: 0;
			}
			.btnCollapse, .btnExpand {
				float: none;
				margin: 0 8px;
			}
			.serializedRow {
				background-color: $meddarkgrey;

				td {
					height: 40px;
				}
			}

			.maintenanceBarcode {
				margin-right: 9px;
			}
		}
	}

	.headerNav {
		span {
			margin-right: 20px;
			color: $darkblue;

			&.active {
				color: $blue;
				font-weight: bold;
				letter-spacing: 0;
			}

			&:hover {
				cursor: pointer;
			}
		}
	}
}

.productExport {
	.actions {
		grid-template-columns: repeat(2, 1fr);
		grid-row-gap: 5px;
		margin: 0 0 20px;
		@include breakpoint($tablet) {
			grid-template-columns: repeat(6, 1fr);
			@include span(13 of 17);
			margin: 0;
			margin-bottom: 10px;
		}
	}
	section {
		@include breakpoint($tablet) {
			@include span(13 of 17);
		}

		ul {
			li {
				line-height: 39px;
				border-top: 1px solid $meddarkgrey;
			}
		}
		& > .check {
			display: block;
			line-height: 39px;
		}
		.accordian {
			.content {
				padding: 0 0 20px 10px;
				@include breakpoint($tablet) {
					padding: 0 20px 20px;
				}

				select {
					width: 100%;
				}
				.dateSelect {
					display: inline-block;
					width: span(1 of 2);
					@include breakpoint($tablet) {
						width: span(2 of 5);
					}

					label {
						font-size: 10px;
						line-height: 14px;
						font-weight: $bold;
						color: $darkgrey;
						text-transform: uppercase;
					}
				}
				.check {
					display: block;
					line-height: 39px;
					border-top: 1px solid $meddarkgrey;
				}
			}
		}
		.checkList {
			.checkListItem {
				padding-left: 20px !important;
				font-weight: $regular;
				color: $darkblue !important;

				.check {
					&.on > svg {
						color: $lightgrey;
						fill: $blue;
					}
					&.off > svg {
						fill: $meddarkgrey;
					}
				}

				& > button {
					svg {
						fill: $darkblue !important;
					}
				}

				& + div {
					background-color: transparent;

					& > div {
						border-bottom: none;
					}
					.checkListItem {
						margin-right: 50px;
						border-top: 1px solid #0C263B; //rgba(7,24,39,0.3);
						color: $darkblue !important;
					}
				}
			}
		}
	}
}

.legendModal {
	.body > div {
		display: grid;
		grid-template-columns: 30px 1fr;
		padding: 10px 0 0;
		border-bottom: 1px solid $meddarkgrey;
		@include breakpoint($tablet) {
			padding: 10px 120px 0;
		}

		svg {
			height: 18px !important;
			width: 18px !important;
			fill: $blue;
			vertical-align: middle;

			&.disabled {
				fill: $meddarkgrey;
			}
		}
		a {
			text-decoration: underline;
		}

		&:last-of-type {
			border: none;
		}
	}
}
