.companiesListDrawer {
  z-index: 110000 !important; // Needs to be in front of Help & Tutorials slideout
  .companiesSearchList {
    .companyItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .editLink {
      border-left: none !important;
      margin-top: 0px !important;
      display: flex;
    }

    .hideMe {
      display: none;
    }

    .drawerForm {
      .form,
      .iconForm {
        .fields {
          margin-bottom: 10px;
          select {
            grid-column: inherit;
            margin: 35px 0 0 0;
          }
        }
      }
    }
  }
}