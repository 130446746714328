.dashboard {
	display: grid;
	@include breakpoint($tablet) {
		grid-template: 40px 1fr / 1fr 1fr;
		grid-row-gap: 40px;
	}

	h1 {
		margin: 0;

		.headerNav {
			@include breakpoint($tablet) {
				border-left: 1px solid $cobalt;
			}

			a {
				color: $blue;

				&.selected {
					color: $white;
				}
			}
		}
	}
	.actions {
		display: none;
		@include breakpoint($tablet) {
			place-self: center end;
			display: flex;
		}

		.btnIcon {
			width: 110px;
			margin: 0 0 0 5px;
			color: $white;

			svg {
				height: 17px !important;
				width: 18px !important;
			}

			&.outline {
				background-color: transparent;
				border: 2px solid $blue;
				color: $blue;
				line-height: 26px;

				&:hover {
					background-color: $blue;
					color: $white;
				}
			}
			&:not(.add) svg {
				fill: $white;
			}

			&.small {
				width: 30px;

				svg {
					margin: 0;
				}
			}
		}
		.divider {
			place-self: center;
			width: 2px;
			height: 30px;
			margin: 0 14px 0 19px;
			background-color: $blue;
		}
	}
	.details, .payments {
		@include breakpoint($tablet) {
			grid-column: 1 / span 2;
			display: grid;
			grid-template: auto 300px auto / 1fr 3fr;
		}
	}
	.payments {
		.legend {
			.callout {
				@include breakpoint($tablet) {
					grid-column: 1 / span 2;
				}
			}
		}
	}

	.filter {
		display: none;
		@include breakpoint($tablet) {
			grid-column: 2;
			grid-row: 1;
			display: block;
		}
	}
	.calendarToggle {
		padding: 20px 10px;
		margin-bottom: 20px;
		background-color: $navyblue;
		font-size: 20px;
		line-height: 20px;
		color: $white;

		svg {
			fill: $blue;
			vertical-align: middle;

			&:first-of-type() {
				height: 20px !important;
				width: 20px !important;
				margin-right: 10px;
			}
			&:last-of-type() {
				float: right;
			}
		}

		& + .calendar {
			margin-top: -20px;
		}
	}
	.calendar {
		padding: 0 20px 20px;
		background-color: $navyblue;
		@include breakpoint($tablet) {
			grid-column: 1;
			grid-row: 1 / span 2;
			padding: 0 30px 0 25px;
		}

		.r-calendar {
			min-width: auto;

			.r-head {
				margin-bottom: 10px;
				line-height: 40px;

				.r-prev, .r-next {
					@include span(1 of 7);
					margin: 0;

					svg {
						fill: $white;
						vertical-align: middle;
					}
					&:focus {
						outline: none;
					}
				}
				.r-prev {
					padding-right: span(1 of 54);
				}
				.r-next {
					padding-left: span(1 of 54);
				}
				.r-title {
					@include span(5 of 7);
					margin: 0;
					font-size: 12px;
					color: $blue;
					text-transform: uppercase;
				}
			}
			.r-weekdays {
				line-height: 22px;
				color: $blue;
			}
			.r-dates {
				font-size: 10px;
				line-height: 10px;
			}
			.r-cell {
				width: span(1 of 9);
				margin-left: span(1 of 54);
				margin-right: span(1 of 54);

				&.r-date.r-in-out {
					border: 2px solid $blue;
				}
			}
		}
	}
	.pagerWrapper .pager {
		li {
			svg {
				fill: $blue;
			}
			&.disabled {
				svg {
					fill: $cobalt;
				}
			}
		}
		div {
			color: $darkgrey;
		}
		input[type="text"] {
			background-color: $blue !important;
		}
		span {
			color: $white;
		}
	}
	.items {
		@include breakpoint($tablet) {
			grid-column: 2;
			grid-row: 2 / span 2;
		}

		h1 {
			display: none;
		}
		section {
			@include breakpoint($tablet) {
				min-height: 450px;
			}
		}
		.grid {
			.rTableRow, tr {
				&.new, &.alert, &.ready, &.expiring {
					.rTableCell:first-child(), td:first-child() {
						position: relative;
						overflow: visible;

						&:before {
							content: " ";
							position: absolute;
							top: 0;
							bottom: 0;
							left: -26px;
							right: calc(100% + 20px);
							cursor: default;
						}
					}
				}
				&.new {
					.rTableCell:first-child():before, td:first-child():before {
						background-color: $pink;
					}
				}
				&.ready {
					.rTableCell:first-child():before, td:first-child():before {
						background-color: $green;
					}
				}
				&.expiring {
					.rTableCell:first-child():before, td:first-child():before {
						background-color: $red;
					}
				}
				&.alert {
					.rTableCell:first-child():before, td:first-child():before {
						background-color: $orange;
					}
				}

				.pickUp svg {
					fill: #8FA9BE;
				}
				.Full, .Fulfilled {
					color: $green;
				}
				.Partial {
					color: $orange;
				}
				.None, .Unfulfilled {
					color: $red;
				}
			}
		}

		.alerts, .checking_out, .checking_in {
			.rTableCell, td {
				&.highlight {
					color: $purple !important;

					svg {
						height: 10px !important;
						width: 10px !important;
						margin-right: 5px;
						fill: $purple !important;
						vertical-align: middle;
					}
				}

				&.alert, &.none, &.partial, &.full {
					padding-left: 0 !important;
					text-align: center;

					svg {
						height: 18px !important;
						width: 19px !important;
					}
				}
				&.alert svg {
					fill: $orange;
				}
				&.none svg {
					color: $lightgrey;
					fill: $lightgrey;
				}
				&.partial svg {
					color: $lightgrey;
					fill: $purple;
				}
				&.full svg {
					color: $green;
					fill: $green;
				}
			}
		}
	}
	.legend {
		display: none;
		@include breakpoint($tablet) {
			grid-column: 1;
			grid-row: 2;
			place-self: start stretch;
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 5px;
			padding: 0 50px;
		}

		.callout {
			background-color: $pink;
			@include border-radius(10px);
			font-size: 10px;
			line-height: 20px;
			color: $white;
			text-align: center;

			&.alert {
				background-color: $orange;
			}
			&.ready {
				background-color: $green;
			}
			&.expiring {
				background-color: $red;
			}
		}
		.key {
			grid-column: 1/ span 2;
			font-size: 12px;
			line-height: 30px;
			color: $darkgrey;

			svg {
				height: 15px !important;
				width: 15px !important;
				margin-right: 5px;
				fill: $orange;
				vertical-align: middle;
			}

			&.none svg {
				color: $cobalt;
				fill: $cobalt;
			}
			&.partial svg {
				color: $cobalt;
				fill: $purple;
			}
			&.full svg {
				color: $green;
				fill: $green;
			}
		}
	}


    .nav-options{
		grid-column: 1;
		grid-row: 1;
		grid-row: 1 / span 2;

		.legend{
			padding: 15px !important;
		}
	}

	.nav {
		@include breakpoint($tablet) {
			@include span(2 of 12);
		}

		.itemList {
			margin-top: 80px;

			li {
				font-size: 11px;
				font-weight: $bold;
				text-transform: uppercase;

				a {
					padding-left: 5px;
					color: $darkgrey;
				}

				&.selected {
					a {
						color: $white;
					}
					&:nth-child(1) {
						border-top: 1px solid #0C263B;
					}
				}
			}
		}
		select {
			width: 100%;
			background-color: $navyblue;
			background-image: url('../../../images/icons/ArrowDown-Blue.png');
			color: $darkgrey;
		}

		& + .legend {
			@include breakpoint($tablet) {
				grid-row: 3;
				margin-top: 40px;
			}
		}
	}
}

.negative {
  color: $red !important;
}

.final_balance_due{
	.check{
		svg{
			fill: white !important;
			color: $blue !important;
		}
	}

}

.advancedPermissionsLink {
	color: $blue !important;
	text-decoration: underline;
}
