.auth-hold-box-title {
  font-size: 14px;
  color: $blue;
  font-weight: bold;
  line-height: 20px;
}

.auth-hold-box-description {
  font-size: 12px;
  margin-bottom: 20px !important;
}

.autobill-selects {
  margin-top: 10px;

  input[name='authHoldAmount'] {
    font-weight: 700;
  }

  span {
    float: right;
    font-size: 10px;
    color: gray;
    margin-bottom: 10px;
  }

  select:first-child {
    margin-bottom: 10px;
  }
}

.authAmount {
  margin-bottom: 10px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  input[type='text'] {
    text-align: end;
  }
}

.notAvailable {
  display: inline-block;
  height: 16px;
  padding: 0;
  margin-left: 5px;
  cursor: help;
  z-index: 25;
  color: gray;
}

.authHoldPlaced {
  .holdAmount {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $meddarkgrey;
    padding-bottom: 10px;

    span {
      font-weight: 700;
    }
  }
}

.confirmation-alert {
  height: 15px;
  fill: orange;
}

.recurring-payment {
  width: 100%;
  .recurringPaymentSettingsIcon {
    a {
      float: right;

      svg {
        height: 16px;
        width: 21px;
        fill: #8fa9be;
      }
    }
  }

  .recurring-payment-amount,
  .recurring-payment-occurence {
    margin: 10px 0;
    vertical-align: middle;

    .recurring-payment-amount-input {
      width: 50%;
      float: right;

      input,
      select {
        text-align: right;
        margin-bottom: 10px;

        &:select {
          width: 70%;
        }
      }
    }
  }

  .recurring-payment-selects {
    margin-top: 10px;
    border-bottom: 1px solid #c1cdd7;

    &.monthly{
      display: flex;
      justify-content: space-between;
    }

    select {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 10px;
      }

      &.monthly {
        width: 45%;
      }
    }
  }

  .recurring-payment-occurs-on {
    &.weekly {
      margin-top: 25px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

      .weekDayCheckBox {
        width: 12.5%;

        input {
          display: none !important;
        }

        input[type='checkbox'] + label {
          display: inline-block;
          background: #e5e8e9;
          height: 40px;
          width: 30px;
          margin-right: 3px;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
        }

        input[type='checkbox'].paneSide + label {
          background: #0d2034;
        }

        input[type='checkbox']:checked + label {
          background: #278ada;
          color: #ffffff;
        }
      }
    }

    &.monthly {
      margin-top: 25px;

      .recurrence-note {
        color: #7d7d7d;
        font-size: 10px;
      }
    }
  }

  .recurring-payment-dates {
    display: grid;
    grid-template-columns: 1fr 1fr;

    label {
      font-size: 10px;
      color: #278ada;
    }

    margin: 25px 0;
    .react-datepicker-component {
      width: 140px;
    }
  }
}

.newCreditCardDrawerForm {
  h2 {
    color: #278ada;
  }
  .fields {
    h4 {
      margin: 5px 0 5px 15px;
    }
    select,
    input {
      width: auto !important;
      margin: 0 20px;
    }
    #credit_card_number_input,
    #credit_card_month_select_list,
    #credit_card_street_address_1_input,
    #credit_card_city_input,
    #credit_card_locale_select_list {
      margin-right: 0px;
    }
    #credit_card_cvc_input,
    #credit_card_year_select_list,
    #credit_card_street_address_2_input,
    #credit_card_locale_select_list,
    #credit_card_postal_code_input {
      margin-left: 0px;
    }
  }
}

.recurring-payment-details {
  div {
    margin: 10px 0;

    &.payment-amount-div {
      span {
        &:last-of-type {
          float: right;
          font-weight: bold;
        }
      }
    }
  }

  table {
    width: 100%;
    margin: 10px 0;
    border-top: 1px solid #c1cdd7;
    tr {
      height: 25px;

      &.occurence-row {
        height: 40px;
      }

      td {
        &:first-of-type {
          width: 40%;
        }

        &.label {
          font-weight: bold;
        }
      }
    }
  }
}

.paymentTermsSelection {
  border-bottom: 1px solid #ced8e3;
  margin-bottom: 10px;
}
.paymentTermsBlock {
  display: flex;
  justify-content: space-between;

  .lineItemName,
  .lastLineItemName {
    margin-left: 29px !important;
  }

  .lineItemAmount,
  .lineItemName {
    margin-bottom: 0px !important;
  }

  .lastLineItemAmount,
  .lastLineItemName {
    margin-top: 6px !important;
    svg {
      fill: $orange;
      height: 15px;
      width: 15px;
      margin-right: 4px;
    }
  }
}
.payment .actions .orange-disabled {
  background-color: $orange;
  cursor: not-allowed;
}
