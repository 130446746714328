.signUpWrapper {
  height: 100%;
  padding: 80px 20px 40px;
  background: $darkblue url('../../../images/marketing/signUp_bg.jpg') no-repeat
    center center;
  background-size: cover;
  overflow: hidden;
  @include breakpoint($tablet) {
    display: grid;
    grid-template-columns: 1fr repeat(12, minmax(#{span(1 of 12)}, 85px)) 1fr;
    overflow: visible;
    padding: 150px 0;
  }
  @include breakpoint(1020px) {
    grid-template-columns: 1fr repeat(12, minmax(auto, 85px)) 1fr;
  }

  &.sf-plan-wrapper {
    grid-template-columns: 1fr repeat(12, minmax(auto, 140px)) 1fr;
  }

  &:before {
    content: '';
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    height: 35px;
    width: 225px;
    background: transparent url('../../../images/logos/logo_white.png')
      no-repeat left top;
    background-size: cover;
    @include breakpoint($tablet) {
      top: 95px;
    }
  }

  header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;

    padding: 20px 0 20px 20px;
    background-color: $navyblue;
    @include breakpoint($tablet) {
      grid-column: 2 / span 3;
      padding: 60px 40px 100px;
    }

    h1 {
      font-size: 18px;
      line-height: 24px;
      font-weight: lighter;
      color: $white;
      margin-bottom: 0 !important;
    }
    .progressHolder {
      position: relative;
      margin: 0;
      padding: 0;
      background-color: transparent;
      text-align: center;

      .stepContainer {
        & > div {
          float: none;

          &.step {
            display: flex;
            width: auto;

            .icon {
              height: 10px;
              width: 10px;
              margin: 3px 18px 3px 3px;
              background-color: $blue;
            }
            label {
              position: static;
              transform: none;
              padding: 0;
              font-size: 15px;
              font-weight: $blue;
              line-height: 16px;
              color: $white;
              text-transform: uppercase;
              opacity: 0.5;
            }

            &.complete,
            &.active {
              .icon {
                height: 16px;
                width: 16px;
                margin: 0 15px 0 0;
                border: 3px solid $white;
              }
              label {
                opacity: 1;
              }
            }
            &.complete {
              .icon {
                background-color: $white;
              }
              label {
                text-decoration: line-through;
              }
            }
            &.active {
              .icon {
                background-color: $darkblue;
              }
            }
          }
          &.connector {
            width: 3px;
            height: 15px;
            margin: -3px 0 -3px 6px;
            background-color: $blue;
            @include breakpoint($tablet) {
              height: 50px;
            }

            &.complete,
            &.active {
              background-color: $white;
            }
          }
        }
      }
    }
    .optOut {
      color: $darkgrey;
      @include breakpoint($tablet) {
        display: block;
        font-size: 12px;
        line-height: 22px;
      }

      a {
        color: $blue;
        font-weight: $bold;
        text-decoration: underline;
      }
    }
    .quote {
      display: none;
      @include breakpoint($tablet) {
        display: block;
        padding-top: 65px;
        background: transparent url('../../../images/logos/logo_capterra.png')
          no-repeat left top;
        background-size: 113px 50px;
      }

      p {
        position: relative;
        color: $darkgrey;
        margin-left: 27px;
        word-break: normal;
        @include breakpoint($tablet) {
          display: inline-block;
          font-size: 18px;
          line-height: 24px;
        }

        &:before,
        &:after {
          content: '';
          position: absolute;
          height: 17px;
          width: 22px;
        }
        &:before {
          top: 0;
          left: -27px;
          background: transparent url('../../../images/icons/quote-open.png')
            no-repeat left top;
        }
        &:after {
          bottom: 6px;
          right: -22px;
          background: transparent url('../../../images/icons/quote-close.png')
            no-repeat left top;
        }
      }
      label {
        display: block;
        font-size: 10px;
        line-height: 14px;
        color: $cobalt;
      }

      &.noLogo {
        @include breakpoint($tablet) {
          margin-top: 40px;
          padding-top: 0;
          background: transparent;
        }
      }
    }
  }
  section {
    margin: 0;
    padding: 40px;
    padding-bottom: 120px !important;

    @include breakpoint($tablet) {
      grid-column: 5 / span 9;
      padding: 40px;
    }

    &.signUp,
    &.signUpSettings,
    &.signUpPayments {
      display: flex;
      justify-content: center;
      align-items: center;

      .form {
        @include breakpoint($tablet) {
          width: 340px;
          margin: 0 auto;
        }

        .divider {
          height: 1px;
          background-color: $lightgrey;
          margin-bottom: 20px;
        }
      }
    }

    &.signUp {
      .termsCheck {
        margin-bottom: 20px;

        a {
          margin: 5px 0 0 25px;
          text-decoration: underline;
          @include breakpoint($tablet) {
            margin: 0 0 0 5px;
          }
        }
      }
    }

    &.signUpPayments {
      p {
        word-break: break-word;
      }

      h2 {
        font-size: 22px;
        font-weight: $bold;
        line-height: 26px;
        color: $darkblue;

        span {
          color: $blue;
        }
      }

      form > p {
        word-break: normal;
      }

      .actions {
        margin-bottom: 20px;
      }
      .caption {
        margin-left: 0;
        line-height: 14px;
      }
    }

    &.signUpPricing {
      text-align: center;
      @include breakpoint($tablet) {
        padding: 0 0 20px;
      }

      .form {
        .summary {
          margin: 0 0 20px;
          padding: 25px 40px;
          background-color: $lightgrey;
          @include breakpoint($tablet) {
            display: flex;
          }

          &.storefront {
            background-color: #e5e8e9;
            font-size: 13px;
            font-weight: bold;
          }

          .viewToggle {
            margin: 0;
          }
          a {
            margin: 20px 0 0;
            text-decoration: underline;
            @include breakpoint($tablet) {
              margin: 0 0 0 auto;
            }
          }
        }
        .plans {
          text-align: left;

          & > div {
            padding: 0 0 25px;
            @include breakpoint($tablet) {
              padding: 0 10px;

              &:nth-of-type(-n + 2) {
                border-right: 1px solid $medlightgrey;
              }
            }
          }
        }

        .sf-plans {
          text-align: left;

          & > div {
            padding: 0 0 20px;
            @include breakpoint($tablet) {
              padding: 0 15px;

              &:nth-of-type(-n + 3) {
                border-right: 1px solid $medlightgrey;
              }
            }
          }
        }
        .storefrontImg {
          background: transparent
            url('../../../images/marketing/storefront_plus_display.png')
            no-repeat left top;
          margin: 20px;
          height: 15vh;
          object-fit: cover;
          background-size: contain;
        }
        .storefrontPlan {
          display: grid;
          grid-template-columns: 30% 40% 30%;
          text-align: left;

          border: 2px solid $blue;
          margin: 35px 40px 0px 40px;

          & > div {
            padding: 20px;
            &.highlights {
              color: $blue;
              ul li {
                font-weight: 700;
                line-height: 25px;
                svg {
                  height: 15px !important;
                  width: 15px !important;
                  margin-right: 5px;
                  fill: transparent;
                  color: $blue;
                  vertical-align: middle;
                }
              }
              h2 {
                color: $blue;
                font-size: 22px;
                margin-bottom: 0;
                margin-top: -5px;
              }
            }
            .blurb {
              padding: 20px 0;
            }
            .price {
              display: flex;
              align-items: center;
              font-size: 48px;
              line-height: 52px;
              font-weight: 700;
              color: $blue;

              p {
                margin: 0 0 0 5px;
                font-size: 14px;
                line-height: 16px;
              }
            }
          }
        }
        .storefrontPlanCheckbox {
          width: 150px;
          margin-left: auto;
          margin-right: auto;
          margin-top: -10px;
          background-color: $white;
        }
        & > .actions {
          margin: 20px auto 0;
          @include breakpoint($tablet) {
            width: span(1 of 3);
          }

          & > .user-count {
            display: flex;
            grid-column: span 3;
            column-gap: 10px;
            margin-right: 5px;
            input[type='number']::-webkit-inner-spin-button,
            input[type='number']::-webkit-outer-spin-button {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              margin: 0;
            }
          }
        }
        & > .caption {
          margin: 0;
          text-align: center;
          @include breakpoint($tablet) {
            margin: 20px 0 0;
          }

          a {
            text-decoration: underline;
          }
        }
      }
    }

    &.signUpSuccess {
      text-align: center;
      @include breakpoint($tablet) {
        padding-top: 70px;
      }

      svg {
        height: 234px;
        width: 220px;
        margin-bottom: 25px;
        fill: $blue;
      }
      h1 {
        color: $darkblue;
        @include breakpoint($tablet) {
          margin-bottom: 20px;
          font-size: 36px;
          line-height: 36px;
        }
      }
      p {
        margin-bottom: 30px;
      }
      .btn {
        width: 100%;
        margin-right: 0;
        @include breakpoint($tablet) {
          width: auto;
        }
      }
    }
  }
}

.signUpTitle {
  background: $darkblue url('../../../images/marketing/signUp_Header.jpg')
    no-repeat center center;
  text-align: center;

  .title {
    height: 160px;
    background-color: transparent;

    h1 {
      font-weight: $bold;
      color: $white;
    }
  }
}

.signUpGettingStarted {
  @include breakpoint($phone $tablet) {
    section:first-of-type {
      display: none;
    }
  }
  @include breakpoint($tablet) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;

    section {
      padding-top: 40px;

      &:first-of-type {
        grid-column: span 2;
        padding-left: 530px;
        background: $white
          url('../../../images/marketing/macbook_Dashboard.png') no-repeat left
          bottom;
        background-size: 486px 294px;
      }
    }
  }

  h1 {
    margin-bottom: 10px;
    line-height: 24px;
  }
  p {
    strong {
      color: $purple;
      text-transform: uppercase;
    }
  }
  .itemList {
    margin: 20px 0;

    li {
      border-top: 1px solid $meddarkgrey;
      border-bottom: none;

      a {
        color: $darkblue;
      }
      label {
        margin-right: 5px;
        font-size: 10px;
        font-weight: $bold;
        color: $purple;
        text-transform: uppercase;
      }
      svg {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translate(0, -50%);
        fill: $purple;
      }
    }
  }
  .actions {
    margin-bottom: 20px;
    @include breakpoint($tablet) {
      width: 50%;
    }
  }
  img {
    width: 100%;
  }
}

.sf-plans-upgrade {
  text-align: left;

  & > div {
    padding: 0 0 20px;
    @include breakpoint($tablet) {
      padding: 0 20px;

      &:not(:nth-last-child(3)) {
        border-right: 1px solid $medlightgrey;
      }
    }
  }
}

.estimatedBill {
  font-weight: 900;
  span {
    font-size: 1.5em;
  }
}

.signUpFooterText {
  font-size: 10px;
  line-height: 14px;
  text-align: left;
  color: #7194ac;
  margin-top: 8px;
  padding: 0 20px;
  word-break: normal;
}

.signUpBtn {
  margin-bottom: 31px !important;
}

.creditCardFooterText {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: 13px;
  margin-right: 13px;
}

.creditCardFooterText p {
  float: left;
  padding: 1.7em;
  width: 33.3333%;
  word-break: break-word;
}

.termsCheck {
  margin-bottom: 20px;

  a {
    margin: 5px 0 0 25px;
    text-decoration: underline;
    @include breakpoint($tablet) {
      margin: 0 0 0 5px;
    }
  }
}

.signaturePadBackground {
  background: #e5e8e9;
  grid-column: 1/-1;
}
