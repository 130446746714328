.ticketsTable {
  background-color: #ffffff;

  .tableHeader {
    color: #7194ac;
  }

  .h2 {
    color: #000000;
  }

  .deliveryTicketRow {

    .itemViewRow {
      width: 42px;
      margin-left: 0px;
      display: flex !important;
      alignItems: center !important
    }

    .actionButtons {
      display: block;
      opacity: 0;
    }

    &:hover .actionButtons {
      display: block;
      opacity: 1;
    }

    .actionButtons.secondaryDisplay {
      display: block;
      opacity: 1;
    }

  }
}
