.geosuggest {
	position: relative;
	
	.geosuggest__input-wrapper {
		.geosuggest__input {
			width: 100%;
		}
	}
	.geosuggest__suggests-wrapper {
		.geosuggest__suggests {
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			max-height: 25em;
			padding: 0;
			margin-top: -1px;
			background: $white;
			@include border-radius(0 0 2px 2px);
			border: 1px solid $lightgrey;
			overflow-x: hidden;
			overflow-y: auto;
			list-style: none;
			z-index: 5;
			-webkit-transition: max-height 0.2s;
			transition: max-height 0.2s;
		  
			.geosuggest__item {
				padding: 0 8px;
				line-height: 30px;
				cursor: pointer;
				
				&:hover, &:focus {
					background: $lightgrey;
				}
				
				&.geosuggest__item--active {
					background: $blue;
					color: $white;
					
					&:hover, &:focus {
						background: $darkgrey;
					}
					
					.geosuggest__item__matched-text {
						font-weight: $bold;
					}
				}
			}
			
			&.geosuggest__suggests--hidden {
				max-height: 0;
				overflow: hidden;
			}
		}
	}
}