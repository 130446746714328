.drawer.categoriesSearchList {
  overflow-y: hidden !important;
}

.categoriesSearchList {
  ul {
    li {
      .check {
        margin-left: 20px;
        margin-right: 16px;
      }
    }
  }

  form {
    margin: 98px 17px 0px;

    input {
      width: 100%;
      margin-bottom: 18px;
    }
  }
}
