.adminTerms {
  .scrollarea {
    height: 150px;
    margin-bottom: 20px;
    @include breakpoint($tablet) {
      height: 300px;
      width: span(3 of 4);
    }
  }

  &.adminExports {
    .filter {
      display: flex;

      .dateSelect {
        display: flex;

        .dateSelectDate {
          display: flex;
          width: 40%;
          align-items: center;
          label {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.bottomSpace {
  margin-bottom: 10px !important;
}

#categoriesAddNewCategory {
  svg {
    padding-left: 4px;
  }
}

.decreaseTextTablet {
  @media screen and (max-width: 768px) {
    #categoriesAddNewCategory {
      font-size: 12px;
    }

    #storefront-url-label {
      margin-left: 16px;
    }
  }
}

#storefront-category-name {
  @media screen and (max-width: 768px) {
    width: 120px;
    font-size: 14px;
    max-width: 120px;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 600px) {
    max-width: 80px;
  }
}

#filter-storefront-categories {
  margin-left: 0;
  margin-right: 0;

  @media screen and (max-width: 768px) {
    margin-right: 15px;
  }
}

#storefront-url-value {
  @media screen and (max-width: 768px) {
    left: 40%;
    font-size: 14px;
    max-width: 140px;
    text-overflow: ellipsis;
  }
}

.storefrontCategory, .serializedContainer {
  .storefrontCategoryHeader {
    width: -webkit-fill-available !important;
    margin-right: 7%;

    .tableSlug {
      position: absolute;
      left: 11rem;
      top: 0.5rem;
    }

    .spacedTableHeaders {
      display: flex;
      justify-content: space-between;
    }

    tr {
      position: relative;
    }

    td {
      border-top: 0px;
      font-weight: bold;
      text-align: left;
    }
  }

  .serializedHeader {
    td {
      font-weight: normal;
    }
  }

  .activeMaintenanceHeader {
    td {
      font-weight: normal;
    }
  }

  .maintenanceHistoryHeader {
    td {
      font-weight: normal;
    }
  }
}

.activeMaintenanceHeader {
  td {
    text-align: left;
    width: 30%;
  }

  td:nth-child(1) {
    width: 170px;
    padding-left: 10px;
  }
  td:nth-child(2) {
    width: 170px;
  }
  td:nth-child(3) {
    width: 170px;
  }
  td:nth-child(4) {
    width: 170px;
  }

  th {
    padding-left: 10px !important;
    width: 23%;
  }
}

.maintenanceHistoryHeader {
  td {
    text-align: left;
  }

  td:nth-child(1) {
    width: 170px;
    padding-left: 10px;
  }
  td:nth-child(2) {
    width: 160px;
  }
  td:nth-child(3) {
    width: 170px;
  }

  th {
    padding-left: 10px !important;
    width: 20%;
  }
}

.serializedTableHeader, .serializedHeader, .serializedTableContent {
  td {
    text-align: left;
  }

  td:nth-child(1) {
    width: 25%;
    padding-left: 10px;
  }
  td:nth-child(2) {
    width: 15%;
  }
  td:nth-child(3) {
    width: 32%;
  }
  td:nth-child(4) {
    width: 19%;
  }
}

.usageHistoryCollapsed {
  margin-top: 20px;
  .btnExpand, .btnCollapse {
    margin: 7px 0;
    color: $blue;

    svg {
      fill: $darkgrey;
    }
  }
}

.headerHistory{
  th{
    padding-left: 9px !important;
  }

  th:nth-child(1) {
    width: 22%;
  }
  th:nth-child(2) {
    width: 44%;
  }
  th:nth-child(3) {
    width: 32%;
  }
}

.usageDetails {
  font-size: 10px;
  color: #7194AC;
}

.noBorderTop {
  td:nth-child(1) {
    width: 128px;
  }
  td:nth-child(2) {
    width: 0%;
  }
  td:nth-child(3) {
    width: 0%;
  }
  td:nth-child(4) {
    width: 18%;
  }
  td:nth-child(5) {
    width: 15%;
  }
}

.storefrontSubCategory {
  margin-left: 20px;

  .grid {
    margin-bottom: 0 !important;
  }
}
.serializedTableContent {
  margin-left: 0px;
}

.tagForm {
  .actions {
    width: 100% !important;
  }
}

.taxes {
  .btnLink {
    &.add {
      margin: 0 0 0 15px;
      padding: 0;
      background-color: transparent;
      font-weight: $bold;
    }
  }
  .cancel {
    padding-right: 20px;
    color: #7194ac;
    font-size: 12px;
  }
  .submit {
    margin-right: 20px;
    font-weight: bold;
  }
  .taxInlineForm {
    td {
      &:first-of-type {
        width: 35%;
      }
      &:nth-child(2) {
        width: 24%;
      }
      &:nth-child(3) {
        width: 13%;
      }
    }
  }
}

.categoryActions {
  width: 10rem;
}

.usageUnitsList {
  border-top: 1px solid black !important;

  .unitUsageHeader {
    width: -webkit-fill-available !important;

    tr {
      td {
        border-top: 0px;
        border-top: 1px solid #c1cdd7 !important;
        font-weight: normal;

        &.actionSm {
          width: 160px;
          text-align: right;

          .btnLink {
            margin-left: 5px;

            &.remove {
              color: $red;
            }
          }
        }

        .btn {
          margin: 0 0 0 15px;
          padding: 0;
          background-color: transparent;
          color: $blue;
          font-weight: $bold;

          &.cancel {
            color: $darkgrey;
            font-weight: $regular;
          }
        }
      }
      .lg.right {
        width: 160px;
      }
    }
    tr:nth-child(1) {
      td {
        border-top: none;
      }
    }
  }
}

.paymentTermModal {
  .actions {
    width: 50%;
  }
}

.paymentTermModalSelects {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr;
  width: 85%;
  align-items: center;

  p {
    font-size: 10px;
    letter-spacing: 0;
    line-height: 14px;
    margin-bottom: 0px;
    padding-left: 10px;
    color: #7194ac;

    span {
      color: black;
      font-weight: bold;
    }
  }

  .netValueInput {
    display: grid;
    grid-template-columns: 1.5fr 2fr;

    span {
      align-self: center;
      justify-self: flex-end;
      padding-right: 10px;
      display: flex;
      justify-content: space-around;
      width: 85%;

      svg {
        fill: #268ada;
      }
    }
  }
}

.netContentName {
  display: flex;
  align-items: center;

  svg {
    padding-right: 5px;
  }
}

.noBorderTop {
  tr {
    td {
      border-top: none !important;
    }
  }
}

.grayBG {
  background: #edf0f4;
}

.accordionBorder {
  border-style: solid;
  border-width: 1px;
}

.displayBlock {
  display: block;
}

.w100 {
  width: 100%;
}


.sales_tax_override_group {
  td:nth-child(-n+2) {
    text-align: left;
  }
  td {
    font-weight: bold;
  }
}

.sales_tax_override {
  td:nth-child(-n+2) {
    text-align: left;
  }
  td {
    font-weight: 400;
    color: #8FA9BE !important;
  }
  .name {
    padding-left: 2em;
  }
  .blue_check {
    fill: $blue;
  }
}

.sales_tax_overrides_table {
  th:first-of-type {
    text-align: left;
  }
  th,td {
    text-align: center;
  }

  .medium {
    width: 12em;
  }
}

.slideout_form {
  width: 90% !important;
  margin: 0 5% !important;
}

.footer_buttons {
  position: fixed !important;
}

.top_spacing {
  margin-top: 2em !important;
}

.hover_hide td a {
  display: none;
}
.hover_hide:hover td a {
  display: inline-block;
}