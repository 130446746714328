.product {
  @media screen and (max-width: 768px) {
    padding-bottom: 0 !important;

    h1 {
      margin-left: 15px;
      margin-top: 15px;
    }

    .itemDelete {
      margin-top: 0;
      margin-bottom: 0;
    }

    #delete_product {
      display: flex;
      position: absolute;
      top: 14px;
      right: 80px;

      svg {
        transform: translate(-210%, 95%);
        scale: 1.6;
      }
    }

    .btnLink {
      gap: 8px;
      display: flex;
      margin-top: 20px;
      margin-right: 8px;
      flex-direction: row-reverse;

      svg {
        scale: 1.6;
      }
    }
  }

  @include breakpoint($tablet) {
    display: grid;
    grid-template: 80px auto auto auto 1fr / repeat(6, 1fr);
    grid-column-gap: 20px;

    & > h1 {
      grid-column: 1 / span 5;
      grid-row: 1;
    }

    & > .btnLink {
      grid-column: 6;
      grid-row: 1;
      justify-self: end;
      line-height: 40px;
    }
  }

  .image {
    @include maintain-aspect-ratio(16, 9);
    width: calc(100% + 20px);
    margin: 0 -10px;

    @include breakpoint($tablet) {
      background-color: $white;
      grid-column: 1 / span 3;
      grid-row: 2;
      width: calc(100% + 10px);
      margin: 0 -10px 20px 0;
    }

    .carousel {
      height: 100%;
      background-color: $white;

      .slider-wrapper {
        height: 100%;

        .slider {
          height: 100%;

          .slide {
            height: 100%;
            background-color: $white;
          }
        }
      }

      .control-dots {
        .dot {
          background-color: $meddarkgrey;
          box-shadow: none;
        }
      }

      .control-prev.control-arrow {
        &:before {
          border-right: 8px solid $meddarkgrey;
        }

        &:hover {
          background-color: transparent;
        }
      }

      .control-next.control-arrow {
        &:before {
          border-left: 8px solid $meddarkgrey;
        }

        &:hover {
          background-color: transparent;
        }
      }

      & > li {
        height: 100%;
      }
    }

    figure {
      height: 100%;
      width: auto;
      max-width: 100%;
      max-height: 40vh;

      svg {
        height: 40px !important;
        width: 40px !important;
      }
    }
  }

  .summary {
    min-height: 170px;
    width: calc(100% + 20px);
    margin: 0 -10px 10px;
    padding: 0 $mobilePadding 10px;
    background-color: $white;
    font-size: 14px;
    overflow: auto;

    @include breakpoint($tablet) {
      grid-column: 4 / span 3;
      grid-row: 2;
      display: grid;
      grid-template: 40px auto 1fr / 7fr 3fr;
      width: calc(100% + 10px);
      margin: 0 0 20px -10px;
      padding: 0 20px 10px;
      font-size: 12px;
    }

    h6 {
      color: $blue;
    }

    .token {
      font-size: 10px;
      color: $darkgrey;
      line-height: 30px;
      text-transform: uppercase;

      @include breakpoint($tablet) {
        grid-column: 1 / span 2;
        line-height: 40px;
      }
    }

    &.noToken {
      padding-top: 30px;

      @include breakpoint($tablet) {
        padding-top: 40px;
      }
    }

    .priceLock {
      margin-bottom: 20px;

      @include breakpoint($tablet) {
        grid-column: 2;
      }

      svg {
        height: 20px;
        width: 17px;
        margin-right: 5px;
        fill: $blue;
        vertical-align: bottom;
      }
    }

    .summaryInfo {
      @media screen and (max-width: 768px) {
        width: 200px;
        text-overflow: ellipsis;
        margin-top: 120px;
      }

      @include breakpoint($tablet) {
        grid-column: 1;
        grid-row: span 2;
        padding-right: 20px;
      }

      label {
        font-size: 36px;
        font-weight: $light;
        line-height: 40px;
        color: $blue;
        display: inline-block;
        margin-bottom: 10px;

        span {
          margin-left: 10px;
          color: $darkblue;
        }
      }

      & > strong {
        display: block;
      }

      .buffer {
        display: inline-block;
        margin-left: 10px;

        span {
          color: $darkgrey;
        }
      }

      .groupItems {
        margin: 10px 0 20px;

        div {
          display: flex;

          strong {
            width: 40px;
          }
        }
      }
    }

    .summaryPricing {
      font-size: 18px;
      font-weight: $light;
      line-height: 18px;
      margin-bottom: 40px;

      @include breakpoint($tablet) {
        grid-column: 2;
        font-size: 22px;
        line-height: 22px;
      }

      div {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-row-gap: 2px;
        align-items: end;
        margin-top: 4px;

        span {
          font-weight: 400;
        }

        label {
          font-size: 10px;
          color: $blue;

          @include breakpoint($tablet) {
            line-height: 16px;
          }
        }

        &.fee {
          padding-top: 5px;
          margin-top: 5px;
          border-top: 1px solid $lightgrey;

          @include breakpoint($tablet) {
            padding-top: 10px;
            margin-top: 10px;
          }
        }
      }

      .defaultPricing,
      .noDiscounts {
        font-size: 14px;
      }
    }

    .maintenance {
      @include breakpoint($tablet) {
        grid-column: 2;
        grid-row: 3;
        place-self: end;
      }

      p {
        margin-bottom: 0;

        @include breakpoint($tablet) {
          margin-bottom: 10px;
        }

        & > svg {
          margin-right: 5px;
          height: 15px;
          width: 15px;
          fill: $blue;
          vertical-align: middle;
        }

        span {
          font-weight: $bold;
          color: $blue;
        }
      }

      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .inventory,
  .links,
  .calendar,
  .details {
    @include breakpoint($tablet) {
      grid-column: span 2;
      align-self: start;
    }
  }

  .inventory,
  .links {
    @include breakpoint($tablet) {
      grid-row: span 2;
    }
  }

  .inventory {
    .ledger {
      & > div {
        padding-top: 10px;
        border-top: 1px solid $meddarkgrey;

        .btnLink {
          float: none;
          height: 12px !important;
          width: 12px !important;
          margin-right: 5px;

          svg {
            height: 12px !important;
            width: 12px !important;
            margin: 0;
          }
        }

        p {
          margin-bottom: 2px;

          &:last-of-type() {
            margin-bottom: 10px;
          }
        }

        span {
          display: inline-block;
          margin-right: 10px;
          color: $blue;
        }

        a:not(.btn) {
          text-decoration: underline;
        }

        .right {
          float: right;
          margin-right: 0;

          span {
            margin-right: 0;
          }
        }

        .caption {
          margin-left: 0;

          span {
            color: $darkgrey;
          }
        }
      }
    }
  }

  .links {
    h4 span {
      float: right;
      font-size: 10px;
      font-weight: $regular;

      svg {
        height: auto !important;
        width: 24px !important;
        margin: 0 -5px 0 -4px;
        fill: $blue;
      }

      &.hidden {
        color: $darkgrey;

        svg {
          fill: $darkgrey;
        }
      }
    }

    .toggle {
      width: 100%;
    }
  }

  .calendar {
    margin-bottom: 20px;

    .r-calendar {
      .r-head {
        .r-prev,
        .r-next {
          @include span(1 of 13);
          padding: 10px;
          margin: 0;

          svg {
            fill: $blue;
            vertical-align: middle;
          }

          &:focus {
            outline: none;
          }
        }

        .r-title {
          @include span(11 of 13);
          margin: 0;
          font-size: 28px;
          font-weight: $thin;
          line-height: 32px;
          color: $blue;
          text-transform: none;

          @include breakpoint($tablet) {
            font-size: 22px;
          }
        }
      }

      .r-weekdays {
        margin: 10px 20px;

        @include breakpoint($tablet) {
          margin: 10px 0;
        }

        @media screen and (max-width: 768px) {
          width: 88%;
        }
      }

      .r-dates {
        margin: 0 20px;

        @include breakpoint($tablet) {
          margin: 0;
          font-size: 10px;
          line-height: 10px;
        }

        .r-cell {
          cursor: default;

          span {
            margin: 0;
          }

          a {
            color: $white;
          }

          &:hover {
            background: none;
            color: $darkblue;
          }

          &.r-highlighted,
          &.r-alerting {
            border: none;
          }

          &.r-alerting {
            color: $white;
            background-color: $orange;
            cursor: pointer;

            &:hover {
              background-color: $orange;
            }
          }

          &.r-highlighted {
            color: $white;
            background-color: $blue;

            &:hover {
              background-color: $blue;
            }
          }
        }
      }

      .r-cell {
        width: span(1 of 9);
        margin-left: span(1 of 54);
        margin-right: span(1 of 54);

        @include breakpoint($tablet) {
          width: span(1 of 11);
          margin-left: span(1 of 33);
          margin-right: span(1 of 33);
        }
      }
    }
  }

  .details {
    @include breakpoint($tablet) {
      grid-column: 5 / span 2;
    }

    .iteminfo,
    .specifications {
      & > div {
        padding-top: 10px;

        h6 {
          margin-bottom: 3px;
          color: $blue;

          &:nth-of-type(n + 2) {
            margin-top: 7px;
          }
        }

        span {
          display: block;
        }
      }
    }

    .iteminfo {
      & > div:first-of-type {
        border-top: 1px solid $meddarkgrey;
      }

      @media screen and (max-width: 768px) {
        padding-left: 15px;
      }

      .inline {
        display: flex;
      }
    }

    .specifications {
      display: grid;
      grid-template-columns: 1fr 1fr;

      h4 {
        grid-column: span 2;
      }

      & > div {
        border-top: 1px solid $meddarkgrey;
      }
    }

    .productNotes {
      & > div {
        padding-top: 10px;
        border-top: 1px solid $meddarkgrey;

        & > p {
          margin-bottom: 22px;
        }
      }
    }
  }

  .itemDelete {
    @include breakpoint($tablet) {
      grid-column: span 6;
    }
  }

  .supplierForm {
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
      margin-left: 15px;
      margin-right: 15px;
    }

    h4 {
      @media screen and (max-width: 768px) {
        font-size: 16px;
        font-weight: 700;
      }
    }

    .radioList {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .form .fields {
      grid-template-columns: repeat(5, 1fr);

      .react-datepicker-component:not(.med),
      textarea {
        grid-column: span 5;
      }
    }

    .fixedInputPaddingLeft input {
      padding-left: 38px;
    }
  }

  .sharedMarketplaceCategories {
    @include breakpoint($tablet) {
      grid-column-start: 5 !important;
      grid-column-end: 7 !important;
      grid-row: 4 !important;
    }

    h5 {
      color: $blue;
      font-size: 1em;
    }

    .emailUs {
      border-bottom: 0;
      padding-bottom: 0;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    &.purchaseCategories {
      @include breakpoint($tablet) {
        grid-row: 3 !important;
      }
    }
  }

  .sharedMarketplace {
    @include breakpoint($tablet) {
      min-height: 95%;
      margin-bottom: 0 !important;
    }
  }

  .sharedStorefront,
  .sharedConnect,
  .sharedMarketplace,
  .usageLedger,
  .sharedMarketplaceCategories {
    @include breakpoint($tablet) {
      grid-column: span 2;
      grid-row: 3;
      align-self: start;
    }

    & > p {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $meddarkgrey;
    }

    a:not(.btn) {
      text-decoration: underline;
    }

    .sharedQuantity,
    .metaDataSettings {
      padding: 20px 0 0;
      margin-top: 20px;
      border-top: 1px solid $meddarkgrey;

      .sectionEdit {
        left: 0;

        & + h4 {
          transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        }
      }

      &:hover {
        & > .sectionEdit {
          & + h4 {
            margin-left: 20px;
          }
        }
      }

      .caption {
        margin-left: 0;
      }

      input[type='text'] {
        position: absolute;
        right: 5px;
        bottom: 33px;
        width: calc(#{span(1 of 2)} - 20px);
        z-index: 10;
      }
    }
  }

  .sharedConnect {
    section {
      padding: 0;

      & > p {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $meddarkgrey;
      }

      & > label {
        display: block;
        margin-bottom: 10px;
      }
    }
  }

  .shareItem,
  .moveInventory {
    .justify {
      text-justify: distribute;
      text-align: justify;
    }

    @include breakpoint($tablet) {
      grid-column: span 2;
      grid-row: 3;
      align-self: start;
    }
  }

  .shareItem {
    .viewToggle {
      .toggle-ml-0 {
        & > div {
          & > div {
            margin-left: 0 !important;
          }
        }
      }
      margin-bottom: 20px;
    }

    .alias {
      margin: 5px 0 20px;

      .btnLink {
        svg {
          margin: 0;
        }
      }
    }

    .fields {
      margin-bottom: 20px;

      .btn {
        grid-column: span 4;
        padding: 0;
        margin-right: 0;
      }
    }
  }

  .flex-container {
    @include breakpoint($phone $tablet) {
      flex-direction: column;
      width: 100%;
    }

    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .price-container {
    width: 33%;
  }

  .flex-row-container {
    display: flex;

    @include breakpoint($phone $tablet) {
      flex-direction: column;
      width: 100%;
    }

    .bundles-total-qty {
      font-size: 33px;
      margin-left: 7px;
    }

    .blue-color {
      color: $blue;
    }

    .orange-color {
      color: $orange;
    }
  }

  .item-container {
    width: 50%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    .pricing-info {
      display: inline-grid;
      margin: 10px 0px 10px 0px;

      span {
        color: $blue;
      }
    }

    .location-list-container {
      h1 {
        display: flex;
        color: $darkGray;
        font-weight: $bold;
        margin-bottom: 7px;
      }

      .blue-color {
        color: $blue;
      }

      table {
        width: 100%;

        tr {
          border-top: 1px solid $meddarkgrey;
          height: 31px;

          td:first-child {
            width: 12%;
            text-align: center;
          }

          span {
            color: $blue;
          }

          .grey-color {
            color: $darkgrey;
          }
        }
      }
    }
  }

  .inventory-calendar-container {
    width: 75%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .margin-left-8 {
    margin-left: 8px;
  }

  .margin-left-5 {
    margin-left: 10px;
  }

  .font-bold {
    font-weight: bold;

    &.orange-color {
      color: $orange;
    }
  }

  .border-left-orange {
    border-left: 5px solid $orange;
  }

  .container-filter {
    display: grid;
    grid-column: span 6;
  }

  .buffer-container {
    h2 {
      color: $darkGray;
      font-weight: 510;
      font-size: 48px;
    }

    h5 {
      color: $blue;
    }

    p {
      color: $darkgrey;
    }

    &.display-flex {
      display: flex;
    }
  }

  .wrapper {
    display: grid;
    grid-column: span 6;
    background-color: $white;
    min-height: 170px;
    padding: 22px 22px 10px;
    margin-bottom: 23px;
  }

  .display-content {
    display: contents;

    .wrapper {
      @media screen and (max-width: 768px) {
        margin-top: 4px;
        margin-bottom: 4px;
      }
    }
  }

  .grid-box {
    display: grid;
    grid-column: span 6;
    grid-template-columns: repeat(3, 1fr);
  }

  .container-box {
    grid-column: span 6;
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 30%;
    align-items: self-start;
  }

  .box {
    background: $white;
    width: 325px;
    border: 1px solid $white;
    height: auto;
  }

  .without-background-white {
    background: $lightgrey;
    border: none;

    section {
      padding: 10px 10px;

      @media screen and (max-width: 768px) {
        padding: 10px 10px 10px 0;
      }

      a {
        width: 100%;
      }
    }
  }

  .bundle-box .box {
    margin-right: 15px;

    &.emptyBox {
      border: transparent;
    }

    &.bundleServices {
      margin-bottom: 15px;
    }
  }

  .date-selection {
    display: grid;
    grid-row-gap: 5px;
    margin-bottom: 20px;

    @include breakpoint($tablet) {
      grid-template-columns: 3fr 3fr 0fr;

      textarea {
        grid-column: span 2;
        width: calc(100% - 30px);
      }
    }
  }

  .date-range {
    display: grid;
    grid-gap: 5px;
    grid-auto-rows: max-content;

    label {
      align-self: center;
      color: $darkgrey;
    }

    .sub-heading {
      grid-column: span 3;
      font-size: 10px;
      color: $blue;
    }

    .select {
      display: flex;
      justify-content: space-between;
      align-items: center;
      grid-column: span 2;
    }

    .react-datepicker-component {
      grid-column: 1 / span 1;
    }

    .rc-time-picker {
      grid-column: 2 / span 1;
    }

    .react-datepicker-component,
    .rc-time-picker {
      margin: 0;

      input[type='text'] {
        margin: 0;
      }
    }

    select {
      grid-column: span 2;
    }
  }

  .section-collapse {
    width: 300px;
    margin-left: 21px;

    @include breakpoint($phone $tablet) {
      width: auto;
      margin-left: 8px;
    }

    .btnExpand,
    .btnCollapse {
      margin: 7px 0;
      color: $blue;

      svg {
        fill: $blue;
      }
    }

    hr {
      width: auto;
      border-bottom: 2px solid $blue;
    }

    h4 {
      margin-bottom: 0;
      padding-bottom: 5px;
    }
  }

  .dateSelect {
    display: flex;
    align-items: center;
  }

  .dateSelectFilter {
    display: flex;
    flex-wrap: wrap;
    grid-column: span 6;
    flex: 0 0;
    padding: 0 15px;
    margin-bottom: 20px;
    margin-top: 12px;
  }

  .dateBox {
    width: 142px;

    label {
      color: #7194ac;
      font-size: 10px;
    }
  }

  .react-datepicker-component .react-datepicker-input {
    background-color: $lightgrey !important;
  }

  .qbSection {
    padding: 5px;

    label {
      margin: 0 5px;
    }
  }

  .image-bundle {
    @include maintain-aspect-ratio(16, 9);

    @media screen and (max-width: 768px) {
      height: 200px;
      position: relative;
    }

    @include breakpoint($tablet) {
      background-color: $white;
      grid-column: 1 / span 3;
    }

    .carousel {
      height: 100%;
      background-color: $white;

      .slider-wrapper {
        height: 100%;

        .slider {
          height: 100%;

          .slide {
            height: 100%;
            background-color: $white;
          }
        }
      }

      .control-dots {
        .dot {
          background-color: $meddarkgrey;
          box-shadow: none;
        }
      }

      .control-prev.control-arrow {
        &:before {
          border-right: 8px solid $meddarkgrey;
        }

        &:hover {
          background-color: transparent;
        }
      }

      .control-next.control-arrow {
        &:before {
          border-left: 8px solid $meddarkgrey;
        }

        &:hover {
          background-color: transparent;
        }
      }

      & > li {
        height: 100%;
      }
    }

    figure {
      height: 170px;
      width: 309px;
      max-width: 100%;
      max-height: 40vh;

      @media screen and (max-width: 768px) {
        max-width: none;
      }

      svg {
        height: 40px !important;
        width: 40px !important;
      }
    }
  }

  .summary-bundle {
    h6 {
      color: $blue;
    }

    .token {
      font-size: 10px;
      color: $blue;
      line-height: 30px;
      text-transform: uppercase;

      @include breakpoint($tablet) {
        grid-column: 1 / span 2;
        line-height: 40px;
      }
    }

    .label {
      color: black;
      text-transform: none;
    }

    &.noToken {
      padding-top: 30px;

      @include breakpoint($tablet) {
        padding-top: 40px;
      }
    }

    .priceLock {
      margin-top: 5px;
      margin-bottom: 20px;

      @include breakpoint($tablet) {
        grid-column: 2;
      }

      svg {
        height: 20px;
        width: 17px;
        margin-right: 5px;
        fill: $blue;
        vertical-align: bottom;
      }
    }

    .summaryInfo {
      @include breakpoint($tablet) {
        grid-column: 1;
        grid-row: span 2;
        padding-right: 20px;
      }

      @media screen and (max-width: 768px) {
        width: 200px;
        max-height: 200px;
        text-overflow: ellipsis;
      }

      span {
        font-size: 18px;
      }

      label {
        font-size: 36px;
        font-weight: $light;
        line-height: 40px;
        color: $blue;
        display: inline-block;
        margin-bottom: 10px;

        span {
          margin-left: 10px;
          color: $darkblue;
        }
      }

      & > strong {
        font-size: 18px;
        display: block;
        font-weight: 500;
      }

      .buffer {
        display: inline-block;
        margin-left: 10px;

        span {
          color: $darkgrey;
        }
      }

      .groupItems {
        margin: 10px 0 20px;

        div {
          display: flex;

          strong {
            width: 40px;
          }
        }
      }
    }

    .summaryPricing {
      font-size: 14px;
      font-weight: $light;
      line-height: 18px;
      margin-bottom: 40px;
      display: flex;
      flex-wrap: wrap;
      grid-column: span 6;
      flex: 0 0 30%;

      @media screen and (max-width: 768px) {
        width: 300px;
      }

      @include breakpoint($tablet) {
        font-size: 22px;
        line-height: 22px;
      }

      div {
        width: 146px;
        align-items: end;
        margin-top: 20px;

        span {
          font-size: 16px;
          font-weight: 600;
        }

        label {
          font-size: 10px;
          color: $blue;

          @include breakpoint($tablet) {
            line-height: 16px;
          }
        }

        &.fee {
          padding-top: 5px;
          margin-top: 5px;
          border-top: 1px solid $lightgrey;

          @include breakpoint($tablet) {
            padding-top: 10px;
            margin-top: 10px;
          }
        }
      }

      h6 {
        font-size: 12px;
        font-weight: bold;
      }

      .defaultPricing,
      .noDiscounts {
        font-size: 14px;
      }
    }

    .maintenance {
      @include breakpoint($tablet) {
        grid-column: 2;
        grid-row: 3;
        place-self: end;
      }

      p {
        margin-bottom: 0;

        @include breakpoint($tablet) {
          margin-bottom: 10px;
        }

        & > svg {
          margin-right: 5px;
          height: 15px;
          width: 15px;
          fill: $blue;
          vertical-align: middle;
        }

        span {
          font-weight: $bold;
          color: $blue;
        }
      }

      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .calendar-bundle {
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
      margin-right: 24px;
    }

    .r-calendar {
      .r-head {
        width: 95%;

        .r-prev,
        .r-next {
          @include span(1 of 13);
          padding: 10px;
          margin: 0;

          svg {
            fill: $blue;
            vertical-align: middle;
          }

          &:focus {
            outline: none;
          }
        }

        .r-title {
          @include span(11 of 13);
          margin: 0;
          font-size: 12px;
          font-weight: $bold;
          line-height: 32px;
          color: $blue;
          text-transform: none;

          @include breakpoint($tablet) {
            font-size: 12px;
          }
        }
      }

      .r-weekdays {
        margin: 10px 20px;
        font-weight: $bold;
        color: $blue;

        @include breakpoint($tablet) {
          margin: 10px 0;
        }
      }

      .r-cell {
        width: span(1 of 9);
        margin-left: span(1 of 54);
        margin-right: span(1 of 54);
        // height: 2.5rem;

        @include breakpoint($tablet) {
          width: span(1 of 11);
          margin-left: span(1 of 33);
          margin-right: span(1 of 33);
        }
      }

      .r-dates {
        margin: 0 20px;

        @media screen and (max-width: 768px) {
          width: 90%;
        }

        @include breakpoint($tablet) {
          margin: 0;
          font-size: 10px;
          line-height: 10px;
        }

        &.margin-4 .r-row {
          margin: 4px 0;
        }

        &.selected-range {
          .r-cell {
            margin-right: 0px;
            margin-left: 0px;

            height: 2.5rem;
            width: span(1 of 7);
          }
        }

        .r-cell {
          cursor: default;
          height: inherit;

          span {
            margin: 0;
          }

          a {
            color: $white;
          }

          &:hover {
            background: none;
            color: $darkblue;
          }

          &.r-highlighted,
          &.r-alerting {
            border: none;
          }

          &.r-alerting {
            color: $white;
            background-color: $orange;
            cursor: pointer;

            &:hover {
              background-color: $orange;
            }
          }

          &.r-highlighted {
            color: $white;
            background-color: $blue;

            &:hover {
              background-color: $blue;
            }
          }

          &.r-highlighted-range-blank,
          &.r-highlighted-range {
            height: 2.5rem;

            &.r-highlighted,
            &.r-alerting {
              .date-container {
                width: 60%;
                height: 70%;
                margin: auto;
                border: none;
              }
            }

            &.r-highlighted .date-container {
              color: $white;
              background-color: $blue;

              &:hover {
                background-color: $blue;
              }
            }
          }

          &.r-highlighted-range-blank {
            background-color: $white;
            border-radius: 0px;

            &:hover {
              background-color: $white;
            }

            &.r-alerting .date-container {
              color: $white;
              background-color: $orange;
              cursor: pointer;

              &:hover {
                background-color: $orange;
              }
            }
          }

          &.r-highlighted-range {
            background-color: $darkblue;
            border-radius: 40px 40px 40px 40px;

            &.r-cell-square {
              border-radius: 0px;
            }

            &.r-cell-left {
              border-radius: 40px 0px 0px 40px;
            }

            &.r-cell-right {
              border-radius: 0px 40px 40px 0px;
            }

            &:hover {
              background-color: $darkblue;
              color: $darkgrey;
            }

            &.r-alerting .date-container {
              color: $white;
              background-color: $orange;
              cursor: pointer;

              &:hover {
                background-color: $orange;
              }
            }
          }
        }
      }

      .r-cell {
        width: span(1 of 9);
        margin-left: span(1 of 54);
        margin-right: span(1 of 54);

        @include breakpoint($tablet) {
          width: span(1 of 11);
          margin-left: span(1 of 33);
          margin-right: span(1 of 33);
        }
      }
    }
  }

  .details-product {
    background-color: $white;

    section {
      @media screen and (max-width: 768px) {
        margin-bottom: 0px;
      }
    }

    @include breakpoint($tablet) {
      grid-column: 5 / span 2;
    }

    &.padding-10 {
      padding: 0px 10px 0px 10px;

      @media screen and (max-width: 768px) {
        padding: 0;
      }
    }

    .iteminfo,
    .specifications {
      h4 {
        @media screen and (max-width: 768px) {
          font-size: 16px;
          font-weight: 700;
        }
      }

      & > div {
        padding-top: 10px;

        h6 {
          margin-bottom: 3px;
          color: $blue;

          &:nth-of-type(n + 2) {
            margin-top: 7px;
          }
        }

        span {
          display: block;
        }
      }

      .display-flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .font-bold-blue {
        font-weight: $bold;
        color: $blue;
      }

      .margin-left-10 {
        margin-left: 10px;

        @media screen and (max-width: 768px) {
          margin-left: 0;
        }
      }
    }

    .iteminfo {
      margin-bottom: 0;

      @media screen and (max-width: 768px) {
        padding-left: 15px;
        border-top: 2px solid #E5E8E9;

        &:not(:last-child) {
          border-bottom: 2px solid #E5E8E9;
        }
      }

      & > div:first-of-type {
        border-top: 1px solid $meddarkgrey;
      }

      .without-border {
        border: none !important;
      }

      .inline {
        display: flex;
      }

      .text-wrap {
        overflow-wrap: anywhere;
      }

      .note-specs-flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        p {
          color: $blue;
        }
      }

      .eye-icon {
        rect {
          fill: transparent !important;
        }

        path {
          fill: $blue;
        }
      }
    }

    .iteminfo {
      .maintenance {
        @include breakpoint($tablet) {
          grid-column: 2;
          grid-row: 3;
          place-self: end;
        }

        p {
          margin-bottom: 0;

          @include breakpoint($tablet) {
            margin-bottom: 10px;
          }

          & > svg {
            height: 15px;
            width: 15px;
            fill: $blue;
            vertical-align: middle;
          }

          span {
            font-weight: $bold;
            color: $blue;
          }

          .display-flex {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }

        a {
          cursor: pointer;
          width: 100%;
        }

        .without-border {
          border: none !important;
        }

        table {
          display: grid;
          width: 100%;
          border-top: 1px solid $meddarkgrey;
          margin-top: 10px;

          tbody {
            margin-top: 10px;

            tr {
              height: 31px;

              td:first-child {
                width: 15%;
                text-align: center;
              }

              td:last-child {
                width: 2%;
                text-align: end;
              }

              label {
                color: $blue;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    .specifications {
      display: grid;
      grid-template-columns: 1fr 1fr;

      h4 {
        grid-column: span 2;
      }

      & > div {
        border-top: 1px solid $meddarkgrey;
      }
    }

    .productNotes {
      & > div {
        padding-top: 10px;
        border-top: 1px solid $meddarkgrey;

        & > p {
          margin-bottom: 22px;
        }
      }

      .btnIcon.preview {
        float: right;
        height: 22px;
        width: 23px;
        margin: 0 0 0 5px;
        line-height: 22px;

        svg {
          height: 22px !important;
          width: 22px !important;
        }
      }
    }
  }

  .filter-radio-group {
    margin-left: 12px;

    .radio {
      max-width: 20px;
      margin-top: 6px;
    }

    div {
      div {
        align-items: center;

        label {
          margin-right: 10px !important;
          font-weight: bold;
          white-space: nowrap;
          font-size: 12px !important;
          color: $white !important;
        }

        div {
          margin-right: 3px !important;
          margin-top: 0px !important;
          width: 20px !important;
          height: 20px !important;
        }
      }
    }
  }

  .filter-tokens {
    width: 13px;
    clear: both;
    margin: 18px 0 0 0;
    overflow: auto;

    & > div {
      span {
        display: inline-flex;
        align-items: center;
      }

      float: right;
      margin-left: 20px;
      line-height: 35px;

      .tag {
        display: inline-block;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .btnLink {
        svg {
          height: 12px !important;
          width: 13px !important;
        }
      }
    }
  }
}

.tagCategory {
  font-size: 10px !important;
  color: $darkgrey !important;
  margin-left: 5px !important;
}

.product.noSpace {
  grid-template: 0 auto auto auto 1fr / repeat(6, 1fr);
}

.usageLedger.no-bg {
  background: transparent;
}

.usageLedger {
  h4 {
    margin-bottom: 0;
    padding-bottom: 10px;
    border-bottom: solid 1px #c1cdd7;
  }

  .inner-body {
    padding-top: 15px;

    input[type='text'] {
      width: 90px;
    }

    input[type='text'].barcode {
      width: 100%;
    }

    label {
      margin-left: 10px;
    }

    .notes {
      width: 100%;
      height: 65px;
      resize: none;
    }
  }

  .inner-body + .inner-body {
    border-top: solid 1px #c1cdd7;
    margin-top: 15px;
  }

  .full-block {
    display: inline-block;
    width: 100%;

    h6 {
      color: #278ada;
    }
  }

  h6 {
    font-size: 12px;
    line-height: 15px;
  }

  .left {
    float: left;
    width: 70%;
  }

  .right {
    float: right;
    width: 30%;
    text-align: right;
  }

  .font-bold {
    font-weight: $bold;
  }

  .mb-5 {
    margin-bottom: 5px;
  }

  .ml-15 {
    margin-left: 15px;
  }

  .mt-25 {
    margin-top: 25px;
  }

  .mb-25 {
    margin-bottom: 25px;
  }
}

.usage-export {
  .date-selector {
    display: flex;
    justify-content: space-between;

    label {
      color: $bluegrey;
    }
  }

  h4 {
    margin-top: 15px;
  }

  .item-list {
    padding: 5px 30px;
  }

  .margin-top-15 {
    margin-top: 15px !important;
  }

  .checkbox {
    svg {
      margin-right: 6px;
    }
  }

  .scrollarea {
    &.height-100 {
      height: 100px;
    }

    &.height-70 {
      height: 70px;
    }
  }
}

.schedule-maintenance-form-content-drawer {
  margin: 60px 15px 0px 15px;

  input[type='text'] {
    width: 100%;
    margin-bottom: 10px;
  }

  select {
    width: 100%;
    margin-left: 0;
    margin: 10px 0px;
  }

  .display-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;

    .width-25 {
      width: 25%;
      margin-bottom: 0;
    }

    p {
      color: $white;
      font-size: 12px;
      margin-bottom: 0;
    }

    input {
      width: 48%;
      text-align: center;
    }

    select {
      width: 48%;
      margin: 0;
    }
  }
}

.scheduled-maintenance-table {
  width: 100%;

  .notes-row {
    border: none;
    align-items: center;
    color: $bluegrey;
  }

  .blue-color svg {
    fill: $blue;
  }

  tr {
    height: 25px;
    border-top: 1px solid $meddarkgrey;

    span {
      font-weight: $bold;
    }

    p {
      margin-bottom: 0;
    }

    .width-50 {
      width: 50%;
    }
  }
}
