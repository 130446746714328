.business {
  section {
    display: grid;
    grid-gap: 20px;
    @include breakpoint($tablet) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px;
    }
  }

  figure,
  .uploadZone {
    align-self: start;
    margin-bottom: $innerPadding;
  }

  figure {
    @include maintain-aspect-ratio(16, 9);
    background-color: $darkblue;

    svg {
      height: 50% !important;
      width: 50% !important;
      fill: $cobalt;
    }
  }

  .uploadZone .drop {
    @include maintain-aspect-ratio(16, 9);

    .placeholder svg {
      margin-top: 55px;
      @include breakpoint($tablet) {
        margin-top: 70px;
      }
    }
  }

  img {
    width: 100%;
  }

  .details {
    @include breakpoint($tablet) {
      text-align: center;
    }

    label {
      white-space: normal;
    }

    .strikeThrough + div {
      font-weight: $bold;
      color: $blue;
    }
  }

  .iconView {
    .info {
      margin-top: 7px;
    }
  }

  .iconForm .info {
    position: absolute;
    top: 149px;
    right: 4px;
    @include breakpoint($tablet) {
      top: 148px;
      right: 4px;
    }
  }
}

.accountSubscription {
  section > strong {
    @include breakpoint($tablet) {
      position: absolute;
      top: 10px;
      right: 20px;
    }
  }

  .settings {
    margin-bottom: 20px;

    a {
      text-decoration: underline;
    }
  }
}

.plan {
  margin-bottom: 0;

  h2 {
    margin: 0;
    font-size: 22px;
    font-weight: $bold;
    line-height: 24px;
    color: $blue;

    & + .price {
      color: $blue;
    }
  }

  h6 {
    display: inline-block;
    padding: 0 12px;
    margin: 0 0 10px;
    color: $white;
    background-color: $purple;
    font-weight: $bold;
    font-size: 11px;
    line-height: 20px;
    text-transform: uppercase;
  }

  .price {
    display: flex;
    align-items: center;
    font-size: 48px;
    line-height: 52px;
    font-weight: $bold;

    p {
      margin: 0 0 0 5px;
      font-size: 14px;
      line-height: 16px;
    }

    .discount {
      position: relative;
      margin: 14px 5px 0 0;
      font-size: 26px;
      line-height: 26px;

      &:after {
        content: ' ';
        position: absolute;
        height: 3px;
        width: 54px;
        background-color: $red;
        top: calc(50% - 2px);
        left: -3px;
        transform: rotate(-25deg);
      }
    }
  }

  .caption {
    margin: 0;
  }

  ul {
    li {
      font-weight: $bold;
      line-height: 25px;

      svg {
        height: 15px !important;
        width: 15px !important;
        margin-right: 5px;
        fill: transparent;
        color: $darkblue;
        vertical-align: middle;
      }
    }

    &.highlight {
      color: $blue;

      svg {
        color: $blue;
      }
    }
  }

  .check {
    line-height: 25px;
  }

  .btn {
    width: 100%;
    margin-top: 10px;
    padding: 0;
  }
}

.plan-details {
  display: block;
  text-align: center;
  font-weight: bold;

  .plan-name {
    border: 1px;
    background: #8fa9be;
    color: white;
    font-size: 11px;
    padding: 5px 0;

    &.standard {
      background: #6696bd;
    }

    &.select {
      background: #3781be;
    }

    &.premier {
      background: #0e2c44;
    }

    &.cart {
      background: #6696bd;
    }

    &.complete {
      background: #3781be;
    }

    &.advanced {
      background: #17578a;
    }

    &.full {
      background: #3781be;
    }
  }

  .plan-data {
    margin: 10px 10px;
    height: 125px;

    &.user {
      height: 100px;
    }

    .user {
      height: 350px !important;
    }

    &.disabled {
      opacity: 0.4;
    }
  }

  .plan-header {
    margin-top: 10px;
    text-align: center;

    @media (min-width: 769px) {
      height: 30px;
    }

    &.storefront {
      text-align: center;
      height: 40px;
    }

    .highlight {
      color: #278ada;
    }

    &.most-common-header {
      display: flex;

      .most-popular-option {
        display: flex;
        align-items: center;
      }
    }
  }

  .price {
    display: flex;
    align-items: center;

    &.storefront {
      display: block;
      text-align: left;
      p {
        margin-bottom: 0px;
      }
    }

    .price-value {
      margin-right: 6px;
      margin-top: 25px;
      margin-bottom: 25px;
      font-size: 48px;
      text-align: left;

      &.storefront {
        text-align: left;
        display: block;
        margin-bottom: 14px;
        &p {
          text-align: left;
        }
      }
      &.proplan {
        margin-right: 6px;
        margin-top: 16px;
        margin-bottom: 15px;
        font-size: 53px;
        text-align: left;
        margin-left: 8px;
      }
    }

    .per-user {
      margin-bottom: 0px;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      line-height: 18px;
      align-items: flex-start;
      color: #278ada;
    }
  }

  .caption {
    margin: 0;
    text-align: left;

    &.storefront {
      text-align: left;
    }
    &.proplan {
      margin: 0;
      text-align: left;
      margin-left: 10px;
    }
    &.premierplan {
      margin: -23px;
      text-align: left;
      margin-left: 3px;
    }
    &.user {
      margin: 0;
      text-align: left;
      margin-top: -9px;
    }
  }

  .monthlyPrice {
    height: 40px;
    margin-bottom: 20px;
  }

  ul {
    text-align: left;

    &.sf-plan {
      height: 200px;
      margin-left: 0px;
      margin-top: 8px;

      .highlight {
        word-break: normal;
      }

      li {
        display: flex;
      }
    }

    li,
    p {
      // margin-bottom: 6px;
      &.highlight {
        color: #278ada;
      }
    }
  }

  .normalFont {
    font-weight: 450;
    &.storefront {
      margin-left: 15px;
    }
  }

  svg {
    height: 20px !important;
    width: 20px !important;
    margin-right: 5px;
    fill: transparent;
    color: $darkblue;
    vertical-align: middle;
  }

  ul {
    height: 350px;
    text-align: left;
    margin-left: 20px;
    margin-top: -20px;
    margin-bottom: 40px;

    li {
      font-weight: $bold;
      line-height: 25px;
      display: flex;
      align-items: baseline;

      &.storefront {
        line-height: 0px;
      }

      svg {
        height: 12px !important;
        width: 12px !important;
      }

      &.highlight {
        color: #278ada;
        font-size: 10px;
        font-weight: 400;
        margin-left: 17px;
      }
    }

    &.highlight {
      color: $blue;

      svg {
        color: $blue;
      }
    }
  }
  .seoTag {
    text-align: left;
  }
}

.businessCheck {
  & > svg {
    color: $blue;
    fill: $white;
    height: 18px !important;
    width: 18px !important;
  }
}

.plans {
  display: grid;
  @include breakpoint($tablet) {
    grid-template-columns: repeat(3, 1fr);
    &.user {
      grid-template-columns: repeat(2, 1fr);

      ul {
        height: 200px;
        text-align: left;
        margin-top: -15px;
        margin-left: 9px;

        &.sf-plan {
          height: 200px;

          li {
            display: block;
          }
        }

        li,
        p {
          // margin-bottom: 6px;
          &.highlight {
            color: #278ada;
          }
        }
      }
    }
  }

  .actions {
    display: block;

    .usersCount {
      display: flex;
      align-items: center;

      .btn {
        margin-bottom: 10px;
      }

      a:not(.btn) {
        grid-column: span 3;
        font-size: 10px;
        text-decoration: underline;
        margin-left: 40px;
      }

      & > p {
        grid-column: span 3;
        font-size: 12px;
        text-align: center;
        color: $darkblue;
        margin-left: 2px;
        padding: 5px;
      }

      .usersInput {
        width: 75px;
        text-align: center;
      }
    }
  }

  & > div {
    position: relative;
    min-width: 0;
    @include breakpoint($tablet) {
      .plan {
        &:first-of-type {
          height: 250px;
        }

        &:nth-of-type(2) {
          height: 215px;
        }
      }
    }

    .actions {
      div {
        div {
          div {
            div {
              svg {
                margin-top: 3px;
                height: 18px !important;
              }
            }
          }
        }
      }

      .btn {
        margin-bottom: 10px;
      }

      a:not(.btn) {
        grid-column: span 3;
        font-size: 10px;
        text-decoration: underline;
        margin-left: 40px;
      }

      p {
        grid-column: span 3;
        font-size: 10px;
        text-align: center;
        color: $darkblue;
        margin-bottom: 0px;
      }
    }
  }
}

.sf-plans {
  display: grid;
  @include breakpoint($tablet) {
    grid-template-columns: repeat(4, 1fr);
  }

  & > div {
    position: relative;
    min-width: 0;
    @include breakpoint($tablet) {
      .plan {
        &:first-of-type {
          height: 250px;
        }

        &:nth-of-type(2) {
          height: 215px;
        }
      }
    }

    .actions {
      display: block;

      .radio {
        width: unset !important;
        height: 20px !important;
        margin-left: auto;
        margin-right: auto;

        label {
          width: 100% !important;
        }
      }

      .learn-more {
        text-align: center;
      }
    }
  }
}

.planModal {
  // top: 50% !important;
  .content .body {
    // height: 500px;
    max-height: 1vh;
    @include breakpoint($tablet) {
      .viewToggle {
        width: 200px;
        margin: 0 auto 10px;
      }
      .plan {
        width: 180px;
        margin: 0 auto 20px;
      }
    }
  }

  .storefrontAmount {
    input {
      grid-column: span 3;
    }
  }

  .gpsAmount {
    input {
      grid-column: span 2;
    }
  }
}

.legalSettings {
  section {
    min-height: 75px;

    .videoButton {
      float: right;
      margin-left: 10px;
    }
  }

  .accordian {
    .header > div {
      font-weight: $bold;

      span,
      a {
        float: right;
        color: $blue;
      }

      a {
        font-weight: $regular;
        text-decoration: underline;
      }
    }

    .content {
      .scrollarea,
      .selection {
        @include breakpoint($tablet) {
          width: span(6 of 8);
        }
      }

      .selection {
        margin-bottom: 5px;
      }

      .scrollarea {
        margin-bottom: 20px;
        max-height: 200px;
      }

      .itemDelete {
        margin: 0 0 20px;
      }
    }
  }
}

%base-settings-class {
  @include breakpoint($tablet) {
    width: span(13 of 17);
    @include post(4 of 17);
  }

  .accordian {
    .header {
      & > div {
        label {
          @include breakpoint($tablet) {
            width: 170px;
          }
        }
      }
    }
  }

  .settingsDetails {
    .details {
      width: span(7 of 8);
      @include breakpoint($tablet) {
        @include push(1 of 13);
        width: span(9 of 13);
        @include post(2 of 13);
      }

      & > div {
        position: relative;
        min-height: $mobileVertical;
        padding: 13px 0 11px;
        border-bottom: 1px solid $meddarkgrey;
        @include breakpoint($tablet) {
          min-height: $vertical;
          padding: 11px 0 10px;
        }

        &:last-of-type() {
          border-bottom: 1px solid transparent;
        }

        & > svg {
          display: none !important;
          position: absolute;
          left: -33px;
          top: 11px;
          height: 20px !important;
          width: 20px !important;
          color: $blue;
          fill: $white;
          @include breakpoint($tablet) {
            display: inline-block !important;
          }
        }
      }

      label,
      span {
        display: inline-block;
        line-height: 16px;
      }

      label {
        width: span(1 of 2);

        &.caption {
          width: auto;
        }
      }

      span {
        font-weight: $bold;
        @include breakpoint($tablet) {
          font-size: 16px;
        }
      }

      .mileage {
        @include breakpoint($tablet) {
          padding-top: 17px;
        }
      }

      .location {
        font-weight: $bold;

        h6 {
          margin-top: 12px;
        }
      }
    }

    .summary {
      padding-top: 15px;
      border-top: 2px solid $cobalt;
      @include breakpoint($tablet) {
        @include push(1 of 13);
        width: span(9 of 13);
        @include post(2 of 13);
      }

      span {
        font-weight: $bold;
        @include breakpoint($phone $tablet) {
          display: block;
        }
      }

      .caption {
        margin-left: 0;
      }
    }

    &.wide .details {
      label {
        width: 100%;
        @include breakpoint($tablet) {
          width: span(3 of 4);
        }

        &.caption {
          width: auto;
        }
      }
    }

    &.expiration .details {
      width: span(7 of 8);
      @include breakpoint($tablet) {
        @include push(1 of 13);
        width: span(11 of 13);
      }
    }

    &.taxes .details {
      width: span(7 of 8);
      @include breakpoint($tablet) {
        @include push(1 of 13);
        width: span(11 of 13);
      }

      label {
        width: 100%;
        white-space: normal;

        span {
          margin-left: 20px;
        }
      }
    }
  }

  .quoteexp {
    & > div {
      position: relative;
      min-height: $mobileVertical;
      border-bottom: 1px solid $meddarkgrey;
      @include breakpoint($tablet) {
        min-height: $vertical;
        border-bottom: 1px solid $meddarkgrey;
      }

      &:last-of-type() {
        border-bottom: 1px solid transparent;
      }

      & > svg {
        display: none !important;
        position: absolute;
        //left: -33px;
        top: 11px;
        height: 20px !important;
        width: 20px !important;
        color: $blue;
        fill: $white;
        @include breakpoint($tablet) {
          display: inline-block !important;
        }
      }
    }

    label,
    span {
      display: inline-block;
      line-height: 12px;
      margin-top: 15px;
      margin-left: 30px;
      padding-right: 8px;
    }

    span {
      font-weight: $bold;
      @include breakpoint($tablet) {
        font-size: 12px;
      }
    }

    .heading {
      margin-top: 20px !important;
      margin-left: 0px !important;
    }
  }
}

.input100 {
  width: 100%;
}

.rentalSettings,
.inventorySettings,
.multilocationSettings,
.picklistSetting,
.contactsSettings {
  @extend %base-settings-class;
}

.picklistSetting {
  .dragGrid {
    &.isOver {
      position: relative;

      &:after {
        content: ' ';
        position: absolute;
        bottom: -1px;
        left: 20px;
        right: 0;
        height: 3px;
        background-color: $blue;
      }
    }
  }

  .accordian .header > div {
    width: auto !important;
  }

  .accordian .header label {
    color: #0e2c44;
  }

  .btnExpand svg {
    height: 17px !important;
    width: 18px !important;
  }
}

.rentalSettings,
.multilocationSettings {
  h2 {
    clear: both;
    margin-top: 30px;
  }
}

.rentalSettings {
  .viewToggle {
    margin-bottom: 40px;

    & > div > div > div {
      margin-left: 0 !important;
    }
  }

  .viewToggle .creditCardPercent {
    padding-left: 67px;
  }

  .disabled {
    color: #606f87;
  }

  #credit_card_fee_percentage {
    font-weight: bold;
  }

  .accordian .viewToggle {
    margin-bottom: 10px;

    &.displaySettings {
      padding: 10px 20px 0 20px;
      border-top: 1px solid #c1cdd7;
    }
  }

  .taxBreakDownSettings {
    padding: 10px 0 0 0;
    border-top: 1px solid transparent;
    display: flex;

    .check {
      display: inline-block;
      width: 5%;
    }

    label {
      width: 80%;
      display: inline-block;
      word-break: break-word;
      white-space: pre-wrap;
    }
  }

  .taxBreakDownActions {
    padding: 10px 20px 0 20px;
  }
}

.inventorySettings {
  @media screen and (max-width: 768px) {
    padding-left: 15px;
    overflow: scroll;
  }
}

.inventorySettings {
  p,
  .bullets {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 768px) {
    h1 {
      .headerNav {
        font-size: 14px;
        font-weight: bold;
      }
      margin-left: 20px;
      margin-bottom: 10px
    }
  }

  .bullets-container {
    @media screen and (max-width: 768px) {
      margin-left: 25px;
      margin-right: 25px;

      li {
        margin-bottom: 10px;
      }
    }
  }

  h1 .headerNav {
    color: $darkblue;
  }

  section {
    .group {
      & > .grid {
        td {
          border-top: 1px solid $darkblue;

          .btn {
            margin: 0 0 0 15px;
            padding: 0;
            background-color: transparent;
            color: $blue;
            font-weight: $bold;

            &.cancel {
              color: $darkgrey;
              font-weight: $regular;
            }
          }
        }
      }

      .dragGrid.indent {
        width: 100%;
        margin-left: 0;
      }

      .toggle {
        width: calc(100% - 20px);
        margin: 10px 0 10px 20px;
      }
    }
  }

  #pricings_section {
    h2 {
      color: #0e2c44;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 10px;
      line-height: 20px;
      padding-bottom: 10px;
    }

    .list {
      margin-bottom: 30px;

      p {
        border-bottom: 1px solid #c5c5c5;
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
    }
  }

  #rate_calculator_settings {
    #add_department_rates {
      margin-bottom: 12px;
    }

    #rate_calculations_section {
      a {
        background-color: $blue;
        cursor: default;

        &.nonDefaultDepartmentPricing {
          opacity: 0;
        }
      }

      .rate_calculations_header {
        display: flex;
        justify-content: space-between;

        .rate_calculations_header_actions {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          .btn {
            width: 150px !important;
          }
        }

        svg {
          height: 18px !important;
          fill: $meddarkgrey;
          margin-top: 8px;
          cursor: pointer;

          &:hover {
            fill: $navyblue;
          }
        }
      }

      .defaultPricing {
        color: $darkgrey;
        font-weight: 400 !important;

        .pricingMultiplier {
          color: $darkgrey !important;
        }
      }

      .pricingSetting {
        font-weight: bold;

        .pricingMultiplier {
          color: $blue;
          margin-right: 4px;
        }
      }

      .pricingExample {
        color: $darkgrey;
      }

      table {
        border-bottom: 1px solid $meddarkgrey;
      }

      th {
        border-bottom: 1px solid $meddarkgrey;

        &:first-of-type {
          width: 120px;
        }
      }

      tbody a.btnLink.config {
        fill: $darkgrey;
        opacity: 0;
        transition: opacity 0.2s ease-out;

        &:hover {
          opacity: 1;
        }
      }

      tbody > tr:first-of-type > td {
        border-top: none;
      }

      tbody > tr {
        border-bottom: 1px solid $meddarkgrey;
      }

      tbody > tr > td:first-of-type {
        font-weight: bold;
      }
    }

    .rate_calculator_table {
      .rate_calculator_table_rate {
        display: flex;
        border-top: 1px solid $midblue;
        padding: 4px 0px;
        align-items: center;

        div,
        input {
          width: 25%;
          color: $lightgrey;
          padding: 0 12px;
        }

        input {
          color: $darkblue;
          text-align: center;

          &:disabled {
            color: $white;
            background-color: $navyblue;
          }
        }
      }
    }

    .rate_calculator_history {
      margin-top: 48px;
    }

    select {
      width: 420% !important;
      margin: 0 0 24px 0;
    }
  }

  #order_durations_settings {
    .headerNav {
      div {
        width: 100%;
        margin-right: 12px;
        color: $darkgrey;
        cursor: pointer;

        &:hover {
          font-weight: bold;
        }

        &.selected {
          color: $blue;
          font-weight: bold;
          border-bottom: 4px solid $blue;
        }
      }
    }

    select#duration_rate {
      width: 420% !important;
      margin: 0;
    }

    #order_durations_section {
      th {
        border-bottom: 2px solid $blue;

        &:first-of-type {
          width: 120px;
        }
      }

      tbody a.btnLink.config {
        fill: $darkgrey;
        opacity: 0;
        transition: opacity 0.2s ease-out;

        &.firstRow {
          opacity: 0.7;
        }

        &:hover {
          opacity: 1;
        }
      }

      tbody > tr:first-of-type > td {
        border-top: none;
      }

      tbody > tr > td:first-of-type {
        font-weight: bold;
      }

      .pricingExample {
        color: $darkgrey;
      }
    }
  }
}

.contactsSettings {
  .categories {
    p {
      width: 500px;
      margin-bottom: 27px;
    }

    > section {
      padding-top: 14px;

      .btn {
        margin: 0 0 0 15px;
        padding: 0;
        background-color: transparent;
        color: $blue;
        font-weight: $bold;

        &.cancel {
          color: $darkgrey;
          font-weight: $regular;
        }
      }

      table {
        width: 100%;

        input.error {
          margin-left: 2px;
        }
      }
    }
  }
}

.conflictList {
  li {
    font-size: 14px !important;
  }
}

.reportSettings {
  .generalLedgerSettings {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 36px;

      h1 {
        display: inline-block;
        margin: 0;
      }

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;

        a {
          & > svg {
            width: 25px !important;
            height: 25px !important;
          }
        }
      }
    }

    .drawer {
      display: flex;
      flex-direction: column;
      height: 100vh;

      h1 {
        padding: 0 16px;
        color: white;
        font-size: 18px;
        letter-spacing: 0;
      }

      background-color: #0e2c44 !important;

      .drawerForm {
        background-color: $navyblue;
      }

      .drawerContent {
        display: flex;
        flex-direction: column;
        height: 100%;

        label {
          display: inline-block;
          line-height: 18px;
          color: #278ada;
          font-weight: bold;
        }

        & > label {
          padding: 0 16px;
        }

        & > div {
          & > label {
            padding: 0 16px;
          }
        }

        .mt-30 {
          margin-top: 30px;
        }

        .mt-15 {
          margin-top: 15px;
        }

        .form {
          padding: 0px 15px;

          input {
            margin-bottom: 16px;
          }

          select {
            background-color: white;
            margin-top: 0;
          }

          div {
            grid-column: 1/-1;
          }

          .departmentToggle {
            margin-top: 8px;
          }
        }

        .departmentOptions {
          max-width: 100%;
        }

        input[type='search'] {
          margin: 5px 17px 30px !important;
          width: 306px !important;
        }

        .actions {
          margin: 16px;
        }
      }
    }

    .checkList {
      width: 100%;

      overflow: auto;

      & > div:first-child {
        border-top: 0px;
        border-bottom: 0px;
      }

      & > div:not(:first-child) {
        border-top: 1px solid #1b4160;
        border-bottom: 0px;
      }

      & > div {
        label.checkListItem {
          .itemContent {
            font-weight: 500 !important;

            .btnLink {
              margin: 0 !important;
            }

            svg {
              margin: 0 !important;
            }
          }
        }
      }
    }

    .table {
      td {
        height: 30px;
      }

      .buttonContainer {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 12px;

        .btnLink {
          svg {
            height: 20px !important;
            width: 20px !important;
            color: $meddarkgrey;
          }
        }
      }
    }

    .checkListItem {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      align-items: center;
      display: flex;

      height: 35px !important;

      .check {
        top: 8px;
      }

      .itemContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 15px;
        color: $meddarkgrey;

        .itemActions {
          display: flex;
          gap: 2px;

          .btnLink.edit {
            svg {
              fill: $darkgrey !important;
              height: 18px !important;
              width: 18px !important;
            }
          }

          svg {
            fill: $orange;
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
}

.zapierSettings {
  width: 356px;

  h1 {
    color: #8fa9be;
    font-size: 18px;
    margin-bottom: 37px;
  }

  .zapierBanner {
    margin-bottom: 25px;
  }

  .printWebhookArea {
    display: flex;
    justify-content: space-between;

    p {
      color: #0e2c44;
      font-size: 12px;
      font-weight: bold;
    }
  }

  svg {
    fill: #466b88;
    height: 15px;
    width: 16px;
  }

  .editZapierSettings {
    input {
      width: 100%;
    }
  }

  .actions {
    margin-top: 5px;

    .cancel {
      margin-right: 0px !important;
    }
  }

  .testZapierAction {
    margin-bottom: 5px;
  }

  .aboutZapierOverview {
    margin-top: 30px;

    a {
      text-decoration: underline;
    }
  }
}

.multilocationSettings {
  section {
    @include breakpoint($tablet) {
      width: 50%;
    }
  }

  .caption {
    margin-left: 0;
  }

  .locationCode {
    & > form,
    & > div {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-column-gap: 5px;
      align-items: center;
      margin-bottom: 20px;
      @include breakpoint($tablet) {
        grid-template-columns: 5fr 2fr 3fr 2fr;
        line-height: 30px;
      }

      & > * {
        min-width: 0;
      }

      strong {
        text-transform: uppercase;
      }

      input[type='text'] {
        margin: 0;
        text-transform: uppercase;
      }

      .btn {
        margin: 0;
        padding: 0;
      }

      .btnLink {
        justify-self: end;
        margin: 0;
        @include breakpoint($tablet) {
          grid-column: 4;
        }

        svg {
          margin: 0;
        }
      }
    }
  }

  .groupSettings {
    & > div {
      display: grid;

      input[type='text'],
      .btn {
        width: 100%;
        margin-right: 0;
      }

      &:first-of-type {
        margin-bottom: 20px;
        @include breakpoint($tablet) {
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 40px;
        }
      }

      &:last-of-type {
        @include breakpoint($tablet) {
          grid-template-columns: 2fr 3fr;
          align-items: center;
        }
      }
    }
  }

  .groupDetails {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    margin-bottom: 20px;

    .btnLink {
      justify-self: end;

      svg {
        margin: 0;
      }
    }
  }
}

.billingSettings {
  @include breakpoint($tablet) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    align-items: start;
  }

  .icon {
    position: relative;
    height: 110px;
    width: 110px;
    margin-bottom: $innerPadding;
    background-color: $blue;
    @include border-radius(50%);

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 36px;
      width: 52px;
      fill: $white;
    }
  }

  & > .btn {
    width: 100%;
    margin-bottom: 20px;
    @include breakpoint($tablet) {
      width: auto;
    }
  }

  .grid .btn {
    width: 100%;
    padding: 0;
    margin: 0;
  }
}

.serializedSettings {
  @include breakpoint($tablet) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;

    .standard,
    .lite {
      .plan {
        margin-bottom: 45px;
      }
    }
  }

  & > p {
    grid-column: span 3;

    &:last-of-type {
      margin-bottom: 40px;
    }
  }
}

.permissions {
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;

    .top {
      height: 80px;

      select {
        width: 100%;
        margin: 0px;
      }

      .caption {
        color: #7194ac;
        font-size: 10px;
        margin: 12px 0 0 0;
      }
    }

    .accordian {
      border-top: none;
      margin-bottom: 55px;

      &.permission-group-hidden {
        display: none;
      }

      .header {
        display: grid;
        align-items: center;

        h4 {
          margin: 0px;
        }
      }

      .content {
        .permission {
          border-top: 1px solid #ced8e3;
          height: 39px;
          display: flex;
          align-items: center;

          .check {
            margin-right: 13px;
          }
        }

        .permission-disabled {
          color: #c1cdd7;
        }

        svg.medlightgrey {
          height: 18px;
          width: 18px;
        }
      }
    }

    .disabled {
      *:not(select):not(option) {
        color: #c1cdd7;
      }
    }

    .accordian:last-of-type {
      margin-bottom: 22px;
    }
  }

  .no-permissions {
    height: 8rem;
    line-height: 8rem;
    text-align: center;
  }

  .actions {
    text-align: center;
  }
}

.connectSettings,
.marketplaceSettings {
  & > p {
    margin-bottom: 40px;
    @include breakpoint($tablet) {
      @include span(5 of 8);
      padding-right: 40px;
    }
  }

  .split {
    @include breakpoint($tablet) {
      @include span(9 of 17);
    }

    &:last-of-type() {
      @include breakpoint($tablet) {
        @include span(8 of 17);
        padding-left: 20px;
      }
    }
  }

  .grid {
    .radio {
      display: inline-block !important;
      width: auto !important;

      & > div > div {
        margin: 0 !important;
      }
    }
  }

  section {
    & > .toggle {
      width: calc(100% - 5px);
    }

    & > input[type='text'] {
      width: 60px;
      margin: 10px 5px 10px 20px;
    }

    & > textarea {
      width: calc(100% - 5px);
      height: 90px;
    }
  }
}

.wordpress {
  .information {
    @include container;
    margin-bottom: 20px;

    img {
      @include breakpoint($tablet) {
        @include span(9 of 17);
        padding-right: 20px;
      }
    }

    & > div {
      @include breakpoint($tablet) {
        @include span(8 of 17);
      }
    }
  }

  .details {
    @include breakpoint($tablet) {
      @include span(8 of 17);
      @include post(9 of 17);
    }
  }

  section {
    @include breakpoint($tablet) {
      @include span(8 of 17);
      @include post(9 of 17);
    }

    input[type='text'] {
      width: 100%;
    }
  }
}

.routingSettings {
  @include container;

  section {
    @include breakpoint($tablet) {
      clear: none;
      float: left;
      width: calc(#{span(11 of 17)} - 20px);
      margin-right: 20px;
    }

    &:last-of-type() {
      @include breakpoint($tablet) {
        width: span(6 of 17);
        margin-right: 0;
      }
    }

    .form {
      margin-bottom: 40px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .truck {
      border-top: 1px solid $meddarkgrey;
      font-weight: $bold;
      line-height: 40px;

      .truck-svg {
        margin-top: 5px;
        margin-right: 5px;
        display: inline-block;
      }

      label {
        display: inline-block;
        width: 100px;
        font-weight: $regular;
      }

      p {
        font-weight: $regular;
        line-height: 15px;

        &.truck-details {
          padding-left: 32px;
          margin-top: 5px;
          padding-right: 55px;
        }
      }

      &:last-of-type() {
        margin-bottom: 20px;
      }
    }
  }
}

.posDevices {
  @include breakpoint($tablet) {
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
  }

  .form {
    display: grid;
    grid-template-columns: 370px 1fr;
    grid-auto-flow: column;
    grid-template-rows: 1fr auto;
    margin-bottom: 20px;

    .fields {
      grid-template-columns: auto;
    }

    .actions {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .icon {
    position: relative;
    height: 110px;
    width: 110px;
    margin-bottom: $innerPadding;
    background-color: $blue;
    @include border-radius(50%);

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 36px;
      width: 52px;
      fill: $white;
    }
  }
}

.lets-talk {
  font-size: 42px;
  text-align: left;

  @media (min-width: 769px) {
    font-size: 38px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 34px;
  }
}

.modal.large.conflictModal {
  .content {
    padding-bottom: 26px;
  }

  h3 {
    font-size: 14px !important;
    font-weight: $regular !important;
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 20px;
    line-height: 18px;
    font-size: 12px;
  }

  section {
    max-height: 30vh;
    overflow-y: auto;

    .grid {
      thead {
        tr {
          th {
            height: 26px !important;
          }
        }
      }

      tbody {
        tr {
          td:first-child {
            font-weight: bold;
          }
        }
      }
    }
  }

  .buttonArea {
    display: flex;
    gap: 8px;
    justify-content: space-between;

    button {
      flex-grow: 1;
      flex-basis: 0;
      margin: 0;
      width: 100%;
      padding: 0px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // .btn {
    //   width: 100% !important;
    //   background-color: red;
    // }
  }
}
