.contactContent {
	h1 {
		margin-bottom: 0;
		color: $blue;
		text-align: left;
	}
	.simpleForm {
		position: relative;
		margin-top: 20px;
		
		label {
			position: absolute;
			left: -15px;
			top: 8px;
			color: $blue;
			
			&:last-of-type() {
				top: 78px;
			}
		}
	}
}

.support {
	display: grid;
	@include breakpoint($tablet) {
		grid-column-gap: 10px;
	
		&.supportLevel0, &.supportLevel1 {
			grid-template-columns: repeat(2, 1fr);
	
			& > a {
				grid-column: span 2;
			}
		}
		&.supportLevel2 {
			grid-template-columns: repeat(3, 1fr);
	
			& > a {
				grid-column: span 3;
			}
		}
	}
	
	section {
		min-height: 350px;
		
		.icon {
			height: 40px;
			width: 40px;
			padding: 10px;
			margin: 0 auto 10px;
			@include border-radius(50%);
			background-color: $blue;
			
			svg {
				height: 20px !important;
				width: 20px !important;
				fill: $white;
				color: $blue;
			}
		}
		h1 {
			margin-bottom: 20px;
			line-height: 24px;
			text-align: center;
		}
		.btn {
			position: absolute;
			bottom: 20px;
			left: 10px;
			right: 10px;
			padding: 0;
			@include breakpoint($tablet) {
				left: 20px;
				right: 20px;
			}
		}
		.itemList {
			li {
				a {
					color: $darkblue;
					text-decoration: none;
				
					svg {
						height: 21px;
						width: 21px;
						margin-right: 10px;
						fill: $darkblue;
						vertical-align: middle;
					}
				}
			}
		}
	}
	
	& > a {
		justify-self: center;
		text-decoration: underline;
		@include breakpoint($tablet) {
			grid-column: span 3;
		}
	}
}