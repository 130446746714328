%mp {
	height: 475px;
}

.marketingContent {
	@include container;
	padding-bottom: 200px;

	h1 {
		font-size: 36px;
		line-height: 40px;
		font-weight: $light;
		margin-bottom: 5px;
		color: white;
		@include breakpoint($tablet) {
			margin-bottom: 10px;
		}
	}
	.btn {
		padding: 0 40px;
		background: $navyblue;
		color: $blue;

		&:hover {
			background: $darkblue;
		}
	}
	#marketingHeroWrapper {
		@include span(full);
		@include bkgd-gradient(180deg, $blue, $lightblue);

		#marketingHero {
			position: relative;
			height: 435px;
			background: url('../../../images/marketing/phone_Angled_InUse_Mobile.png') no-repeat right 0 bottom 10px;
			@include breakpoint($tablet) {
				height: 630px;
				width: 1020px;
				margin: 0 auto;
				background: url('../../../images/marketing/phone_Angled_InUse.png') no-repeat 50px center;
			}

			& > div {
				position: absolute;
				top: 30px;
				left: 20px;
				@include breakpoint($tablet) {
					top: 120px;
					left: 50%;
					width: 350px;
				}

				h1 {
					width: 200px;
				}
				.subHeader {
					width: 180px;
					font-size: 16px;
					line-height: 22px;
					font-weight: $light;
					color: $darkblue;
					@include breakpoint($tablet) {
						width: auto;
						font-size: 18px;
						line-height: 27px;
					}
				}
				.content {
					@include container;
					margin-bottom: 20px;
					font-size: 12px;
					line-height: 18px;
					color: white;
					@include breakpoint($phone $tablet) {
						display: none;
					}

					p {
						float: left;
						width: 38%;
						margin-right: 22%;
					}
					ul {
						@include span(2 of 5);

						li {
							list-style: inside;
						}
					}
				}
			}
		}
	}

	#marketingOne, #marketingTwo, #marketingThree, #marketingFour, #faqQuestions, #faqAnswers {
		position: relative;
		height: 480px;
		@include span(full);
		@include breakpoint($tablet) {
			height: 630px;
			@include span(1 of 2);
		}

		& > div {
			position: absolute;
			font-weight: $light;

			p {
				font-size: 16px;
				line-height: 24px;
				color: white;
				@include breakpoint($tablet) {
					font-size: 14px;
					line-height: 21px;
				}
			}
		}
	}

	#marketingOne {
		background: $navyblue url('../../../images/marketing/marketing_Phone_Mobile.jpg') no-repeat right top;
		@include breakpoint($tablet) {
			background: $navyblue url('../../../images/marketing/marketing_Phone.jpg') no-repeat right top;
		}

		& > div {
			top: 30px;
			right: 25px;
			text-align: right;
			@include breakpoint($tablet) {
				top: 80px;
				right: 40px;
			}

			p {
				width: 190px;
				@include breakpoint($tablet) {
					width: 160px;
				}
			}
		}
	}
	#marketingTwo {
		background: $darkblue url('../../../images/marketing/macbook_Straight_InUse_Mobile.png') no-repeat 15px 145px;
		@include breakpoint($tablet) {
			background: $darkblue url('../../../images/marketing/macbook_Straight_InUse.png') no-repeat 160px 120px;
		}

		& > div {
			top: 35px;
			left: 20px;
			@include breakpoint($tablet) {
				top: 240px;
				left: 40px;
			}

			h1 {
				@include text-gradient(to bottom right, $blue, $lightblue)
				float: left;
				width: 170px;
				@include breakpoint($tablet) {
					float: none;
				}
			}
			p {
				float: left;
				width: 150px;
				@include breakpoint($tablet) {
					float: none;
					width: 130px;
				}
			}
		}
	}
	#marketingThree {
		@include bkgd-gradient(135deg, $blue, $lightblue, url('../../images/marketing/phone_Straight_Rentals_Mobile.png') no-repeat left -63px bottom 0);
		@include breakpoint($tablet) {
			@include bkgd-gradient(135deg, $blue, $lightblue, url('../../images/marketing/phone_Straight_Rentals.png') no-repeat right 305px top 80px);
		}

		& > div {
			top: 165px;
			right: 30px;
			@include breakpoint($tablet) {
				top: 225px;
				right: 115px;
			}

			h1 {
				color: $darkblue;
			}
			p {
				width: 150px;
				@include breakpoint($tablet) {
					width: 185px;
				}
			}
		}
	}
	#marketingFour {
		background: $navyblue url('../../../images/marketing/marketing_Tablet_Mobile.jpg') no-repeat left top;
		@include breakpoint($tablet) {
			background: $navyblue url('../../../images/marketing/marketing_Tablet.jpg') no-repeat left top;
		}

		& > div {
			top: 25px;
			left: 20px;
			@include breakpoint($tablet) {
				top: 60px;
				left: 40px;
			}

			p {
				width: 170px;
				@include breakpoint($tablet) {
					width: 120px;
				}
			}
		}
	}
	#faq {
		clear: both;
	}
	#faqMobile {
		clear: both;

		h1 {
			padding: 60px 0 0 20px;
			color: $blue;
		}
		.question {
			padding: 20px 20px 15px;
			border-top: 1px solid $meddarkgrey;

			&.open {
				background-color: $blue;
				border-top: 1px solid $blue;

				svg {
					float: right;
					fill: $lightgrey;
				}
				.answer {
					display: block;
				}
			}

			svg {
				float: right;
				fill: $cobalt;
			}
			p {
				width: 250px;
			}
			.answer {
				display: none;
				width: 250px;
				color: white;

				a {
					font-size: 10px;
					font-weight: $bold;
					color: $darkblue;
					text-transform: uppercase;
					text-decoration: underline;
				}
			}
		}
	}
	#faqQuestions {
		background-color: $lightgrey;

		& > div {
			top: 180px;
			right: 35px;
			@include breakpoint($tablet) {
				top: 180px;
				right: 35px;
			}

			h1 {
				position: absolute;
				top: -100px;
				right: 309px;
				width: 165px;
				color: $blue;
			}
			p {
				width: 175px;
				color: $darkblue;
				text-align: right;
				cursor: pointer;
				@include breakpoint($tablet) {
					width: 175px;
					margin-bottom: 20px;
					font-size: 12px;
					line-height: 16px;
				}

				&.active {
					color: $blue;
					font-weight: $bold;
				}
			}
		}
	}
	#faqAnswers {
		@include bkgd-gradient(315deg, $blue, $lightblue);

		& > div {
			top: 171px;
			left: 40px;
			@include breakpoint($tablet) {
				top: 171px;
				left: 40px;
			}

			p {
				width: 300px;
				@include breakpoint($tablet) {
					width: 300px;
					font-size: 24px;
					line-height: 44px;
				}
			}
			a {
				display: block;
				font-size: 12px;
				color: white;
				text-decoration: underline;
			}
		}
		#faqStepper {
			width: 20px;
			line-height: 10px;
			@include breakpoint($tablet) {
				top: 265px;
				left: 416px;
			}

			a {
				display: inline-block;
				height: 8px;
				width: 8px;
				margin: 5px;
				@include border-radius(50%);
				background-color: $darkblue;
				cursor: pointer;

				&.selected {
					height: 18px;
					width: 18px;
					margin: 0;
					background-color: transparent;
					border: 5px solid $darkblue;
				}
			}
		}
	}
	#about {
		clear: both;
	}
	#aboutOne, #aboutTwo {
		position: relative;
		height: 630px;

		h1 {
			position: absolute;
			width: 200px;
		}
		& > div {
			position: absolute;
			font-weight: $light;

			p {
				margin-bottom: 30px;
				font-size: 16px;
				line-height: 24px;
				color: white;
				@include breakpoint($tablet) {
					font-size: 18px;
					line-height: 27px;
				}
			}
		}
	}
	#aboutOne {
		background: $purple url('../../../images/marketing/about_bg.jpg') no-repeat center top;

		h1 {
			top: 70px;
			left: 20px;
			color: white;
			@include breakpoint($tablet) {
				top: 220px;
				right: calc(50% + 60px);
				left: auto;
				text-align: right;
			}
		}
		& > div {
			top: 210px;
			left: 20px;
			@include breakpoint($tablet) {
				top: 192px;
				left: 50%;
			}

			p {
				width: 206px;
				@include breakpoint($tablet) {
					width: 347px;
				}
			}
		}
	}
	#aboutTwo {
		height: 690px;
		background-color: $lightgrey;
		background-image: url('../../../images/marketing/marketing_Hand.png');
		background-repeat: no-repeat;
		background-position: center bottom -40px;
		background-size: 435px 360px;
		@include breakpoint($tablet) {
			height: 630px;
			background-position: left calc(50% + 125px) bottom -60px;
			background-size: 726px 600px;

		}

		h1 {
			top: 40px;
			right: 20px;
			color: $purple;
			text-align: right;
			@include breakpoint($tablet) {
				top: 100px;
				left: calc(50% + 35px);
				right: auto;
				text-align: left;
			}
		}
		& > div {
			top: 134px;
			right: 20px;
			text-align: right;
			@include breakpoint($tablet) {
				top: 100px;
				right: 50%;
			}

			p {
				width: 300px;
				color: $purple;
				@include breakpoint($tablet) {
					width: 385px;
				}
			}
		}
	}
}
