.filterOptions {
  margin-left: 40px;
  border-left: 1px solid lightgray;
  height: 50%;
  width: 50%;
  justify-content: space-between;

  input {
    visibility: hidden;
  }
  label {
    display: inline-block;
    height: $vertical;
    padding: 15px 0;
    font-size: 11px;
    line-height: 12px;
    font-weight: $regular;
    color: $blue;
    cursor: pointer;
    margin-left: 10px;
  }
  input:checked + label {
    font-weight: bolder;
    font-size: 12px;
  }
}

.statementRentalEmails {
  @include breakpoint($tablet) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  clear: both;
  background-color: #ffffff;

  .details {
    align-self: start;
    margin: 0;

    .radioList {
      padding-bottom: 10px;
      margin-bottom: 15px;
      border-bottom: 1px solid $meddarkgrey;

      .radio {
        display: inline-block !important;
        width: auto !important;
        margin-right: 20px;

        label {
          white-space: nowrap;
        }
      }
    }

    .scrollarea {
      grid-column: span 4;

      .check {
        display: block;
      }
    }

    .Select-value {
      .transactionOptionValue {
        display: flex;
        align-items: center;

        .details {
          font-size: 10px;
          color: $blue;
          overflow: hidden;
          text-overflow: ellipsis;

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .Select-menu-outer {
      .transactionOptionValue {
        overflow: auto;

        .details {
          @include container;
          width: 100%;
          font-size: 10px;
          line-height: 14px;
          color: $blue;

          span {
            display: inline-block;
            margin-right: 5px;

            &:last-of-type() {
              float: right;
              margin-right: 0;
            }
          }
        }
      }
    }

    .react-datepicker-component {
      width: span(1 of 3);
    }

    .documentSelectList {
      grid-column: span 4;
      margin: 0;

      .toggle {
        width: 100%;
      }
    }

    .actions {
      grid-template-columns: repeat(8, 1fr);

      .btn {
        grid-column: span 5;

        &.cancel {
          grid-column: span 2;
        }
      }

      .btnIcon {
        grid-column: span 1;
        margin-right: 0;
      }
    }
  }

  .attachments {
    .grid {
      margin-bottom: 15px;
    }
  }

  .emailHistory {
    .entry {
      padding-top: 5px;
      border-top: 1px solid $meddarkgrey;
      padding-bottom: 5px;

      h4 {
        line-height: 25px;
      }

      & > div {
        padding: 3px 0;

        strong {
          display: inline-block;
          width: 55px;
          vertical-align: top;
        }

        & > span {
          display: inline-block;
          width: calc(100% - 100px);

          span {
            display: inline-block;
            margin-right: 5px;

            svg {
              height: 10px !important;
              width: 10px !important;
              margin-left: 2px;
              fill: $green;
            }
          }
        }
      }

      .btnLink {
        margin-top: 2px;
      }

      .btnIcon.preview {
        float: right;
        height: 22px;
        width: 23px;
        margin: 0 0 0 5px;
        line-height: 22px;

        svg {
          height: 22px !important;
          width: 22px !important;
        }
      }

      ul {
        clear: both;

        li {
          padding: 4px 0;
          line-height: 22px;

          a {
            position: relative;
            display: inline-block;
            height: 100%;
            width: 100%;
            padding-left: 30px;
            color: $darkblue;

            svg {
              position: absolute;
              top: 4px;
              left: 3px;
              height: 14px;
              width: 14px;
              fill: $white;
            }

            &:before {
              content: ' ';
              position: absolute;
              top: 0;
              left: 0;
              height: 22px;
              width: 22px;
              background-color: $darkgrey;
              @include border-radius(2px);
            }
          }
        }
      }
    }
  }

  @media print {
    @include breakpoint($tablet) {
      grid-template-columns: 1fr;
    }

    .details {
      display: none;
    }
  }
}

.statement-bulk-email-modal {
  section {
    position: inherit;
  }
  .body {
    display: grid;
  }

  .content {
    width: 370px!important;
    padding: 0px;
  }

  section {
    margin-bottom: 0px;
  }

  .companies {
    border-radius: 5px;
    p {
      svg {
        height: 15px;
        fill: $orange;
      }
    }
    .amount-remaining {
      display: flex;
      justify-content: space-between;
      margin-top: 32px;
    }

    .companies-list {
      max-height: 410px;
      background-color: #e5e8e9;
      padding: 8px;
      margin-top: 10px;
      .scroll {
        .scrollarea {
          min-height: 300px;
        }
      }
    }
  }
}
