.displayRow {
  display: flex;
  flex-direction: row;
  margin-top: 10%;
}

.deliveryTicketName {
  color: $blue;
}

.relativePosition {
  position: relative;
}

.iconPosition {
  position: absolute;
  top: 8px;
  right: 12px;
}

.mainHeaderSection {
  // margin: 0 0 20px 0;

  .rectangleMargin {
    margin-left: -5px;
  }

  .backgroundColor {
    border-left: 5px solid #ff886d;
  }

  .ticketsItemsHeaders {
    display: inline-block;
    width: 165px;
    height: 120px;
    background-color: #ffffff;
    color: #0E2C44;
    margin-top: 20px;
    margin-left: 5px;

    .span1 {
      display: flex;
      justify-content: space-between;

      .span2 {
        margin-top: 10px;
        margin-left: 13px;
        color: black;

        .span3 {
          color: #8fa9be;
        }
      }

      .span4 {
        margin-top: 5px;
        margin-right: 5px;
      }
    }

    .div1 {
      margin-left: 15px;
      color: black;
    }

    .div2 {
      margin-left: 15px;
      margin-top: 7px;
    }

    b {
      color: black;
    }
  }

  .ticketsItemsHeaders2 {
    display: inline-block;
    width: 165px;
    height: 120px;
    background-color: #0e2c44;
    margin-top: 20px;

    .span1 {
      display: flex;
      justify-content: space-between;

      .span2 {
        margin-top: 10px;
        margin-left: 13px;

        .span3 {
          color: #8fa9be;
        }

        .textColor {
          color: #ffffff;
        }
      }

      .span4 {
        margin-top: 5px;
        margin-right: 5px;
      }
    }

    .div1 {
      margin-left: 10px;

      .textColor {
        color: #ffffff;
      }
    }

    .div2 {
      margin-left: 10px;
      margin-top: 7px;

      .textColor {
        color: #ffffff;
      }
    }
  }
}

.slick-slider {
  margin-left: 145px !important;

  .slick-prev {
    left: 5px !important;
    background: #466b88 !important;
    height: 120px !important;
    top: 57% !important;
  }

  .slick-next {
    background: #466b88 !important;
    height: 120px !important;
    top: 57% !important;
    right: 0 !important;
  }

  .slick-track {
    float: left !important;

    a {
      color: #000000;
    }
  }
}


.scrollBarWithoutButton {
  .slick-slider {

    margin-left: 22px !important;

    .slick-prev {
      left: -21px !important;
      background: #466b88 !important;
      height: 120px !important;
      top: 57% !important;
    }
  }
}

.selectDeliveryTicketBackground {
  background-color: #0E2C44 !important;
  color: $white  !important;

  .div1,
  b {
    color: $white  !important;
  }
}
