.productForm {
	& > form {
		clear: both;
		@include breakpoint($tablet) {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-column-gap: 10px;
		}
	}
	label {
		&.caption {
			margin: 0;
		}
	}
	.colorPicker {
		grid-column: span 4;
		@include breakpoint($tablet) {
			grid-column: span 3;
		}
	}

	.specifications {
		.uploadZone {
			.drop {
				@include maintain-aspect-ratio(16, 9);
				margin-bottom: 5px;
			
				.placeholder svg {
					margin-top: 25%;
				}
			}
			li {
				@include maintain-aspect-ratio(16, 9);
			}
		}
		.dimensions {
			grid-template-columns: 4fr 2fr 1fr 2fr 1fr;
			@include breakpoint($tablet) {
				grid-template-columns: 3fr 2fr 1fr 2fr 3fr;
			}
			
			h4 {
				grid-column: span 5;
			}
		}
		.metaDataForm {
		   padding: 0;
		   
		   h3 {
			   border-bottom: none;
		   }
		}

		.itemNotes {
			.itemNote {
				border-top: 1px solid #ced8e3;
				display: flex;
				justify-content: space-between;
			}

			.noteDetails {
				padding-top: 12px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				p:nth-child(2) {
					color: #278ADA;
					font-size: 10px;
					line-height: 14px;
				}
			}
			.noteActions {
				padding-top: 12px;
				display: flex;
			}

			.toggle {
				width: 100%;
				margin-top: 12px;
				margin-bottom: 36px;
			}
		}
	}
	.details {
		.selection {
			margin-bottom: 20px;
			
			h4 {
				margin-bottom: 5px;
			}
			.grid {
				a {
					color: $darkblue;
				}
				.caption {
					margin: 0;
				}
			}
			.toggle {
				width: 100%;
			}
		}
	}
	.numbers {
		.info {
			float: none;
			margin-left: 5px;
			vertical-align: text-top;
		}
		.quantity, .pricing {
			grid-template-columns: 2fr 1fr;
			
			h4, p, .check {
				grid-column: span 2;
			}
		}
		.bundlePricing {
			grid-template-columns: 2fr 1fr;
			
			h4 {
				grid-column: span 2;
			}
			span {
				text-align: right;
			}
			
			&.locked {
				grid-template-columns: 2fr 1fr 1fr;
				
				h6 {
					grid-column: span 1;
					text-align: center;
				}
				.caption {
					text-align: center;
				}
			}
		}
		.costs, .lifespan, .turnover {
			grid-template-columns: 2fr 1fr 1fr;
			
			h4 {
				grid-column: span 3;
			}
		}
		.lifespan {
			margin-top: -15px;
		}
		.selection {
			margin-bottom: 20px;

			h4 {
				margin-bottom: 5px;
			}
			.grid {
				a {
					color: $darkblue;
				}
				.caption {
					margin: 0;
				}
			}
			.toggle {
				width: 100%;
			}
		}
	}
}