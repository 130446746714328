.featureManagement {
	@include breakpoint($tablet) {
		@include push(2 of 12);
		@include span(10 of 12);
	}

	.featureList {
		@include breakpoint($tablet) {
			@include span(11 of 20);
			@include post(9 of 20);
		}
		
		li {
			@include container;
			padding: 5px 0;
			border-top: 1px solid $darkgrey;
			
			.btnLink {
				margin-top: 7px;
			}
			
			.feature {
				span, p {
					padding: 8px 8px 7px;
					margin: 0;
				}
				span {
					@include span(3 of 8);
					@include breakpoint($tablet) {
						@include span(3 of 11);
					}
				}
				p {
					@include span(4 of 8);
					@include breakpoint($tablet) {
						@include span(7 of 11);
					}
				}
			}
			.featureForm {
				.react-datepicker-component {
					float: left;
					width: calc(#{span(4 of 8)} - 5px);
					@include breakpoint($tablet) {
						width: calc(#{span(3 of 11)} - 5px);
					}
					
					& > div {
						width: 100%;
					}
				}
				textarea {
					height: 90px;
					@include span(full);
					@include breakpoint($tablet) {
						@include span(8 of 11);
					}
				}
				.actions {
					grid-template-columns: repeat(4, 1fr);
					@include breakpoint($tablet) {
						@include push(3 of 11);
						@include span(8 of 11);
					}
				}
				
				&.newItem {
					textarea {
						@include breakpoint($tablet) {
							@include span(8 of 11);
						}
					}
				}
			}
		}
	}
}