


.barcode-scanner {
  /* the host element for a BarcodeReader needs to have position: relative */
  position: relative;
  background-color: black;
  flex-grow: 1;

  @media (min-width: 769px) {
    height: 200px;
  }

  @media screen and (max-width: 768px) {
    aspect-ratio: 1/1;
  }
}
