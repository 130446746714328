#root {}

.container {
  position: relative;
  display: grid;
  grid-template: auto 1fr auto / 1fr;
  min-height: 100vh;

  &>aside {
    grid-column: 1;
    grid-row: 3;
  }

  &>header {
    grid-column: 1;
    grid-row: 1;
  }

  &>main {
    grid-column: 1;
    grid-row: 2;

    &>div {
      height: 100%;
    }
  }

  &>footer {
    grid-column: 1;
    grid-row: 3;
    height: 200px;
    padding: $vertical 0px;
    background-color: $navyblue;
    text-align: center;

    .logo {
      margin: 10px 0 20px;

      img {
        width: 150px;
        height: 25px;
      }
    }

    p,
    a {
      max-width: inherit;
      padding: 0;
      margin: 20px 0;
      font-size: 12px;
      color: $darkgrey;
    }

    a {
      margin: 0 10px;
      text-decoration: underline;
    }
  }

  header.topBadges+main {
    margin-top: 50px;
  }
}

.invoicesPopUpDescription {
  font-size: 10px;
}

.headerWrapper {
  &>div {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 30;
  }

  .progressBar {
    display: none;
    height: 20px;

    &.visible {
      display: block;
    }
  }

  .infoProgressBar {
    display: none;
    position: relative;
    height: 20px;

    .progressContainer {
      height: 20px;
      background-color: $navyblue;

      .progress {
        position: absolute;
        height: 20px;
        background-color: $green;
      }

      label {
        position: relative;
        display: block;
        font-size: 10px;
        line-height: 20px;
        color: $white;
        text-align: center;
      }
    }

    &.visible {
      display: block;
    }
  }

  .topBadges {
    background-color: $meddarkgrey;
    overflow-x: scroll;

    @include breakpoint($tablet) {
      overflow-x: auto;
    }

    &>div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 120px;
      width: 940px;
      padding: 0 20px;

      @include breakpoint($tablet) {
        width: auto;
        max-width: 1020px;
        margin: 0 auto;
        padding: 0;
      }

      h1 {
        width: 105px;
        margin: 0;
        font-size: 14px;
        font-weight: $bold;
        line-height: 21px;
        color: $darkblue;

        @include breakpoint($tablet) {
          width: 180px;
          font-size: 22px;
          line-height: 27px;
        }
      }

      a:not(.btnLink) {
        width: 125px;
        padding: 70px 0 0;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: 60px 60px;
        font-size: 10px;
        color: $darkblue;
        text-align: center;

        &.signup {
          background-image: image('/icons/onboarding_signup_active.png');
        }

        &.merchant {
          background-image: image('/icons/onboarding_merchant.png');
          white-space: nowrap;

          &.complete {
            background-image: image('/icons/onboarding_merchant_active.png');
          }
        }

        &.inventory {
          background-image: image('/icons/onboarding_inventory.png');

          &.complete {
            background-image: image('/icons/onboarding_inventory_active.png');
          }
        }

        &.renting {
          background-image: image('/icons/onboarding_renting.png');

          &.complete {
            background-image: image('/icons/onboarding_renting_active.png');
          }
        }

        &.settings {
          background-image: image('/icons/onboarding_settings.png');

          &.complete {
            background-image: image('/icons/onboarding_settings_active.png');
          }
        }

        &.payments {
          background-image: image('/icons/onboarding_payments.png');

          &.complete {
            background-image: image('/icons/onboarding_payments_active.png');
          }
        }
      }

      .btnLink {
        float: none;
        height: auto;
        font-size: 10px;
        font-weight: $regular;
        color: $darkblue;

        svg {
          height: 15px !important;
          width: 15px !important;
          margin-top: -1px;
        }
      }
    }
  }

  .topWarning {
    background-color: $orange;
    font-size: 11px;
    line-height: 60px;
    color: $white;
    text-align: center;

    svg {
      height: 12px;
      width: 13px;
      margin-right: 5px;
      fill: $white;
      vertical-align: middle;
    }

    a {
      margin: 0 5px;
      font-weight: $bold;
      color: $white;
      text-decoration: underline;
      cursor: pointer;

      &:first-of-type(),
      &:last-of-type() {
        margin: 0 20px;
      }
    }

    &.alert {
      background-color: $pink;
      line-height: 30px;

      a {
        margin: 0 0 0 2px;
      }
    }

    &.success {
      background-color: $green;
      line-height: 30px;

      a {
        margin: 0 0 0 5px;
      }
    }

    &.topWarningTrial {
      svg {
        fill: transparent;
      }

      .action {
        float: right;
      }
    }
  }

  .header {
    background-color: $blue;
  }
}

.invoiceSelect {
  display: flex;
}

#selected_rentals_area {
  width: 100%;
  height: 140px;
  resize: none;
}

.nowstaFlex {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .nowstaFlexInner {
    margin-top: -220px;
    display: flex;
    flex-direction: column;
    margin-left: 80px;

    .nowstaFlexInnerSection {
      padding: 15px 30px;
      background-color: #fefefe;
      margin-bottom: 15px;

      b {
        color: #278ada;
      }
    }

    .dateSelection {
      display: flex;
      margin-top: 15px;
      margin-bottom: 5px;

      .dateRange {
        input {
          background-color: #e5e8e9;
        }

        label {
          display: block;
          color: #7194ac;
        }
      }

      .dateRange:first-child {
        margin-right: 10px;
      }
    }

    .accordian {
      margin-top: 20px;
      border-top: none;
      background-color: transparent;

      .header {
        div {
          label {
            color: #000000;
            font-weight: inherit;
          }
        }
      }

      .content {
        .check {
          display: block;
          margin-bottom: 10px;
          margin-left: 20px;
        }
      }
    }
  }
}

.contentWrapper {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: auto 1fr;
  height: 100%;
  overflow: hidden;

  @include breakpoint($tablet) {
    grid-template-columns: 1fr repeat(12, minmax(#{span(1 of 12)}, 85px)) 1fr;
    overflow: visible;
  }

  @include breakpoint($desktop) {
    grid-template-columns: 1fr repeat(12, minmax(80px, 85px)) 1fr;
  }

  // minmax(auto, 85px) broke with chrome 80

  &>header {
    grid-column: 1 / span 8;
    grid-row: 1;
    display: grid;
    height: 60px;
    padding: 0 10px;

    @include breakpoint($tablet) {
      grid-column: 1 / span 14;
      grid-template-columns: 1fr minmax(auto, 1020px) 1fr;
      height: 160px;
      padding: 0;
      background-color: $darkblue;
    }

    &>* {
      position: relative;
      grid-column: 1;

      @include breakpoint($tablet) {
        grid-column: 2;
      }
    }

    &>h1 {
      place-self: center start;
      margin: 0;

      @include breakpoint($tablet) {
        place-self: center;
      }
    }

    h1 {
      @include breakpoint($tablet) {
        color: $white;
      }
    }
  }

  &>aside {
    grid-column: 1;
    grid-row: 2;
    background-color: $darkblue;

    @include breakpoint($tablet) {
      grid-column: 2 / span 3;
      padding-top: 40px;
      padding-left: 10000px;
      margin-left: -10000px;
    }

    &+.content {
      grid-column: 2 / span 7;

      @include breakpoint($tablet) {
        grid-column: 5 / span 9;
        padding-left: span(1 of 18);

        .subrentals_all {
          width: 60vw;

          section {
            overflow: hidden;
          }
        }
      }
    }

    &+.purchaseOrderContent {
      grid-column: 2 / span 7;
      margin-top: 46px;
      padding-top: 0px;

      @media (max-width: 769px) {
        margin-top: 0px;
      }

      @include breakpoint($tablet) {
        grid-column: 5 / span 9;
        padding: 0px 20px;
      }
    }

    @include breakpoint($phone $tablet) {
      .itemList {
        position: fixed;
        width: span(1 of 8);
        margin-top: 0;

        li {
          padding: 0 10px;

          svg {
            height: 20px !important;
            width: 21px !important;
          }

          .alertCallout {
            right: 10px;
            transform: translate(20%, 0);
          }

          &:nth-child(1) {
            border-top: none;
          }
        }
      }

      .expandToggle {
        position: fixed;
        bottom: 5px;
        left: 10px;

        svg {
          height: 20px !important;
          width: 20px !important;
          fill: $blue;
        }
      }

      &.open {
        grid-column: 1 / span 4;

        .itemList {
          width: span(1 of 2);
        }

        &+.content {
          grid-column: 5 / span 7;
          margin-right: -75%;
        }
      }
    }

    @include breakpoint($tablet) {
      &.large {
        grid-column: 2 / span 5;
        display: grid;
        grid-template-columns: 2fr 3fr;
        padding-top: 0;

        &+.content {
          grid-column: 7 / span 7;
          padding-left: span(1 of 14);
        }
      }

      &.m-hide {
        grid-column: 2 / span 2;
        display: grid;
        grid-template-columns: 2fr 3fr;
        padding-top: 0;

        &+.content {
          grid-column: 4 / span 10;
          padding-left: span(1 of 14);
        }
      }
    }

    .full,
    .left,
    .right {
      padding-bottom: 40px;

      h2 {
        color: $darkgrey;
      }
    }

    .full,
    .left {
      color: $meddarkgrey;

      .linkAction {
        margin-right: 10px;
      }

      .btnLink {
        display: block;
        float: none;
        height: 40px;
        margin: 0 10px 0;
        line-height: 40px;
        text-align: right;
      }

      input[type='search'] {
        width: 245px;
      }

      .scrollarea {
        max-height: calc(100vh - 240px);
        padding-left: 1000px;
        padding-right: 0;
        margin-top: 40px;
        margin-left: -1000px;

        .scrollarea-content {
          padding-left: 1000px;
          margin-left: -1000px !important;

          .itemList {
            margin: 0;
          }
        }

        .scrollbar-container {
          background-color: transparent;
          opacity: 0;

          .scrollbar {
            background-color: $blue;
          }
        }

        &:hover .scrollbar-container {
          opacity: 1;
        }
      }

      .itemList {
        margin-bottom: 40px;

        li {
          padding-left: 1000px !important;
          margin-left: -1000px !important;

          &.blank {
            min-height: 39px;
          }
        }
      }

      .checkList {
        &>div {
          padding-left: 1000px !important;
          margin-left: -1000px !important;
        }

        .checkListItem {
          &+div {
            padding-left: 1000px !important;
            margin-left: -1000px !important;
          }
        }
      }

      .key {
        margin: 40px 20px 0 0;
        text-align: right;

        div {
          position: static;
          margin-right: 5px;
        }
      }
    }

    .full:not(.static) {
      position: fixed;
      width: span(3 of 12);
      max-width: 255px;
      overscroll-behavior: none;
    }

    .left {
      padding: 80px 0 0;
      padding-left: 10000px;
      margin-left: -10000px;

      &.staffList {
        padding: 40px 80px 0 0;
        padding-left: 10000px;
      }

      .itemList {
        position: fixed;
        width: span(2 of 12);
        max-width: 170px;
      }
    }

    .right {
      padding: 40px 0 0;
      background-color: $navyblue;
      color: $darkgrey;

      &>div {
        position: fixed;
        width: span(3 of 12);
        max-width: 255px;
        padding: 0 10px;
        overflow: hidden;
        overscroll-behavior: none;
      }

      .btnLink {
        display: block;
        float: none;
        margin: 10px 0 5px 10px;
        text-align: right;
      }

      input[type='search'] {
        margin-top: 45px;
      }

      .actionMenu {
        margin: 0 -10px;

        &.open>div {
          background-color: $darkblue;
        }
      }

      .scrollarea {
        max-height: calc(100vh - 200px);

        .scrollbar-container {
          background-color: transparent;
          opacity: 0;

          .scrollbar {
            background-color: $blue;
          }
        }

        &:hover .scrollbar-container {
          opacity: 1;
        }
      }

      .infinite {
        margin-right: -30px;
      }

      .itemList {
        li {
          border-bottom: 1px solid transparent;
          color: $darkgrey !important;

          a {
            color: $darkgrey !important;
          }

          &:nth-child(1) {
            border-top: 1px solid transparent;
          }

          &.selected {
            color: $white !important;
            font-weight: $regular;

            a {
              color: $white !important;
              font-weight: $regular;
            }
          }
        }
      }

      p {
        margin: $vertical 0 10px;
        text-align: center;

        span {
          color: $white;
        }
      }
    }
  }

  &>#routingContent {
    @include breakpoint($tablet) {
      padding: 0;
    }

    @media (max-width: 769px) {
      padding: 0 !important;
    }

    #breadcrumbPrintContainer {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .breadcrumb {
        font-size: 10px;
        opacity: 0.9;
      }

      .btnLink>svg {
        height: 19px !important;
        width: 19px !important;
        fill: #278ada;
      }
    }

    #ordersFilter {
      input {
        width: 150px !important;
      }

      select {
        background-color: white;
      }
    }

    .filter {
      text {
        margin: 0 8px 0 2px;
      }
    }

    .routingCalendarFilter {
      width: 98vw;

      #filterContainer {
        @media only screen and (min-width: $tablet) {
          display: flex;
          justify-content: space-between;
        }

        #filterRight {
          display: flex;

          polygon {
            fill: #466b88;
          }

          a.btnIcon {
            &.filtering {
              background-color: #278ada;
              color: #fff !important;

              .routing-filter-icon {
                filter: invert(100%) brightness(100);
              }
            }

            &.notFiltering {
              background-color: #d3dce4;
              color: #0e2c44 !important;
            }
          }

          @media only screen and (max-width: $tablet) {
            justify-content: space-between;

            a.btnIcon {
              transform: translateY(-40px);
            }
          }

          #iconsButtonsContainer {
            display: flex;
            align-items: center;

            * {
              display: flex;
              align-items: center;
              margin: 0;
            }

            #calendarIcons,
            .radioToggleContainer {
              margin-right: 15px;

              div,
              label {
                margin-right: 10px;
              }

              input {
                margin: 5px 5px 0 0;
              }

              svg {
                margin-right: 5px;
              }
            }

            #calendarIcons {
              div {
                color: #466B88;
              }

              #dropOffIconContainer {
                svg {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }

        #filterLeft {
          align-items: center;

          @media only screen and (max-width: $tablet) {
            width: 90%;
          }

          @media only screen and (min-width: $tablet) {
            display: flex;
          }
        }
      }

      .radioToggleContainer {
        margin-top: 8px;

        @media screen and (max-width: $tablet) {
          display: inline-flex;
          gap: 4px;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
      }
    }

    #routingOrdersContainer {
      background-color: white;
      padding: 1px 0 1px 0;

      #rentalContainer.unassigned {
        .accordian {
          border: 1px solid #ff886d;

          .header {
            background-color: #ff886d;
            color: white;

            #headerRentalTokenContainer {
              @media (max-width: 769px) {
                #tokenContainer {
                  border-bottom: solid 1px white;
                }
              }
            }
          }

          .btnExpand,
          .btnCollapse {
            svg {
              fill: white;
            }
          }

          #ordersContentLeftColumn {
            .btnExpand {
              svg {
                fill: #8fa9be;
              }
            }
          }
        }
      }

      .accordian {
        margin: 20px;
        border: 1px solid #c1cdd7;

        @media (max-width: 769px) {
          margin: 0;
        }
      }

      .btnExpand .btnCollapse {
        margin: 15px 9px 0;
      }

      .btnCollapse {
        svg {
          fill: #0e2c44;
        }
      }

      #noOrders {
        height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 10px;

        svg {
          height: 50px !important;
          width: 50px !important;
          fill: #c1cdd7;
        }

        text {
          color: #c1cdd7;
        }
      }

      .header {
        background-color: #c1cdd7;
        width: 100%;

        @media (max-width: 769px) {
          height: 68px;
        }

        #ordersHeaderContainer {
          height: 37px;
          display: flex;

          #headerRentalTokenContainer {
            display: flex;
            width: 70%;

            #tokenContainer {
              margin-right: 5px;
            }

            @media (max-width: 769px) {
              display: flex;
              flex-direction: column;

              div {
                height: 27px;
              }

              #tokenContainer {
                width: fit-content;
                border-bottom: solid 1px black;
              }
            }
          }

          @media (max-width: 769px) {
            height: 68px;
          }

          #headerStopsContainer {
            width: 30%;
            display: flex;
            justify-content: flex-end;
          }
        }
      }

      .content {
        padding: 20px 0 20px 0;

        #ordersContentContainer {
          display: flex;

          @media (max-width: 769px) {
            flex-direction: column;
            width: 100%;
          }

          #ordersContentLeftColumn {
            width: 34%;

            .btnCollapse {
              svg {
                fill: #278ada !important;
              }
            }

            .rentalCustomer {
              font-weight: bold;
              color: black;
            }

            #rentalContainer {
              color: #466b88;
              margin-bottom: 40px;
              margin-left: 20px;

              .rentalDetail {
                width: 100% !important;
                display: flex;
                flex-direction: column;

                svg.attachment-icon {
                  height: 15px;
                  opacity: 55%;
                  margin-left: 10px;
                }

                .orderTokenName {
                  display: flex;
                  flex-direction: row;
                }

                a {
                  display: flex;
                  align-items: center;
                  color: $cobalt;

                  &:hover {
                    color: $blue;
                    text-decoration: underline;
                    transition: color 0.2s;
                  }
                }
              }

              #tokenNameContainer {
                margin-bottom: 5px;
              }

              #orderToken {
                text-decoration: underline;
                margin-right: 5px;
              }
            }

            #customerContainer,
            #companyContainer {
              display: flex;
              flex-direction: column;
              color: #466b88;
              margin-bottom: 20px;
              margin-left: 20px;
            }

            .accordian {
              border: 0;
              margin: 0;
              width: calc(100% - 20px);
              border-top: 1px solid rgba(193, 205, 215, 0.5);

              @media (max-width: 769px) {
                width: 100%;
              }

              .header {
                background-color: transparent;
                padding: 0;
              }

              .content {
                padding: 0;

                #inventoryContainer {
                  border-top: 0;
                }
              }
            }

            #inventoryTotal {
              margin-bottom: 20px;
            }

            .pickListProductContainer {
              display: flex;
              margin-bottom: 5px;
              color: #0e2c44;

              .inventoryQuantity {
                font-weight: bold;
                color: black;
                margin-right: 10px;
                width: 30px;
                margin-left: 5px;
                display: flex;
                justify-content: flex-end;
              }
            }

            #inventoryContainer {
              border-top: 1px solid #c1cdd7;
              padding-top: 5px;
              margin-right: 20px;
            }

            @media (max-width: 769px) {
              width: 100%;
            }
          }

          #ordersContentRightColumn {
            width: 66%;
            font-weight: bold;

            #transportStop:not(:first-child) {
              border-top: 1px solid rgba(193, 205, 215, 0.5);
              padding-top: 5px;
            }

            #transportStop {
              margin-bottom: 40px;
              display: flex;
              width: 100%;

              @media (max-width: 769px) {
                display: flex;
                flex-direction: column;
              }

              #stopDetails {
                width: calc(45% - 20px);
                display: flex;
                margin-right: 20px;
                flex-direction: column;

                @media (max-width: 769px) {
                  margin-left: 10px;
                  width: 100%;
                }

                .drop_off {
                  color: #2655be;
                  display: flex;
                  margin-right: 5px;
                }

                #dateTimeContainer {
                  display: flex;

                  #startDate {
                    margin-right: 10px;
                  }

                  #startTimeRange {
                    margin-right: 4px;
                  }
                }

                #transportWithIcon {
                  display: flex;
                  margin-bottom: 40px;

                  svg {
                    margin-right: 10px;
                    height: 35px;
                    width: 18px;
                  }

                  #serviceIcon>svg>g>g>g {
                    fill: #966cb8;
                  }

                  .unassigned {
                    color: #ff886d !important;

                    svg {
                      g>g>g {
                        fill: #ff886d !important;

                        g {
                          fill: #ff886d !important;

                          g {
                            g {
                              fill: white !important;
                            }
                          }
                        }
                      }
                    }
                  }

                  #rentalTransportContainer {
                    #rentalTransportHeader {
                      display: flex;
                      align-items: center;

                      .unassigned {
                        color: #ff886d !important;
                      }

                      .pick_up,
                      .custom {
                        margin-right: 20px;
                        display: flex;
                        margin-right: 5px;
                        height: 27.5px;
                      }

                      .drop_off {
                        height: 27.5px;
                      }

                      .service {
                        color: #966cb8;
                      }

                      svg {
                        display: flex;
                        user-select: none;
                        height: 100%;
                        margin-right: 10px;
                        border-top: 0px solid #c1cdd7;
                      }
                    }
                  }
                }

                #setByContainer {
                  margin-left: 30px;
                  margin-bottom: 10px;

                  .unassigned {
                    color: #ff886d !important;
                  }
                }
              }

              .trucksContainer {
                display: flex;
                flex-direction: column;
                width: 55%;
                padding-right: 20px;

                @media (max-width: 769px) {
                  width: 100%;
                  padding-right: 0;
                }

                #truckContainer {
                  margin: 0;
                  border: none;

                  .header {
                    min-width: auto;
                    line-height: normal;
                    padding: 0 0 0 10px;
                  }

                  .content {
                    padding: 0;
                    margin-top: -10px;
                  }

                  .btnExpand {
                    margin: 10px 15px 0 0;
                  }

                  .drop_off,
                  .pick_up,
                  .custom {
                    color: white;
                    padding: 7px;
                    border-radius: 2px;
                    margin-bottom: 10px;
                    font-weight: 400;
                  }

                  .details {
                    margin-left: 5px;
                  }

                  .singleStaff {
                    +.singleStaff {
                      border-top: 1px solid #DBE3ED;
                    }

                    display: flex;
                    padding: 10px 0 10px 2px;
                    width: 100%;

                    #staffName {
                      font-weight: bold;
                      width: 40%;
                    }

                    #staffRole {
                      font-weight: 400;
                      width: 60%;
                    }
                  }
                }
              }

              #noTruck {
                width: calc(55% - 20px);
                background-color: #ff886d;
                color: white;
                padding: 7px;
                border-radius: 2px;
                height: 32px;
                margin-right: 20px;
                font-weight: 300;

                @media (max-width: 769px) {
                  width: 100%;
                }
              }
            }

            @media (max-width: 769px) {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &>.content {
    grid-column: 1 / span 8;
    grid-row: 2;
    min-width: 0;
    padding: 0 10px 40px;

    @media (max-width: 768px) {
      padding: 0 0 40px;
    }

    @include breakpoint($tablet) {
      grid-column: 2 / span 12;
      padding: 40px 0;
    }

    @include breakpoint($tablet) {
      &.medium {
        grid-column: 3 / span 10;
      }

      &.small {
        grid-column: 4 / span 8;
      }

      &.narrow {
        grid-column: 5 / span 6;
      }

      &.thin {
        grid-column: 6 / span 4;
      }
    }

    .delivery-tickets-table {
      margin-top: 20px;
    }

    .picklist-headers {
      .headers {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-gap: 1rem;
        padding-bottom: 26px;

        @include breakpoint($tablet) {
          grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        }

        div {
          h4 {
            font-size: 18px;
            margin-bottom: 8px;
          }

          .header-info {
            p {
              margin-bottom: 0px;
            }

            .day {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

.dashboardWrapper,
.splashWrapper {
  height: 100%;

  .content {
    padding: 40px 10px 0;

    @include breakpoint($tablet) {
      max-width: 1020px;
      padding: 40px 0;
      margin: 0 auto;
    }
  }
}

.dashboardWrapper {
  background-color: $darkblue;
}

.splashWrapper {
  background-color: $navyblue;

  .content {
    &>div {
      margin-bottom: 20px;

      @include breakpoint($tablet) {
        display: flex;
      }

      section {
        padding: 0;
        margin-bottom: 20px;
        background-color: $darkblue;

        @include breakpoint($tablet) {
          width: span(1 of 4);
          margin: 0;
        }

        h2 {
          margin: 0 0 0 20px !important;
          line-height: 60px;
        }

        h4 {
          margin: 0 0 0 20px !important;
        }
      }

      &>ul {
        @include breakpoint($tablet) {
          width: span(1 of 4);
          margin-top: 15px;
        }
      }
    }

    ul {
      padding: 0 20px;

      @include breakpoint($tablet) {
        padding: 0 20px 20px;
      }

      li {
        a {
          position: relative;
          display: block;
          padding-left: 5px;
          border-top: 1px solid $midblue;
          font-size: 11px;
          line-height: 39px;
          color: $white;

          svg {
            height: 18px !important;
            width: 18px !important;
            margin-right: 10px;
            fill: $blue;
            vertical-align: middle;
          }

          &.add svg {
            fill: $green;
          }

          &.alert svg {
            fill: $orange;
          }

          &.qb svg {
            fill: #00a337;
          }

          &:hover {
            background-color: $navyblueHover;
          }

          .alertCallout {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
          }
        }
      }
    }
  }
}

.emailWrapper {
  min-height: 100%;
  background-color: #bcc2c7;
  overflow: auto;

  table {
    justify-content: center;
    display: flex;
  }

  .content {
    width: 640px;
    margin: 20px auto;
  }

  th,
  td {
    &[align='center'] {
      text-align: center;
    }

    &[align='right'] {
      text-align: right;
    }
  }

  h3 {
    border: none;
  }

  .browserLink {
    display: none;
  }

  .grid {
    table-layout: auto;

    tbody tr td {
      height: auto;
      border-top: none;
      white-space: normal;
    }
  }
}

.errorLanding {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  background: linear-gradient(180deg, $blue 50%, transparent 50%);
  text-align: center;

  @include breakpoint($tablet) {
    padding: 100px;
  }

  p {
    margin: 0;
    font-size: 16px;
    line-height: 20px;
  }

  h1 {
    margin: 0;
    font-size: 160px;
    line-height: 180px;
    font-weight: $thin;
    color: $white;

    @include breakpoint($tablet) {
      font-size: 288px;
      line-height: 380px;
    }

    svg {
      fill: $white;
      vertical-align: middle;

      @include breakpoint($tablet) {
        height: 240px !important;
        width: 240px !important;
      }
    }
  }

  .btn {
    margin: 0;
  }
}

.formFooter {
  padding: 5px;
  text-align: center;
  background-color: transparent;
  opacity: 1;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  z-index: 1000;

  @include breakpoint($tablet) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: $vertical;
    background-color: $navyblue;
    transform: translate(0, 0);
  }

  &.hidden {
    transform: translate(0, 55px);
    opacity: 0;

    @include breakpoint($tablet) {
      transform: translate(0, 40px);
    }
  }

  .actions {
    @include breakpoint($tablet) {
      display: inline-grid;
      width: 300px;
    }
  }
}

.auth-hod-acitons {
  display: flex;
  justify-content: center;
  margin-top: 10px;

  .btn {
    margin-left: 10px;
  }
}

.noMobile {
  display: none !important;

  @include breakpoint($tablet) {
    display: inherit !important;
  }
}

.event-name-container {
  gap: 4px;
  display: flex;
  margin-top: 12px;
  margin-bottom: 6px;

  a {
    color: $darkgrey;
    display: flex;
    align-items: center;
  }
}

.noDesktop {
  display: inherit !important;

  @include breakpoint($tablet) {
    display: none !important;
  }
}

.printOnly {
  display: none;
}

/* Used for text-only pages (privacy policy, terms, etc.) */
.text {
  @include breakpoint($tablet) {
    @include push(2 of 12);
    width: span(8 of 12);
    padding: 0 0 $vertical;
  }

  ul {
    margin-left: 15px;
    list-style-type: initial;
  }
}

.breadcrumbs-calendar {
  @media only screen and (max-width: $tablet) {
    margin-left: 8px;
    margin-top: 8px;
  }
}

.navbar-calendar-container {
  @media only screen and (max-width: $tablet) {
    padding-left: 4px;
    padding-right: 2px;
    margin-top: 8px;
    margin-bottom: 16px;
  }
}

.noData {
  display: flex;
  margin-top: 80px;

  &>div {
    width: span(1 of 2);
    text-align: center;

    &:first-of-type() {
      border-right: 1px solid $meddarkgrey;
    }

    .btnLink,
    .btnIcon {
      float: none;
      display: inline-block;
      height: auto;
      margin: 0 0 20px;
      line-height: 25px;
      font-weight: $bold;
      color: $darkblue;

      svg {
        margin: 0 0 0 5px;
      }
    }

    .btnIcon {
      border: none;

      svg {
        float: right;
        height: 18px !important;
        width: 19px !important;
        margin-top: 4px;
      }

      &:hover {
        background-color: transparent;
        color: $darkblue;

        svg {
          fill: $greenHover;
        }
      }
    }

    .logo {
      height: 170px;
      width: 170px;
      margin-bottom: 20px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: left top;
    }

    p+a {
      display: block;
      margin-top: 10px;
      text-decoration: underline;
    }
  }
}

.invisibleMap {
  opacity: 0;
}

.calendarFlex {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &.calendarFlexInner {
    justify-content: center;
  }

  .calendarFlexInner {
    margin-top: -225px;
    display: flex;
    flex-direction: column;

    section {
      width: 340px;
    }

    h2 {
      color: $blue;
      font-weight: bold;
    }
  }
}

.blueCheck svg {
  fill: $white !important;
  color: $blue;
}

.calendarGrid {
  display: grid;
  grid-template-columns: 20px 1fr;
}

.smDeleteSpinner {
  float: right !important;

  div {
    padding: 0 !important;

    svg {
      width: 18px !important;
      height: 18px !important;
      vertical-align: middle !important;
    }
  }
}

.forceSignout {
  margin-bottom: 70px;
}

.pickListMobileAccordion {
  background-color: #e5e8e9;
}

.backgroundWhite {
  .content {
    background-color: white;
  }
}

.bold {
  font-weight: bold;
}

.ImportWizardUploadContainer {
  grid-column-start: 2;
  grid-column-end: 14;
  padding: 3em;
}

.flex-actions-container {
  display: flex;
  flex-direction: row;

  .btn {
    flex: 0.75;
    gap: 20px;

    &.cancel {
      flex: 0.25;
    }
  }
}

.ImportWizardAdjustContainer {
  grid-column-start: 2;
  grid-column-end: 14;
  padding: 3em;
  background-color: white;
}

.importWizardUpload {
  justify-content: center;
  text-align: center;
  background-color: white;
  padding: 2em;
  height: 50%;

  .uploadInput {
    position: relative;
    display: inline-block;
    padding: 0 8px 0 43px;
    margin-bottom: 5px;
    top: 45%;
    bottom: 55%;
    @include border-radius(3px 2px 2px 3px);
    background-color: $lightgrey;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 35px 35px;
    line-height: 35px;
    text-align: start;
    width: 30%;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 0;

    @include breakpoint($tablet) {
      padding: 0 8px 0 38px;
      background-size: 30px 30px;
      line-height: 30px;
    }

    input {
      display: none;
    }

    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 35px;
      background-color: $blue;
      border-radius: 2px;
      z-index: -1;

      @include breakpoint($tablet) {
        width: 30px;
      }
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      height: 35px !important;
      width: 35px !important;
      padding: 11px;
      fill: $white;

      @include breakpoint($tablet) {
        height: 30px !important;
        width: 30px !important;
        padding: 8px;
      }
    }

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.secondary:after {
      background-color: $cobalt;
    }
  }
}

.importWizardDone {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;

  a {
    width: 14em;
  }
}

.paymentFormWrapper {
  display: block;

  @media (max-width: 769px) {
    background-color: white;
  }

  .paymentFormHeader {
    display: flex;
    justify-content: space-around;
    background-color: $navyblue;
    padding-bottom: 7em;
    text-align: center;
    align-items: center;
    padding-top: 6em;

    h1 {
      color: white;
    }

    svg {
      margin-top: 0.5em;
    }
  }

  .errorMessage {
    justify-content: center;
    display: flex;

    .errorText {
      color: $darkblue;
    }
  }

  .stripeFormWrapper {
    @media (min-width: 769px) {
      width: 60%;
      margin-top: 2em;
    }

    margin: auto;
    top: 50%;
    left: 50%;
    bottom: 50%;
    right: 50%;
    background-color: white;
    padding: 1em;
  }

  .paymentInfoHeader {
    color: $blue !important;
  }

  .termsCheckbox {
    white-space: nowrap;

    label {
      white-space: normal;

      span {
        z-index: 1;
        position: relative;

        a {
          margin: 0 4px 0 4px;
        }
      }
    }
  }

  .stripePaymentForm {
    @media (min-width: 769px) {
      margin: 6em;
    }

    .stripePaymentFormInputs {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 1em;
      white-space: nowrap;

      button {
        margin-top: 0.5em;
        width: 50%;
      }
    }
  }

  .paymentStatusMessage {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 1em;
    height: 50px;
    background-color: white;

    span {
      margin: 15px 0 15px 0;
      color: $darkblue;
      font-size: large;
    }
  }
}

.ImportWizardHeader {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  height: 160px;
  padding: 0;
  background-color: #0e2c44;
}

.importWizardActions {
  position: absolute;
  width: 20em;
  top: 35%;
  bottom: 25%;
  right: 0;
  text-align: center !important;
}

.importWizardNextButton {
  display: block !important;
  background-color: #0e2c44;

  .ImportWizardSubHeader {
    h1 {
      color: lightgray;
    }
  }
}

.importWizardSecondaryBtn {
  margin-top: 10px;
  display: block !important;

  .ImportWizardSubHeader {
    h1 {
      color: #0e2c44;
    }
  }
}

.ImportWizardOptions {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  background-color: white;
  height: 40%;
  padding: 4em;
  margin: 4em;
  align-items: flex-start;

  a {
    text-decoration: underline;
  }

  svg {
    fill: green;
  }

  .ImportOption {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .banner {
      background-color: $blue;
      color: $white;
      font-size: 80%;
      margin-top: -48px;
      margin-bottom: 31px;
      width: 100px;
      align-self: center;
    }

    a {
      text-decoration: none;
    }

    span {
      cursor: pointer;
    }

    .Header {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;

      span {
        font-weight: bold;
        color: black;
      }

      svg {
        padding-left: 0.5em;
        min-width: 1.5em;
        min-height: 1.5em;
      }
    }
  }

  .CircleStyle {
    height: 12em;
    width: 12em;
    margin: 1.5em;
    font-size: 10px;
    line-height: 10px;
    color: #278ada;
    background-color: #0e2c44;
    text-align: center;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;

    svg {
      min-width: 3em;
      min-height: 3em;
      fill: #278ada;
    }
  }
}

.ImportWizardSelectBody {
  .ImportHistories {
    width: 50%;
    margin-left: 28em;
  }
}

.filterOptionColor {
  color: white;
}

.adjustTableImportWizard {
  border-collapse: collapse;
  overflow-x: auto;
  white-space: nowrap;
  display: block;
  border-spacing: 3px 0;
  border-collapse: separate;
}

.tableRowProblem {
  border-left: solid #ff886d 5px !important;
}

.importWizardTableInput {
  color: black !important;
}

.importColumnUnmatched {
  background-color: #d3347f;
}

.importColumnMatched {
  background-color: #54c06e;
}

.importWizardTableCell {
  white-space: nowrap;
  cursor: pointer;
}

.h-2 {
  height: 2px;
}

.bg-darkBlue {
  background-color: #0E2C44;
}

.color-darkBlue {
  color: #0E2C44;
}

.text-bold {
  font-weight: bold;
}

.justify-end {
  justify-content: flex-end;
}

.font-size-16 {
  font-size: 16px !important;
}

.gap-5 {
  gap: 5px;
}

.w-550 {
  width: 550px;
  max-width: 550px;
  min-width: 550px;
}

w-full {
  width: 100%;
}

.importWizardTableCell a {
  visibility: hidden;
}

.min-w-100-tablet {
  @media screen and (max-width: $tablet) {
    min-width: 100px;
  }
}

.importWizardTableCell:hover a {
  visibility: visible;
}

.importWizardTableRow .actions {
  visibility: hidden;
}

.importWizardTableRow:hover .actions {
  visibility: visible;
}

.actionsColumn {
  border-top: none !important;
}

.importWizardTableFilterHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inputProblemWarning {
  color: #ff886d !important;
}

.cellProblemWarning {
  border-bottom: solid;
  border-color: #ff886d;
}

.adjustTableErrorTooltip {
  word-wrap: break-word;
  overflow: hidden;
}

.importWizardAdjustFilter {
  border-bottom: 1px solid $darkblue;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.unsetWidth {
  width: unset !important;
}

.adjustTableMatches {
  padding-bottom: 3em;

  span {
    padding: 0.6em;
    margin-right: 1em;
    color: white;
  }

  .matches {
    background-color: $green;
  }

  .unmatched {
    background-color: #d3347f;
  }

  .problems {
    background-color: #ff886d;
  }
}

.importWizardSubmit {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  text-align: center;
  background-color: $darkGray;

  a {
    width: 100px;
    margin-top: 5px;
  }

  .submitBtn {
    width: 150px;
  }

  .cancelBtn {
    background-color: gray;
  }
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.vertical-alignment-sub {
  vertical-align: sub;
}

.no-margin-bottom-mobile {
  @media screen and (max-width: 768px) {
    margin-bottom: 0 !important;
  }
}

.no-margin-top-mobile {
  @media screen and (max-width: 768px) {
    margin-top: 0 !important;
  }
}

.fill-vw-mobile {
  @media screen and (max-width: 768px) {
    width: 100vw;
  }
}

.overflow-scroll-mobile {
  @media screen and (max-width: 768px) {
    overflow: scroll;
  }
}

.left-15-mobile-padding {
  @media screen and (max-width: 768px) {
    padding-left: 15px;
  }
}