div.bounce-start {
    animation-name: bounce;
    animation-duration: 0.5s; 
    animation-direction: alternate; 
    animation-timing-function: cubic-bezier(.5, .8, .05, .5); 
    animation-iteration-count: infinite; 
    -webkit-animation-name: bounce; 
    -webkit-animation-duration: 0.5s; 
    -webkit-animation-direction: alternate; 
    -webkit-animation-timing-function: cubic-bezier( 
    .5, .8, .05, .5); 
    -webkit-animation-iteration-count: infinite; 
}

@keyframes bounce { 
    from { 
        transform: translate3d(0, 0, 0); 
    } 
    to { 
        transform: translate3d(0, -20px, 0); 
    } 
} 

@-webkit-keyframes bounce { 
    from { 
        -webkit-transform: translate3d(0, 0, 0); 
        transform: translate3d(0, 0, 0); 
    } 
    to { 
        -webkit-transform: translate3d(0, 200px, 0); 
        transform: translate3d(0, -20px, 0); 
    } 
} 