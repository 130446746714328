.inventorySlideOut {

  margin-top:  30px;

  p.total {
    margin-right: 20px;
    font-weight: bold;
    font-size: 12px;
    color: $blue;
    margin-bottom: 34px;

    span {
      float: right;
    }
  }

  input {
    width: 210px !important;
  }

  .btn {
    &.btn-submit {
      padding: 0px;
      margin-left: 10px !important;
    }
  }

  .itemList {
    li {
      display: flex;

      .checkbox {
        margin-left: auto;
        margin-right: 30px;
      }
    }
  }

  .serializedMultiple {
    li {
      justify-content: space-between;
      align-items: center;

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 50%;

        input {
          width: 120px !important;
          margin: 10px 20px;
        }
      }
    }

    
  }
}