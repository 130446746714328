.editDocument {
  display: grid !important;
  width: span(9 of 10) !important;
  .hintText {
    color: #7194AC;
  }
  .react-datepicker-component {
    .react-datepicker-input {
      width: 50% !important;
    }
  }
}

.noMargin {
  margin: 0 !important;
}

.fullWidth {
  width: 100%;
}

.overrideWidth {
  width: 100% !important;
}
