.itemViewRow {
  .checkBox {
    margin-top: -10px;
  }
  .quantity {
    margin-top: -10px;
  }
  .notes {
    color: #000000;
  }
  .name1 {
    color: #000000;
    padding-left: 20px;
    margin-top: 10px;
  }
  .name2 {
    color: #000000;
    padding-left: 20px;
  }
  .name3 {
    color: #000000;
    padding-left: 250px;
  }
  .name4 {
    color: #000000;
    padding-left: 325px;
  }
  .name5 {
    color: #000000;
    padding-right: 300px;
  }
  .name6 {
    color: #000000;
    margin-top: 10px;
  }
}
