.categoryManagement {	
	@include breakpoint($tablet) {
		@include push(2 of 12);
		@include span(10 of 12);
	}
	
	.bullets {
		margin-bottom: 40px;
	}
	.categoryList {
		@include breakpoint($tablet) {
			@include span(13 of 20);
			@include post(7 of 20);
		}
		
		.category {
			@include container;
			position: relative;
			padding-bottom: 5px;
			border-bottom: 2px solid $darkgrey;
			margin-top: 5px;
		
			.btnExpand {
				float: left;
				margin: 10px 10px 0 15px;
				
				svg {
					fill: $blue;
				}
			}
			h2 {
				margin: 0;
			}
			& > input[type="text"] {
				width: 235px;
				margin-bottom: 0;
				@include breakpoint($tablet) {
					width: 429px;
				}
			}
			.btnLink {
				position: absolute;
				top: 7px;
				@include breakpoint($tablet) {
					top: 5px;
				}
				
				&.add, &.config {
					right: 5px;
				}
				&.delete {
					right: 35px;
				}
			}
			
			.subcategories {
				margin-top: 5px;
				@include push(2 of 13);
				@include span(11 of 13);
			
				li {
					position: relative;
					height: 40px;
					line-height: 35px;
					@include breakpoint($tablet) {
						height: 35px;
						line-height: 30px;
					}
					
					input[type="text"] {
						width: 205px;
						@include breakpoint($tablet) {
							width: span(9 of 11);
						}
					}
				}
			}
			.actions {
				@include push(1 of 8);
				@include span(6 of 8);
				@include post(1 of 8);
				@include breakpoint($tablet) {
					@include push(2 of 13);
					@include span(6 of 13);
					@include post(5 of 13);
				}
			}
		}
	}
}
.marketplaceCategories {
	section {
		@include breakpoint($tablet) {
			width: span(9 of 17);
		}
		
		.grid {
			margin-bottom: 20px;
		}
		.toggle {
			width: 100%;
		}
	}
}