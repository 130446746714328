.inventory {
  &.thumbnail {
    .serializedProduct svg {
      display: none !important;
    }
  }
  tbody {
    .rTableCell {
      vertical-align: top;
      padding-top: 10px;
    }

    .serializedProductContainer {
      white-space: normal;
      padding-bottom: 8px;
    }

    a.serializedProduct {
      color: #272727;
      background: lightgray;
      margin-right: 5px;
      padding: 0 6px;
      border-radius: 3px;
    }

    tr {
      th,
      td {
        vertical-align: top;
        padding-top: 10px;

        &.action {
          vertical-align: top;
        }
      }
    }
  }
}

.strong-border-top {
  border-top: 3px solid black !important;
  padding-top: 7px;
}

#imageInventoryOnMobile {
  padding-top: 20px;
  
  @include breakpoint($tablet) {
    padding-top: 0;
  }
}