.pricingsSection {
  .pricingselection {
    margin-bottom: 20px;

    span {
      width: 30%;
      display: inline-block;
    }

    select {
      width: 70%;
    }
  }
  tr {
    td {
      white-space: normal !important;
    }
    td:first-child {
      color: $blue;
      width: 90px;
    }
  }

  div.check {
    margin-top: 12px;
  }
}

.productPricingDrawer {
  .content {
    margin-bottom: 20px;

    > div {
      margin-bottom: 10px;

      div {
        border-top: 1px solid #0c263b;
        padding: 5px 15px;
      }

      div:not(.active) input {
        color: $darkgrey;
      }
    }

    h4 {
      color: $white;
      margin-left: 15px;
      display: inline-block;
    }

    input {
      width: 100px;
      text-align: right;
    }

    span {
      color: $darkgrey;
      margin-left: 10px;
    }

    .active span {
      color: white;
    }
  }

  &.drawer.withForm .drawerForm {
    p {
      color: $darkgrey !important;
    }
  }
}
