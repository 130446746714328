.hide {
  display: none;
}

.loadingDots .dot1 {
  animation: load 1s infinite;
}

.loadingDots .dot2 {
  animation: load 1s infinite;
  animation-delay: 0.2s;
}

.loadingDots .dot3 {
  animation: load 1s infinite;
  animation-delay: 0.4s;
}

@keyframes load {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media print {
  .hide-on-print {
    display: none !important;
  }
}
.rentalItemEllipsis {
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.rentalItemNameTooltip {
  word-wrap: break-word;
  width: 280px;
}

.pbiReportEllipsis {
  max-width: 320px;
  display: inline-block !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
