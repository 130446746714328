.customerPayment {
	header {
		height: auto;
		background-color: $white;
		@include breakpoint($tablet) {
			height: 200px;
		}

		& > div {
			display: grid;
			@include breakpoint($tablet) {
				grid-template-columns: 1fr 1fr;
			}
			
			img, h1 {
				place-self: center;
				margin: 20px 0;
				@include breakpoint($tablet) {
					place-self: center end;
					margin: 0 40px 0 0;
				}
			}
			img {
				width: span(3 of 4);
				@include breakpoint($tablet) {
					height: 150px;
					width: auto;
				}
			}
			h1 {
				color: $darkblue;
				font-weight: $light;
			}
			.details {
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin-bottom: 20px;
				font-size: 16px;
				line-height: 24px;
				text-align: center;
				@include breakpoint($tablet) {
					padding: 10px 0 10px span(1 of 12);
					margin-bottom: 0;
					border-left: 1px solid $meddarkgrey;
					text-align: left;
				}
			}
		}
	}

	.content {
		padding-top: 20px;
		@include breakpoint($tablet) {
			padding-top: 40px;
		}
		
		.signatureWrapper {
			@include breakpoint($tablet) {
				@include push(3 of 12);
				@include span(6 of 12);
				@include post(3 of 12);
			}

			.signature {
				margin-bottom: $vertical;
				text-align: center;
				@include breakpoint($tablet) {
					@include push(1 of 6);
					@include span(4 of 6);
					@include post(1 of 6);
				}

				.signaturePad {
					height: 70px;
					width: 100%;
					margin-bottom: 10px;
					text-align: left;
				}
				.btn {
					width: 100%;
				}
			}
			.agreement {
				clear: both;
				
				h1 {
					padding-top: 0;
					margin-bottom: 10px;
					color: $blue;
				}
				.scrollarea {
					height: 500px;
					
					.scrollbar-container {
						background-color: transparent;
						
						.scrollbar {
							background: $blue;
						}
					}
				}
			}
		}

		.paymentWrapper {
			@include container;
			display: flex;
			flex-direction: column;
			@include breakpoint($tablet) {
				display: block;
			}

			& > h1 {
				color: $darkblue;
				font-weight: $regular;
				text-align: center;
			}
			.details {
				order: 2;
				@include breakpoint($tablet) {
					@include span(6 of 12);
					padding-right: span(1 of 12);
					border-right: 1px solid $darkgrey;
				}

				h1 {
					margin: 0;
				}

				&.full {
					@include breakpoint($tablet) {
						@include push(3 of 12);
						@include span(6 of 12);
						@include post(3 of 12);
						padding-right: 0;
						border-right: none;
					}
				}
			}
			.payment {
				order: 1;
				margin-bottom: 30px;
				@include breakpoint($tablet) {
					@include push(1 of 24);
					@include span(11 of 24);
				}

				h1 {
					margin-bottom: 0;
					font-weight: $light;
					color: $blue;
					@include breakpoint($tablet) {
						@include push(3 of 11);
					}
				}
				label {
					@include span(break 1 of 2);
					padding: 8px $innerPadding 6px 0;
					font-size: 16px;
					line-height: 16px;
					font-weight: $regular;
					color: $darkblue;
					@include breakpoint($tablet) {
						@include span(break 3 of 11);
						color: $darkgrey;
						text-align: right;
					}

					&.caption {
						@include push(1 of 2);
						width: auto;
						padding: 8px 0 6px;
						font-size: 12px;
						color: $darkgrey;
						text-align: left;
						@include breakpoint($tablet) {
							@include push(0 of 11);
							@include span(nobreak 4 of 11);
							padding-left: 5px;
						}

						span {
							float: none;
							font-size: 12px;
						}
					}
				}
				span {
					float: left;
					padding: 8px 5px 6px 0;
					font-size: 16px;
					line-height: 16px;
					@include breakpoint($tablet) {
						padding-left: 5px;
					}
				}
				input[type="text"] {
					@include span(full);
					@include breakpoint($tablet) {
						@include span(6 of 11);
					}

					&.sm {
						@include breakpoint($phone $tablet) {
							@include span(1 of 2);

							&:nth-of-type(even) {
								width: calc(50% - 5px);
								margin-right: 5px;
							}
						}
						@include breakpoint($tablet) {
							@include span(2 of 11);
						}
					}
				}
				.info {
					float: left;
					margin-top: 6px;
				}
				p {
					clear: left;
					margin-bottom: 0;
					font-size: 12px;
					@include breakpoint($tablet) {
						@include push(3 of 11);
						padding: 0 5px;
					}

					span {
						float: none;
						padding: 0;
						font-size: inherit;
					}
				}
				.highlight {
					color: $blue;
				}
				.actions {
					@include container;
					@include breakpoint($tablet) {
						@include push(3 of 11);
					}

					.btn {
						float: left;
						width: calc(#{span(2 of 3)} - 5px);

						&.cancel {
							width: calc(#{span(1 of 3)} - 5px);
						}
						&.full {
							width: 100%;
							margin-right: 0;
							@include breakpoint($tablet) {
								@include span(6 of 8);
								@include post(2 of 8);
							}
						}
					}
				}
				.divider {
					clear: both;
					position: relative;
					margin: 20px 0;
					color: $darkgrey;
					text-transform: uppercase;
					text-align: center;
					@include breakpoint($tablet) {
						@include push(3 of 11);
						@include span(6 of 11);
						@include post(2 of 11);
					}

					&:before, &:after {
						content: " ";
						position: absolute;
						top: 7px;
						height: 1px;
						width: span(4 of 10);
						background-color: $darkgrey;
					}
					&:before {
						left: 0;
					}
					&:after {
						right: 0;
					}
				}

				.completed {
					@include breakpoint($phone $tablet) {
						label {
							@include span(full);
						}
					}
				}
			}
		}
	}
}
