@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

.scheduleMonth {
  /*
   * Inventory calendar share some styles with others, like routing schedule.
   * These are specific overrides for the inventory calendar only.
   * Be mindful that changing calendar styles outside of the scope below might
   *   affect other areas of the application.
   */
  &.inventoryCalendarSection {
    padding: 10px 0px !important; // Necessary to override section default padding.

    .Week:nth-child(even) {
      .Day {
        background-color: $lightgrey;
      }
    }

    .MyMonth .Week .Day > .Day__number {
      top: 0px !important;
      left: 0px !important;
      bottom: 0px;
      padding: 10px;
      width: 100%;
    }

    .Month {
      border: 1px solid $mediumgrey;
      border-right: 0px;

      .Week {
        & > * {
          padding: 10px;
        }

        &:not(:last-child) {
          border-bottom: 1px solid $mediumgrey !important;
        }

        &:last-child {
          border-bottom: 0px;
        }

        .EmptyCell {
          border-right: 1px solid $mediumgrey !important;
          background-color: $white;
        }
      }

      .Day {
        position: static;
        display: flex;
        flex-direction: column;
        height: 60px;

        border-right: 1px solid $mediumgrey !important;

        .Day__number {
          right: 0px;
          left: 0px;
          font-weight: normal;
        }

        .Day__container {
          text-align: left;

          .days {
            font-size: 12px !important;

            text-align: left;
          }
        }
      }
    }
  }

  .dayNames {
    display: flex;
    font-size: 9px;
    line-height: 40px;
    color: $blue;

    label {
      width: span(1 of 7);
    }
  }

  .Month {
    .Day:hover {
      background-color: $lightgrey;
    }

    .Day__container {
      padding: 5px 0;
      font-size: 10px;
      line-height: 20px;
      color: $white;

      div {
        display: inline-block;
        cursor: pointer;

        * {
          cursor: pointer;
        }
      }

      svg {
        height: 20px !important;
        width: 27px !important;
        fill: $darkblue;
        vertical-align: middle;
      }

      .customStopIcon svg {
        padding-top: 2px !important;
      }

      .assigned,
      .unassigned {
        width: 20px;
        margin-right: 2px;
        @include border-radius(50%);
        text-align: center;
      }

      .assigned {
        background-color: $blue;
      }

      .unassigned {
        background-color: $orange;
      }

      .dropOff,
      .pickUp {
        position: relative;
        margin-right: 2px;
        font-weight: $bold;
        line-height: 14px;

        label {
          position: absolute;
          top: 0;
          text-align: center;
        }
      }

      .dropOff label {
        left: 0;
        right: 11px;
      }

      .pickUp label {
        left: 11px;
        right: 0;
      }
    }
  }
}

.days {
  font-size: 20;
  color: black;
}

.MyMonth {
  background-color: #ddd;
  color: black;
}

.MyMonth .Week .Day > .Day__number {
  color: #aaa;
  bottom: 4px;
}

.MyMonth .Week {
  background-color: white;
}

.pt-40 {
  padding-top: 40px;
}

.calendarHead {
  margin-bottom: -30px;
}

.calendarTopIconsRight {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  svg {
    height: 25px !important;
    width: 25px !important;
    fill: $blue;
  }

  a {
    margin-left: 5px;
    cursor: pointer;

    svg {
      width: 20px;
      height: auto;
    }

    &.printIcon {
      svg {
        path {
          &:first-child {
            fill: #466b88;
          }
        }
      }
    }
  }

  span {
    cursor: pointer;

    img {
      width: 25px;
      height: auto;
    }
  }
}

.text-light-grey {
  color: #466b88;
}

.text-blue-grey {
  color: $bluegrey;
}

.text-blue {
  color: #278ada !important;
}

.product-view {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 30px;
  gap: 16px;

  .flex-row-container {
    .bundles-total-qty {
      margin-left: 0px;
    }
  }

  @include breakpoint($tablet) {
    display: flex;
    flex-direction: row !important;
  }

  @include breakpoint($desktop) {
    display: flex;
    flex-direction: row !important;
  }

  table {
    td {
      padding-right: 8px;
    }
  }

  .caption {
    margin-left: 0px;
  }

  .left {
    flex-grow: 5;
    display: flex;
    flex-direction: column;

    @include breakpoint($tablet) {
      display: flex;
      flex-direction: row !important;
    }

    @include breakpoint($desktop) {
      display: flex;
      flex-direction: row !important;
    }

    .leftblk {
      min-width: 195px;

      figure {
        width: 100%;
        max-height: 200px;
        overflow: hidden;
        height: 110px;

        svg {
          height: 40px !important;
          width: 40px !important;
        }
      }

      .figureBottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;
        gap: 2px;

        #see_more_details {
          margin-bottom: 10px;
          @include breakpoint($tablet) {
            margin-bottom: 0;
          }
        }

        b {
          color: $darkGray;
          size: 12px;
        }

        a {
          &.moreDetails {
            color: #278ada;
            text-decoration: underline;
            font-size: 10px;
          }
        }

        .token {
          color: #278ada;
          font-size: 10px;
        }
      }
    }

    .rightblk {
      margin-left: 0;
      flex-grow: 1;

      h1 {
        font-size: 24px;
        letter-spacing: 0;
        line-height: 35px;
        color: $darkGray;
        font-weight: bold;
        margin-bottom: 10px;
      }

      @include breakpoint($tablet) {
        margin-left: 20px;
      }

      .maintenanceQuantity {
        svg {
          fill: #278ada;
          height: 18px !important;
          width: 18px !important;
          margin: 0px 10px 0px 5px;
        }

        display: flex;
        align-items: center;

        margin-bottom: 15px;
      }

      .summaryInfo {
        table {
          width: 100%;

          tr:first-child {
            border-top: 1px solid $mediumgrey;
          }

          tr {
            border-bottom: 1px solid $mediumgrey;
          }

          td {
            line-height: 26px;
            font-size: 12px;
          }

          td.quantityLabel {
            text-align: right;
            width: 20px;
            font-weight: bold;
          }

          td.locationLabel {
            padding-left: 12px;
          }
        }

        p {
          margin-bottom: 0px;
          display: inline-block;
          margin-left: 6px;
        }

        label {
          font-size: 10px;
          color: #278ada;
        }

        .groupItems {
          .listing {
            font-size: 14px;
            line-height: 24px;
            color: #2e475c;
            display: flex;

            strong {
              color: #278ada;
              margin-right: 15px;
              margin-top: 2px;
            }
          }
        }
      }
    }
  }

  .right {
    text-align: right;

    .summaryPricing {
      .price {
        font-size: 16px;
        margin-bottom: 5px;
        text-align: left;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        gap: 2px; // Acts as gap between the price type header and the actual amount

        h6 {
          color: #278ada;
        }

        span {
          font-weight: bold;
        }

        strong {
          display: block;
          min-width: 90px;
        }

        label {
          color: #92adc0;
        }
      }
    }
  }

  #productCalendarMobileFlex {
    min-width: 500px;
    width: 66%;
  }
}

/* Calendar Ui Design */
.scheduleMonth {
  .dayNames {
    label {
      font-weight: 600;
      color: #278ada;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #278ada;
      height: 24px;
      margin-right: 0px;
    }
  }

  .Month {
    .Week {
      .Day {
        position: relative;

        &.active {
          background-color: $darkGray;

          .Day__number {
            color: $white;
          }

          .days {
            color: $white;
          }
        }

        .Day__number {
          right: 4px;
          left: inherit;
          font-size: 12px !important;
          height: fit-content;
          font-weight: bold;

          &.blue,
          &.orange {
            width: 25px;
            height: 25px;
            border-radius: 100%;
            color: #fff;
            display: flex;
            justify-content: center;
            font-weight: 600 !important;
            align-items: center;
          }

          &.blue {
            background: #278ada;
          }

          &.orange {
            background: #ff886d;
          }
        }

        .Day__container {
          padding: 0px 10px;
          bottom: 3px;
          top: inherit;
          width: 100%;
          text-align: left;
          left: 0;

          &.orange {
            .days {
              color: #ff886d;
            }
          }

          .days {
            font-size: 14px;
          }
        }
      }
    }
  }
}

section.calendar-table-filter {
  padding: 0px 0px 10px;
}

.calendar-table-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  h3 {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    border: 0;
    padding: 0;
  }
}

section.inventoryCalendarRentalList {
  padding: 10px 0px 0px 0px;
}

.inventoryCalendarRentalList {
  .maintenance {
    &,
    td {
      &:hover {
        background-color: white !important;
        cursor: default;
      }

      color: $darkgrey;
    }
  }

  .initialQuantity {
    &,
    td {
      &:hover {
        background-color: white !important;
        cursor: default;
      }
    }

    td {
      padding: 0px 0px 4px 0px;
      border: none;
      height: 26px;

      label {
        // float: right;
        font-size: 11px;
      }

      padding-right: 6px;
    }
  }
  .strongBorder {
    td {
      border-top: 2px solid $darkblue !important;
    }
  }

  tr {
    td.right:last-of-type {
      padding-right: 6px !important;
    }
  }

  td {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  td {
    vertical-align: middle !important;

    color: $darkblue;
  }

  thead {
    .qtyWidth {
      width: 35px;
    }

    th.medSm {
      @include breakpoint($tablet) {
        width: 105px !important;
      }
    }

    th:last-child {
      padding-right: 4px;
    }
  }

  .token {
    font-weight: normal;
    color: $darkgrey;
  }

  .rowDetailsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px 25px;

    .rowDetails {
      width: 100%;

      tr {
        border-top: 1px solid $mediumgrey;
      }

      td {
        line-height: 26px;
        font-size: 12px;
        padding-top: 0px;
        padding-bottom: 0px;

        p {
          margin-bottom: 0px;
          display: inline-block;
          margin-left: 6px;
        }

        label {
          font-size: 10px;
          color: #278ada;
        }
      }

      thead {
        tr {
          border-top: none !important;
        }

        th {
          text-align: center;
        }
      }

      .quantity {
        padding-left: 16px;
        font-weight: bold;
        width: 6%;
        text-align: center;
      }

      .available {
        text-align: center;
        width: 15%;
      }
    }
  }
}

.filter {
  .autocomplete {
    .results {
      li {
        background-color: #e0e5e9;
      }

      li:hover {
        background-color: #c0c0c0;
      }
    }
  }

  select {
    max-width: 270px;
  }
}

.productList {
  .monthPicker {
    .datePickerView {
      position: relative;
      padding-right: 10px;

      label {
        min-width: 230px;
        background: #e5e8e9;
      }

      a {
        position: absolute;
        width: 25px;
        height: 30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        &.left {
          right: 25px;
        }

        &.right {
          right: 0;
        }
      }
    }
  }
}

.pagerWrapper {
  .pager {
    input[type='text'] {
      background-color: $darkblue !important;
    }
  }
}

.checkListItem + div {
  margin-left: 20px;
}

.section-collapse {
  width: auto;

  .btnExpand,
  .btnCollapse {
    color: $blue;

    svg {
      fill: $blue;
    }
  }

  hr {
    width: auto;
    border-bottom: 2px solid $blue;
  }

  h4 {
    margin-bottom: 0;
    padding-bottom: 5px;
  }
}

.bundle-content {
  margin-bottom: 2rem;

  &:not(.outer-content) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  table {
    width: -webkit-fill-available !important;
    margin-left: -20px;
  }

  @include breakpoint($phone $tablet) {
    table {
      width: 100% !important;
      margin-left: -10px;
    }
  }

  .bundle-tables-container {
    &.padded {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    section {
      margin-bottom: 0;
    }
  }

  .orange-color {
    color: $orange;
  }

  .btnExpand {
    margin-top: 7px;
  }
}

.inventoryCalendarItemContent {
  .colored-row:nth-child(2n) {
    background-color: $lightgrey;
  }

  .caption {
    margin-left: 0px;
    padding-left: 0px;
  }

  .rightSide {
    float: right;

    a {
      text-decoration: underline;
    }
  }
}

.flex-row-container {
  .bundles-total-qty {
    font-size: 33px;
    margin-left: 7px;
  }

  .blue-color {
    color: $blue;
  }

  .orange-color {
    color: $orange;
  }
}

.buffer-container {
  h2 {
    color: $darkGray;
    font-weight: 510;
    font-size: 48px;
  }

  h5 {
    color: $blue;
  }

  p {
    color: $darkgrey;
  }

  &.display-flex {
    display: flex;
  }
}
