.pricing-tiers-container {
  display: flex;
  background-color: #ffffff;
  padding: 45px 20px 20px;

  .pricing-tier-col {
    flex-direction: column;
    width: 100%;

    &.header-col {
      .section {
        margin-bottom: 20px;
      }

      .content-box {
        height: 50px;

        .heading {
          font-weight: bold;
          padding-bottom: 15px;
          border-bottom: 0.5px solid lightgrey;
        }

        .label {
          padding-right: 20px;
        }
      }
    }

    &.plan-col {
      .section {
        margin-bottom: 20px;
      }

      .content-box {
        height: 50px;
        min-width: 100px;

        &.small {
          min-width: 25px;
        }

        .section-heading {
          border-bottom: 0.5px solid lightgrey;
          padding-bottom: 15px;
          color: #278ada;
          line-height: 18px;
          font-weight: bold;
        }

        .input-container {
          input {
            text-align: left;
            max-width: 55px;
          }
        }
      }
    }
  }
}
