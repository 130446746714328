.formWrapper {
  & > form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .fields {
      display: flex;
      flex-direction: column;
      gap: 8px;

      padding: 0 15px;

      & > input {
        flex: 1;
      }

      .datasetWrapper {
        display: flex;
        flex-wrap: nowrap;
        gap: 5px;
        align-items: center;
        justify-content: space-between;

        input {
          flex: 1;
        }

        & > a.btnLink.add {
          margin: 0 0 5px 0 !important;
          padding: 0;

          svg {
            margin: 0 !important;
          }
        }

        & > a.btnLink.remove {
          margin: 0 0 5px 0 !important;
          padding: 0;

          svg {
            margin: 0 !important;
          }
        }
      }
    }
  }
}
