%button {
  display: inline-block;
}
button,
input[type='submit'] {
  -webkit-appearance: none;
}

.btn,
.btnIcon,
.btnEmail {
  display: inline-block;
  height: 35px;
  padding: 0;
  margin-right: 5px;
  border: none;
  @include border-radius(2px);
  font-size: 12px;
  font-weight: $bold;
  line-height: 35px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
  @include breakpoint($tablet) {
    height: 30px;
    font-weight: $regular;
    line-height: 30px;
  }

  &.outline {
    background-color: transparent;
    border: 2px solid $blue;
    color: $blue;
    line-height: 31px;
    @include breakpoint($tablet) {
      line-height: 26px;
    }

    &:hover {
      background-color: $blue;
      color: $white;
    }
  }
  &.preview {
    background-color: $purple;

    &:hover {
      background-color: $purpleHover;
    }
  }
  &.disabled,
  &:disabled {
    background-color: $meddarkgrey;
    color: $darkblue;
    cursor: auto;

    & > * {
      cursor: auto;
    }
    svg {
      fill: $darkgrey;
    }
    &:hover {
      background-color: $meddarkgrey;
    }
  }
}
/* Looks like a full button */
.btn {
  background-color: $blue;
  color: $white;
  // @include breakpoint($tablet) {
  //   padding-left: 60px;
  //   padding-right: 60px;
  // }

  &:hover {
    background-color: $lightblue;
  }

  &.secondary,
  &.tertiary,
  &.cancel,
  &.warn,
  &.secondary {
    background-color: $cobalt;
    color: $meddarkgrey;

    &:hover {
      background-color: $cobaltHover;
    }
  }
  &.tertiary {
    background-color: $darkblue;
    color: $white;

    &:hover {
      background-color: $darkblueHover;
    }
  }
  &.cancel {
    background-color: $darkgrey;
    color: $darkblue;

    &:hover {
      background-color: $darkgreyHover;
    }
  }

  &.change {
    background-color: $orange;
    color: $white;

    &:hover {
      background-color: $orangeHover;
    }
  }
  &.warn {
    background-color: $red;
    color: $white;

    &:hover {
      background-color: #f00;
    }
    &.disabled:hover {
      background-color: $red;
    }
  }
}

/* Looks like a full button with an icon inside */
.btnIcon {
  position: relative;
  background-color: $cobalt;
  color: $white;

  svg {
    height: 25px !important;
    width: 26px !important;
    margin-right: 5px;
    fill: $darkblue;
    vertical-align: middle;
    @include breakpoint($tablet) {
      height: 20px !important;
      width: 21px !important;
    }
  }
  &:hover {
    background-color: $cobaltHover;
  }

  &.tertiary {
    background-color: $darkblue;
    color: $white;

    svg {
      fill: $white;
    }
    &:hover {
      background-color: $darkblueHover;
    }
  }
  &.outline {
    svg {
      fill: $blue;
    }
    &:hover svg {
      fill: $darkblue;
    }
    &.addItem {
      background-color: transparent;
      color: $blue;
      border: 2px solid $blue;
      &:hover {
        cursor: pointer;
        color: $lightblue;
        border: 2px solid $lightblue;
      }
      &:disabled:hover {
        color: $blue;
        border: 2px solid $blue;
        cursor: auto;
      }
    }
    &.warn {
      background-color: transparent;
      color: $orange;
      border: 2px solid $orange;
      &:disabled:hover {
        color: $orange;
        border: 2px solid $orange;
        cursor: auto;
      }
    }
  }
  &.add {
    background-color: $blue;

    svg {
      fill: $green;
    }
    &:hover {
      background-color: $lightblue;

      svg {
        fill: $greenHover;
      }
    }

    &.outline {
      background-color: transparent;
    }

    &.purchase_order {
      &:hover {
        color: $white;
        background-color: $blue;
      }
    }
  }

  &.action {
    background-color: $blue;

    svg {
      fill: $white;
    }
    &:hover {
      background-color: $lightblue;

      svg {
        fill: $white;
      }
    }

    &.outline {
      background-color: transparent;
    }
  }

  &.preview {
    width: 30px;
    @include breakpoint($tablet) {
      padding: 0;
    }

    svg {
      height: 30px !important;
      width: 30px !important;
      margin-right: 0;
      fill: $white;
    }
  }
  &.quickScan {
    @include breakpoint($tablet) {
      padding-left: 10px;
      padding-right: 10px;
    }
    background-color: $lightgrey;
    color: $darkblue;

    svg {
      fill: $blue;
    }
    // &:hover {
    // 	background-color: $darkblueHover;
    // }
  }
  &.transparent {
    background-color: transparent;
    svg {
      fill: $red;
    }
  }
}

.btnSm {
  position: relative;
  display: inline-block;
  height: 20px;
  padding: 0 10px;
  @include border-radius(2px);
  background-color: $blue;
  color: $white;
  font-size: 10px;
  line-height: 22px;
}

/* Looks like a link with an icon */
.btnLink {
  display: inline-block;
  float: right;
  font-weight: $bold;
  color: $darkgrey;
  cursor: pointer;

  div {
    display: inline;
  }
  svg {
    height: 18px !important;
    width: 19px !important;
    margin-left: 5px;
    fill: $cobalt;
    vertical-align: middle;
  }
  &:hover {
    svg {
      fill: $cobaltHover;
    }
  }

  &.add {
    svg {
      fill: $green;
    }
    &:hover {
      svg {
        fill: $greenHover;
      }
    }
  }
  &.config {
    svg {
      fill: $darkgrey;
    }
    &:hover {
      svg {
        fill: $darkgreyHover;
      }
    }
  }
  &.remove {
    svg {
      fill: $darkgrey;
    }
    &:hover {
      color: $red;
      svg {
        fill: $red;
      }
    }
  }
  &.delete {
    svg {
      fill: $red;
    }
    &:hover {
      color: $red;
      svg {
        fill: $red;
      }
    }
  }

  &.excel {
    svg {
      fill: #00744c;
    }
    &:hover {
      svg {
        fill: $green;
      }
    }
  }
  &.qb {
    svg {
      fill: #00a337;
    }
    &:hover {
      svg {
        fill: $green;
      }
    }
  }

  &.disabled {
    cursor: auto;

    & > * {
      cursor: auto;
    }
    svg {
      fill: $darkgrey;
    }
    &:hover {
      svg {
        fill: $darkgrey;
      }
    }
  }
}
.btnLink.printBtn {
  margin-top: 10px;
  margin-right: 10px;
}
.btnExpand,
.btnCollapse {
  display: inline-block;
  float: right;
  margin: 13px 15px 0 0;
  font-size: 10px;
  line-height: 12px;
  font-weight: $bold;
  color: $darkgrey;
  text-transform: uppercase;
  cursor: pointer;
}
.btnExpand {
  svg {
    fill: $darkgrey;
  }
}
.btnCollapse {
  svg {
    fill: $blue;
  }
}

.positionRight {
  position: absolute;
  top: 2px;
  right: 5px;
}

/* Modal Close X */
.btnClose {
  position: absolute;
  top: 15px;
  right: 10px;
  height: 10px;
  width: 10px;
  line-height: 0;
  cursor: pointer;
  @include breakpoint($tablet) {
    right: 20px;
  }

  svg {
    fill: $blue;
  }
}

/* Styled links */
.linkAction {
  float: right;
  font-size: 10px;
  line-height: 40px;
  font-weight: $bold;
  color: $blue;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;

  svg {
    width: 19px !important;
    margin-left: 10px;
    fill: $blue;
    vertical-align: middle;
  }

  &.disabled {
    color: $darkgrey;
    text-decoration: none;

    svg {
      fill: $darkgrey;
    }
  }
}
.linkBack {
  display: inline-block;
  height: $vertical;
  padding: 15px 0;
  font-size: 10px;
  line-height: 12px;
  font-weight: $bold;
  color: $blue;
  text-transform: uppercase;
  cursor: pointer;

  svg {
    margin: 2px 10px -2px 0;
    fill: $blue;
  }
}

.transparentRemove {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s;
  svg {
    fill: $red;
    height: 13px;
    flex: 0 0 13px;
    margin-right: 5px;
  }
  p {
    margin: 0;
  }
  &.invisible {
    opacity: 0;
  }
  &.visible {
    opacity: 100;
  }
}

.pdfPreview {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 100px;

  &:hover {
    background-color: $purple !important;
  }
}

.previewIcon {
  background-color: transparent !important;
  padding-bottom: 6px;
}
