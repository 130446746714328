.rentalBilling {
  @include breakpoint($tablet) {
    display: grid;
    grid-template: auto 1fr / 4fr 5fr 3fr;

    .payment {
      &.accountIsTrial {
        height: 201px;
        width: 320px;
      }
    }
  }

  .signatures {
    @include breakpoint($tablet) {
      grid-column: span 3;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    }

    .signaturePad {
      margin-bottom: 20px;
    }
  }

  .details {
    .payment,
    .refund {
      .summary {
        border-bottom: 1px solid $meddarkgrey;
        margin-bottom: 10px;
        line-height: 35px;

        span {
          float: right;
        }
      }

      textarea {
        height: 60px;
        width: 100%;
        resize: vertical;
      }
    }

    .quickbooksToggle {
      .viewToggle {
        margin-top: 15px;
      }

      div.grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 10px 0;
      }
    }

    .payment {
      select {
        width: 100%;
      }

      .paymentAmount {
      }

      .customerSelection {
        margin: 10px 0;
        padding: 10px 0 5px;
        border-top: 1px solid $meddarkgrey;
        border-bottom: 1px solid $meddarkgrey;
      }

      .paymentType {
        .radioList {
          margin-bottom: 20px;
        }

        .fields {
          margin-bottom: 10px;
        }

        .check {
          margin-bottom: 10px;
        }
      }

      p {
        margin: 10px 0;
      }

      &.auto-billing {
        p span {
          float: right;
          font-weight: bold;
        }
      }
    }

    .refund {
      .billing {
        margin-top: $mobileVertical;

        @include breakpoint($tablet) {
          margin-top: 0;
        }
      }

      .Select-value {
        .refundOptionValue {
          display: flex;
          align-items: center;

          .details {
            font-size: 10px;
            line-height: 14px;
            color: $blue;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
              margin-left: 10px;
            }
          }
        }
      }

      .Select-menu-outer {
        z-index: 100;
        .refundOptionValue {
          overflow: auto;

          .details {
            display: flex;
            width: 100%;
            font-size: 10px;
            line-height: 14px;
            color: $blue;

            & > span {
              margin-right: 5px;

              &:last-of-type() {
                float: right;
                margin-left: auto;
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    .actions {
      margin-top: 10px;
    }
  }

  .billing {
    @include breakpoint($tablet) {
      margin: 0 50px;
    }

    .btnLink {
      display: none !important;
    }
  }

  .billingNotes {
    .btnIcon {
      width: 100%;
      margin-bottom: 20px;
    }

    & > span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-top: 24px;

      padding-bottom: 4px;

      p {
        color: $red;
        font-weight: bold;
        margin: 0;
        size: 12px;
        line-height: 18px;
      }

      svg {
        fill: $red;
      }
    }

    & > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 10px 0;
      border-top: 1px solid $meddarkgrey;

      .firstRowContainer {
        text-align: right;
        grid-row: 1;
        line-height: 14px;
        font-size: 10px;
        height: 18px;

        svg.editIcon {
          width: 16px;
          height: 16px;
          grid-column: 1;
          fill: $darkgrey;
          cursor: pointer;
        }
      }

      // Overrides the default text-align property from .firstRowContainer
      .firstRowContainer.icon {
        text-align: left;
        opacity: 0.8;
        transition: 0.1s;
      }

      .firstRowContainer.icon:hover {
        opacity: 1;
      }

      .deleted {
        color: $red;
      }

      .edited {
        color: $blue;
      }

      span {
        text-align: right;
      }

      p {
        grid-column: span 2;
        margin: 10px 0;
      }

      &:first-of-type {
        padding-top: 0;
        border-top: none;
      }
    }

    .recurring-payment-info {
      svg {
        margin-right: 4px;
      }
    }

    .recurring-data-tooltip-header {
      font-weight: normal;
    }

    .recurring-data-tooltip-table {
      width: 100%;

      tr {
        td {
          color: white;
        }
      }
    }
  }

  @media print {
    @include breakpoint($tablet) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;

      .signatures {
        grid-column: 1 / span 2;
        grid-template-columns: 1fr;
      }

      .details {
        display: none;
      }

      .billing {
        margin: 0;
      }
    }
  }
}

.edit-payment-modal.modal.large .content {
  max-width: 660px !important;
  padding-bottom: 48px !important;

  @include breakpoint($tablet) {
    width: 660px !important;
  }
}

.edit-payment-modal {
  .edit-payment-form {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-column-gap: 20px;

    @media (max-width: $tablet) {
      grid-row-gap: 20px;
    }

    h3 {
      font-size: 14px !important;
      font-weight: normal !important;
      color: $blue;
      grid-column: 1/-1;

      svg {
        width: 15px !important;
        height: 15px !important;
        margin-right: 2px;
      }
    }

    .form-column {
      padding: 0 8px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .amountSelector {
      grid-column: 1;
      .summary {
        border-bottom: 1px solid $meddarkgrey;
        margin-bottom: 15px;
        line-height: 35px;

        span {
          float: right;
        }
      }

      input {
        margin-bottom: 15px;
      }

      border-bottom: 1px solid $meddarkgrey;
    }

    .customerSelection {
      grid-column: 1;

      select {
        margin: 0;
        background-color: $white;
      }
    }

    .radioList {
      grid-column: 2;
      grid-row: 2;

      .fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 12px;
        margin-bottom: 8px;
      }
    }

    .buttonArea {
      grid-column: 1/-1;
      display: flex;

      align-items: center;

      margin-top: 56px;

      .btn {
        width: 196px;
        margin-right: 3px;
      }

      .btn.cancel {
        width: 80px;
        margin: 0;
      }

      .btnLink.delete {
        svg {
          width: 25px !important;
          height: 25px !important;
          margin-left: 10px;
        }
      }
    }

    h6 {
      grid-column: 1/-1;
      color: #278ada;
      margin-top: 10px;
    }
  }
}

.signatureModal {
  .content {
    .actions {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  .signaturePadInput {
    display: flex;
  }
}
input[name='signerTitle'] {
  margin-left: 5px;
}

.shortageModal {
  &.multilocationSubrent {
    .overlay {
      opacity: 0.6 !important;
    }
  }

  .content {
    .body {
      .numbers {
        display: flex;
        justify-content: space-between;
        padding: 10px 60px;
        margin: 0 -20px;
        @include border-radius(3px 3px 0 0);
        background-color: $midblue;
        font-size: 10px;
        line-height: 14px;
        color: $blue;
        text-align: center;

        h1 {
          margin: 0;
          color: $lightgrey;
          line-height: 24px;
        }

        .alertQuantity {
          display: flex;
          align-items: center;

          svg {
            height: 14px;
            width: 15px;
            margin: 0px 5px 0 0;
            fill: $orange;
          }
        }
      }

      .title {
        display: flex;
        margin: 20px 0;
        color: $darkblue;

        svg {
          height: 28px;
          width: 30px;
          margin: 6px 10px 0 0;
          fill: $orange;
        }

        h2 {
          margin: 0;
          line-height: 22px;
          color: $darkblue;
        }

        .name {
          overflow: hidden;
          text-overflow: ellipsis;

          @include breakpoint($tablet) {
            width: 280px;
          }
        }

        .dates {
          margin: 0 0 0 auto;

          label {
            font-size: 10px;
            line-height: 14px;
            color: $blue;
          }
        }

        .count {
          width: 100px;
          font-size: 36px;
          line-height: 40px;
          text-align: center;
        }

        .selectedDate {
          width: 100px;
          margin: 0 0 0 auto;
          text-align: center;

          span {
            margin: 0 5px;
            color: $darkblue;
            vertical-align: middle;
          }

          svg {
            margin: 0;
            fill: $cobalt;
            vertical-align: middle;
            cursor: pointer;
          }
        }
      }

      .scrollarea {
        height: auto !important;
        max-height: 280px;
        margin-right: -10px;
        padding-right: 10px;
      }

      .gridWrapper {
        .grid {
          .rTableRow {
            .orderName {
              display: inline-block;
              vertical-align: middle;
              height: 100%;
              margin-right: 5px;
            }

            .token {
              color: #278ada;
              font-size: 10px;
              font-weight: normal;
              vertical-align: middle;
              height: 100%;
              display: inline-block;
            }
          }
        }
      }

      .multiSubRentModalForm {
        display: grid;
        grid-template-columns: 4fr 1fr 1fr;
        align-items: center;
        margin-bottom: 20px;
        max-height: 50vh;
        overflow-y: auto;

        h4 {
          margin: 0;
        }

        h6 {
          color: $blue;

          &:last-of-type {
            text-align: center;
          }
        }

        div:not(.actions) {
          grid-column: span 3;
          display: grid;
          grid-template-columns: 4fr 1fr 1fr;
          align-items: center;

          span:last-of-type {
            text-align: center;
          }
        }
      }
    }
  }
}

.subRentalForm {
  padding: 20px 20px 10px;
  background-color: $darkblue;

  h4 {
    margin-bottom: 20px;
    color: $blue;

    svg {
      height: 15px !important;
      width: 16px !important;
      margin: -3px 5px 0 0;
      fill: $blue;
      vertical-align: middle;
    }
  }

  h6 {
    display: inline-block;
    margin: 0 5px 0 0;

    &.lg {
      width: calc(#{span(3 of 4)} - 5px);
    }

    &.med {
      width: calc(#{span(2 of 4)} - 5px);

      @include breakpoint($phone $tablet) {
        width: calc(#{span(2 of 4)} - 25px);

        & + h6 {
          width: calc(#{span(1 of 4)} + 15px);
        }
      }
    }

    &.sm {
      width: calc(#{span(1 of 4)} - 5px);
    }
  }

  input[type='text'],
  input[type='number'],
  select {
    width: calc(#{span(full)} - 5px);
    margin: 0 5px 5px 0;

    &.lg {
      width: calc(#{span(3 of 4)} - 5px) !important;
    }

    &.med {
      width: calc(#{span(2 of 4)} - 45px) !important;

      @include breakpoint($tablet) {
        width: calc(#{span(2 of 4)} - 25px) !important;
      }
    }

    &.sm {
      width: calc(#{span(1 of 4)} - 5px) !important;

      @include breakpoint($tablet) {
        width: calc(#{span(1 of 4)} - 25px) !important;
      }
    }
  }

  select.med {
    margin-right: 45px !important;

    @include breakpoint($phone $tablet) {
      width: calc(#{span(2 of 4)} - 5px) !important;
      margin-right: 5px !important;
    }
  }

  .discountCheck {
    margin-bottom: 10px;

    label {
      color: $white;
    }
  }

  span {
    display: inline-block;
    font-size: 16px;
    padding: 6px 5px;
    color: $white;
  }

  .divider {
    height: 1px;
    margin: 15px 0;
    background-color: $cobalt;
    opacity: 0.25;
  }

  .actions {
    padding: 10px 0;

    @include breakpoint($tablet) {
      width: span(1 of 2);
    }
  }
}

.connectForm {
  strong {
    color: $blue;

    svg {
      height: 15px !important;
      width: 16px !important;
      margin-right: 5px;
      fill: $blue;
      vertical-align: middle;
    }

    span {
      color: $darkblue;
    }
  }

  .actions {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 5px;

    @include breakpoint($tablet) {
      grid-template-columns: repeat(4, 1fr);
    }

    .btn.outline {
      grid-column: span 1;
    }
  }
}

.rentalEmails {
  @include breakpoint($tablet) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .details {
    align-self: start;
    margin: 0;

    .radioList {
      padding-bottom: 10px;
      margin-bottom: 15px;
      border-bottom: 1px solid $meddarkgrey;

      .radio {
        display: inline-block !important;
        width: auto !important;
        margin-right: 20px;

        label {
          white-space: nowrap;
        }
      }
    }

    .scrollarea {
      grid-column: span 4;

      .check {
        display: block;
      }
    }

    .Select-value {
      .transactionOptionValue {
        display: flex;
        align-items: center;

        .details {
          font-size: 10px;
          color: $blue;
          overflow: hidden;
          text-overflow: ellipsis;

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .Select-menu-outer {
      .transactionOptionValue {
        overflow: auto;

        .details {
          @include container;
          width: 100%;
          font-size: 10px;
          line-height: 14px;
          color: $blue;

          span {
            display: inline-block;
            margin-right: 5px;

            &:last-of-type() {
              float: right;
              margin-right: 0;
            }
          }
        }
      }
    }

    .react-datepicker-component {
      width: span(1 of 3);
    }

    .documentSelectList {
      grid-column: span 4;
      margin: 0;

      .toggle {
        width: 100%;
      }
    }

    .actions {
      grid-template-columns: repeat(8, 1fr);

      .btn {
        grid-column: span 5;

        &.cancel {
          grid-column: span 2;
        }
      }

      .btnIcon {
        grid-column: span 1;
        margin-right: 0;
      }
    }
  }

  .attachments {
    .grid {
      margin-bottom: 15px;
    }
  }

  .emailHistory {
    .entry {
      padding-top: 5px;
      border-top: 1px solid $meddarkgrey;
      padding-bottom: 5px;

      h4 {
        line-height: 25px;
      }

      & > div {
        padding: 3px 0;

        strong {
          display: inline-block;
          width: 55px;
          vertical-align: top;
        }

        & > span {
          display: inline-block;
          width: calc(100% - 100px);

          span {
            display: inline-block;
            margin-right: 5px;

            svg {
              height: 10px !important;
              width: 10px !important;
              margin-left: 2px;
              fill: $green;
            }
          }
        }
      }

      .btnLink {
        margin-top: 2px;
      }

      .btnIcon.preview {
        float: right;
        height: 22px;
        width: 23px;
        margin: 0 0 0 5px;
        line-height: 22px;

        svg {
          height: 22px !important;
          width: 22px !important;
        }
      }

      ul {
        clear: both;

        li {
          padding: 4px 0;
          line-height: 22px;

          a {
            position: relative;
            display: inline-block;
            height: 100%;
            width: 100%;
            padding-left: 30px;
            color: $darkblue;

            svg {
              position: absolute;
              top: 4px;
              left: 3px;
              height: 14px;
              width: 14px;
              fill: $white;
            }

            &:before {
              content: ' ';
              position: absolute;
              top: 0;
              left: 0;
              height: 22px;
              width: 22px;
              background-color: $darkgrey;
              @include border-radius(2px);
            }
          }
        }
      }
    }
  }

  @media print {
    @include breakpoint($tablet) {
      grid-template-columns: 1fr;
    }

    .details {
      display: none;
    }
  }
}

.rentalConversations {
  @include breakpoint($phone $tablet) {
    .rentalActions {
      display: none;
    }
    .content div .btn {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  aside {
    background-color: transparent;
    border-right: 1px solid $meddarkgrey;

    .full {
      position: static;
      width: auto;
    }

    .itemList {
      li {
        border-bottom: 1px solid $meddarkgrey;

        label {
          color: $darkblue;

          &.caption {
            color: $cobalt;
          }
        }

        &:nth-child(1) {
          border-top: 1px solid $meddarkgrey;
        }

        &.selected {
          background-color: $meddarkgrey;

          .smallRentalName {
            color: $navyblue !important;
          }

          &:nth-child(1) {
            border-top: 1px solid $meddarkgrey;
          }
        }
      }
    }
  }

  .content {
    h1,
    .rentalLink,
    .filter {
      display: none;
    }

    .conversationForm {
      margin-top: 0;
    }
  }
}

.rentalHistory {
  .grid td:last-of-type {
    white-space: normal;
  }

  a.warn {
    display: block;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    text-decoration: underline;
  }
}

.email {
  width: 160px !important;
}

.radioRow {
  display: flex;
  flex-direction: row;
}

.sortRow {
  color: #0e2c44;
  font-family: Heebo;
  font-size: 9px;
  letter-spacing: 0;
  line-height: 13px;
  padding-left: 10px;
}

.alignLeft {
  display: flex !important;
  justify-content: flex-start !important;
}

.alignLeft .radio div div {
  margin: 2px 0px 0px 12px !important;
}

.deliveryManifestDates {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
}

.spacer {
  width: 10%;
}

.subRentalModalForm {
  .fields {
    display: grid;
    grid-template: 1fr 1fr 1fr / 1fr 1fr 1fr 1fr;
    grid-gap: 5px;

    .longColumn {
      grid-column: 1 / 3;
    }

    input:last-child {
      grid-column: 3 / -1;
    }
  }

  @include breakpoint($phone $tablet) {
    .fields {
      display: block;

      label {
        display: none;
      }

      input[name='supplierName'] {
        margin-top: 10px;
      }

      .sm {
        width: 100%;
      }
    }
  }

  strong {
    color: $blue;
    align-self: center;

    svg {
      height: 15px !important;
      width: 16px !important;
      margin-right: 5px;
      fill: $blue;
      vertical-align: middle;
    }

    span {
      color: $darkblue;
    }
  }

  label {
    font-size: 10px;
    color: $blue;
    text-align: center;
    align-self: center;
  }
}
