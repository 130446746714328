@include breakpoint($tablet) {

    .accounts{

        .right{
            margin-bottom: 10px;
        }

        h2 {
            color: black;
            margin-bottom: 0;
        }

        h4{
            color: black;
            font-weight: bold;
        }

        section{
            display: flex;

            .accountSection{
                padding-left: 30px;
            }

            figure{
                background-color: $meddarkgrey;
                width: 300px;
                height: 200px;

                &.businessPicture{
                    margin-right: 20px;
                }
            }
        }   
    }
}


    .accountCompanyImg{
       padding: 20px;
       background-color: white;
    }

    .locationFigure{
        height: 75%;
        background-color: white;
    }


