.r-calendar {
  min-width: 280px;

  .r-inner {
    width: 100%;
    height: 100%;
  }

  .r-row {
    @include clearfix;
    text-align: center;
  }

  .r-cell {
    float: left;
    outline: none;

    @include breakpoint($phone) {
      width: span(1 of 9);
      margin-left: span(1 of 54);
      margin-right: span(1 of 54);
    }
    @include breakpoint($tablet) {
      width: span(1 of 13);
      margin-left: span(1 of 26);
      margin-right: span(1 of 26);
    }

    &:nth-child(1) {
      margin-left: 0 !important;
    }
    &:nth-child(7) {
      margin-right: 0 !important;
    }
    &.r-date {
      color: $darkgrey;
      font-weight: $light;
      cursor: pointer;

      &:not(.r-past):hover {
        background-color: $blue;
        color: $white;
      }

      &.r-highlighted,
      &.r-alerting,
      &.r-in-out {
        background-color: transparent;
        color: $white;
        font-weight: bold;
      }

      &.r-unassigned-highlighted {
        background-color: $orange;
        color: $white !important;
        font-weight: bold;

        &:hover {
          background-color: $orangeHover;
        }
      }

      &.r-highlighted {
        border: 2px solid $blue;
      }
      &.r-alerting {
        border: 2px solid $orange;
      }
      &.r-in-out {
        border: 2px solid $purple;
      }

      &.r-selected {
        background-color: $blue;
        color: $white;
      }

      &.r-past {
        color: #999999;
        cursor: not-allowed;
      }
    }
    &.r-disabled {
      color: $lightgrey;
      font-weight: $light;
    }
  }

  .r-head {
    font-size: 12px;
    color: $darkgrey;
    line-height: 12px;
    text-transform: uppercase;
    @include breakpoint($phone) {
      padding-left: 10px;
    }
    @include breakpoint($tablet) {
      padding-left: 0;
    }

    .r-cell {
      margin: 0;

      &.r-title {
        width: auto;
      }
      &.r-prev,
      &.r-next {
        cursor: pointer;
      }
    }
  }

  .r-weekdays {
    color: $darkgrey;
    font-size: 9px;
    line-height: 9px;
  }
  &:nth-of-type(n + 2) {
    .r-weekdays {
      display: none;
    }
  }

  .r-dates {
    padding: 0;
    font-size: 14px;
    color: $darkblue;
    line-height: 14px;
    @include border-radius(50%);

    .r-row {
      @include breakpoint($phone) {
        margin: 15px 0;
      }
      @include breakpoint($tablet) {
        margin: 17px 0;
      }

      &:last-child() {
        @include breakpoint($phone) {
          margin: 15px 0 0;
        }
        @include breakpoint($tablet) {
          margin: 17px 0 17px;
        }
      }
    }
    .r-cell {
      @include maintain-aspect-ratio(1, 1);
      background-color: transparent;
      @include border-radius(50%);

      div {
        @include border-radius(50%);
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
  }
}
