.contentWrapperTwoFactor {
  .content {
    .container-two-factor-content {
      display: flex;
      column-gap: 10px;
      align-items: center;

      input {
        width: 30px;
        padding: 6px 12px;
      }
    }
  }
}