    .cartLayoutSettings {
        @include breakpoint($tablet) {
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 120px;
        }

        & > div {
            position: relative;
            width: 100%;
            padding: 220px 0 0;
            margin-bottom: 40px;
            background-size: 297px 178px;
            background-position: 11px 12px;
            background-repeat: no-repeat;
            @include breakpoint($tablet) {
                padding: 220px 20px 0;
                width: 320px;
            }

            .btn {
                width: 100%;
                margin: 0 0 10px;
            }
            .viewToggle {
                label {
                    width: calc((100% - 52px) / 2);
                    color: $blue;

                    &.selected {
                        font-weight: $bold;
                    }
                    &:last-of-type {
                        text-align: right;
                    }
                }
            }

            &.chic {
                background-image: url("../../../images/cart_layout/Harper_Light.png");

                &.dark {
                    background-image: url("../../../images/cart_layout/Harper_Dark.png");
                }
            }
            &.fun {
                background-image: url("../../../images/cart_layout/Emmit_Light.png");

                &.dark {
                    background-image: url("../../../images/cart_layout/Emmit_Dark.png");
                }
            }
            &.catalina {
                background-image: url("../../../images/cart_layout/Catalina_Light.png");

                &.dark {
                    background-image: url("../../../images/cart_layout/Catalina_Dark.png");
                }
            }
            &.wisteria {
                background-image: url("../../../images/cart_layout/Wisteria_Light.png");

                &.dark {
                    background-image: url("../../../images/cart_layout/Wisteria_Dark.png");
                }
      }
      &.magnolia {
        background-image: url("../../../images/cart_layout/Magnolia_Light.png");
        &.dark {
          background-image: url("../../../images/cart_layout/Magnolia_Dark.png")
        }
      }

      &.preScott {
            background-image: url("../../../images/cart_layout/Prescott_Light.png");
        }

        &.elaina {
            background-image: url("../../../images/cart_layout/Elaina_Light.png");
        }

        .singleTheme {
            justify-content: center;
            h4 {
                margin-bottom: 0;
            }
        }

            &:before {
                content: " ";
                position: absolute;
                top: 0;
                left: 0;
                height: 200px;
                width: 320px;
                border: 5px solid transparent;
            }

            &.selected:before {
                border: 5px solid $blue;
            }
        }
    }

    .styleSettings {
        a:not(.btn) {
            grid-column: 1 / span 4;
            text-decoration: underline;
        }
        .colors {
            grid-template-columns: repeat(2, 1fr);
            @include breakpoint($tablet) {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 0 10px;
            }

            h3 {
                grid-column: span 2;
            }
            & > label {
                grid-row: 2;
                font-size: 10px;
                color: $blue;
            }
            .colorSelector {
                grid-row: 3;
                position: static;
                border: 1px solid $navyblue;
                @include border-radius(2px);

                .swatch {
                    position: relative;
                    height: 28px;
                    width: 100%;

                    label {
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        font-size: 11px;
                        line-height: 28px;
                        text-align: center;
                    }
                }

                &.primary {
                    color: $white;
                }
                &.darkSecondary {
                    color: $white;
                }
            }
        }
        .buttons {
            .radioGroup {
                grid-row-gap: 5px;
                @include breakpoint($tablet) {
                    grid-row-gap: 0;
                }
            }
            .radio {
                & > div > div {
                    margin-top: 13px !important;
                    @include breakpoint($tablet) {
                        margin-top: 7px !important;
                    }
                }
                label {
                    display: inline-block;
                    height: $mobileVertical;
                    padding: 0;
                    background-color: $lightgrey;
                    @include border-radius(2px);
                    font-size: 11px;
                    font-weight: $bold;
                    line-height: 45px !important;
                    text-align: center;
                    text-transform: uppercase;
                    white-space: nowrap;
                    vertical-align: middle;
                    @include breakpoint($tablet) {
                        height: 30px;
                        font-weight: $regular;
                        line-height: 30px !important;
                    }
                }

                &:last-of-type() label {
                    @include border-radius(23px);
                    @include breakpoint($tablet) {
                        @include border-radius(15px);
                    }
                }
            }
        }
        .fonts {
            .radio {
                margin-top: 10px;

                label {
                    width: calc(100% - 30px) !important;
                    padding: 26px 0 0;
                    background-position: left 2px;
                    background-repeat: no-repeat;
                    background-size: 391px 23px;
                    font-size: 10px;
                    color: $darkgrey !important;
                }

                &.heebo label {
                    background-image: url("../../../images/marketing/font_Heebo.png");
                }
                &.lora label {
                    background-image: url("../../../images/marketing/font_Lora.png");
                }
                &.teko label {
                    background-image: url("../../../images/marketing/font_Oswald.png");
                }
                &.arvo label {
                    background-image: url("../../../images/marketing/font_Arvo.png");
                }
                &.nunito label {
                    background-image: url("../../../images/marketing/font_Nunito.png");
                }
                &.roboto_slab label {
                    background-image: url("../../../images/marketing/font_RobotoSlab.png");
                }
                &.questrial label {
                    background-image: url("../../../images/marketing/font_Questrial.png")
                }
                &.gupter label {
                    background-image: url("../../../images/marketing/font_Gupter.png")
                }
                &.goudy label {
                    background-image: url("../../../images/marketing/font_Goudy.png")
                }
            }
        }
    }
