.serialized {
  .rightSideNav {
    display: flex;
    float: right;
    align-items: center;

    .btn, .btnIcon {
      background-color: $blue !important;
      color: $white;
      padding-left: 20px;
      padding-right: 20px;

      svg {
        display: none !important;
      }
    }
  }
  .summary {
    display: grid;
    margin-bottom: 20px;
    @include breakpoint($tablet) {
      grid-template-columns: 170px 1fr;
    }

    .image {
      @include maintain-aspect-ratio(16, 9);

      .carousel {
        height: 100%;

        & > li {
          height: 100%;
        }
      }

      figure {
        height: 100%;
        width: auto;
        max-width: 100%;
      }
    }
    .summaryInfo {
      display: flex;
      align-items: center;
      padding: 0 20px;
      background-color: $white;

      label {
        margin-right: 30px;
        font-size: 22px;
        line-height: 22px;
        color: $blue;
      }
      div {
        margin-left: auto;
        font-size: 10px;
        line-height: 14px;
        color: $darkgrey;
      }
    }

    .summaryInfoRight {
      display: flex;
      align-items: baseline;
    }
  }

  .scheduledMaintenanceContent {
    display: grid;
    margin-bottom: 20px;
    @include breakpoint($tablet) {
      grid-template-columns: 2fr 1fr;
    }

    .image {
      @include maintain-aspect-ratio(16, 9);

      .carousel {
        height: 100%;

        & > li {
          height: 100%;
        }
      }

      figure {
        height: 100%;
        width: auto;
        max-width: 100%;
      }
    }
    .summaryInfo {
      display: flex;
      align-items: center;
      padding: 0 20px;
      background-color: $white;

      label {
        margin-right: 30px;
        font-size: 22px;
        line-height: 22px;
        color: $blue;
      }
      div {
        margin-left: auto;
        font-size: 10px;
        line-height: 14px;
        color: $darkgrey;
      }
    }

    .maintenanceTableColumn {
      padding-right: 15px;
    }
  }

  .grid {
    .idForm {
      input[type="text"] {
        width: 120px;
        margin: 0 5px 0 0;
        vertical-align: middle;
      }
      .btn {
        padding: 0 10px;

        &.outline {
          display: none;
        }
      }
      .btnLink {
        display: none;
        margin-left: 5px;
      }
    }

    thead .btnIcon {
      height: 20px;
      padding: 0 5px;
      background-color: $blue;
      line-height: 20px;

      svg {
        height: 11px !important;
        width: 12px !important;
        margin-top: -1px;
        fill: $white;
      }

      &:hover {
        background-color: $lightblue;
      }
    }

    tr:hover .idForm {
      .btn.outline,
      .btnLink {
        display: inline-block;
      }
    }

    .serial-number-archived {
      color: $orange;
    }

    .serial-number {
      width: 30%;
    }
  }
}

.serializedModal {
  .fullForm {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 5px;
    @include breakpoint($tablet) {
      grid-template-columns: 2fr 1fr 2fr 1fr;
    }

    input[type="text"], input[type="number"] {
      grid-column: span 2;
      margin: 0;
      -moz-appearance: textfield;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    .btn {
      padding: 0;
      margin: 0;
    }
  }

  .checkInFullForm {
    @include breakpoint($tablet) {
      grid-template-columns: 2fr 1fr 2fr
    }
  }

  .scroll {
    overflow: scroll;
    height: 200px;
  }

  .fields p {
    grid-column: span 3;
    line-height: 30px;
  }
  .details {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr;
    align-items: center;
    margin: 10px 0 20px;
    line-height: 30px;

    label {
      grid-row: 1;
      text-align: center;

      &:last-of-type {
        grid-column: 4;
      }
    }
    span,
    input[type="text"] {
      grid-row: 2;
      font-size: 22px;
      text-align: center;
    }
    span:last-of-type {
      grid-column: 4;
    }
    input[type="text"] {
      width: 100%;
      margin: 0;
    }

    .alert {
      color: $orange;
    }
  }
  section {
    strong {
      position: absolute;
      top: 10px;
      right: 30px;
      text-align: right;
    }
    .scrollarea {
      height: 200px !important;
    }
  }
  a:not(.btn) {
    display: block;
    text-align: center;
    text-decoration: underline;
  }

  .form-error {
    background-image: url(svg-encode(barcode-icon($orange))) !important;
  }
  .form-alert {
    color: $orange;
    margin-top: 5px;
    font-size: 12px;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .w-40 {
    width: 40px;
  }
  .w-150 {
    width: 150px;
  }
  .actions-status {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .quantity {
    margin-top: 5px !important;
    width: 100% !important;
  }
}

.serializedModal.checkin-modal,
.serializedModal.pickModal {

  @media screen and (max-width: 768px) {
    .content {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .fullForm {
    grid-template-columns: 1fr 1fr;
    input[type="text"], input[type="number"] {
      grid-column: span 1;
      width: 90px;
      -moz-appearance: textfield;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    .barcode[type="text"] {
      width: 100%;
    }
    label {
      margin-left: 10px;
      color: #7194ac;
    }
    .mb-5 {
      margin-bottom: 5px;
    }
    .notes {
      height: 65px;
      padding: 6px 8px;
      border: none;
      outline: none;
      border-radius: 2px;
      width: 100%;
    }
    .barcode-choose-input, .barcode-scanner {
      grid-column: 1 / span 2;
    }
  }
  .checkInUsage {
    td {
      vertical-align: top;
      padding-top: 8px;
      padding-bottom: 8px;
      span {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  section {
    .infinite {
      overflow: auto !important;
    }
  }
}

.serializedProductsFilter {
  .link {
    padding: 0 5px;
    color: black;

    &.selected {
      font-weight: $bold;
    }
  }
}

.search-input {
  margin: 35px 0px 10px -2px;
}

.serial-number-error {
  background-color: $red !important;
}

.serial-number-success {
  background-color: $green !important;
}

.serial-number-product-title {
  font-size: 12px;
  color: $white;
  font-weight: bold;
  margin: 15px;
}

.input-container {
  color: #FFFFFF;
  padding: 0px 20px 0px 20px;
  height: 40px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.without-padding {
    padding: 0;
  }
}

.number-input-container {
  background-color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
    width: 30%;
    margin: 0px;
    text-align: center;
  }

  button {
    background-color: $white;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.usage-unit-input-container {
  input[type="text"] {
    margin: 0px;
    text-align: center;
  }
}

.not-on-drawer {
  label {
    color: black;
  }
}

.red-check {
    fill: $orange !important;

}
