@import url('https://fonts.googleapis.com/css?family=Heebo:100,300,400,700');
@import url('https://fonts.googleapis.com/css?family=Lora:400,700');

//Font Variables
$fontfamily: "Heebo", Helvetica, sans-serif;
$fontfamilySerif: "Lora", "Times New Roman", Times, serif;

// Font Weight
$thin: 100;
$light: 300;
$regular: 400;
$bold: 700;

// Colors
$lightblue: #00AFEF;
$blue: #278ADA;
$darkblue: #0E2C44;
$midblue: #1B4160;
$navyblue: #071827;
$navySecondary: #1E3E58;

$lightbluegrey: #B4C2CE;
$bluegrey: #7194AC;
$darkgrey: #8FA9BE;
$meddarkgrey: #C1CDD7;
$mediumgrey: #CED8E3;
$medlightgrey: #E0E5E9;
$lightgrey: #E5E8E9;
$white: #FFFFFF;

$red: #CA3A3A;
$pink: #D3347F;
$orange: #FF886D;
$purple: #966CB8;
$green: #54C06E;
$cobalt: #466B88;
$black: #000000;

$lightGray: #B4C2CE;
$darkGray: #0E2C44;

// Rollover Colors
$darkgreyHover: #A6C0D4;
$darkblueHover: #153A57;
$navyblueHover: #052135;
$orangeHover: #FF9F89;
$purpleHover: #7C688C;
$greenHover: #6AE187;
$cobaltHover: #5A82A2;

$focusPlaceholder: #AAAAAA;

// Min Screen Widths
$phone: 0px;
$tablet: 769px;
$desktop: 1020px;

// MaxScreenWidths
$maxWidthOfPhone: (max-width: 768px);

// Min Screen Height
$desktopShort: (max-height: 812px);

// Numbers
$vertical: 40px;
$innerPadding: 20px;

$mobileVertical: 45px;
$mobilePadding: 10px;
