@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

.maintenanceDocuments {
  .documentsHeader {
    display: inline-block;
    color: #0E2C44;
    margin: 10px 0px 16px;
    font-size: 12px;
  }

  .table {
    margin-bottom: 29px;

    .row {
      display: grid;
      grid-template-columns: 2.5fr 1.73fr 1fr;
      padding: 10px 0px 12px;
      border-bottom: 1px solid #ced8e3;

      @include breakpoint($tablet) {
        grid-template-columns: 3.73fr 1.73fr 1fr;
      }

      &:hover {
        cursor: pointer;
      }

      .hoverActions {
        height: 10px;

        @include breakpoint($tablet) {
          display: none;
        }
        svg {
          width: 20px !important;
          height: 20px !important;
        }
  
        svg:first-child {
          margin-right: 5px;
          fill: $cobalt;
        }
  
        svg:nth-child(2) {
          fill: $red;
        }

        &.show {
          display: inline-block;
        }
      }
    }

    .header {
      font-size: 10px;
      line-height: 14px;
      color: $blue;
    }
  }

  #add_note {
    width: 100%;
    &.disabled {
      background-color: $darkgrey !important;
      cursor: not-allowed;
      color: black;
    }

    svg {
      cursor: not-allowed;
      path {
        cursor: not-allowed;
      }
    }
  }
}

.documentsHeader {
    display: inline-block;
    color: black;
}

.uploadDocumentAwaiting {

  background-color: #8FA9BE !important;

  svg {
    margin-top: 10px !important;
  }

}

.uploadDocumentArea {

  margin-left: 15px;
  margin-top:  30px;

  .uploadDocAction {
    margin-left: 20px;
  }

  .footerActions {
    margin-bottom: 14px;
  }

}

#maintenanceDoc {
  margin-left: 0 !important;
}

.slideOutHeading {
  color: #8FA9BE;
  font-size: 18px;
  margin: 0 0 44px 15px !important;
}

#maintenanceDocHeader {
  color: #466b88;
  margin: 0 0 20px 10px !important;
}


