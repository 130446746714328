.adminBusiness {
  @include breakpoint($tablet) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    align-items: start;
  }

  & > * {
    min-width: 0;
  }
  .headerNav {
    grid-column: span 1;
  }
  .topActions {
    grid-column: span 1;
    place-self: center end;

    .settingsToggle {
      margin-top: 10px;
      @include breakpoint($tablet) {
        float: right;
        margin-top: 8px;
      }

      & > div {
        margin-right: 0;
      }
    }
  }
  .customizations {
    grid-column: 1 / span 2;
    display: grid;
    min-height: 240px;
    grid-template-rows: 25px 1fr;
  }
  .summary {
    grid-column: 1 / span 2;
    display: grid;
    min-height: 240px;
    grid-template-rows: 25px 1fr;

    @include breakpoint($tablet) {
      grid-template-columns: 1fr 1fr;

      grid-column-gap: 20px;
    }

    & > * {
      min-width: 0;
    }
    .viewToggle {
      @include breakpoint($tablet) {
        place-self: center end;
        margin: 0;
      }
    }
    figure {
      width: 100%;
      @include maintain-aspect-ratio(16, 9);
      background-color: $darkblue;
      grid-column: 1;
      max-height: 140px;

      svg {
        fill: $cobalt;
      }
    }
    .details {
      margin-left: 8px;
      & > div {
        margin: 5px 0;

        label {
          display: inline-block;
          width: 60px;
          font-size: 10px;
          color: $blue;
          vertical-align: top;
        }
        & > div {
          display: inline-block;
        }
      }
    }
  }
  .pricing,
  .account {
    .sectionEdit {
      top: 13px;
      left: auto;
      right: 20px;
    }
    .billing {
      .expenses {
        margin: 0;

        & > div {
          grid-template-columns: 3fr 2fr;

          &.storefront-details {
            grid-template-columns: 2fr 3fr;
          }
        }
      }
      label {
        position: relative;
        font-weight: bold;

        .btnLink {
          position: absolute;
          top: 2px;
          transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
          @include breakpoint($tablet) {
            opacity: 0;
          }

          svg {
            height: 15px !important;
            width: 16px !important;
            margin: 0;
          }

          & + span {
            margin-left: 20px;
            transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
            @include breakpoint($tablet) {
              margin-left: 0;
            }
          }
        }
        &:hover {
          & > .btnLink {
            opacity: 1;

            & + span {
              margin-left: 20px;
            }
          }
        }

        & > svg {
          height: 22px !important;
          width: 23px !important;
          margin-right: 5px;
          fill: $blue;
          vertical-align: middle;
        }
      }
      span {
        .btnLink {
          float: none;

          svg {
            height: 15px !important;
            width: 16px !important;
          }
        }
      }
      .toggle {
        width: 100%;
      }
    }
  }
  .pricing {
    grid-row: span 2;
  }
  .propay {
    grid-column: 1 / span 2;

    .radio {
      padding: 8px 0;
      padding: 8px 0;
    }

    .right {
      float: right;
    }
  }
  .clients {
    grid-column: 1 / span 2;

    &.rentalTokenLookup {
      .fields {
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
          flex: 0.9;
          display: flex;
          margin-bottom: 0px;
        }

        .btn {
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      .transactionsTable {
        table {
          display: flex;
          flex-direction: column;

          thead {
            flex: 1;
            display: flex;
            padding: 0 10px;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $meddarkgrey;
          }

          tr {
            display: flex;
            flex: 1;

            th, td {
              flex: 0.2;
              height: 30px;
              text-align: center;
            }

            td {
              display: flex;
              padding-top: 5px;
              align-items: center;
              justify-content: center;
            }
          }

          tbody {
            display: flex;
            flex-direction: column;
          }

          .btn {
            height: 20px;
            padding-left: 10px;
            padding-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        margin-top: 2rem;
        margin-bottom: 1rem;
      }
    }

    .accordian {
      .header {
        & > div {
          display: flex;
          overflow: visible;

          .btn {
            width: 70px;
            padding-left: 0;
            padding-right: 0;
            margin: 2px 5px 0 0;
            @include breakpoint($tablet) {
              margin: 4px 20px 0 0;
            }
          }
          div {
            display: inline-block;
            width: 90px;
            text-overflow: ellipsis;
            overflow: hidden;
            @include breakpoint($tablet) {
              width: 160px;
            }
          }
        }
      }
      .content {
        padding: 5px 10px;

        & > div {
          @include breakpoint($tablet) {
            padding-left: 90px;
          }

          div {
            position: relative;
            @include push(1 of 8);
            @include span(7 of 8);
            min-height: 35px;
            padding: 10px 0 0;
            @include breakpoint($tablet) {
              @include push(1 of 10);
              @include span(9 of 10);
            }

            & > svg {
              position: absolute;
              top: 9px;
              left: -12%;
              fill: $blue;
              @include breakpoint($tablet) {
                top: 6px;
                left: -34px;
              }
            }
          }
        }
      }
    }
  }
  .feeHistory {
    grid-column: 1 / span 2;

    h1 {
      margin-bottom: 10px;
    }
    div {
      padding: 3px 0 10px;
      @include breakpoint($tablet) {
        padding: 3px 0;
      }

      span {
        display: inline-block;
        width: 50%;
        font-size: 12px;
        line-height: 15px;
        color: $blue;
        @include breakpoint($tablet) {
          width: 80px;
          font-size: 10px;
          line-height: 14px;
        }
      }
      label {
        display: block;
        white-space: normal;
        @include breakpoint($tablet) {
          display: inline-block;
        }
      }
    }
  }
  .businessRefunds,
  .businessFees {
    input[type='text'] {
      width: 50%;
    }
    textarea {
      width: calc(100% - 5px);
      height: 90px;
    }

    section {
      & > div,
      & > p {
        padding-top: 10px;
        border-top: 1px solid $meddarkgrey;
      }
      h6 {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }

  &.noData {
    & > div {
      grid-column: span 2;
      width: auto;
      padding-left: span(3 of 13);
      padding-right: span(6 of 13);
      border: none;

      .logo {
        background-image: url('../../../images/icons/noData_Company.png');
      }
    }
  }
}

.adminBusinessFees,
.adminBusiness {
  .countInfo {
    float: right;
    display: flex;
    font-size: 22px;
    line-height: 24px;
    text-align: center;

    div {
      width: 70px;
    }
    label {
      display: block;
      font-size: 12px;
      font-weight: $bold;
      line-height: 18px;
    }
  }
  .grid {
    tr {
      td {
        .check {
          visibility: hidden;
        }

        &.change svg {
          height: 16px !important;
          width: 17px !important;
          margin: 0 0 0 5px;
          fill: $red;
        }

        svg.green {
          color: $green;
          fill: $white !important;
        }
      }

      &:hover,
      &.selected {
        td {
          .check {
            visibility: visible;
          }
        }
      }
    }
  }
  span.alert {
    display: inline-block;
    margin-left: 10px;

    svg {
      fill: $orange;
    }
  }
}
.adminDiscounts {
  .form {
    margin-top: 20px;
    @include breakpoint($tablet) {
      width: span(2 of 3);
    }

    .fields {
      h4 {
        margin-top: 10px;
      }
      input[type='text'].partial {
        grid-column: span 3;
        width: calc(#{span(1 of 3)} - 2.5px);
      }
      .dateSelect {
        grid-column: span 4;

        label {
          display: block;
        }
        .react-datepicker-component {
          margin-right: 5px;
        }
      }
    }
  }
}

.worldpayForm {
  padding: 0px 30px;

  .fields > div {
    label {
      width: 20%;
      display: inline-block;
    }

    input {
      width: calc(80% - 35px);
      margin-left: 30px;
    }
  }
}
.worldpayInfo {
  padding: 10px 10px;

  div {
    word-break: break-word;

    p {
      margin-bottom: 0px;
    }
  }
}

.updateSubscriptionModal {
  .content {
    width: 700px !important;

    .body {
      .linkContainer.borderTop,
      .total.borderTop {
        border-top: 1px solid #7894ac;
        padding-top: 20px;
      }

      .linkContainer {
        display: flex;
        justify-content: end;
        margin-bottom: 14px;

        .btnLink.add {
          float: none;
          position: static;
        }
      }

      .interval {
        position: relative;
        display: flex;
        flex-direction: column;

        .medlightgrey.small {
          position: absolute;
          top: 14px;
          left: 0;
          margin-left: 0;
          white-space: nowrap;
        }
      }

      .medlightgrey {
        margin-left: 5px;
        font-size: 10px;
        color: $darkgrey;
      }

      .additionalUsers.included {
        margin-bottom: 5px;
      }

      .additionalUsers {
        align-items: start;
        margin-bottom: 24px;

        > label {
          grid-column: span 1;
          align-items: start;
        }

        .default {
          grid-column: span 1;
          justify-self: end;
          font-weight: bold;
        }

        .usersInput {
          grid-column: 3 / span 2;
          display: flex;
          justify-content: space-between;
          position: relative;

          input,
          .rate {
            width: 75px;
            margin-right: 8px;
          }

          .delete {
            width: 24px;
          }
        }
      }

      .total {
        height: 100px;
        display: flex;
        align-items: start;
        justify-content: end;

        > span {
          margin-right: 24px;
          align-self: start;
        }
      }
    }
  }
}

.paymentMethodInfoLable {
  text-align: right;
  left: -3em;
}

.paymentInfoContainer {
  align-items: center;
}

.paymentUrlBtn {
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
}

.paymentTokenUrl {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.copyToClipboard {
  grid-row-start: 1;
  grid-column-start: 3;
  cursor: pointer;
  fill: $blue;
}
.direction-row { flex-direction: row; }
.direction-row-reverse { flex-direction: row-reverse; }
.direction-column { flex-direction: column; }
.direction-column-reverse { flex-direction: column-reverse; }
.wrap { flex-wrap: wrap;}
.no-wrap { flex-wrap: no-wrap;}
.wrap-reverse { flex-wrap: wrap-reverse;}
.justify-start { justify-content: flex-start;}
.justify-end { justify-content: flex-end;}
.justify-center { justify-content: center;}
.space-between { justify-content: space-between;}
.space-around { justify-content: space-around;}
.space-evenly { justify-content: space-evenly;}
.customizationList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: column;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.customizationItem {
  border-top: 1px solid #C1CDD7;
  height: 40px;
  align-items: center;
  width: 100%;
  padding: 0 15px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.middleLine {
  border-top: 1px solid #C1CDD7;
  height: 1px;
  width: 100%;
}
.featureTier {
  display: flex;
  flex-direction: row;
  align-items:  center;
}
.tierSelect {
  margin: 10px;
}
.customizationTitle {
  margin-top: 20px;
}
.underline {
  text-decoration: underline;
}
.customizationHeader {
  height: 40px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.px-2 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}