.quickbooks {
  .reportNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

@include breakpoint($tablet) {
  .quickbooks {
    position: relative;

    // .quickbookExportBtn {
    //   position: absolute;
    //   top: 0;
    //   right: 10px;
    // }

    .btn {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    .quickbooksContainer {
      background-color: white;
      padding: 20px;

      select {
        width: 100%;
      }

      .react-datepicker {
        background-color: $lightgrey;
      }

      section {
        display: flex;
        padding: 0;

        .dateSelect {
          display: flex;
          align-items: center;
        }

        img {
          width: 20vw;
        }

        .qbSection {
          padding: 5px;

          label {
            margin: 0 5px;
          }
        }
      }

      .btnIcon {
        padding: 0 10px;
      }
    }

    .status {
      display: flex;
      justify-content: space-between;
      padding: 8px 16px 0 36px;

      .quickbooksAuth {
        width: 100%;
      }

      .quickbooksBtns {
        display: flex;
        justify-content: space-between;

        .blue {
          background-color: blue;
        }
      }

      p {
        font-weight: bold;
      }
    }
  }

  .quickbooksNav {
    margin-bottom: 30px;
    display: flex;

    h1 {
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }

    .qblinks {
      display: flex;

      a {
        margin-left: 12px;
        display: flex;
        align-items: center;
        color: $darkblue;

        &.selected {
          color: $blue;
          font-weight: $bold;
        }
      }
    }
  }

  .quickbooksSyncToggle {
    padding: 20px 0;

    .viewToggle {
      margin-bottom: 0;
    }
  }

  .quickbooksSyncToggle,
  .connectOptions {
    border-top: 1px solid $medlightgrey;
  }

  .connectOptions {
    .connectOption {
      display: flex;
      margin: 10px 0;

      &.singleSyncType {
        display: block;
      }
    }
  }

  .editQuickbooksSettings {
    background-color: $medlightgrey;
    margin-bottom: 20px;
    width: 100%;
    // width: 640px;
    // height: 490px;

    .quickbooksSettings {
      display: flex;
      justify-content: space-between;

      .locationLabel {
        text-align: left;
        display: block;

        .locationName {
          font-size: 12px;
          line-height: 18px;
          font-weight: normal;
          margin-bottom: 15px;
        }

        .advancedLabel {
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 20px;
        }
      }

      .m-t-10 {
        margin-top: 20px;
      }

      .inputQuickbooks {
        margin-left: 30px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        label {
          display: flex;
          align-items: center;
          margin-right: 10px;
        }

        input {
          width: 200px;
          height: 30px;
        }
      }

      .classInput {
        display: flex;
        justify-content: space-between;

        label {
          display: flex;
          align-items: center;
          margin-right: 10px;
        }

        input {
          width: 200px;
          height: 30px;
        }
      }
    }

    .label-select {
      margin-bottom: 10px;
      display: flex;

      label {
        flex: 3;
        justify-content: flex-start;
        display: flex;
      }

      select {
        width: 200px;
        height: 30px;
        margin-bottom: 0px;
      }
    }

    .selectInput {
      justify-content: flex-end;
      display: flex;

      input {
        width: 200px;
        height: 30px;
        margin-bottom: 10px;
      }
    }

    form {
      text-align: center;
      padding: 10px;

      .green {
        background-color: green;

        &:hover {
          background-color: green;
        }
      }

      .advanceSettingLabel {
        display: flex;
        justify-content: space-between;

        label {
          font-weight: bold;
        }
      }

      .modal-btns {
        margin-top: 30px;
        margin-left: 20px;
        justify-content: flex-start;
        display: flex;

        button {
          width: 165px;
          height: 30px;
        }
      }
    }

    .advanceSettingLabel {
      justify-content: space-evenly;
    }

    .viewToggle {
      justify-content: center;
    }
  }
}

@include breakpoint($maxWidthOfPhone) {
  .quickbooks {
    padding-top: 15px;

    .qblinks {
      a {
        margin: 5px;

        &.selected {
          font-weight: $bold;
        }
      }
    }

    .quickbookExportBtn {
      a {
        padding: 0 10px;
      }
    }
    .qbSection {
      img {
        width: 100%;
      }
    }
    .dateSelect {
      display: grid;
      grid-gap: 5px;
      grid-template-columns: 20% 1fr 0%;

      img {
        width: 100%;
      }
    }
  }
}
