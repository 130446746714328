.companyParent {
	height: calc(100% - 40px);
}

.customer {
	.details {
		.customerLinks {
			display: flex;
            flex-direction: column;
            align-items: flex-end;
			.btn {
				width: 152px;
				margin-bottom: 10px;
			}
		}
		.iconView {
			clear: both;
			display: flex;
    		justify-content: space-between;

			h2 .type {
				margin-left: 5px;
				font-size: 12px;
				color: $blue;
				vertical-align: middle;

				svg {
					height: 15px !important;
					width: 12px !important;
					margin: 0 3px;
					fill: $blue;
					vertical-align: sub;
				}
			}
			@include breakpoint($tablet) {
				&.disabled {
					padding-top: 35px;

					.info {
						vertical-align: top;
					}
				}
			}
		}

		.accordian {
			.iconView {
				@include breakpoint($tablet) {
					p {
						width: 300px;
					}
				}

				.fields {
					@include breakpoint($tablet) {
						grid-template-columns: 35px 1fr 1fr;
					}
					.check {
						label {
							font-size: 10px;
							color: $blue;
						}
					}
				}
			}
		}

		& > .btnExpand, & > .btnCollapse {
			margin-bottom: 10px;
			color: $blue;

			svg {
				margin-left: 5px;
				fill: $blue;
			}
		}
	}
	.iconForm {
		@include breakpoint($tablet) {
			@include span(9 of 13);
			@include post(4 of 13);
		}
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		#contactsBarTop {
			overflow-y: hidden;
			.fields {
				grid-template-columns: repeat(4, 1fr);
				grid-gap: 10px;
				margin-bottom: 45px;
				.check {
					grid-column-start: 1;
				}
				div {
					grid-gap: 10px;
				}
				.indent {
					grid-column: 1 / span 4;
					margin-left: 0;
					font-size: 12px;
				}
				select {
					margin: 0 0 0 0;
				}
			}
			#customerContactDiv {
				margin-bottom: 45px;
				#venuesPhoneInput {
					grid-column: span 3;
				}
			}
			#driversLicenseDiv {
				margin-bottom: 0;
			}
			#locationDiv {
				margin-top: 10px;
				.med {
					grid-column: span 4;
				}
			}
			#regionAndPostal {
				grid-column: span 4;
				#regionDropdown {
					width: 64% !important;
				}
				#postal {
					width: 32% !important;
					margin-left: 10px;
				}
			}
			#venueFieldDiv {
				margin-bottom: 10px;
			}
		}
		#customerActions {
			width: 100%;
			bottom: 0;
			margin: 10px 0 10px 0;
			grid-template-columns: repeat(4, 1fr);
			.btn {
			  grid-column: span 3;
			}
			.cancel {
			  grid-column: span 1;
			}
		}
	}
	.noteContent {
		width: calc(100% - 40px);

		ul, ol {
			margin: 0 0 10px 15px;
		}
		ul {
			list-style-type: initial;
		}
		ol {
			list-style-type: decimal;
		}
	}
}



.customer, .company, .venue {
	&.noData > div {
		padding-left: span(3 of 26);
		padding-right: span(2 of 26);

		&:first-of-type() {
			padding-left: span(2 of 26);
			padding-right: span(3 of 26);
		}
	}
	span.hintText {
		margin-left: 10px;
		font-size: 11px;
		color: #7194AC;
	}
	.react-datepicker-input {
		background-color: #E5E8E9;
		width: 50%;
	}
	span.alert {
		display: inline-block;
		color: $orange;
		
		svg {
			fill: $orange;
			margin-left: 10px;
		}
	}
}
.customer.noData > div {
	.logo {
		background-image: url('../../../images/icons/noData_Customers.png');
	}

	&:first-of-type() {
		.logo {
			background-image: url('../../../images/icons/noData_Customer.png');
		}
	}
}
.company.noData > div {
	.logo {
		background-image: url('../../../images/icons/noData_Companies.png');
	}

	&:first-of-type() {
		.logo {
			background-image: url('../../../images/icons/noData_Company.png');
		}
	}
}
.venue.noData > div {
	.logo {
		background-image: url('../../../images/icons/noData_Venues.png');
	}

	&:first-of-type() {
		.logo {
			background-image: url('../../../images/icons/noData_Venue.png');
		}
	}
}

.accordian {
	.form.paymentSettings {
		.indent {
			margin-left: 30px;
		}

		.actions {
			margin-top: 25px;
			width: 100%;

			.btn {
				grid-column: span 1;
			}
		}
	}
}


