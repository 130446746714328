@keyframes MoveUpDown {
  0%,
  100% {
    transform: translate(-50%, -5px);
  }
  50% {
    transform: translate(-50%, 10px);
  }
}

.tutorialInputs {
  display: flex;
  width: 100%;
  input {
    width: 100%;
  }
}

.tutorialWrapper {
  border-right: 5px solid $purple;
  border-bottom: 5px solid $purple;
  border-left: 5px solid $purple;

  & > header {
    .stepWrapper {
      height: 40px;

      .tutorialSteps {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: $purple;
        line-height: 40px;
        color: $white;
        text-align: center;
        z-index: 2000;

        & > div {
          display: flex;
          max-width: 1020px;
          padding-left: 30px;
          margin: 0 auto;
          background: transparent image('icons/tutorial_header.png') no-repeat
            left center;
          background-size: 20px 20px;

          svg {
            height: 12px;
            width: 13px;
            margin-right: 5px;
            fill: $white;
            vertical-align: middle;
          }
          p {
            margin: 0 0 0 auto;
          }
        }
      }
    }

    .headerWrapper .header {
      top: 40px;
      left: 5px;
      right: 5px;
    }
  }

  .subNavWrapper {
    .subNav {
      top: 80px;
      left: 5px;
      right: 5px;
      width: auto;
    }
  }

  .content {
    position: relative;

    .pointer {
      animation: MoveUpDown 1.5s infinite;
      position: absolute;
      transform: translate(-50%, 0);
      height: 30px;
      width: 29px;
      padding-top: 9px;
      background: transparent image('icons/tutorial_pointer.png') no-repeat left
        top;
      background-size: 29px 30px;
      font-size: 10px;
      color: $white;
      text-align: center;
      z-index: 35;
    }
  }

  .formFooter {
    display: flex;
    left: 5px;
    right: 5px;

    div:first-child {
      width: 100%;
      padding: 0 20px;
    }
  }

  &.addItem {
    .step1 .pointer {
      top: 70px;
      right: 245px;
    }
    .step2 .pointer {
      top: 325px;
      left: span(6 of 12);
    }
    .step3 .pointer {
      top: 420px;
      left: span(6 of 12);
    }
    .step4 .pointer {
      top: 470px;
      left: span(6 of 12);
    }
    .step5 .pointer {
      top: 540px;
      left: span(6 of 12);
    }
    .step6 .pointer {
      top: 220px;
      left: span(11 of 12);
    }
    .step7 .pointer {
      top: 300px;
      left: span(1 of 6);
    }
    .step8 .pointer {
      position: fixed;
      bottom: 35px;
      left: calc(50% - 50px);
      padding-top: 5px;
      background-image: image('icons/tutorial_pointer_down.png');
      z-index: 10001;
    }
  }
  &.addCustomer {
    .step1 .pointer {
      top: 70px;
      right: 130px;
    }
    .step2 .pointer {
      top: 145px;
      left: span(2 of 7);
    }
    .step3 .pointer {
      top: 145px;
      left: span(4 of 7);
    }
    .step4 .pointer {
      top: 350px;
      left: span(3 of 7);
    }
    .step5 .pointer {
      top: 545px;
      left: span(9 of 28);
    }
  }
  &.createRental {
    .step1 .pointer {
      top: 70px;
      right: 360px;
    }
    .step2 .pointer {
      top: 125px;
      left: span(1 of 12);
    }
    .step3 .pointer {
      top: 125px;
      left: span(9 of 24);
    }
    .step4 .pointer {
      top: 380px;
      left: span(2 of 24);
    }
    .step5 .pointer {
      top: 270px;
      left: 160px;
    }
    .step6 .pointer {
      top: 620px;
      left: span(3 of 12);
    }
    .step7 .pointer {
      top: -10px;
      left: span(2 of 12);
    }
    .step8 .pointer {
      top: 170px;
      left: span(2 of 12);
    }
    .step11 .pointer {
      top: -130px;
      left: span(10 of 12);
    }
  }
}
.inventoryModal {
  .pointer {
    animation: MoveUpDown 1.5s ease-out infinite;
    position: absolute;
    transform: translate(-50%, 0);
    height: 30px;
    width: 29px;
    padding-top: 9px;
    background: transparent image('icons/tutorial_pointer.png') no-repeat left
      top;
    background-size: 29px 30px;
    font-size: 10px;
    color: $white;
    text-align: center;
    z-index: 35;
  }

  &.step9 .pointer {
    top: 160px;
    right: 120px;
  }
  &.step10 .pointer {
    bottom: 0;
    left: 210px;
  }
}

.tutorialModal {
  .content {
    h3 {
      color: $purple !important;
    }
    .body {
      .btnClose svg {
        fill: $purple;
      }
      .itemList {
        margin: 0;

        li {
          border-top: 1px solid $meddarkgrey;
          border-bottom: none;

          a {
            color: $darkblue;
          }
          label {
            font-size: 10px;
            font-weight: $bold;
            color: $purple;
            text-transform: uppercase;
          }
          svg {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translate(0, -50%);
            fill: $purple;
          }
        }
      }
    }
    .actions {
      .btn {
        background-color: $purple;
      }
    }
  }
  .overlay {
    top: 30px !important;
    left: 5px !important;
    right: 5px !important;
    width: auto !important;
  }
}
