.rentalProposal {
  display: grid;
  grid-template: 50px 1fr / 2fr 1fr;

  .templateProposalPageSelect {
    grid-column: 1 / span 2 !important;
  }

  .startWithTemplate {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 1;
    justify-self: end;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;

    & > span {
      margin-right: 10px;
    }
  }
  & > select {
    background-color: #fff;
    grid-column: 1 / span 1;
    justify-self: start;
  }
  section {
    align-self: start;
    min-height: 1000px;
    min-width: 0;

    .header {
      display: grid;
      grid-template-columns: 110px 1fr 1fr;
      grid-column-gap: 15px;
      padding-bottom: 20px;
      margin: 10px 0 20px;
      border-bottom: 1px solid $meddarkgrey;

      figure {
        @include maintain-aspect-ratio(16, 9);
        width: 110px;
        background-color: $darkblue;

        svg {
          fill: $blue;
        }
      }
    }
    .grid {
      tbody:first-of-type tr:first-of-type td {
        border-top: 2px solid $blue;
      }
    }
  }
  aside {
    padding-left: 40px;

    & > div {
      margin-bottom: 40px;
    }
    .actionsPanel {
      padding: 20px;
      background-color: $darkblue;
      color: $white;

      .linkOptions {
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid $darkgrey;

        .actions {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }

  .coverPage {
    margin-top: 100px;

    .fields {
      grid-template-columns: repeat(3, 1fr);

      .uploadZone {
        @include maintain-aspect-ratio(17, 10);
        grid-column: span 2;
        grid-row: span 1;

        .drop {
          svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: 20px;
            width: 20px;
            fill: $blue;
          }
        }

        &:nth-of-type(1),
        &:nth-of-type(8) {
          @include maintain-aspect-ratio(7, 10);
          grid-row: span 2;
        }
        &:nth-of-type(1) {
          grid-column: 1;
        }
        &:nth-of-type(8) {
          grid-column: 3;
          grid-row: 3 / span 2;
        }
      }
      .color {
        position: relative;
        height: 45px;
        background-color: $darkblue;

        & > div {
          height: 100%;
          width: 100%;

          &:last-of-type {
            top: 20px;
          }
        }
        svg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          height: 20px;
          width: 20px;
          fill: $blue;
        }

        &:nth-of-type(2) {
          grid-column: 2;
        }
        &:nth-of-type(3) {
          grid-column: 3;
        }
        &:nth-of-type(6) {
          grid-column: 1;
        }
        &:nth-of-type(7) {
          grid-column: 2;
        }
      }
    }
    .details {
      font-weight: $bold;
      text-align: center;
      color: $blue;

      h1 {
        margin: 0;
        font-size: 36px;
        line-height: 53px;
        color: $darkblue;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .inspiration {
    .fields {
      grid-template-columns: repeat(6, 1fr);

      h1 {
        grid-column: span 6;
        height: 40px;
        color: $darkblue;
        text-align: center;
      }
      & > a {
        grid-column: span 2;

        figure {
          width: 100%;
          @include maintain-aspect-ratio(16, 9);
          background-color: $darkblue;

          svg {
            fill: $blue;
          }
        }
      }
      .uploadZone {
        @include maintain-aspect-ratio(1, 1);
        grid-row: span 2;
        grid-column: span 2;

        .drop {
          svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: 20px;
            width: 20px;
            fill: $blue;
          }
        }

        &:nth-of-type(2) {
          @include maintain-aspect-ratio(2, 1);
          grid-column: span 4;
        }
      }
      .color {
        position: relative;
        background-color: $darkblue;

        & > div {
          height: 100%;
          width: 100%;

          &:last-of-type {
            top: 20px;
          }
        }
        svg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          height: 20px;
          width: 20px;
          fill: $blue;
        }
      }

      &:last-of-type {
        margin-top: 80px;
      }
    }
  }
  .proposalItems {
    margin-top: 20px;

    & > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 10px;
      margin-bottom: 20px;

      h3 {
        grid-column: span 3;
      }
      .item {
        align-self: start;
        display: grid;
        grid-template-columns: 1fr 1fr;
        min-width: 0;
        margin-bottom: 20px;

        figure {
          grid-column: span 2;
          @include maintain-aspect-ratio(16, 9);
          width: 100%;
          margin-bottom: 10px;
        }
        strong:nth-of-type(2) {
          text-align: right;
        }
        label {
          grid-column: span 2;
          white-space: normal;
        }
        span {
          &.strikeThrough {
            color: $darkgrey;
          }
        }
        p {
          grid-column: span 2;
          margin-top: 10px;
          font-size: 10px;
          line-height: 14px;
          color: $darkgrey;
        }
        div {
          grid-column: span 2;

          .caption {
            margin: 0 5px 0 0;
            color: $blue;
          }
        }
      }
      .bundle {
        grid-column: span 3;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 10px;
      }
      .subtotal {
        grid-column: span 3;
        display: block;
        padding-top: 10px;
        border-top: 2px solid $darkblue;
        text-align: right;
      }
    }
  }
  .agreement {
    margin: 60px 80px;

    p {
      overflow: hidden !important;
    }
  }
  .billing {
    margin: 60px 150px;

    h1 {
      height: 40px;
      margin: 0;
      color: $darkblue;
      text-align: center;
    }
  }
}

.templateProposalDrawerContainer {
  display: flex;
  height: 100%;
  flex-flow: column;
  overflow-y: scroll;
  color: $white;
}

.templateProposalSelect {
  border-bottom: 1px solid $darkblue;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
