.conversation {
	.rentalLink {
		float: right;
		font-size: 18px;
		line-height: 40px;
		color: $darkblue;
	}
	.thumb {
		display: inline-block;
		height: 23px;
		width: 23px;
		margin-right: 10px;
		@include border-radius(50%);
		background-color: $meddarkgrey;
		font-size: 10px;
		line-height: 23px;
		color: $white;
		text-align: center;
		text-transform: uppercase;
		overflow: hidden;
		vertical-align: middle;
		
		img {
			height: 100%;
			width: 100%;
		}
	}
	
	.scrollarea {
		background-color: $white;
		height: calc(100vh - 175px);
		@include breakpoint($tablet) {
			height: 500px;
		}

		.scrollbar-container {
			background-color: transparent;
			
			.scrollbar {
				background: $blue;
			}
		}
	}

	.message {
		padding: 20px;
		border-top: 1px solid $lightgrey;
		@include breakpoint($tablet) {
			padding: 20px 40px;
		}
		
		&:first-of-type() {
			border-top: none;
		}
		
		time {
			font-size: 10px;
			font-weight: $bold;
			color: $blue;
		}
		p {
			width: 75%;
			margin: 10px 0;
			line-height: 20px;
		}
		.author {
			font-size: 12px;
			line-height: 18px;
			
			.name, .company {
				display: inline-block;
			}
			.name {
				font-weight: $bold;
			}
			.company {
				padding: 0 4px;
				margin-left: 12px;
				@include border-radius(2px);
				background-color: $blue;
				font-size: 9px;
				line-height: 16px;
				color: $white;
			}
		}
		
		&.mine {
			.author {
				.name {
					color: $blue;
				}
				.company {
					display: none;
				}
			}
		}
		&.theirs {
			background-color: $medlightgrey;
			
			.author {
				.thumb {
					background-color: $blue;
				}
			}
		}
	}
	
	.newMessage {
		padding: 20px;
		margin-top: 10px;
		background-color: $blue;
		@include breakpoint($tablet) {
			padding: 20px 40px;
			margin-top: 40px;
		}
		
		input {
			width: calc(100% - 38px);
			background-color: $lightgrey;
		}
	}
}

.conversationForm {
	position: relative;
	background-color: $white;
	margin-top: 10px;
	@include breakpoint($tablet) {
		margin-top: 40px;
		height: 550px;
	}
	
	.form {
		padding: 20px;
		background-color: $blue;
		@include breakpoint($tablet) {
			padding: 20px 40px;
		}
		
		input[type="text"], textarea {
			background-color: $lightgrey;
		}
		.autocomplete {
			.results {
				background-color: $lightgrey;
				
				li:not(.highlight) {
					background-color: $lightgrey;
				}

				li {
					span {
						display: inline-block;
					}
				}
			}
			.loading {
				border: 2px solid #17cae6;
				border-left-color: transparent;
				border-radius: 50px;
				width: 20px;
				height: 20px;
				animation: loading-fadeIn .3s ease-in,loading-spin 1.3s infinite ease-in-out;
				animation-fill-mode: forwards;
				transform: translate(-50%, -50%);
				z-index: 1;
				margin: auto 10px;
				float: right;
				right: -40px;
				top: 4px;
				position: absolute;
			}
		}
		.actions {
			.btn {
				background-color: $darkblue;
			}
		}
	}
	svg {
		display: none !important;
		position: absolute;
		bottom: 50px;
		left: 50%;
		transform: translate(-50%, 0);
		width: 185px;
		height: 189px;
		fill: $darkgrey;
		@include breakpoint($tablet) {
			display: inline-block !important;
		}
	}
}

.unreadCount {
	line-height: 40px;
	margin-bottom: -40px;
	
	span {
		display: inline-block;
		margin-right: 2px;
		padding: 0 8px;
		@include border-radius(8px);
		background-color: $blue;
		font-size: 10px;
		line-height: 15px;
		color: $white;
	}
}

.convItemList {
	li {
		a {
			padding: 9px 0;
			line-height: 18px;
		}
		label {
			display: block;
			max-width: 80%;
			font-size: 12px;
			line-height: 18px;
			overflow: hidden;
			text-overflow: ellipsis;
			
			&.highlight, &.caption {
				display: inline-block;
				font-size: 10px;
				line-height: 14px;
			}
			&.highlight {
				color: $blue;
				margin-right: 3px;
			}
			&.caption {
				margin: 0;
			}
		}
		span, time {
			position: absolute;
			top: 10px;
			right: 10px;
			font-size: 10px;
			line-height: 14px;
			color: $blue;
			text-transform: uppercase;
		}
		
		&.unread {
			a {
				position: relative;
				padding-left: 15px;
				
				&:after {
					content: " ";
					position: absolute;
					top: 13px;
					left: 0;
					height: 9px;
					width: 9px;
					@include border-radius(50%);
					background-color: $blue;
				}
				.emptyMsg {
					padding: 9px 0;
				}
			}
		}
	}
	.rTableRow {
		&.unread {
			td:first-child {
				position: relative;
				overflow: visible;
			
				span {
					display: block;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				
				&:first-child:after {
					content: " ";
					position: absolute;
					top: 0;
					bottom: 0;
					left: -25px;
					border-left: 5px solid $blue;
				}
			}
		}
	}
}

.pickListUpdateMessage {
  .blue-box {
    margin-left: 37px;
    margin-top: 40px;
    background-color: #278ada;
    height: 81px
  }

  .textSection {

    display: inline-flex;

    svg {
      height: 26px;
      margin: 25px 0px 4px 61px;
    }
    p {
      color: white;
      font-size: 22px;
      margin: 32px 0px 0px 10px;
      display: inline-flex;
    }

    a {
      text-decoration: underline !important;
    }
  }
  .messageSection {
    background-color: #f3f6f8;
    font-size: 12px;
    color: white;
    height: 123.56px;
    margin-left: 37px;

    .content {

      padding: 40px 53px;

      .timeSection {
        font-weight: bold;
      }

      .timeSection {
        color: #278ADA;
        font-size: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .textArea {
        color: #0E2C44;
        font-size: 12px;
      }
    }

  }
}

.smallRentalName{
	font-size: 10px;
	color: $meddarkgrey !important;
}

.pickListConvMessage {
  max-width: 200px;
  white-space: break-spaces;
}


@-webkit-keyframes loading-spin {
    0%,5% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100%,95% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes loading-spin {
    0%,5% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100%,95% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes loading-fadeIn {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes loading-fadeIn {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}
