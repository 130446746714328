.rentalList {
  &.rentalMobileList {
    .filter {
      display: flex;
    }

    .infinite-table-wrapper {
      padding-left: 0;
      padding-right: 0;
    }

    .gridWrapper {
      max-width: 100%;

      .grid {
        margin-left: 0;

        th:first-child {
          padding-left: 15px;
        }

        td {
          padding-left: 15px !important;
        }
      }

      table {
        margin-left: 6px;
        overflow-x: scroll;
      }
    }

    #rental-status-select {
      margin-left: 15px;
      margin-bottom: 15px;

      select {
        background-color: white;
      }
    }

    h1 {
      margin-top: 10px;
      margin-left: 15px;
      margin-bottom: 15px;
    }

    input {
      margin-left: 10px;
    }

    .right {
      .btnIcon {
        margin-right: 7px;

        svg {
          margin-right: 0;
        }
      }
    }
  }

  thead {
    tr {
      th {
        &.checkOutAndIn {
          width: 686px !important;
          height: 60px !important;
          letter-spacing: 0 !important;
          line-height: 30px !important;
        }
      }
    }
  }
  .grid p.rTableRow a {
    display: contents;
  }
  .grid .rTableRow,
  .grid tr {
    &.ready,
    &.alert,
    &.balanceDue {
      .rTableCell,
      td {
        &:first-child() {
          padding-left: 10px;
          position: relative;
          overflow: visible;
          @include breakpoint($tablet) {
            padding-left: 0;
          }
        }
      }
    }
    &.ready {
      .rTableCell,
      td:not(.skip-left-border) {
        &:first-child():after {
          content: ' ';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          border-left: 4px solid $green;
          @include breakpoint($tablet) {
            left: -26px;
            border-left: 6px solid $green;
          }
        }
      }
    }

    &.alert {
      & > td {
        &:first-child():before {
          content: ' ';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          border-left: 4px solid $pink;
          @include breakpoint($tablet) {
            left: -26px;
            border-left: 6px solid $pink;
          }
        }
      }

      &.ready {
        .rTableCell,
        td {
          &:first-child():before {
            left: 2px;
            border-left: 2px solid $pink;
            z-index: 1;
            @include breakpoint($tablet) {
              left: -23px;
              border-left: 3px solid $pink;
            }
          }
        }
      }
    }
    &.balanceDue {
      .rTableCell,
      td {
        &:first-child():before {
          content: ' ';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          border-left: 4px solid $orange;
          @include breakpoint($tablet) {
            left: -26px;
            border-left: 6px solid $orange;
          }
        }
      }
    }
    .expired {
      color: $red;
    }
  }

  &.reservation,
  &.in_use {
    .rTableCell,
    td {
      &.highlight {
        color: $purple !important;

        svg {
          height: 10px !important;
          width: 10px !important;
          margin-right: 5px;
          fill: $purple !important;
          vertical-align: middle;
        }
      }
    }
  }

  &.checking_out,
  &.checking_in {
    .padded {
      td {
        padding: 10px 0;
        vertical-align: middle !important;
      }

      td.actionSm {
        padding: 10px 0;
        vertical-align: top !important;
      }
    }

    .header_with_print_button {
      display: flex;
      justify-content: space-between;

      .print {
        cursor: pointer;
      }
    }
  }

  &.alerts,
  &.checking_out,
  &.checking_in,
  &.payments_due {
    .rTableCell,
    td {
      &.highlight {
        color: $purple !important;

        svg {
          height: 10px !important;
          width: 10px !important;
          margin-right: 5px;
          fill: $purple !important;
          vertical-align: middle;
        }
      }

      &.alert,
      &.none,
      &.partial,
      &.full {
        padding-left: 0 !important;
        text-align: center;
        vertical-align: middle !important;

        svg {
          height: 18px !important;
          width: 19px !important;
        }
      }
      &.alert svg {
        fill: $orange;
      }
      &.none svg {
        color: $lightgrey;
        fill: $lightgrey;
      }
      &.partial svg {
        color: $lightgrey;
        fill: $purple;
      }
      &.full svg {
        color: $green;
        fill: $green;
      }
    }
  }
}

.billing_status,
.payments,
.sub_rented {
  .Full,
  .Fulfilled {
    color: $green !important;
  }
  .Partial {
    color: $orange !important;
  }
  .None,
  .Unfulfilled {
    color: $red !important;
  }
}
