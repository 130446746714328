@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

.workOrderSaved {

  grid-column: 1 / span 2;
  margin-top: 12px;

  .empty {
    display: flex;
    justify-content: center;
  }

  .cancelOrder {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 23px;
  }

  .deleteOrder {
    font-weight: normal;
    color: #CA3A3A;
    font-size: 12px;
  }
  

  .savedForm {
    margin-top: -20px;
  }

  .workHeader {
    margin-bottom: 27px;
    margin-top: 20px;
    h2 {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 14px;
    }

    h5 {
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 5px;
      font-weight: bold;
    }

    p {
      line-height: 18px;
    }
  }

  .workSide {
    display: grid;
    grid-template-columns: 3fr 4fr;
    align-self: end;
    margin-bottom: 27px;
    margin-top: 20px;

    span:nth-child(odd) {
      color: #7194ac;
      font-size: 9px;
      line-height: 14px;
      padding-right: 18px;
      justify-self: end;
      margin-bottom: 12px;
    }

    span:nth-child(even) {
      line-height: 18px;
      font-weight: bold;
    }
  }

  .bottomSection {
    display: flex;
    flex-direction: column;
    margin-bottom: 34px;
    @include breakpoint($tablet) {
      display: grid;
      grid-template-columns: 5fr 1fr;
    }
   

    h4 {
      align-items: center;
      color: black; 
      margin: 0px;
    }

    hr {
      margin-bottom: 8px;
      background-color: #1B4160;

      @include breakpoint($tablet) {
        width: 395px;
      }
    }

    textarea {
      width: 395px;
      height: 75px;
    }


  }

  .spanTop {

    @include breakpoint($tablet) {
      display: grid;
      grid-template-columns: 2fr 1fr;
      justify-content: space-between;
      align-items: start;
    }
  }

  .divOrder {
    display: grid;
    grid-template-columns: 3fr 4fr;
  }

  .div1 {
    color: #7194AC;
    font-size: 10px;
  }

  .rightSection {
    align-items: center;
    justify-content: column;
    gap: 12px;
    margin-top: 20px
  }

  .leftSection {
    display: flex;
    flex-direction: column; 
    align-items: flex-start; 
    margin-right: 57px;
    margin-top: 20px;
  }

  .total {
    margin-top: -2px;

    hr {
      height: 2px;
      color: #1B4160;

      @include breakpoint($tablet) {
        width: 235px;
      }
    }

    h4 {
      margin-bottom: 10px;
    }

    .grid {
      display: grid;
      grid-template-columns: 4fr 1fr;

      span {
        margin-bottom: 16px;
      }

      span:nth-child(odd) {
        font-weight: bold;
      }

      span:last-child {
        font-weight: bold;
      }
    }
  }

  
  .notesh4 {
    align-items: center;
    color: black;
    margin: 0px
  }

  .smallerHeader {
    line-height: 18px;
    padding-bottom: 10px;
    border-bottom: 1px solid $midblue;
    font-weight: bold;
    margin-bottom: 14px;
    color: black;
    align-items: center;
  }

  .workOrderNotes {
    p {
      width: 365px;
    }
  }

  .shipTo {
    h6 {
      margin-top: 12px;
      margin-bottom: 6px;
      line-height: 18px;
      font-weight: bold;
      color: $darkblue;
    }
  }

  .lineItems {
    grid-column: 1 / span 2;
    margin-bottom: 50px;

    .lineItemRow {
      display: grid;
      grid-template-columns: 11fr 2.5fr 8fr 1.2fr;
      margin-bottom: 0;

      .subTotal {
        grid-column: 3 / span 1;
      }
    }

    .lastDataRow {
      border-bottom: 1px solid #ced8e3;
    }

    .dataRow {
      line-height: 39px;
    }

    .center {
      justify-self: center;
    }
  }

  .notes {
    display: flex;
    justify-content: space-between;
    align-items: start;

    span {
      display: flex;
      align-items: start;
      gap: 12px;
    }

    .span1 {
      width: 365px;
      height: 54px;
      font-weight: normal;
    }

    .span2 {
      display: grid; 
      align-items: center;
      grid-template-columns: 1fr 1fr;
      column-gap: 5px;

    }

    .span3 {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      align-items: center;
    }
  }

  

  .noteshr {
    width: 395px;
    margin-bottom: 8px;
    background-color: #1B4160;
  }

  .serviceLabor {
    display: grid; 
    grid-template-columns: 10fr 6fr 8fr 8fr; 
    justify-content: space-between; 
    align-items: start;

    .span1 {
      display: flex; 
      flex-direction: column; 
      align-items: flex-start; 
      margin-top: 40px;
    }

    .span2 {
      display: flex; 
      flex-direction: column; 
      align-items: flex-end; 
      margin-top: 40px;
    }

    h4 {
      display: inline-block; 
      align-items: center;
      color: black;
      margin: 0px;
    }

    .span3 {
      margin-top: 22px;
    }

    .span4 {
      margin-top: 12px;
    }
  }

  

  .partsMaterials {
    display: grid; 
    grid-template-columns: 10fr 6fr 8fr 8fr; 
    justify-content: space-between; 
    align-items: start;

    .span1 {
      display: flex; 
      flex-direction: column; 
      align-items: flex-start; 
      margin-top: 40px;
    }

    .span2 {
      display: flex; 
      flex-direction: column; 
      align-items: flex-end; 
      margin-top: 40px;
    }

    h4 {
      display: inline-block; 
      align-items: center;
      color: black;
      margin: 0px;
    }

    .span3 {
      margin-top: 22px;
    }

    .span4 {
      margin-top: 12px;
    }
  }

  .jobDescription {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    // display: flex;
    // justify-content: space-between;
    // align-items: start;
    // margin-top: 25px;

    @include breakpoint($tablet) {
      display: grid;
      grid-template-columns: 5fr 1fr;
    }

    .h4 {
      display: inline-block;
      align-items: center;
      color: black;
      margin: 0px;

      p {
        width: 365px;
      }
    }

    .hr {
      margin-bottom: 8px; 
      background-color: #1B4160;

      @include breakpoint($tablet) {
        width: 395px; 
      }
    }

    .hr2 {
      margin-bottom: 8px; 
      background-color: #1B4160;

      @include breakpoint($tablet) {
        width: 235px;
      }
    }

    .span {
      width: 365px;
      height: 54px;
      font-weight: normal;
    }

    .span2 {
      margin-top: 24px;

      @include breakpoint($tablet) {
        margin-top: 0;
      }
    }

    .span3 {
      display: flex;
      flex-direction: column;

      .locationName {
        line-height: 18px;
        margin-bottom: 2px;
      }
    }
  }




  .savedHeader {
    background-color: #C1CDD7;
    height: 40px;
    margin-top: 6px;

    .gear {
      margin: 0px;
      fill: #466B88;
    }

    span {
        display: flex; 
        justify-content: space-between;
    }

    .span1 {
        display: flex; 
        align-items: center;
        gap: 12px
    }

    .span2 {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    h4 {
      display: inline-block;
      color: black;
    }

    a {
      margin-top: -10px;
    }
    
    select {


      width: 125px;
      height: 30px;
      margin-right: 18px;
      margin-top: -6px;
    }

  }
}