$icon_size: 25px;
$icon_size_mobile: 30px;
$step_label_font_size: 12px;
$step_count: 7;
$step_count_service: 6;
$label_centering_padding_mobile: calc((#{$icon_size_mobile} - #{$step_label_font_size} - 6px))/2;
$connector_width_mobile: calc((100% - (#{$icon_size_mobile} * #{$step_count})) / #{$step_count - 1});
$connector_width_mobile_service: calc((100% - (#{$icon_size_mobile} * #{$step_count_service})) / #{$step_count_service - 1});

.progressContainer {
	position: relative;
	height: 200px;
	@include breakpoint($tablet) {
		height: 160px;
		margin-bottom: 0 !important;
	}

    h1 {
		padding-top: 20px !important;
		text-align: center;
		@include breakpoint($tablet) {
			padding-top: 40px !important;
		}
    }
}

.progressHolder {
	padding: 80px 10px 20px;
	margin: 0 -10px;
	background-color: $darkblue;
	@include breakpoint($tablet) {
		position: relative;
		padding: 0 0 0 15px;
		margin: 0;
	}

	.stepContainer {
		@include container;
		@include breakpoint($tablet) {
			display: inline-block;
		}

		& > div {
			float: left;
			&.connector {
				height: 4px;
				width: $connector_width_mobile;
				margin-top: 13px;
				/* This is where we set the progress bar colors */
				background: $navyblue;

				@include breakpoint($tablet) {
					width: 60px;
					margin-top: 11px;
				}

				&.complete, &.active {
					background: $blue;
				}
				&.showAnts {
					background: transparent url('../../../images/icons/bkgd_progress.gif') no-repeat no-repeat left top;
				}
				&.invisible {
					visibility: hidden;
				}
				&.dotted {
					background: transparent url('../../../images/icons/dotted-line.svg');
				}

			}

			&.dottedConnector {
				height: 4px;
				width: $connector_width_mobile;
				margin-top: 13px;
				/* This is where we set the progress bar colors */
				//background: transparent url('../../../images/icons/dotted-line.svg');
				background: transparent url('../../../images/icons/dotted-line.svg');
				@include breakpoint($tablet) {
					width: 60px;
					margin-top: 11px;
				}

				&.complete, &.active {
					background: $blue;
				}
				&.showAnts {
					background: transparent url('../../../images/icons/bkgd_progress.gif') no-repeat no-repeat left top;
				}
				&.invisible {
					visibility: hidden;
				}
			}
			&.step {
				position: relative;
				width: $icon_size_mobile;
				text-align: center;
				@include breakpoint($tablet) {
					width: $icon_size;
				}

				&.complete, &.active {
					.icon {
						background-color: $blue;

						svg {
							fill: $white;
						}
					}
				}
				&.active {
					label {
						color: $white;
					}
					&.alert {
						.icon {
							background-color: $red;
						}
					}
				}
				.icon {
					height: $icon_size_mobile;
					width: $icon_size_mobile;
					background-color: $navyblue;
					@include border-radius(100%);
					@include breakpoint($tablet) {
						height: $icon_size;
						width: $icon_size;
						margin-bottom: 5px;
					}

					svg {
						height: 15px !important;
						width: 16px !important;
						margin: 7px auto;
						fill: $cobalt;
						@include breakpoint($tablet) {
							margin: 5px auto;
						}
					}
				}
				label {
					position: absolute;
					display: block;
					padding-left: 35px;
					padding-top: $label_centering_padding_mobile;
					font-size: $step_label_font_size;
					color: $darkgrey;
					transform: rotate(-90deg);
					transform-origin: left top 0;
					white-space: nowrap;

					@include breakpoint($tablet) {
						position: static;
						padding-left: 0;
						padding-top: 0;
						transform: none;

						&:before {
							content: '';
							display: inline-flex;
							margin-right: -900%;
						}
						&:after {
							content: '';
							display: inline-flex;
							margin-left: -900%;
						}
					}

					svg {
						position: absolute;
						/* right: -28px;
						height: 14px !important; */
						margin-left: 2px;
						width: 16px !important;
						fill: $orange;
					}
					span {
						svg {
							left: -20px !important;
						}
					}
				}
			}
		}
	}

	&.serviceTier {
		.stepContainer .connector {
			width: $connector_width_mobile_service;
			@include breakpoint($tablet) {
				width: 82px;
			}
		}
	}
	&.change {
		.stepContainer {
			& > div {
				&.connector {
					&.complete, &.active {
						background: $orange;
					}
					&.showAnts {
						background: transparent url('../../../images/icons/bkgd_progress_orange.gif') no-repeat no-repeat left top;
					}
				}
				&.step {
					&.complete, &.active {
						.icon {
							background-color: $orange;
						}
					}
					&.active {
						&.alert {
							.icon {
								background-color: $red;
							}
						}
					}
				}
			}
		}
	}
}
