.profile {
	@include breakpoint($tablet) {
		display: grid;
		grid-template-columns: 1fr 3fr 2fr;

		.btnLink {
			grid-column: 3;
			place-self: start end;
		}
		form {
			grid-column: 1 / span 3;
			display: grid;
			grid-template-columns: 1fr 3fr 2fr;
		}
	}
	figure, .uploadZone {
		margin: 0 span(1 of 4) 20px;
		@include breakpoint($tablet) {
			align-self: start;
			margin: 0;
		}
	}
	figure {
		@include maintain-aspect-ratio(1, 1);
		background-color: $darkblue;
		@include border-radius(50%);

		svg {
			height: 50% !important;
			width: 50% !important;
			fill: $cobalt;
		}
	}
	.uploadZone  .drop {
		@include maintain-aspect-ratio(1, 1);
		@include border-radius(50%);
		overflow: hidden;

		.placeholder svg {
			margin: 60px 0 0;
		}
	}
	.iconView, .iconForm {
		@include breakpoint($tablet) {
			margin-left: 40px;
		}
	}
}

.googleCalendar {

	img {
		width: 355px;
        margin-bottom: 20px;
		@include breakpoint($tablet) {
			width: 355px;
		}
	}
	span {
		display: inline-block;
		margin-bottom: 10px;

		svg {
			height: 20px !important;
			width: 21px !important;
			margin-right: 5px;
			fill: $darkgrey;
			vertical-align: middle;
		}

		&.connected {
			svg {
				fill: $green;
			}
		}
	}
	.btn {
		margin-bottom: 40px;

		&.full {
			display: block;
			width: 35%;
		}

		&.connectedFull {
		display: block;
		width: 190%;
		}
	}
}

.nowsta {
	.tooltip {
		pointer-events: auto;
	}
	.steps {
		position: relative;
		top: -479px;
		left: 50%;
		background-color: #fefefe;
		padding: 20px 30px;
		display: block;
		width: 50%;
		a {
			color: #0E2C44;
		}
		h4 {
			margin-bottom: 0;
		}
		.step {
			&:before {
				content: none;
			}
			width: 145px;
			margin-bottom: 15px;
		}
		.reminder {
			position: absolute;
			top: 0;
			padding-top: 20px;
			padding-right: 30px;
			left: 50%;
			line-height: 14px;
			font-size: 10px;
			color: #7194AC;
		}
	}
	h1 {
		.btn {
			float: right;
			margin-right: 0;
			&:disabled {
				background-color: #8FA9BE;
			}
		}
		color: #278ADA;
		a {
			svg {
				fill: #8FA9BE;
				color: #ffffff;
			}
			vertical-align: middle;
			margin-left: 5px;
		}
		.tooltip {
			a {
				color: #ffffff;
			}
			p {
				margin-bottom: 0;
			}
		}
	}
	.btn {
		&.full {
			margin-bottom: 20px !important;
		}
	}
	a {
		text-decoration: underline;
	}
	.rectangle {
		height: 200px;
		width: 355px;
		margin-bottom: 20px;
		text-align: center;
		background: linear-gradient(135deg, #00DAD8 0%, #00BAFF 100%);
		@include breakpoint($tablet) {
			width: 355px;
		}
		svg {
			height: 49.88px !important;
			width: 52.47px !important;
			position: relative;
			left: 14%;
			top: 25%;
		}
		span {
			position: relative;
			top: 45%;
			right: 8%;
			font-size: 32px;
			color: #ffffff;
		}
	}
	.displayBlock {
		display: block;
	}
	span {
		margin-bottom: 10px;
		word-break: break-word;
		svg {
			height: 20px !important;
			width: 21px !important;
			margin-right: 5px;
			fill: $darkgrey;
			vertical-align: middle;
		}

	}
	.full {
		margin-bottom: 20px;
	}
	span.edit {
		cursor: pointer;
		position: relative;
    top: -105px;
    left: 60px;
	}

	.connected {
		svg {
			fill: $green;
		}
		width: 49%;
	}
	.nowstaInput {
		width: 45%;
		min-height: 140px;
	}
	.btn {
		margin-top: 10px;
		margin-bottom: 40px;

		&.full {
			display: block;
			width: 45%;
		}

		&.connectedFull {
			display: block;
			width: 108%;
			background-color: #466B88;
		}
	}
	.btnSection {
		margin-top: 5px;
		width: 45%;
		button:first-child {
			margin-right: 10px;
		}
	}
	.btn.btn-sm {
		padding: 0 60px;
		float: left;
	}
	.btn.btn-cancel {
		padding: 0 15px;
		float: right;
	}
}

.notificationInputs {
	margin-top: 20px;
	margin-left: 45px;
	input {
		margin-left: 30px;
		max-width: 250px;
    width: 100%;
	}
	.radio {
		margin-top: 20px;
	}
	.label-input {
		input {
			margin-left: 10px;
		}
		label {
			margin-left: 30px;
		}
	}
}

.full-width {
	width: 100%;
}
.calendarMargin {
  margin-bottom: 10px;
}
.actions .btn.calendarCancel {
  margin-top: 90px;
}
