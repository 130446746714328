.gettingStartedHeader {
	height: auto !important;
	background: $navyblue url("../../../images/marketing/gettingStarted_bg.png") no-repeat center top;
	@include breakpoint($tablet) {
		height: 160px !important;
	}

	& > div {
		margin: 20px 0;
		color: $white;
		@include breakpoint($tablet) {
			display: flex;
			align-items: center;
			margin: 0;
		}

		h1 {
			color: $white;
			@include breakpoint($tablet) {
				margin: 0 30px 0 0;
				font-size: 42px;
				line-height: 42px;
				font-weight: $bold;
			}
		}
		p {
			@include breakpoint($tablet) {
				margin: 0;
			}
		}
		.callout {
			align-self: start;
			position: relative;
			margin-left: auto;
			padding: 0 15px;
			@include border-radius(13px);
			background-color: $blue;
			font-size: 10px;
			line-height: 26px;
			@include breakpoint($tablet) {
				margin: 20px 0 0 auto;
			}

			@include breakpoint($tablet) {
				&:after {
					content: " ";
					position: absolute;
					top: -6px;
					right: 42px;
					border-top: none;
					border-bottom: 6px solid $blue;
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
				}
			}
		}
	}
}

.gettingStarted {
	padding: 0 !important;
	@include breakpoint($tablet) {
		grid-column: 1 / span 14 !important;
	}

	h2 {
		line-height: 24px;
		color: $darkblue;
	}
	a:not(.btn) {
		text-decoration: underline;
	}

	.steps, .helpCallouts {
		padding: 20px 10px;
		@include breakpoint($tablet) {
			padding: 40px 0 0;
		}
	}
	.steps {
		background-color: $white;
		@include breakpoint($tablet) {
			display: grid;
			grid-template-columns: 1fr minmax(auto, 510px) minmax(auto, 510px) 1fr;
		}

		& > div {
			padding: 160px 0 40px;
			background-position: center top;
			background-repeat: no-repeat;
			background-size: 152px 152px;
			@include breakpoint($tablet) {
				height: 300px;
				padding: 0 40px 0 180px;
				background-position: left top;
			}

			h2 {
				font-weight: $bold;
			}
			.videoButton, .btnSm {
				height: 15px;
				line-height: 16px;
				text-decoration: none;

				&:before {
					top: 3px;
				}
			}

			&.signup {
				grid-column: 2;
				background-image: url("../../../images/icons/onboarding_signup_active.png");
			}
			&.merchant {
				grid-column: 3;
				background-image: url("../../../images/icons/onboarding_merchant.png");

				&.complete {
					background-image: url("../../../images/icons/onboarding_merchant_active.png");
				}
			}
			&.inventory {
				grid-column: 2;
				background-image: url("../../../images/icons/onboarding_inventory.png");

				&.complete {
					background-image: url("../../../images/icons/onboarding_inventory_active.png");
				}

				.btnIcon {
					display: inline;
					border: none;
					background-color: transparent;
					line-height: initial;
					white-space: normal;

					svg {
						display: none !important;
					}

					&:hover {
						color: $blue;
					}
				}
			}
			&.renting {
				grid-column: 3;
				background-image: url("../../../images/icons/onboarding_renting.png");

				&.complete {
					background-image: url("../../../images/icons/onboarding_renting_active.png");
				}
			}
			&.settings {
				grid-column: 2;
				background-image: url("../../../images/icons/onboarding_settings.png");

				&.complete {
					background-image: url("../../../images/icons/onboarding_settings_active.png");
				}
			}
			&.payments {
				grid-column: 3;
				background-image: url("../../../images//icons/onboarding_payments.png");

				&.complete {
					background-image: url("../../../images/icons/onboarding_payments_active.png");
				}
			}
		}
	}
	.helpCallouts {
		background-color: $meddarkgrey;
		@include breakpoint($tablet) {
			display: grid;
			grid-template-columns: 1fr minmax(auto, 510px) minmax(auto, 510px) 1fr;
			padding: 40px 0;
		}

		h2 {
			grid-column: 2 / span 2;
			position: relative;
			text-align: center;

			&:before, &:after {
				content: " ";
				position: absolute;
			}
			&:before {
			}
			&:after {
			}
		}
		div {
			grid-column: 2;
			justify-self: end;
			margin: 40px 20px;
			padding-top: 150px;
			background-image: url("../../../images/icons/onboarding_call.png");
			background-position: center top;
			background-repeat: no-repeat;
			@include breakpoint($tablet) {
			}

			&:last-of-type {
				grid-column: 3;
				justify-self: start;
				background-image: url("../../../images/icons/onboarding_shop.png");
			}

			.btn {
				width: 100%;
				margin: 0;
				@include breakpoint($tablet) {
					padding: 0 20px;
				}
			}
		}
		a {
			grid-column: 2 / span 2;
			place-self: center;
		}
	}
}
