.Month {
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 1px solid $darkgrey;

  .Week {
    display: inline-flex;
    border-bottom: 1px solid $darkgrey;

    &:last-child {
      border: none;
    }

    .Day {
      display: inline-flex;
      flex-grow: 1;
      flex-shrink: 0;
      flex-direction: column;
      height: 100px;
      border-right: 1px solid $darkgrey;
      position: relative;
      cursor: pointer;

      .Day__container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 20px;
        right: 0;
        overflow: hidden;
      }
      .Day__number {
        position: absolute;
        top: 4px;
        left: 4px;
        font-size: 10px;
        font-weight: $bold;
      }
      &:last-child {
        border: none;
      }
    }

    .EmptyCell {
      display: inline-flex;
      flex-grow: 1;
      flex-shrink: 0;
      border-right: 1px solid $darkgrey;
      color: $darkgrey;

      &:last-child {
        border: none;
      }
    }
  }
}

.day-revenue {
  width: 95%;

  .day-revenue-data {
    display: flex !important;
    justify-content: space-between;
    line-height: 0.9rem;

    &:nth-child(even) {
      border-bottom: 1px solid $lightgrey;
    }

    &:last-child {
      border-bottom: none;
    }

    .day-revenue-label {
      color: $cobalt;
      font-weight: bold;
    }

    .day-revenue-amount {
      color: $blue;
    }

    p {
      color: black;
      line-height: 1.5;
      margin: 0;
    }
  }
}
