.detailsFilter {
  .filterCSS {
    display: inline-block;
    white-space: nowrap;
    width: 150px;
    margin-bottom: -5px;

    .h4 {
      font-size: 9px;
      color: #0e2c44;
      font-weight: normal;
      display: inline-block;
    }

    .radioButton1 {
      font-weight: bold;
      display: inline-block;
      margin-left: 14px;
      width: 150px;
      margin-bottom: -5px;
    }

    .radioButton2 {
      font-weight: bold;
      display: inline-block;
      margin-left: 14px;
      margin-bottom: -5px;
    }

    .radioButton3 {
      font-weight: bold;
      display: inline-block;
      margin-left: 44px;
      margin-bottom: -5px;
    }
  }
}
