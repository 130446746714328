.powerbiReport {
  height: 100%;
  .embed {
    padding: 0px !important;
    margin: 0px !important;
    height: 90%;
    width: 110em;
    left: -10%;
  }
}

.powerbiFilters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.end {
  text-align: end;
}

.start {
  text-align: start !important;
}

.powerbiPaddingRight-10 {
  padding-right: 10px;
}

.powerbiPaddingRight-5 {
  padding-right: 5px;
}

.powerbiWordBreak {
  word-break: keep-all;
}

.powerbiModalContainer {
  background: $white;
  padding: 20px;
  overflow-y: scroll;
  max-height: 385px;
}

.powerbiModalReports {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.powerbiMarginX-10 {
  margin: 0 10px;
}

.powerbiColorWhite {
  color: $white;
}

.powerbiBgDark {
  background: $darkblue;
}

.powerbiColorBlue {
  color: $blue;
}

.powerbiDrawerContainer {
  max-height: 400px;
  overflow-y: scroll;
  color: $white;
}

.powerbiAssignCompany {
  border-bottom: 1px solid $darkblue;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.powerbiUnsetWidth {
  width: unset !important;
}

.powerbiMarginTop-20 {
  margin-top: 20px;
}
