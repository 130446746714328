.categoriesList {
  h4 {
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    margin: 10px 0 12px;
  }

  ul {
    li {
      display: flex;
      align-items: center;
      height: 40px;
      justify-content: space-between;
      border-top: 1px solid #ced8e3;

      .remove {
        width: 40px;
        svg {
          fill: $red;
        }
      }
    }
  }
}
