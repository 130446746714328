.availability {
  position: relative;

  form {
    margin-bottom: 50px;

    .btn {
      width: 157px;

      &.cancel {
        width: 76px;
      }
    }
  }

  > header {
    display: flex;
    justify-content: space-between;
    a.btnIcon {
      width: 110px;
      margin: 0 0 0 5px;
    }
    a.btnIcon.service {
      width: 150px;
    }
  }

  .toggleRow {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    flex-wrap: wrap;

    > div:first-child {
      width: auto !important;
    }

    > label {
      margin-left: 8px;
    }

    .caption {
      margin-left: 7px;
      font-size: 10px;
      color: $blue;
    }

    > input {
      margin-left: 8px;
      margin-bottom: 0px;
      width: 246px;
    }

    .openWindowTimes {
      display: flex;
      margin-left: 8px;
      align-items: center;

      .dash {
        color: $blue;
        margin: 0 3px;
      }

      .toggle {
        margin-left: 0px;
      }
    }

    .toggle {
      padding: 0 0 0 30px;
      margin-left: 8px;
      margin-bottom: 0px;
      width: 115px;

      input {
        margin-bottom: 0px;
      }
    }
  }
}
