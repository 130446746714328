.feesList {
  .rowGroup {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #ced8e3;
    padding: 12px 0 4px;

    .row {
      display: flex;
      align-items: center;


      div {
        padding-right: 5px;
        flex: 1;
      }

      div.action {
        flex: 0;
        width: 36px;
      }
    }
  }
}
