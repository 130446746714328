.rentalFormWrapper {
  header {
    height: auto;
    background-color: $darkblue;
    @include breakpoint($tablet) {
      height: 200px;
    }

    & > div {
      display: grid;
      @include breakpoint($tablet) {
        grid-template: 160px 40px / 3fr 1fr;
      }
    }

    .title {
      padding: 10px;
      margin: 0 -10px;
      color: $darkgrey;
      text-align: center;
      @include breakpoint($tablet) {
        grid-column: 1;
        grid-row: 1;
        place-self: center start;
        min-width: 0;
        max-width: 100%;
        padding: 0;
        margin: 0;
        font-size: 10px;
        line-height: 14px;
        text-align: left;
      }

      display: flex;
      align-items: center;

      h1 {
        margin: 0;
        color: $white;
        @include breakpoint($tablet) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .disabledTabs {
      cursor: not-allowed;

      li {
        a {
          pointer-events: none;
        }
      }
    }

    .progress {
      display: flex;
      flex-direction: column;
      @include breakpoint($tablet) {
        grid-column: 1;
        grid-row: 2;
        place-self: start;
        flex-direction: row;
      }

      li {
        margin-right: 35px;

        a {
          display: block;
          height: 35px;
          line-height: 35px;
          color: $darkgrey;
          @include breakpoint($tablet) {
            height: 40px;
            border-bottom: 5px solid transparent;
          }

          svg {
            height: 20px !important;
            width: 20px !important;
            margin-right: 5px;
            fill: $darkgrey;
            vertical-align: middle;
          }

          &.active {
            color: $white;
            @include breakpoint($tablet) {
              border-bottom: 5px solid $blue;
            }

            svg {
              fill: $blue;
            }
          }
        }
      }
    }

    .actions {
      grid-row-gap: 5px;
      // margin-bottom: 20px;
      @include breakpoint($tablet) {
        grid-column: 2;
        grid-row: 1;
        place-self: end stretch;
        // margin-bottom: 50px;
      }

      a:not(.btn) {
        line-height: 30px;
        text-align: center;
        text-decoration: underline;
      }

      @include breakpoint($phone $tablet) {
        a:first-child {
          grid-column: span 3;
        }
        .btn.full {
          grid-column: span 2;
        }
      }

      .btn.disabled {
        display: flex;
        align-items: center;
        justify-content: center;

        .spinner {
          display: flex;
          align-items: center;
          justify-content: center;

          padding: 0;

          svg {
            width: 20px !important;
            height: 20px !important;
            fill: none !important;
          }
        }
      }
    }
    .btnLink {
      grid-column: 1;
      grid-row: 1;
      place-self: end;
      margin: 9px 0 0;
      font-weight: $regular;
      @include breakpoint($tablet) {
        grid-column: 2;
        grid-row: 2;
        place-self: start end;
      }
    }
  }
}

.rentalFormWrapper.rentalFormV2 {
  @media (max-width: 769px){
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
}

@include breakpoint($tablet) {
  .select-container {
    display: flex;
    justify-content: space-between;
    section {
      width: 48%;
    }
  }
}

.radiosContainer {
  padding: 10px 0;
}

.rentalForm {
  padding: 20px 10px 80px !important;
  @include breakpoint($tablet) {
    padding: 40px 0 !important;
  }

  .eventDetails,
  .itemsDelivery,
  .optionsDiscountsFees {
    @include breakpoint($tablet) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
    }

    .column {
      min-width: 0;
    }
  }
  .eventDetails {
    @include breakpoint($tablet) {
      grid-template-columns: 7fr 5fr;
    }
    z-index: 1;

    .column {
      .grid {
        a:not(.btnLink) {
          color: $darkblue;
          cursor: pointer;

          svg {
            height: 22px;
            width: 23px;
            fill: $blue;

            &.circle {
              width: 22px;
              fill: $white;
            }
          }
        }
      }
    }
    .expiration {
      margin-top: 5px;
      margin-bottom: 5px;
      &.blue {
        color: $blue;
      }
      &.orange {
        color: $orange;
      }
      .alert {
        color: $orange;
        line-height: 24px;

        svg {
          height: 15px;
          width: 16px;
          margin-right: 5px;
          fill: $orange;
          vertical-align: middle;
        }
      }
      .react-datepicker-component {
        width: span(1 of 2);
        margin-left: 23px;
        @include breakpoint($tablet) {
          width: span(1 of 3);
        }
      }
      .actions {
        margin-top: 20px;
      }
    }

    .teamMembersTable {
      margin-bottom: 13px;
      .employeeInfo {
        display: flex;
        height: 24px;
        justify-content: space-between;
        margin: 12px 0px 0px;

        a {
          display: flex;
          align-items: center;
        }
      }

      h4 {
        font-size: 10px;
        font-weight: normal;
      }

      select {
        margin-bottom: 13px;
      }
    }
  }
  .itemsDelivery {
    display: block;

    .rentalToggleSettings {
      min-width: 12em;
      margin-bottom: 10px !important;
      background-color: #fafeff !important;
      padding: 0px 0px !important;

      .rentalToggleSettingsSelect {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2px;
        padding: 4px 9px;
      }

      option {
        display: none;
      }
    }
    .rentalToggleSettingsDiv {
      position: absolute;
      background-color: #0e2c44;
      color: white;
      z-index: 1;
      width: 24em;
      right: 0px;
      margin-top: 10px;
      padding: 10px;
      &.toggleRentalSettingsV2 {
        @media(max-width: 769px){
          width: 100%;
        }
      }
    }

    #add-section-button {
      display: flex;
      width: 100%;
      margin-bottom: 0;
      height: 40px;
      &.addSectionButtonV2 {
        @media(max-width: 769px){
          padding-top: 10px;
          display: block;
          height: auto;
        }
        .btn {
          margin-bottom: 10px;
        }
      }
      .btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          padding-right: 1px;
          fill: #54c06e;
          width: 15px;
          height: 15px;
        }
      }

      section {
        background-color: transparent;
        margin-bottom: 0;
        padding-top: 4px;

        h4 span a {
          width: 2em;
          padding-top: 2px;
        }
        select {
          background-color: #fafeff !important;
          margin-top: -4px;
        }
      }
    }

    .extra-tablet-section-bottom-padding {
      @media screen and (max-width: 769px) {
        padding-bottom: 110px;
        display: block;
      }
    }

    .grid {
      .rTableCell,
      td {
        &.hasShortage {
          background-color: transparent;
          cursor: default;

          svg {
            fill: $orange;
          }
        }
      }
    }

    .bundleItems {
      margin-bottom: 20px;

      .grid.belongsToBundle {
        tr td:first-of-type() {
          padding-left: 30px;
        }
      }
      .rentalItemMobile.belongsToBundle {
        padding-left: 15px;
      }
      .linkLock {
        display: inline-block;
        width: 15px;
        height: 18px;
        margin-left: 5px;

        svg {
          width: 15px;
          height: 18px;
          margin: 0;
          fill: $blue;
        }
      }
      .toggle {
        width: calc(100% - 15px);
        margin: 0 5px 5px 10px;
        @include breakpoint($tablet) {
          width: 95%;
          margin: 10px 5px 0 0;
        }

        &:first-of-type() {
          margin: 10px 5px 5px 10px;
          @include breakpoint($tablet) {
            margin: 10px 5px 0 30px;
          }
        }
      }
    }
  }

  form {
    .fields {
      margin-bottom: 20px;
    }

    .discountDrawer {
      margin: 20px 10px 5px 10px;

      input[type='text'] {
        width: 100% !important;
        margin-bottom: 20px;
      }
      > div {
        display: flex;
        justify-content: space-between;
        select,
        input[type='text'] {
          width: 49% !important;
        }
      }
    }
    h4 {
      margin-bottom: 10px;
    }
    .column {
      .btn {
        padding-left: 0;
        padding-right: 0;

        &.full {
          width: calc(100% - 5px);
        }
      }
    }

    select,
    textarea {
      width: 100%;
    }
    textarea {
      height: 90px;
      resize: vertical;
    }
    .dateRange {
      display: grid;
      grid-template-columns: 5fr 4fr 1fr;
      grid-gap: 5px;
      grid-auto-rows: max-content;

      label {
        align-self: center;
        color: $darkgrey;
      }
      .subHeading {
        grid-column: span 3;
        font-size: 10px;
        color: $blue;
      }
      .marginImg {
        margin-left: 7px;
      }
      .select {
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-column: span 2;
      }
      .react-datepicker-component {
        grid-column: 1 / span 1;
      }

      .rc-time-picker {
        grid-column: 2 / span 1;
      }

      .react-datepicker-component,
      .rc-time-picker {
        margin: 0;

        input[type='text'] {
          margin: 0;
        }
      }
      select {
        grid-column: span 2;
      }
    }
    .dropOff {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 25px 1fr 1fr 1fr;
      grid-gap: 5px;

      label {
        align-self: center;
        text-align: center;
        color: $darkgrey;
      }
      .subHeading {
        text-align: left;
        grid-column: span 7;
        font-size: 10px;
        color: $blue;
      }
      .react-datepicker-component {
        grid-column: span 2;
      }

      .rc-time-picker {
        grid-column: span 1;
      }

      .react-datepicker-component,
      .rc-time-picker {
        margin: 0;

        input[type='text'] {
          margin: 0;
        }
      }
      select {
        grid-column: span 2;
      }
    }
    .check {
      clear: both;
      display: block;
      line-height: 30px;
    }
    .indent {
      margin-left: 30px;
    }
    .info-text {
      font-size: 11px;
      color: $darkgrey;
      margin-top: 5px;
    }
    .radioList {
      margin: 0;
    }
    .toggle,
    .grid,
    .accordian {
      width: 100%;
    }
    .grid {
      .rTableHead,
      th,
      .rTableCell,
      td {
        &.rightGap {
          padding-right: 25px;
        }
        .right {
          float: right;
        }
      }
      .check {
        margin: 0;

        svg {
          margin: 0;
        }
      }
    }
    .rentalItemMobile {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-gap: 5px;
      align-items: center;
      padding: 3px 0;
      border-top: 1px solid $meddarkgrey;
      line-height: 35px;

      & > * {
        width: 100%;
      }
      label {
        grid-column: 1 / span 7;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .btnLink {
        grid-column: 8;
        justify-self: end;
      }
      div {
        grid-column: 1 / span 2;

        &.hasShortage {
          position: relative;
          padding: 0 25px 0 5px;
          background-color: $orange;

          svg {
            position: absolute;
            top: 50%;
            right: 0;
            height: 15px;
            width: 16px;
            margin-right: 5px;
            transform: translate(0, -50%);
            fill: $white;
          }
        }
      }
      .Dropdown-root {
        grid-column: 3 / span 4;
        @include border-radius(2px);
        background-color: $lightgrey;
      }
      span {
        grid-column: 7 / span 2;
        text-align: right;
      }
      input[type='text'] {
        grid-column: span 4;
        margin: 0;

        &.full {
          grid-column: span 8;
        }
      }
    }
    .accordian {
      .header {
        & > div {
          width: calc(100% - 20px);
        }
      }
      .grid {
        width: 100%;
      }
    }
    .actions {
      display: grid;
      .btn {
        grid-column: span 2;
        &.cancel,
        &.change {
          grid-column: span 1;
        }
        &.full {
          grid-column: span 3;
        }
        &.cancelV2 {
          @media(max-width: 769px){
            grid-column: span 2;
          }
        }
      }
      grid-template-columns: repeat(3, 1fr);
      &.threeButtons {
        grid-template-columns: repeat(1,1fr) !important;
      }
    }
    #companyActions {
      margin-top: 5%;
      width: 100%;
      grid-template-columns: repeat(4, 1fr);
      .btn {
        grid-column: span 3;
      }
      .cancel {
        grid-column: span 1;
      }
    }
    .itemDetails {
      padding: 0;
      white-space: normal;

      .priceFormTabs {
        background-color: $cobalt;

        li {
          display: inline-block;

          a {
            display: inline-block;
            padding: 0 15px;
            font-size: 11px;
            font-weight: $bold;
            line-height: 40px;
            color: $white;
            text-transform: uppercase;
            cursor: pointer;

            svg {
              height: 17px;
              width: 18px;
              margin-right: 5px;
              fill: $white;
              vertical-align: middle;
            }

            &.active {
              background-color: $darkblue;
            }
          }
        }
      }
      .pricing,
      .itemNotes,
      .subRentalForm {
        .actions {
          width: 100%;
          margin-top: 10px;
          @include breakpoint($tablet) {
            grid-template-columns: repeat(6, 1fr);
          }

          .btnLink {
            float: none;
            grid-column: 1 / span 3;
            place-self: center;
            height: 25px;
            width: auto;
            margin: 40px 0 0;
            color: $white;
            @include breakpoint($tablet) {
              grid-column: 5 / span 2;
              place-self: center end;
              margin: 0;
            }

            svg {
              height: 25px !important;
              width: 26px !important;
              margin-left: 5px;
            }
          }
        }
      }
      .pricing,
      .itemNotes {
        padding: 20px 20px 10px;
        background-color: $darkblue;

        .itemNotesSection {
          background-color: #e5e8e9;
          padding: 19px 15px 10px 9px;
        }

        .itemNote {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        }

        .itemNote:not(:first-child) {
          border-top: 1px solid #ced8e3;
          padding-top: 12px;
        }

        .noteDetails {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .visibleToCustomer {
            color: #278ada;
            font-size: 10px;
            line-height: 14px;
          }
        }
        .noteActions {
          display: flex;
          .edit {
            margin-right: 7px;
          }
        }

        .toggle {
          width: 49%;
        }
      }
      .itemNotesFlex {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        white-space: nowrap;

        .columnFlex {
          display: flex;
          flex-direction: column;
          width: 33%;
          padding-right: 20px;
        }

        .columnFlex label {
          align-self: flex-start;
        }

        .rowFlex {
          width: 900px;
          display: flex;
          flex-direction: row;

          label {
            color: #c1cdd7 !important;
          }

          .to {
            color: $bluegrey;
          }

          .btn {
            grid-column: span 1;
          }
        }
        .blueBrakeLine {
          border-top: 1px solid #278ada;
          padding: 10px 0;
        }
        .notesList {
          color: #c1cdd7;
          font-family: Heebo;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 18px;

          .grayBrakeLine {
            border-top: 1px solid #1b4160;
            padding: 10px 0;
          }

          .notesRow {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
          }
          .editedText {
            padding-right: 15px;
          }
        }
        .sampleDiv {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .mt5 {
          margin-top: 5px;
        }
        .mb20 {
          margin-bottom: 20px;
        }
        .w85 {
          width: 85%;
        }
      }
      .pricing {
        input.customPricing:disabled {
          background-color: #a4afb7 !important;
          color: #70849c;
        }
        .discounts {
          display: contents !important;
        }
        .border {
          border-bottom: 1px solid $cobalt;
          margin: 10px 0 10px 0;
        }

        @media screen and (max-width: 768px) {
          padding-top: 0;
        }

        & > div:not(.action):not(.taxes) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-auto-rows: 40px;
          grid-column-gap: 20px;

          @media screen and (max-width: 768px) {
            grid-column-gap: 6px;

            .cancel {
              color: white;
              padding-left: 30px !important;
              padding-right: 30px !important;
            }

            .remove {
              display: flex;
              justify-content: center;
            }
          }

          place-items: center start;
          @include breakpoint($tablet) {
            grid-template-columns: 250px 2fr 1fr;
          }

          & > * {
            width: 100%;
            min-width: 0;
            margin: 0;
          }
          input[type='text'] {
            grid-column: span 1 !important;
            width: 75px;
            place-self: center end;
          }

          .radio {
            grid-column: 1 / span 2;
            @include breakpoint($tablet) {
              grid-column: 1;
            }

            label {
              color: $cobalt !important;
              grid-column: 1;
            }
          }
          .check {
            grid-column: 1;
            @include breakpoint($tablet) {
              grid-column: 1 / span 3;
            }

            label {
              color: $white !important;
              grid-column: 1;
            }
          }
          & > label {
            padding-left: 8px;
            color: $cobalt;
            text-align: right;

            &.full {
              padding-left: 0;
              grid-column: 1 / span 2;
              text-align: left;
              @include breakpoint($tablet) {
                grid-column: 1;
              }
            }
          }
          span {
            color: $cobalt;
          }

          &.highlight {
            div.Dropdown-root {
              text-align: right;
              right: -38px;

              .Dropdown-placeholder {
                margin-right: 13px;
                line-height: 14px;
                align-self: center;
              }
              .Dropdown-menu {
                right: 27px;
              }
            }
            label {
              color: $white !important;
            }
            & > span {
              color: $blue;
            }
          }
        }
        .taxes {
          label {
            color: white !important;
          }
        }
        .discountButton {
          width: 49% !important;
          margin: 25px 0 5px 0 !important;
        }
      }
      .itemNotes {
        textarea {
          width: 100%;
          margin: 0;
        }

        .staffMemberToggle {
          margin-bottom: 1rem;
        }

        .rentalStaffMembers {
          width: 50%;
          margin-bottom: 2rem;
        }

        .check > label {
          color: #0e2c44;
        }

        .showTimeInOut label {
          color: $lightgrey;
        }
      }
    }

    .btnLink {
      svg {
        margin: 0;
      }
    }
    .drawer {
      .btnLink {
        svg {
          margin: -4px 0 0 5px;
        }
      }
    }
  }

  .rentalDates {
    @include breakpoint($tablet) {
      padding-bottom: 17px;
    }

    & > a {
      position: absolute;
      top: 13px;
      right: 10px;
      font-size: 10px;
      line-height: 14px;
      @include breakpoint($tablet) {
        right: 20px;
      }
    }
    & > div {
      border-top: 1px solid $meddarkgrey;

      &:first-of-type {
        border: none;
      }
    }
    .check {
      display: inline-block;
      line-height: 40px;

      & + label {
        margin-left: 5px;
      }

      @media screen and (max-width: 769px) {
        height: 36px;
        padding-bottom: 2px;
        padding-top: 2px;
      }

      @media screen and (max-width: 769px) {
        & + label {
          vertical-align: text-top;
        }
      }
    }
    .dateSelection {
      display: grid;
      grid-row-gap: 5px;
      margin-bottom: 20px;
      @include breakpoint($tablet) {
        grid-template-columns: 1fr 1fr;

        .timeZone {
          grid-column: span 2;
          grid-template-columns: 10fr 9fr 1fr;
        }
        textarea {
          grid-column: span 2;
          width: calc(100% - 30px);
        }
      }
    }
  }

  #finalPaymentDaysNum {
    width: 15%;
    margin-right: 10px;
    text-align: center;
  }

  #finalPaymentDaysOption {
    &.unselected {
      color: $darkgrey;
    }
    width: 30%;
    margin-left: 10px;
  }

  #rentalAutoBill {
    .selectField {
      display: flex;
      width: 100%;
      padding: 5px 0;

      :nth-child(1) {
        float: left;
        width: 40%;
        color: $blue;
      }
      :nth-child(2) {
        float: right;
        width: 60%;
      }
    }
  }

  .radioListBold {
    .radio label {
      font-weight: bold;
    }

    .radio {
      margin-bottom: 5px;
    }

    .radio:first-child {
      margin-top: 15px;
    }

    .radio:last-child {
      margin-bottom: 0px;
    }
  }

  #rentalPaymentNotifications {
    select {
      width: 30%;
      margin: 10px 0;
    }

    .radio label {
      font-weight: bold;
    }

    .option {
      display: inline-block;
      margin-bottom: 10px;
      width: 100%;

      .check {
        float: left;
      }

      input[type='number'] {
        width: 50px;
        text-align: center;
        margin: 0 15px;
      }
    }

    p {
      width: 80%;
      text-align: center;
      margin: 15px auto 2px;
      color: #7d7d7d;
    }
  }

  .dateSelectionOver {
    grid-template-columns: 1fr !important;
    @include breakpoint($tablet) {
      grid-template-columns: 1fr !important;
    }
  }

  .customerSelection {
    .companyList {
      & > table {
        border-bottom: 1px solid $meddarkgrey !important;
        margin-bottom: 25px;
        tr {
          td {
            border-top: none;
          }
        }
      }

      .editCompanyContacts {
        color: $blue !important;
        text-decoration: underline;
        display: inline-block;
        margin: 9px 0px;
      }
    }
    @include breakpoint($tablet) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
    }
  }

  .delivery {
    .shipping {
      margin-bottom: 5px;
      margin-top: 15px;
    }
    .deliverReturn {
      @include breakpoint($phone $tablet) {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-gap: 10px;

        input {
          grid-column: 2;
        }
      }
      display: flex;
      grid-column: span 4;
      justify-content: space-around;
    }
    .viewToggle {
      height: 70px;
      margin-top: -20px;
      div > div > div:first-child {
        margin-left: 0px !important;
      }
      .h4 {
        display: inline;
      }
      .label {
        margin-left: -200px;
        margin-bottom: 0px;
        margin-top: 70px;
      }
    }
  }

  .customersList {
    & > div {
      display: grid;
      grid-template-columns: 7fr 1fr;
      padding: 7px 0 3px;
      border-top: 1px solid $meddarkgrey;

      label {
        grid-column: 1;

        svg {
          height: 15px;
          width: 16px;
          margin-right: 5px;
          vertical-align: middle;
          fill: $blue;
        }
      }
      div {
        grid-column: 1;

        .check {
          display: inline-block;
        }
        .info {
          float: none;
          margin-left: 5px;
        }
      }
      .btnLink {
        grid-column: 2;
        grid-row: 1 / span 2;
        place-self: center end;
        float: none;
        margin: 0;
      }

      &:last-of-type {
        margin-bottom: 10px;
      }
    }
  }
  .delivery {
    .radioList {
      margin: 10px 0;

      select {
        width: calc(100% - 22px);
        margin-left: 22px;
      }
    }
    .actions {
      span {
        grid-column: span 2;
        align-self: center;
        font-weight: $bold;
      }
      .btn + span {
        grid-column: span 1;
      }
      input[type='text'] {
        min-width: 0;
        margin: 0;
      }
    }
    .taxLink {
      display: block;
      margin-top: 15px;
      border-top: 1px solid $meddarkgrey;
      line-height: 40px;
      text-decoration: underline;
    }
  }

  .rentalInventory,
  .rentalServices {
    .emptyDragBox {
      display: block !important;
      width: 100% !important;
      margin-left: 0px !important;
    }
    section {
      & > div {
        margin-bottom: 20px;
      }

      &.noItems {
        overflow: auto;
        cursor: pointer;

        div {
          .btnLink {
            position: absolute;
            color: $darkblue;
            white-space: nowrap;

            svg {
              margin-left: 5px;
            }
          }

          &#add-inventory-buttons {
            width: 250px;
            margin-top: 60px;
            align-items: center;
            span {
              vertical-align: top;
            }

            .btn {
              width: 200px;
              margin-bottom: 20px;
              padding: 0 20px;
              vertical-align: top;

              svg {
                padding: 3px;
                height: 100%;
                fill: $green;
              }
            }
          }
          &#icon {
            position: relative;
            height: 170px;
            width: 170px;
            background-color: $darkblue;
            @include border-radius(50%);
            @include breakpoint($tablet) {
              height: 170px;
              width: 170px;
            }
            & > svg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              height: 40px;
              width: 40px;
              fill: $blue;
            }
          }
        }
      }
    }
  }
  .rentalInventory {
    & > div > .btn {
      width: 100%;
      margin: 0 0 5px;
      @include breakpoint($tablet) {
        width: calc(#{span(2 of 3)} - 5px);
        margin: 0 5px 20px 0;
      }

      &.outline {
        margin: 0 0 20px;
        @include breakpoint($tablet) {
          width: span(1 of 3);
        }
      }
    }

    td label.edited {
      color: gray;
      margin-left: 5px;
      font-style: italic;
    }

    section {
      &.noItems {
        div {
          .btnLink {
            top: -40px;
            left: 50%;
            transform: translate(-50%, 0);
            @include breakpoint($tablet) {
              top: 50%;
              left: auto;
              right: 190px;
              transform: translate(0, -50%);
            }
          }
          &#add-inventory-buttons {
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            @include breakpoint($tablet) {
              margin-left: 0;
              margin-right: 0;
              display: inline-block;
              float: left;
              text-align: left;
            }
          }
          &#icon {
            margin-left: auto;
            margin-right: auto;
            @include breakpoint($tablet) {
              display: inline-block;
              float: left;
              margin: 25px 30px 15px 20%;
            }
          }
        }
      }

      .emptyItemsInDefaultSection {
        border: 1px dashed #c1cdd7;
        padding: 15px;

        p {
          color: #7194ac;
          font-size: 10px;
          letter-spacing: 0;
          line-height: 14px;
          width: 100%;
          text-align: center;
          margin-bottom: 0px;
        }
      }
    }

    .sectionHeader {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 500px) {
        display: block;
      }

      .sectionHeaderName {
        font-size: 12px;
        width: 50%;
        line-height: 20px;
        font-weight: $bold;
        color: $blue;
      }

      .button-actions {
        .btn {
          width: 135px;
        }
        svg {
          fill: #54c06e;
          height: 15px;
          width: 15px;
        }
      }
    }
  }
  .rentalServices {
    section {
      .grid {
        thead {
          tr {
            th {
              &.med {
                width: 120px;
                @include breakpoint($tablet) {
                  width: 120px;
                }
              }

              border-bottom: 1px solid $blue;

              .check {
                margin-right: 4px;
                display: inline-block;
              }
            }
          }
        }

        tbody {
          tr:first-child {
            td {
              border-top: 0px;
            }
          }
        }
      }

      .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        height: 40px;
        width: 100%;
        background-color: $meddarkgrey;
        margin: 0px;
        padding-left: 20px;
        &.headerV2 {
          @media (max-width: 769px) {
            display: block;
            height: auto;
            padding: 10px 0px 10px 20px;
          }
        }

        .leftContainer {
          display: flex;
          align-items: center;
          .title {
            font-weight: bold;
          }

          input {
            font-weight: bold;
            color: $blue;
            width: 290px;
          }

          p {
            margin: 0px;
          }
        }

        .rightContainer {
          display: flex;
          align-items: center;

          .btn.add {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 100px;

            svg {
              fill: #54c06e;
              width: 15px;
              height: 15px;
            }

            span {
              padding-left: 5px;
            }
          }

          .arrowIcon {
            fill: $blue;
            margin: 0px 10px 0px 6px;
          }
        }
      }
    }

    & > div > .btn {
      width: 100%;
      margin: 0 0 20px;
    }

    .service {
      border-top: 1px solid $meddarkgrey;

      .role {
        @include breakpoint($tablet) {
          display: grid;
          grid-template-columns: 30px 180px 1fr 25px;
        }

        & > * {
          grid-row: 1;
        }
        .btnLink {
          grid-column: 4;
        }
        & > label,
        & > span {
          line-height: 40px;
        }
        & > span {
          font-weight: $bold;
        }
        .pricing {
          margin: 5px 0;
        }
      }
      .staffMembers {
        .rentalStaffMember {
          &:not(:first-child) {
            border-top: 1px solid #466b88;
          }
          display: flex;
          flex-direction: column;
          margin-top: 10px;

          label {
            color: $lightgrey;
          }

          .information {
            margin-top: 11px;

            .btnExpand {
              margin-top: 5px;
            }
          }

          .times .dateRange {
            margin-bottom: 1rem;
          }
        }

        .staffMember {
          line-height: 2.2rem;
          border-top: 1px solid #466b88;
          @include breakpoint($tablet) {
            display: grid;
            grid-template-columns: 180px 1fr 25px;
          }

          & > * {
            grid-row: 1;
          }

          & > label {
            color: $lightgrey;
          }

          .btnLink {
            grid-column: 3;
            margin-top: 5px;
          }
        }
      }
      .dateRange {
        margin-bottom: 2em;
        .subHeading {
          display: none;
        }
      }
      .toggle {
        @include breakpoint($tablet) {
          width: calc(100% - 30px);
        }
      }

      .itemNotes {
        .heading {
          color: $darkgrey;
        }
        .dateRange.error {
          padding: 2px;
          border-radius: 2px;
        }
        .errorText {
          color: $red !important;
        }
      }
      .textModalMultipleService {
        color: $blue;
        height: 16px;
        width: 107px;
        font-size: 14px;
        flex-grow: 4;
        margin-left: 4px;
      }

      .containerTitleModalMultipleService {
        width: 259px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 7px 0px 10px 0px !important;
      }
    }

    .noItems {
      div {
        margin: 60px auto 15px;
        @include breakpoint($tablet) {
          margin: 25px auto 15px;
        }

        .btnLink {
          top: -40px;
          left: 50%;
          transform: translate(-50%, 0);
          @include breakpoint($tablet) {
            top: 50%;
            left: -100%;
            transform: translate(0, -50%);
          }
        }
      }
    }
  }

  .dragAndDropShaddow {
    filter: brightness(80%);
    transition: filter 0.2s ease-in-out;
  }

  .rentalPricing {
    min-width: 25em;
    &.rentalPricingV2{
      @media(max-width: 769px) {
        min-width: 0;
        padding-top: 10px;
      }
    }

    span {
      display: flex;
    }

    h4 span a {
      float: initial;
      margin-left: 5px;
      width: 7em;
    }
  }

  .rentalStaff {
    .header {
      label {
        display: inline-block;
        width: calc(100% - 60px);
        font-weight: $regular;
        color: $darkblue;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      span {
        position: absolute;
        right: 68px;
      }
      .btnLink {
        margin-right: 3px;
      }
    }
    .content {
      margin-bottom: 20px;
      @include breakpoint($tablet) {
        margin-left: 30px;
      }

      .dateRange {
        margin-bottom: 5px;
      }
    }
    .staffMember {
      & > label {
        line-height: 40px;
      }
      .btnLink {
        margin: 10px 16px 0 0;
      }
    }
  }

  .rentalFee {
    .fields {
      grid-template-columns: 2fr 2fr 3fr 1fr;
    }
  }

  .taxes {
    & > span {
      position: absolute;
      top: 10px;
      right: 20px;
      font-weight: bold;
      color: $blue;
    }
  }

  .formFooter {
    @include breakpoint($tablet) {
      & > div {
        display: flex;
        max-width: 1020px;
        margin: 0 auto;
      }
    }

    .runningTotal {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      height: $mobileVertical;
      padding: 0 20px;
      background-color: $navyblue;
      text-align: left;
      @include breakpoint($tablet) {
        position: relative;
        height: auto;
        width: 335px;
        margin-left: auto;
        border-right: 1px solid $blue;
      }
      &.runningTotalV2{
        @media(max-width: 769px){
          position: absolute;
        }

        .billing {
          bottom: 70px;

          .bottom-35 {
            bottom: 35px;
          }
        }
      }

      .total {
        display: flex;
        padding: 12px 0;
        @include breakpoint($tablet) {
          padding: 6px 0;
        }

        label {
          color: $blue;
        }
        span {
          margin-left: auto;
          font-size: 22px;
          color: $meddarkgrey;
        }
        svg {
          margin: 4px 0 0 5px;
          fill: $blue;
        }
      }

      .billing {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 45px;
        padding: 10px 20px 0;
        margin: 0;
        background-color: $navyblue;
        z-index: 9999;
        @include breakpoint($tablet) {
          bottom: 33px;
        }
        label {
          color: $darkgrey;
        }
        span {
          color: $white;
        }

        .charges {
          margin-bottom: 0;
          border-bottom: 3px solid $blue;

          & > div {
            border-top: 1px solid $cobalt;

            &:first-of-type():not(.total) {
              border-top: 1px solid transparent;
            }
          }
          .subtotal {
            padding: 11px 0 9px;
            border-top: 1px solid $blue;
            @include breakpoint($tablet) {
              padding: 13px 0 11px;
            }
          }
          .btn {
            place-self: center end;
            height: 25px;
            width: 115px;
            padding: 0;
            margin: -4px 0 0;
            line-height: 25px;
          }
        }
      }
    }

    .actions {
      grid-template-columns: repeat(5, 1fr);
      width: 400px;
      @include breakpoint($tablet) {
        margin: 0 auto 0 20px;
      }

      @media (max-width: 769px) {
        grid-template-columns: repeat(4, 1fr);
      }

      &.actionsV2 {
        @media (max-width: 769px) {
          grid-template-columns: none;
          width: 100%;
        }
      }
      .change {
        grid-column: span 1;
      }
    }
  }

  .deleteRental {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .itemDelete {
    float: left;
    @include breakpoint($tablet) {
      float: right;
      width: auto !important;
      margin: 0 !important;
    }

    .btnLink.delete {
      height: auto;
      width: 100px;
      margin: 0;
      @include breakpoint($tablet) {
        padding: 0;
        margin: 3px 5px 0 0;
      }

      svg {
        @include breakpoint($tablet) {
          position: static;
          transform: none;
        }
      }
    }
  }
}

.upgrade-form-modal {
  background-color: #278ada;
}

.inventoryModal {
  @include breakpoint($phone $tablet) {
    .content h3 .headerNav {
      padding: 0;
      margin: 0 0 10px;
      border: none;
    }
  }

  div {
    .content {
      max-width: none !important;
      min-width: 80vw !important;

      .filter {
        select[name='query_type'] {
          width: auto;
        }
      }
    }
  }

  .grid {
    @include breakpoint($phone $tablet) {
      .inventoryTr {
        display: grid;
        grid-template-rows: 120px 50px;
        grid-template-columns: 150px 150px;
        border-top: 1px solid $meddarkgrey;

        td {
          border-top: none;
        }

        .itemName {
          grid-row: 2;
          grid-column: 1 / span 3;

          .caption {
            display: none !important;
          }
        }

        .sm {
          width: 100%;

          input[type='number'] {
            width: 90px !important;
            margin-left: 10px;
            text-align: center;
            font-size: 20px;
          }

          .caption {
            font-size: 20px;
          }
        }
      }
    }

    .rTableRow,
    tr {
      .rTableHead,
      th,
      .rTableCell,
      td {
        &.height-30 {
          height: 35px;
        }

        &.without-border {
          border-top: none !important;
        }

        &.border-top-black {
          border-top: 2px solid $midblue;
        }

        &.orange-color {
          color: $orange;
          font-weight: bold;
        }

        .orange-background {
          background-color: $orange !important;
          background-image: url(svg-encode(alert-icon()));
          background-repeat: no-repeat;
          background-position: right 22px center;
        }

        .margin-items {
          margin-left: 24px;
          margin-right: 8px;
        }

        &.padding-left-6 {
          padding-left: 6%;
        }

        &:first-of-type() {
          .caption {
            display: none;

            @include breakpoint($tablet) {
              width: 50px;
              margin-right: 10px;
              display: inline-block;
              width: 60px;
              margin: 0;
              color: $blue;
            }
          }
        }
        &.medSm {
          width: 100px;
          @include breakpoint($tablet) {
            width: 90px;
          }
        }
      }
    }

    &.thumbnail {
      .rTableRow .rTableCell,
      tr td {
        &:nth-of-type(2) {
          .caption {
            position: static;
            display: block;
            color: $blue;
            @include breakpoint($tablet) {
              position: absolute;
              top: 7px;
              bottom: auto;
            }
          }
        }
        &:last-of-type() {
          .caption {
            position: static;
            margin-left: 5px;
          }
        }
      }
    }
  }
}

.splitForm {
  max-height: 65vh;
  overflow-y: scroll;

  .splitInputs {
    grid-column: 1 / span 3;
    display: flex;

    .splitItemName {
      display: inline-block;
      padding-left: 10px;
      width: 50%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.salesTaxForm {
  input[type='text'] {
    margin: 10px 0 !important;

    &.lg {
      width: 100%;
    }
  }
  .salesTaxInputs {
    grid-column: span 4;
  }
}
.additionalTax {
  display: flex !important;
  flex-direction: row;

  .additionalTaxCheckBox {
    width: 18% !important;
  }
  .additionalTaxSelect {
    width: 40% !important;
  }
}

.itemActionButtons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .btnLink {
    svg {
      fill: #466b88;
    }
  }
}

.rowboat {
  &:hover {
    display: none;
  }
}

.editCompanyContactsModal,
.selectContactVenuesModal {
  .check {
    display: block;
    padding-left: 30px;
    margin-bottom: 12px;

    label {
      margin-left: 14px;
    }
  }

  .check:first-of-type {
    padding-left: 0;
  }

  .scrollDiv {
    height: 250px;
    overflow: hidden auto;
  }
}

.selectContactVenuesModal {
  .scrollDiv {
    height: 120px;
  }
}

.employeeRoleModal {
  p {
    margin-bottom: 20px;
  }

  .check {
    margin-right: 7px;
    margin-bottom: 12px;
  }

  .content {
    .actions {
      margin: 5px 0 0 !important;
    }
  }
}

.quantity-caption {
  position: relative !important;
  bottom: 0px !important;
  left: 7px !important;
}

input.isOverbooked {
  border: 2px solid $orange;
}

.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}

.recurrenceContainer {
  display: flex !important;
  margin-bottom: '1em';
}

.multipleServicesAlertModal {
  .alertContainer {
    display: flex;
    align-items: baseline;
    padding-left: 0px !important;
    padding-top: 10px !important;

    span.title {
      color: $blue;
      font-size: 14px !important;
      padding-left: 5px;
    }
  }

  .mb-100 {
    margin-bottom: 100px;
  }
}

.disclaimerCheckbox {
  div {
    margin: 0 !important;
  }

  svg {
    margin-top: 3px !important;
    width: 18px !important;
    height: 18px !important;
  }
}

.disclaimerIsntChecked {
  svg {
    clear: both !important;
    fill: #c1cdd7 !important;
  }
}

.mtDisclaimers {
  margin-top: 1rem;
}

.disclaimerContainer {
  border-top: 1px solid #ced8e3;
  padding-top: 10px;
}

.disclaimerContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.mx-15 {
  margin: 0 15px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.disclaimersList {
  .rowGroup {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #ced8e3;
    padding: 12px 0 4px;

    .row {
      display: flex;
      align-items: center;

      div {
        padding-right: 5px;
        flex: 1;
      }

      div.action {
        flex: 0;
        width: 36px;
      }
    }
  }
}

.barcodeButton {
  background: $midblue;
  border: 0;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
  transition: opacity 4ms;

  &:hover {
    opacity: 0.8;
  }

  .icon {
    margin-left: 3px;
    width: 25px;
    height: 15px;
    fill: $white;

    &:hover {
      fill: $white !important;
    }
  }
}

.displayDiscountRadioGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid $mediumgrey;

  & > p {
    margin: 0;
  }

  label {
    color: $white !important;
  }
}

.subTaxes {
  color: #6a6a6a;
  padding-left: 1em !important;

  label {
    width: 150%;
  }
  p {
    margin: 0px;
  }
}
