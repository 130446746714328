.selectBusiness {
	background: linear-gradient(180deg, $blue 50%, transparent 50%);
	@include breakpoint($tablet) {
		padding: 100px;
	}
	
	.content {
		@include breakpoint($tablet) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 20px;
			max-width: 680px;
			margin-left: auto;
			margin-right: auto;
		}
		
		h1 {
			color: $white;
			text-align: center;
			@include breakpoint($tablet) {
				grid-column: span 2;
			}
		}
		section {
			figure {
				@include maintain-aspect-ratio(16, 9);
				margin-bottom: 20px;
			}
			h2 {
				line-height: 24px;
				color: $darkblue;
			}
			.btn {
				width: 100%;
				margin: 10px 0 20px;
			}
		}
	}
}