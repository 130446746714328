.menuLogo {
  display: inline-block;
  height: 25px;
  width: 150px;
  margin-top: 9px;
  background: transparent image("logos/logo_white.png") no-repeat no-repeat 0 0;
  background-size: 150px 25px;
  border: none;
  text-indent: -10000px;
}

@include breakpoint($desktopShort) {
  .adminLgMenu {
    height: 70vh;
    overflow-y: scroll;
  }
}

.mainMenu {
  display: flex;
  height: 40px;
  max-width: 1200px;
  margin: 0 auto;

  &>a {
    display: inline-block;
    max-width: 300px;
    padding: 11px 0 6px;
    font-size: 12px;
    color: $white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.menuLogo {
      padding: 0;
    }
  }

  .companySelect {
    position: relative;
    display: inline-block;
    height: 40px;
    padding-right: 20px;

    &>a {
      .badge {
        margin-top: -3px;
      }

      display: inline-flex;
      max-width: 300px;
      padding-top: 11px;
      font-size: 12px;
      color: $white;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &>svg {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      fill: $white;
    }

    .subMenu {
      position: absolute;
      left: 0;
      top: 40px;

      .searchInput {
        background: #1E3E58;
        background-image: image('icons/icon_Search.png');
        background-image: url(svg-encode(search-icon(white)));
        background-repeat: no-repeat;
        background-position: left 9px center;
        background-size: 12px 12px;
        margin-left: 7px;
        color: $white;
      }

      .scrollarea {
        max-height: 300px;
        width: 250px;
        background-color: transparent;
        overflow-y: auto;

        .scrollarea-content {
          width: 250px;
          background-color: transparent;
          overflow-y: auto;
        }

        .scrollbar-container {
          background-color: $darkblue;

          .scrollbar {
            background-color: #355a78;
          }
        }
      }
    }
  }

  &>ul {
    margin-left: auto;

    &>li {
      position: relative;
      float: left;
      height: 40px;
      width: 85px;

      &.disabled {
        align-items: center;
        color: #cae0f2;
        cursor: not-allowed;
        display: flex;
        filter: opacity(0.3);
        justify-content: center;
        user-select: none;
      }

      &>a {
        display: block;
        height: 40px;
        padding: 11px 0 6px;
        border-bottom: 5px solid transparent;
        color: #cae0f2;
        text-align: center;
        cursor: pointer;

        &.selected {
          border-bottom: 5px solid $white;
          color: $white;
        }
      }

      &:hover>a {
        border-bottom: 5px solid $darkblue;
        color: $white;
      }

      .subMenu {
        position: absolute;
        left: -170px;
        top: 40px;
      }

      &.offset2 {
        &.col2 .subMenu {
          left: -255px;
        }

        &.col3 .subMenu {
          left: -425px;
        }
      }

      &.offset3 {
        &.col3 .subMenu {
          left: -340px;
        }
      }

      &.offset4 {
        &.col3 .subMenu {
          left: -255px;
        }
      }
    }

    .convMenu {
      .callout {
        position: absolute;
        top: 5px;
        left: 50%;
        display: inline-block;
        height: auto;
        width: 20px;
        padding: 3px 8px 2px !important;
        @include border-radius(8px);
        font-size: 10px !important;
        font-weight: $light;
        line-height: 10px !important;
        background-color: $darkblue !important;
        color: $white !important;
        text-align: center;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 15px;
        width: 24px;
        fill: $white;
      }
    }

    .profileMenu {
      &>.imageWrapper {
        position: absolute;
        top: 50%;
        left: 17px;
        transform: translate(0, -50%);
        height: 30px;
        width: 30px;
        @include border-radius(50%);
        overflow: hidden;

        img {
          height: 30px;
          width: 30px;
        }

        div {
          display: inline-block;
          height: 30px;
          width: 30px;
          padding: 10px 0;
          font-size: 10px;
          line-height: 10px;
          color: $blue;
          background-color: $darkblue;
          text-align: center;
        }
      }

      &>svg {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translate(0, -50%);
        height: 8px;
        width: 12px;
        fill: white;
      }

      .subMenu {
        left: auto;
        right: 0;
      }
    }

    .adminSignOut {
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 24px;
        width: 25px;
        fill: white;
      }

      .subMenu {
        left: auto;
        right: 0;
      }
    }
  }
}

.subMenu {
  margin-left: -2px;
  background-color: $darkblue;
  border-left: 3px solid #355a78;
  border-right: 3px solid #355a78;
  border-bottom: 3px solid #355a78;

  &>div {
    display: flex;

    &:nth-of-type(n + 2) {
      border-top: 1px solid #355a78;
    }

    div {
      width: 169px;
      background-color: $navyblue;

      h4 {
        padding-left: 10px;
        margin: 0;
        line-height: 40px;
        color: $white;
      }

      ul {
        width: 100%;
      }
    }

    ul {
      width: 170px;

      li {
        a {
          display: block;
          padding-left: 10px;
          line-height: 40px;
          color: $darkgrey;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          cursor: pointer;

          svg {
            height: 14px !important;
            width: 15px !important;
            margin-right: 5px;
            fill: $blue;
            vertical-align: sub;
          }

          &.add {
            svg {
              fill: $green;
            }
          }

          &:hover {
            background-color: $navyblue;
            color: $white;
          }
        }
      }
    }
  }
}

.mobileMenu {
  padding: 0 0 0 15px !important;
  box-shadow: none !important;

  &>div:nth-child(1) {
    height: $mobileVertical !important;
    line-height: 40px !important;

    .menuLogo {
      margin-top: 11px !important;
    }

    .menuCompany {
      font-size: 16px;
      color: white;
    }
  }

  &>div:nth-child(2) {
    height: $mobileVertical !important;
    margin: 0 !important;

    a {
      display: inline-block;
      padding: 4px 15px;
      margin: 6px 5px 0 0;
      border: 2px solid $white;
      @include border-radius(2px);
      font-size: 12px;
      color: $white;
      text-transform: uppercase;
    }

    svg {
      height: 45px !important;
      width: 47px !important;
      padding: 15px;
      fill: white;
    }
  }
}

.mobileMenuOverlay {
  height: calc(100vh - #{$mobileVertical});
  background-color: $blue;
  overflow: auto;

  &>div {
    &>ul {
      margin: 30px 0;
      border-top: 1px solid #247dc6;

      &>li {
        position: relative;
        border-bottom: 1px solid #247dc6;

        a {
          display: block;
          padding-left: 15px;
          color: $white;
          font-size: 20px;
          font-weight: $light;
          line-height: 44px;

          &.btnExpand {
            position: absolute;
            top: 0;
            right: 0;
            height: 44px;
            width: 45px;
            padding: 0;
            margin: 0;
            text-align: center;

            svg {
              height: 10px !important;
              width: 15px !important;
              fill: $white;
            }
          }
        }

        &.open {
          background-color: $navyblue;

          a {
            color: $blue;

            &.btnExpand {
              svg {
                fill: $blue;
              }
            }
          }

          li a {
            color: $white;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .callouts {
      padding: 0 0 60px;
      font-size: 10px;

      a {
        position: relative;
        display: block;
        padding: 30px 0 0;
        margin: 0;
        font-weight: $bold;
        color: white;
        opacity: 1;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;

        svg {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);
          height: 25px !important;
          width: 26px !important;
          color: $blue !important;
          fill: white;
        }
      }
    }
  }
}

.subNavWrapper {
  display: none;
  height: 40px;

  @include breakpoint($tablet) {
    display: block;

    &+div {
      height: calc(100% - 40px);
    }
  }

  .subNav {
    position: absolute;
    width: 100%;
    background-color: $navyblue;
    z-index: 29;

    .subNavMenu {
      @include breakpoint($tablet) {
        max-width: 1020px;
        margin: 0 auto;
      }

      a:not(.selected) {
        opacity: 0.5;
      }

      a {
        position: relative;
        display: inline-block;
        padding: 11px 0 6px;
        margin-right: 20px;
        border-bottom: 5px solid transparent;
        color: $darkgrey;
        text-align: center;

        &.selected {
          border-bottom: 5px solid $blue;
        }

        &:hover {
          border-bottom: 5px solid $darkblue;
        }

        .callout {
          position: absolute;
          top: 5px;
          right: 0;
          display: inline-block;
          height: auto;
          padding: 3px 4px 2px;
          @include border-radius(8px);
          font-size: 10px;
          font-weight: $light;
          line-height: 10px;
          background-color: $blue;
          color: $white;
          text-align: center;
        }

        &.selected {
          font-weight: $bold;
          color: $white;
        }
      }
    }
  }
}

.expandMenu {
  position: fixed;
  top: 45px;
  min-height: 100vh;
  margin-left: -10px;
  background-color: $darkblue;
  margin-bottom: -10000px;
  padding-bottom: 10000px;

  .itemList {
    margin-top: 0;

    li {
      padding-left: 12px;
      padding-right: 12px;

      svg {
        height: 20px !important;
        width: 21px !important;
      }

      .alertCallout {
        right: 10px;
        transform: translate(50%, 0);
      }

      &:nth-child(1) {
        border-top: none;
      }
    }
  }

  .expandToggle {
    position: fixed;
    bottom: 5px;
    left: 13px;

    svg {
      height: 20px !important;
      width: 20px !important;
      fill: $blue;
    }
  }

  &.hasTopWarning {
    top: 65px;
  }

  &.open {
    @include span(4 of 8);

    &+.pushContent {
      @include push(4 of 8);
      @include post(-3 of 8);
    }
  }

  &.closed {
    float: left;
    width: span(1 of 8);

    &+.pushContent {
      margin-left: calc(#{span(1 of 8)} - 10px);
      @include post(0 of 8);
    }
  }
}

.pushContent {
  width: calc(#{span(7 of 8)} + 10px);
  padding-left: 10px;
}

.m-t-30 {
  margin-top: 30px;
}

.noBreak {
  width: 90px;
  white-space: nowrap;
}

.blueIcon {
  background-image: url(svg-encode(down-arrow($blue)));
}
