.simpleForm {
  margin-top: 0;
  padding: $vertical 10px;
  background-color: $white;
  text-align: center;
  @include breakpoint($tablet) {
    margin-top: $vertical;
    padding: 0;
    background-color: transparent;
  }

  input[type='text'],
  input[type='email'],
  input[type='password'],
  select,
  textarea {
    width: 100%;
    background-color: $lightgrey;
    @include breakpoint($tablet) {
      background-color: $white;
    }
  }
  textarea {
    resize: vertical;
    height: 90px;
  }
  a:not(.btn) {
    display: block;
    text-decoration: underline;
  }
  .btn {
    width: 100%;
    margin-bottom: 25px;
  }
}
.rmBottom {
  margin-bottom: 0 !important;
}

.form,
.iconForm {
  .fields {
    position: relative;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px;
    margin-bottom: 20px;
    line-height: 30px;

    &.globalEmail {
      grid-template-columns: repeat(6, 1fr);

      & > p {
        grid-column: 1 / span 6;
      }

      & > input[name='paymentNotificationEmails'] {
        grid-column: 1 / span 6;
        background-color: rgb(229, 232, 233);
        border: none;
      }
      h6 {
        color: $darkgrey;
      }
    }

    & > h6 {
      grid-column: span 4;
      color: $blue;
      & > span {
        color: $darkgrey;
      }
    }
    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > p {
      grid-column: span 4;
      margin: 0;
    }
    & > p {
      margin: 6px 0;
      line-height: 18px;
    }
  }

  input[type='text'],
  input[type='number'],
  input[type='password'],
  select,
  textarea,
  .react-datepicker-component,
  .toggle,
  .viewToggle,
  .uploadZone,
  .autocomplete,
  .check,
  .rdw-editor-wrapper,
  .Select,
  .radioGroup,
  .radioList,
  .geosuggest {
    grid-column: span 4;
    min-width: 0;
    margin: 0;

    &.lg {
      grid-column: span 3;

      @include breakpoint($tablet) {
        &.full {
          grid-column: span 4;
          width: calc(#{span(3 of 4)} - 2.5px);
        }
      }
    }

    &.spanTwoSpaces {
      grid-column: span 2 !important;
    }

    &.darken {
      background: #0e2b44 !important;
      color: white;

      &:disabled {
        opacity: 1;
      }

      &::placeholder {
        color: #466b88;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-text-fill-color: white !important;
        transition: background-color 5000s ease-in-out 0s;
        -webkit-box-shadow: 0 0 0px 1000px #0e2b44 inset !important;
      }
    }

    &.blueArrowColor {
      -webkit-appearance: none; /* For Chrome, Safari */
      appearance: none; /* Standard syntax */
      padding: 8px 12px; /* Add padding for text and custom arrow */
      position: relative;
      background-repeat: no-repeat !important;
      background-position: right 4px center !important;

      background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill="%23007BFF" d="M6.292 8.292a1 1 0 000 1.416l4 4a1 1 0 001.416 0l4-4a1 1 0 10-1.416-1.416L11 11.586 7.708 8.292a1 1 0 00-1.416 0z"/></svg>') !important;
      background-size: 24px !important;
    }

    &.defaultValueGrayColor {
      color: #466b88;
    }

    &.med {
      grid-column: span 2;

      @include breakpoint($tablet) {
        &.full {
          grid-column: span 4;
          width: calc(#{span(2 of 4)} - 2.5px);
        }
      }
    }
    &.sm {
      grid-column: span 1;

      @include breakpoint($tablet) {
        &.full {
          grid-column: span 4;
          width: calc(#{span(1 of 4)} - 2.5px);
        }
      }
    }
  }
  .emailButtons {
    background-color: $lightgrey;
  }

  input[type='number'] {
    width: 100%;
  }
  textarea {
    resize: vertical;
    height: 90px;
  }
  .uploadZone {
    line-height: initial;
  }
  & > .full {
    grid-column: span 3;

    &.user {
      width: 60%;
      margin-top: 80px;
    }
  }
  label,
  span {
    align-self: center;

    &.full {
      grid-column: span 3;
    }
    &.lg {
      grid-column: span 2;
    }
    &.med {
      grid-column: span 1;
    }
  }

  @include breakpoint($phone $tablet) {
    input[type='text'],
    input[type='password'] {
      &.med.full {
        grid-column: span 4;
      }
    }
    & > div input[type='text'].med {
      grid-column: span 2;
    }
  }

  &:after {
    display: none;
  }
  &.nogap {
    margin-bottom: 0;
  }
  .counted {
    grid-column: span 4;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px;
  }
  #company_city_input {
    grid-column: span 4;
  }
}

.companyFieldsWrapper {
  overflow-y: auto;
}

.localeFields {
  display: flex;
  flex-direction: row;
  grid-column: span 2;
  grid-gap: 5px;
}

#companyForm {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;
  height: calc(100% - 40px);

  .fields {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px;
    margin-bottom: 20px;
    line-height: 30px;

    &.globalEmail {
      grid-template-columns: repeat(6, 1fr);

      & > p {
        grid-column: 1 / span 6;
      }

      & > input[name='paymentNotificationEmails'] {
        grid-column: 1 / span 6;
        background-color: rgb(229, 232, 233);
        border: none;
      }
      h6 {
        color: $darkgrey;
      }
    }

    & > h6 {
      grid-column: span 4;
      color: $blue;
      & > span {
        color: $darkgrey;
      }
    }
    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > p {
      grid-column: span 4;
      margin: 0;
    }
    & > p {
      margin: 6px 0;
      line-height: 18px;
    }
  }

  input[type='text'],
  input[type='number'],
  input[type='password'],
  select,
  textarea,
  .react-datepicker-component,
  .toggle,
  .viewToggle,
  .uploadZone,
  .autocomplete,
  .check,
  .rdw-editor-wrapper,
  .Select,
  .radioGroup,
  .radioList,
  .geosuggest {
    grid-column: span 4;
    min-width: 0;
    margin: 0;

    &.lg {
      grid-column: span 3;

      @include breakpoint($tablet) {
        &.full {
          grid-column: span 4;
          width: calc(#{span(3 of 4)} - 2.5px);
        }
      }
    }
    &.med {
      grid-column: span 2;

      @include breakpoint($tablet) {
        &.full {
          grid-column: span 4;
          width: calc(#{span(2 of 4)} - 2.5px);
        }
      }
    }
    &.sm {
      grid-column: span 1;

      @include breakpoint($tablet) {
        &.full {
          grid-column: span 4;
          width: calc(#{span(1 of 4)} - 2.5px);
        }
      }
    }
  }
  .emailButtons {
    background-color: $lightgrey;
  }

  input[type='number'] {
    width: 100%;
  }
  textarea {
    resize: vertical;
    height: 90px;
  }
  .uploadZone {
    line-height: initial;
  }
  & > .full {
    grid-column: span 3;

    &.user {
      width: 60%;
      margin-top: 80px;
    }
  }
  label,
  span {
    align-self: center;

    &.full {
      grid-column: span 3;
    }
    &.lg {
      grid-column: span 2;
    }
    &.med {
      grid-column: span 1;
    }
  }

  @include breakpoint($phone $tablet) {
    input[type='text'],
    input[type='password'] {
      &.med.full {
        grid-column: span 4;
      }
    }
    & > div input[type='text'].med {
      grid-column: span 2;
    }
  }

  &:after {
    display: none;
  }
  &.nogap {
    margin-bottom: 0;
  }
  .counted {
    grid-column: span 4;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px;
  }
  .fields {
    grid-column: span 4;
  }
}

.actions {
  grid-gap: 5px;
  &.threeButtons {
    grid-gap: 10px;
  }
}

.iconForm {
  .fields {
    @include breakpoint($tablet) {
      grid-template-columns: 25px repeat(4, 1fr);

      h1,
      h2,
      h3,
      h4 {
        grid-column: 2 / span 4;
      }
    }

    span {
      grid-column: span 2;
    }

    & > svg {
      grid-column: 1;
      place-self: start;
      height: 20px !important;
      width: 20px !important;
      margin-top: 4px;
      fill: $blue;
      @include breakpoint($phone $tablet) {
        display: none !important;
      }
    }
    & > div {
      grid-column: span 4;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 5px;
    }
    .check,
    .react-datepicker-component,
    .geosuggest,
    .tooltip {
      display: inline-block;
    }
    @include breakpoint($tablet) {
      .indent {
        grid-column: 2 / span 4;
      }
    }
    .noIconFull {
      grid-column: span 5;
    }
    .fields {
      grid-column: span 5;
    }
    &.deliveryFees {
      column-gap: 1rem;
    }
  }
}
.iconView {
  .fields {
    position: relative;
    display: grid;
    grid-auto-rows: 40px;
    margin-bottom: 15px;
    line-height: 35px;
    @include breakpoint($tablet) {
      grid-template-columns: 25px 1fr;
      grid-auto-rows: minmax(35px, auto);
      grid-column-gap: 5px;
      line-height: 30px;
    }

    & > svg {
      grid-column: 1;
      place-self: start;
      height: 20px !important;
      width: 20px !important;
      margin-top: 4px;
      fill: $blue;
      @include breakpoint($phone $tablet) {
        display: none !important;
      }
    }
    h2 {
      margin: 0;
      line-height: 35px;
      @include breakpoint($tablet) {
        line-height: 30px;
      }
    }
    a {
      color: $darkblue;

      & > div {
        line-height: 18px;

        &:first-of-type {
          padding-top: 7px;
        }
        &:last-of-type {
          padding-bottom: 7px;
        }
      }
    }
    p {
      padding-top: 7px;
      line-height: 18px;
    }

    &:after {
      display: none;
    }
  }
}

.actions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .btn {
    grid-column: span 2;
    padding: 0 !important;
    &.disabled {
      cursor: not-allowed;
    }
    &.cancel,
    &.secondary {
      grid-column: span 1;
    }
    &.cancel.cancelV2 {
      grid-column: span 2;
    }
    &.full {
      grid-column: span 3;
    }
  }

  &.three {
    grid-template-columns: repeat(4, 1fr);

    .btn.full {
      grid-column: span 4;
    }
  }
}

.customZipCodeDeliveryFees {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  select {
    grid-column: span 2;
  }
  div {
    grid-column: span 2;
    font-weight: 700;
    justify-content: flex-end;
    align-self: center;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    span {
      align-self: flex-end;
    }
    input {
      max-width: 40%;
    }
  }
}

.drawerTitle {
  width: span(9 of 10) !important;
  margin: 0 span(1 of 20) 30px !important;
}

.drawerForm {
  h1 {
    @include push(1 of 20);
  }

  .form,
  .iconForm {
    width: span(9 of 10) !important;
    margin: 0 span(1 of 20) !important;

    .fields {
      input[type='text'],
      input[type='password'],
      select,
      textarea,
      .toggle,
      .autocomplete,
      .check {
        width: 100% !important;
        margin: 0;
      }
      select {
        grid-column: inherit;
        margin: 35px 0 5px 0;
      }
      .fieldSpacing {
        label {
          color: $lightgrey;
        }
      }
    }
  }
  .uploadZone {
    width: 50%;
    margin: 0 30% 40px;
    @include breakpoint($tablet) {
      width: 125px;
      margin: 0 30% 40px;
    }

    .drop {
      @include maintain-aspect-ratio(1, 1);
      @include border-radius(50%);
      overflow: hidden;

      .placeholder {
        svg {
          margin: 45px 0 0;
          @include breakpoint($tablet) {
            margin: 40px 0 0;
          }
        }
      }
    }
  }
}

.fullWidth {
  width: 100% !important;
}

.emailInput {
  grid-column: 1 / span 4;
  background-color: rgb(229, 232, 233);
  border: none;
}

.bulkDeleteConfirm {
  .check {
    padding-bottom: 15px;
  }
}

.urlTooltip {
  display: flex;
  align-items: center;

  a {
    padding-left: 3px;
  }
}

.underneathOption {
  margin-top: 4px;
}

.delivery-time-icons {
  & > div {
    color: #966cb8 !important;
    display: flex;
    flex-wrap: nowrap;
    gap: 3px;
    align-items: center;
    vertical-align: middle;
    svg :not(.morning) {
      width: 19px !important;
      height: 19px !important;
    }
  }

  @media screen and (max-width: 768px) {
    min-height: 50px;
  }

  svg :not(.morning) {
    width: 19px !important;
    height: 19px !important;
    span {
      vertical-align: middle;
    }
  }
}

.noteVisibilityFloat {
  float: right;
}

.syncTermForm {
  .dropDownLabel {
    margin-left: 10px;
  }

  div > select {
    width: 93%;
  }
}

.imageItemsContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 24px;
  padding-right: 10px;
}

.faqDragHandle {
  @extend .dragHandle;

  margin-bottom: -42px;
  z-index: 100;
}

.imageItemWrapper {
  margin-bottom: 8px;

  #storefront-accordian-0 {
    border-top: 0;
  }

  &:hover {
    .faqDragHandle {
      svg {
        fill: $meddarkgrey;
        transition: fill 0.3s ease-in-out;
      }
      &:hover svg {
        fill: $blue;
        transition: fill 0.3s ease-in-out;
      }
    }
  }
}
