.emailSettings {

	.summary:not(.accordian) {
		@include container;
		@include breakpoint($tablet) {
			margin-bottom: 20px;
			border-bottom: 1px solid $meddarkgrey;
			line-height: 18px;
		}

		a {
			text-decoration: underline;
		}


		.viewToggle {
			label span {
				color: $blue;
			}
		}
	}

	.accordian {
		.header {
			padding: 6px 10px;
			line-height: 24px;

			svg {
				height: 25px !important;
				width: 26px !important;
				margin-right: 10px;
				vertical-align: bottom;
			}
		}
		.content {
			& > div {
				@include breakpoint($tablet) {
					display: flex;
					flex-direction: column;
				}

				.description {
					margin-top: 20px;
					@include breakpoint($tablet) {
						padding: 10px 20px 0 0;
					}

					.switch {
						display: flex;
						margin-bottom: 10px;
						& > p {
							@media (min-width: 769px) {
								padding-left: 40px;
							}
							@media (max-width: 769px) {
								padding-left:0px;
								margin-top: 20px;
							}
						}
						.toggleAndLabel {
							display: flex;
							flex-direction: row;
							& > div {
								width: auto !important;
								margin-right: 8px;
							}
							& > label {
								line-height: initial;
								color: $darkgrey;
								@media (min-width: 769px) {
									padding-top: 5px;
									font-size: 9px;
								}
								@media (max-width: 769px) {
									font-size: 13px;
									display: flex;
									align-self: center;
								}
							}
						}
						@media (min-width: 769px) {
							flex-direction: row;
						}
						@media (max-width: 769px) {
							flex-direction: column;
						}
					}
					p {
						color: $blue;
						padding-left: 155px;

						&.noToggle{
							padding-left: 30px;
						}

						a {
							text-decoration: underline;
						}
					}
					.btn {
						width: 100%;
					}
				}
				.details,
				.detailForm {
					display: flex;
					align-content: start;
					margin-top: 10px;
					@include breakpoint($tablet) {
						margin-top: 0;
					}

					.eventTiming {
						line-height: 40px;
						@include breakpoint($tablet) {
						}

						& > svg,
						.check {
							height: 20px !important;
							width: 20px !important;
							margin: 0 30px 0 0;
							vertical-align: middle;
						}
						& > svg {
							color: $blue;
							fill: $white;
						}
						.check {
							line-height: 18px;
							@media (min-width: 769px) {
								margin-left: 50px;
							}

							svg {
								height: 20px !important;
								width: 20px !important;
								color: $white !important;
							}
						}
						& > div {
                        display: inline-block;
						}
						input[type="text"] {
							width: 45px;
							margin-left: 5px;
						}
						span {
							margin-left: 5px;
						}
					}
					.caption {
   						margin: 0;
                        line-height: 30px;
					}
					input[type="text"] {
						margin: 0;
					}
				}
				.details {
					display: flex;
    				flex-direction: column;
                    position: relative;
					@include breakpoint($tablet) {
                        padding: 20px 40px 0 20px;
                    }
					p {
						margin: 5px 0;
					}
					div {
						margin-bottom: 5px;
					}
					.btnIcon{
						margin-left: 50px;
					}
					.btnLink {
						position: absolute;
						top: 20px;
                        right: 20px;
						svg {
							margin: 0;
						}
					}
					.subjectContainer, .noteContainer {
						@media (min-width: 769px) {
							display: flex;
						}
						@media (max-width: 769px) {
							flex-direction: column;
						}
						label {
							width: 40px;
							@media (min-width: 769px) {
								margin-left: 10px;
							}
							@media (max-width: 769px) {
								margin-right: 10px;
							}
						}
						label, p, div {
							align-self: center;
						}
					}
				}
				.detailForm {
					display: flex;
    				flex-direction: column;
					@include breakpoint($tablet) {
						padding: 20px 0 0 20px;
					}
					.actions {
                        margin-top: 10px;
						@include breakpoint($tablet) {
						}
					}
					.editSubjectContainer {
						div {
							width: 100%;
							@media (min-width: 769px) {
								margin-left: 5px;
							}
						}
					}
					.editSubjectContainer, .editNoteContainer {
						display: flex;
						margin-top: 5px;
						@media (max-width: 769px) {
							flex-direction: column;
							margin-right: 10px;
						}
						label {
							width: 50px;
						}
					}
				}
			}
		}

		&.reminder,
		&.expiration,
    &.staffReminder,
    &.recurringPaymentSetup,
		&.thankyou {
			.header svg {
				fill: $pink;
			}
		}
		&.quote,
		&.invoice,
		&.reservation,
		&.connect,
		&.summary {
			.header svg {
				fill: $blue;
			}
		}
		&.picklist,
		&.sigconfirm,
		&.confirmation,
		&.sigpay,
		&.customerWelcome {
			.header svg {
				fill: $green;
			}
		}
		&.nps {
			.header svg {
				fill: $purple;
			}
		}
	}
}

.detailFormOverride {
  grid-template-columns: 50px 1fr !important;
}

.btnCustomEmail {
  margin-top: -70px;
  padding-right: 25px;
}

.pushHeader {
  margin-top: 20px;
}
.actionsOverride {
  padding: 20px 40px 0px 20px;
  display: grid;
  grid-template-columns: 10px 40px 4fr 2fr 30px 1fr;
  margin-top: 5px;
}
.grC3 {
  grid-column: 3;
}
.grc4 {
  grid-column: 4;
}

.grc5 {
  grid-column: 5;
}

.grc6 {
  grid-column: 6;
}

.actionsGrid {
  height: 30px;
  margin-top: 3px;
  grid-column: 2;
  display: grid;
  grid-gap: 5px;
  @media (min-width: 769px) {
	margin-left: 50px;
	grid-template-columns: 1fr 1fr 1fr 30px;
  }
  @media (max-width: 769px) {
	.cancel {
		margin-right: 10px;
	}
  }
  .save {
    grid-column: 1 / span 2;
  }

  .ca {
    grid-column: 3;
  }

  .pr {
    grid-column: 4;
  }
}

.btnEmail {
  background-color: $darkgrey;
  color: $white;
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.customEmail {
  display: grid;
  grid-template-columns: span(8 of 10) span(2 of 10);
  .field1 {
    grid-column: 1;
  }
  .field2 {
    grid-column: 2;
  }
}

.emailStyling {
	@include breakpoint($tablet) {
    width: span(11 of 17);
    .emailColorSelector {
      grid-column: span 3 !important;
    }

    .buttons {
    .radioGroup {
        grid-row-gap: 5px;
        @include breakpoint($tablet) {
          grid-row-gap: 0;
        }
      }
      .radio {
        & > div > div {
          margin-top: 13px !important;
          @include breakpoint($tablet) {
            margin-top: 7px !important;
          }
        }
        label {
          display: inline-block;
          height: $mobileVertical;
          padding: 0;
          background-color: $lightgrey;
          @include border-radius(2px);
          font-size: 11px;
          font-weight: $bold;
          line-height: 45px !important;
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          vertical-align: middle;
          @include breakpoint($tablet) {
            height: 30px;
            font-weight: $regular;
            line-height: 30px !important;
          }
        }

        &:last-of-type() label {
          @include border-radius(23px);
          @include breakpoint($tablet) {
            @include border-radius(15px);
          }
        }
      }
    }
    .btnIcon {
      width: 100%;
      margin: 0;
      background-color: $blue;
      vertical-align: top;

      svg {
        margin: 0;
        fill: $white;
      }
    }
	}

	.videoButton {
		float: right;

		& + .form {
			margin-top: 30px;
		}
	}
	.colors {
		grid-template-columns: 1fr;

		h3 {
			grid-column: 1 / span 4;
    }

    h6 {
      color: $blue;
    }
		.colorSelector {
			position: static;
			border: 1px solid $navyblue;
			@include border-radius(2px);
      color: $white;
      grid-column: 1 / span 4 !important;

			.swatch {
				position: relative;
				height: 28px;
				width: 100%;

				label {
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					font-size: 11px;
					line-height: 28px;
					text-align: center;
				}
			}
    }
	}
}

.emailTemplate {
  display: flex;
  .emailAccordions {
    width: 75%;
	@media (max-width: 769px) {
		width: 100%;
	}
  }
  .emailCustomFields {
	margin-left: 20px;
    h4 {
      font-size: 12px;
      line-height: 18px;
      color: $blue;
      border-bottom: 2px solid $darkgrey;
    }
    p {
      margin: 0;
    }
    .emailCustomizableName {
      margin-bottom: 15px;
    }
    .copyCustomField {
      width: 12px;
      height: 12px;
      margin-left: 5px;

	}
  }
}

.emailPreviewPositon {
  grid-column: 3 !important;
}

.emailCustomFields {
	@media (min-width: 769px) {
    	margin-left: 20px;
	}
    h4 {
      font-size: 12px;
      line-height: 18px;
      color: $blue;
      border-bottom: 2px solid $darkgrey;
    }
    p {
      margin: 0;
    }
    .emailCustomizableName {
      margin-bottom: 15px;
        .copyButton {
          width: 12px;
          height: 12px;
            fill: $blue;
            margin-left: 10px;
        }
      }
    .copyCustomField {
      width: 12px;
      height: 12px;
      margin-left: 5px;

    }
	div {
		@media (max-width: 769px) {
		  display: flex;
		  p {
			  margin-right: 5px;
		  }
		}
	}
  }

.email_summary {
  .btn {
    margin-bottom: 25px;
  }
}

.emailGlobal {
	.fields {
		margin-bottom: 0;
	}
	.indent {
		padding-left: 20px;
		@include breakpoint($tablet) {
			padding-left: 30px;
		}
	}
}

.eventTiming .specialCheck .check {
  margin-right: 5px !important;
}

.blueh6 {
  color: $blue !important
}

.emailInfoList{
	list-style: unset;
	list-style-position: inside;
	margin: 20px;
	li{
		margin: 5px 0;
	}
}

.emailAccordionHeader{
	display: flex;
	align-items: center;

	label{
		color: black !important;
	}

	svg{
		fill: $blue;
		margin-right: 10px;
	}
}
input.subjectField {
	width: 65%;
}

.smallTxt {
	font-size: 9px;
}
