.featureTitle {
	top: $vertical !important;
	height: 450px !important;
	padding: 0 !important;
	@include bkgd-gradient(180deg, $orange, #FFAE6D);
	@include breakpoint($tablet) {
		top: $vertical !important;
		height: 520px !important;
		padding: 0 !important;
	}

	h1 {
		padding: 130px 180px 212px 40px;
		background-image: url('../../../images/marketing/features_bg.jpg');
		background-repeat: no-repeat;
		background-position: -360px top;
		font-size: 36px;
		font-weight: $light;
		line-height: 36px;
		color: $white;
		white-space: inherit !important;
		@include breakpoint($tablet) {
			padding: 242px 0;
			background-position: center top;
			font-size: 48px;
		}
	}
}

.features {
	.featureList {
		display: flex;
		flex-wrap: wrap;
		margin-top: -120px;
		@include breakpoint($tablet) {
			margin-top: 0;
		}

		div {
			min-height: 250px;
			width: 320px;
			padding: 40px 20px 20px;
			margin: 0 10px 20px;
			@include border-radius(6px);
			background-color: white;
			font-size: 20px;
			font-weight: $light;
			line-height: 29px;
			@include breakpoint($tablet) {
				min-height: 230px;
				width: 245px;
				margin: 0 5px 50px;
				font-size: 18px;
				line-height: 27px;
			}

			span {
				display: block;
				margin: 0 auto 25px;
				font-size: 14px;
				line-height: 21px;
				color: $orange;
				text-align: center;
				text-transform: uppercase;
				@include breakpoint($tablet) {
					font-size: 9px;
					line-height: 13px;
				}
			}
		}
	}
}
