.customersListDrawer {
  z-index: 110000 !important; // Needs to be in front of Help & Tutorials slideout
  .customersSearchList {
    .customerItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 95%;
    }

    .editLink {
      border-left: none !important;
      margin-top: 0px !important;
      display: flex;
    }

    .hideMe {
      display: none;
    }
  }
}