.itemsTable {
  .title {
    margin-left: -20px;
    margin-top: -10px;
  }
  .textColor {
    color: #7194ac;
  }
  .div6 {
    padding-left: 18px;
  }
  .div7 {
    width: 51px;
  }
  .div8 {
    width: 34px;
  }
  .div9 {
    width: 10px;
  }
  .div2 {
    color: #7194ac;
    text-align: left;
    .div4 {
      padding-right: 40px;
    }
    .div5 {
      padding-right: 360px;
    }
  }

  .div1 {
    padding-right: 6px;
    text-align: center;
  }

  .div3 {
    padding-right: 5px;
    text-align: center;
  }

  .div6 {
    padding-right: 260px;
  }

  .itemName {
    color: #0e2c44;
    margin-top: 30px;
  }

  .itemName2 {
    color: #278ada;
    margin-top: 37px;
  }

  .itemName3 {
    color: #278ada;
  }

  .itemName4 {
    color: #0e2c44;
  }
}
