.workOrders {
  grid-column: 1 / span 2;
  margin-top: 12px;

  h4 {
    color: $darkblue;
  }





  .formHeader {
    background-color: #c1cdd7;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 5px;
    margin-bottom: 5px;

    section {
      background-color: #C1CDD7; width: 725px; height: 40px
    }

    h4 {
      font-weight: bold;
      margin-bottom: 0;
    }

    .buttons {
      a:nth-child(1) {
        width: 80px;

        @include breakpoint($tablet) {
          width: 165px;
        }
      }

      a:nth-child(2) {
        width: 60px;
        color: $darkblue;
        
        @include breakpoint($tablet) {
          width: 80px;
        }
      }
    }

  }

  .btnIcon.add {
    &:hover {
      color: $blue;
    }
  }

  .editForm {

    .span1 {
      display: flex; 
      flex-direction: column;
      margin-top: 25px;

      @include breakpoint($tablet) {
        flex-direction: row;
        align-items: start;
        justify-content: space-between;
      }
    }

    .span2 {
      margin-bottom: 25px;

      @include breakpoint($tablet) {
        margin-bottom: 0;
      }
    }

    h4 {
      display: inline-block;
      align-items: center;
      color: black;
      margin: 0px
    }

    hr {
      margin-bottom: 8px; 
      background-color: #1B4160;
    }

    textarea {
      height: 75px;
      width: 100%;

      @include breakpoint($tablet) {
        width: 395px;
      }
    }

    input {
      width: 234px;
      height: 25px;
    }

    .dropdown {
      width: 165px;
      height: 25px;
      margin-right: 4px;
    }

    .smallInput {
      width: 65px;
      height: 25px;
    }

    .firstSection {

      @include breakpoint($tablet) {
        display: grid;
        grid-template-columns: 2fr 1fr;
        justify-content: space-between;
        align-items: start;
      }

      .vendorSection {
        margin-top: 33px;
        display: inline-block;

        h4 {
          margin: 2px 0 5px;
          border-bottom: 1px solid $midblue;
          width: 390px;
        }

        .toggle {
          margin-bottom: 10px;
          width: 390px;
          height: 30px;
          margin-top: 0px;
        }

        .full {
          width: 390px;
          height: 30px;
        }

        .medium {
          width: 175px;
          height: 30px;
        }

        .mr-5 {
          margin-right: 5px;
          height: 30px;
        }

        .med {
          width: 140px;
          height: 30px;
        }

        .small {
          width: 65px;
          height: 30px;
        }

        .vendorsSlideOut {

          .firstSlideOutHeadingArea {

            margin-bottom: 104px;
            margin-top: 45px;

            p {
              color: #8FA9BE;
              font-size: 18px;
              margin-left: 20px;
              margin-right: 73px;
              line-height: 24px;
              font-weight: normal;
            }

            a {
              margin-top: 0px !important;
              color: #7194AC;
              font-size: 12px;
              line-height: 18px;
              font-weight: normal;
            }
          }

        }

        .secondSlideOutHeadingArea {

          margin-bottom: 70px !important;
          margin-top: 47px !important;

          p {

            color: #8FA9BE !important;
            font-size: 18px !important;
            margin-bottom: 0px !important;
            margin-left: 20px !important;
          }
        }

        .vendorCreateForm {

          .stateArea {
            display: flex;

            select {
              margin-top: 0px !important;
              width: 500px !important;
            }
          }

          .midFields {
            width: 192px !important;
          }

          .actions {
            margin-left: 0px;
            margin-top: 223px !important;

            input {
              width: 215px !important;
              margin-right: 2px;
            }
            a {
              margin-left: 14px !important;
              width: 80px !important;
            }
          }
        }

      }

      .topRight {
        margin-top: 69px;
        display: grid;
        grid-template-columns: 3fr 4fr;
        align-items: center;

        .gridLabel {
          font-size: 10px;
          line-height: 14px;
          color: #7194ac;

        }

        span {
          font-size: 9px;
        }

        .react-datepicker-component {
          margin-bottom: 5px;
        }

        .small1 {
          width: 140px;
          height: 30px;
        }

        .small2 {
          width: 140px;
          height: 30px;
          margin-top: -8px;
        }
      }
    }

    .lineItemTable {
      width: 100%;
      margin: 34px 0px 17px;
      

      div:nth-child(1) {
        border-bottom: 1px solid $midblue;
        margin-bottom: 10px;
      }

      .lineItemRow .lastItem {
        padding-bottom: 9px;
        border-bottom: 1px solid #c3d8e3;
      }

      .lineItemRow {
        display: grid;
        grid-template-columns: 6fr 2fr 4fr 3.5fr;
        width: 100%;
        align-items: center;
        padding-bottom: 5px;

        @include breakpoint($tablet) {
          grid-template-columns: 10fr 2fr 4fr 3.5fr;
        }

        .firstColumn {
          width: 100%;
          height: 25px;

          @include breakpoint($tablet) {
            margin-right: 30px;
          }
        }

        .secondColumn {
          height: 25px;
          width: 30px;

          @include breakpoint($tablet) {
            width: 50px;
          }
        }

        &.lastItem {
          padding-bottom: 9px;
          border-bottom: 1px solid #c3d8e3;
        }

        .cancelButton {
          height: 30px;
          width: 80px;
          background-color:'#8FA9BE';
        }

        .btnIcon {
          height: 30px;

          @include breakpoint($tablet) {
            width: 205px;
          }
        }

        h4 {
          font-size: 12px;
          line-height: 18px;
          color: $darkblue;
          justify-self: center;
          margin-bottom: 0;
        }

        .amountColumn {
          justify-self: center;
          text-align: center;
        }

        input, select {
          margin-bottom: 0px;
        }

        .rateColumn {
          display: flex;
          flex-direction: column;
          align-items: center;

          @include breakpoint($tablet) {
            flex-direction: row;
            justify-content: center;
          }
          
          .currencyInput {
            height: 25px; 
            width: 50px;
            margin-bottom: 5px;

            @include breakpoint($tablet) {
              width: 60px;
              margin-bottom: 0px;
              margin-right: 5px;
            }
          }

          select {
            height: 25px;
            width: 50px;

            @include breakpoint($tablet) {
              width: 60px;
            }
          }
        }

        .lastColumn {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          flex-direction: column;

          @include breakpoint($tablet) {
            flex-direction: row;
          }
        }
      }

      .subTotal {
        display: grid;
        grid-template-columns: 10fr 2fr 4fr 3.5fr;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 8px;

        span:first-child {
          grid-column: 3 / span 1;
        }

        span {
          text-align: center;
        }
      }
    }

    .bottomSection {
      display: flex;
      flex-direction: column;
      margin-bottom: 34px;

      @include breakpoint($tablet) {
        align-items: start;
        flex-direction: row;
      }

      h4 {
        align-items: center;
        color: black; 
        margin: 0px;
      }

      hr {
        margin-bottom: 8px;
        background-color: #1B4160;

        @include breakpoint($tablet) {
          width: 395px;
        }
      }

      textarea {
        height: 75px;

        @include breakpoint($tablet) {
          width: 395px;
        }
      }

      .totals {
        display: grid;
        align-items: center;
        grid-template-columns: 2fr 1fr;
        flex: 1;
        margin-top: 26px;
        border-top: 2px solid $midblue;
        padding-top: 7px;

        @include breakpoint($tablet) {
          margin-left: 55px;
        }

        input {
          width: 65px;
          text-align: center;
          margin-bottom: 0;
        }

        span {
          margin-bottom: 8px;
        }

        .bold {
          font-weight: bold;
        }

        :nth-child(even) {
          text-align: center;
          justify-content: center;

        }
      }
    }
  }
}