.itemsRow {
  .div1 {
    width: 150px;
    height: 30px;
    background-color: #e5e8e9;

    .span1 {
      display: flex;
      text-align: left;
      margin-left: 10px;

      .div2 {
        margin-top: 7px;
      }
    }
  }
}
