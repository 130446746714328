.sideCalendar {
  padding-top: 45px;
  @include breakpoint($tablet) {
    padding-top: 80px !important;
  }

  .scrollarea {
    max-height: calc(100vh - 120px);
    padding-right: 10px;
    margin-right: -10px;
  }
  .r-calendar {
    padding-top: 15px;
    @include breakpoint($tablet) {
      padding-top: 0;
    }

    .r-weekdays {
      top: 45px;
      height: 45px;
      width: 100%;
      background-color: $lightgrey;
      line-height: 45px;
      @include breakpoint($tablet) {
        top: 80px;
        height: auto;
        background-color: transparent;
        line-height: 9px;
      }
    }
    .r-dates .r-cell {
      color: $darkblue;
      @include breakpoint($tablet) {
        color: $darkgrey;
      }

      &.r-highlighted,
      &.r-highlighted-border,
      &.r-alerting,
      &.r-in-out {
        background-color: transparent;
        color: $darkblue;
        font-weight: bold;

        @include breakpoint($tablet) {
          color: $white;
        }
      }

      &.r-selected {
        background-color: $blue;
        color: $darkblue;
        font-weight: bold;

        @include breakpoint($tablet) {
          color: $white;
        }
      }
    }
  }

  @include breakpoint($tablet) {
    &.hasSubNav {
      .r-calendar .r-weekdays {
        top: 120px;
      }
    }
  }

  .r-calendar {
    min-width: auto;

    .r-head {
      color: $blue;
      font-weight: bold;
      text-transform: none;
      display: flex;
      justify-content: space-between;

      .r-next,
      .r-prev {
        svg {
          @include breakpoint($tablet) {
            fill: white;
          }
        }
      }
    }

    .r-weekdays {
      color: $blue;
    }
    .r-weekdays {
      margin-top: 34px;
      margin-bottom: 12px;
      z-index: 2;
    }
    .r-dates {
      font-size: 10px;
      line-height: 10px;
    }
    .r-cell {
      width: span(1 of 9);
      margin-left: span(1 of 54);
      margin-right: span(1 of 54);
    }
  }
  .mobileRentals {
    margin-top: -45px;
  }

  &.alertsCalendar {
    .r-calendar {
      .r-date.r-cell {
        &.r-selected {
          background-color: $orange;
        }
        &:hover {
          background-color: $orange;
        }
      }
    }
  }

  &.inOutCalendar {
    .r-calendar {
      .r-date.r-cell {
        &.r-selected {
          background-color: $purple;
        }
        &:hover {
          background-color: $purple;
        }
      }
    }
  }

  &.availabilityCalendar {
    .r-calendar {
      .r-date.r-cell {
        &.r-selected,
        &.r-highlighted {
          background-color: $red;
        }

        &.r-highlighted-border,
        &.r-highlighted {
          border: 2px solid $red;
        }

        &:hover {
          background-color: $red;
        }
      }
    }
  }
}
