@media print {
  /* Fix for Chrome using a mobile viewport for print */
  @page {
    size: 330mm 427mm;
    margin: 10mm;
  }

  body,
  tr,
  td,
  input,
  textarea,
  select,
  button {
    font-size: 12px;
    line-height: 18px;
  }

  thead,
  .rTableHeading {
    display: contents !important;
  }

  tbody {
    page-break-inside: avoid;
  }

  .container {
    display: block;

    & > aside {
      display: none;
    }
    & > header {
      display: none;
    }
    & > footer {
      display: none;
    }
  }

  .subNavWrapper {
    display: none !important;
  }

  .contentWrapper,
  .dashboardWrapper {
    grid-template-columns: 1fr !important;

    & > header {
      grid-column: 1 !important;
      height: 100px !important;
      background-color: transparent !important;

      h1 {
        color: $darkblue !important;
      }
    }
    & > aside {
      display: none !important;
    }
    & > .content {
      grid-column: 1 !important;
      padding: 40px 10px !important;
    }
  }

  .btn,
  .btnIcon,
  .btnLink,
  .linkAction,
  .drawer {
    display: none !important;
  }
  .printOnly {
    display: inherit !important;
  }

  .expandMenu {
    display: none;
  }
  .pushContent {
    width: 100%;
    padding: 0;
    margin: 0;
  }
}

.printWrapper {
  background-color: $white;
  width: fit-content;
  margin: auto;
  padding: 20px;

  .content {
    display: grid;
    grid-template: auto auto auto 1fr / 2fr 1fr;
    grid-gap: 20px 15px;
    position: relative;
    max-width: 90vw;
    padding: $vertical 0;
    margin: 0 auto;

    .header {
      grid-column: 1 / span 2;
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-gap: 15px;
      page-break-after: avoid;

      .logo {
        display: grid;
        grid-template-columns: 1fr 1fr;

        figure {
          width: 250px;
          @include maintain-aspect-ratio(16, 9);
          background-color: inherit;

          svg {
            height: 50% !important;
            width: 50% !important;
          }

          img {
            object-fit: contain;
          }
        }
        .businessPrint {
          padding: 20px;
        }
      }
      .title {
        display: grid;
        grid-template: auto 1fr 1fr / 1fr 1fr;

        > h4 {
          grid-column: 1/3;
        }
        h1 {
          grid-column: 1/3;
        }
        .dates {
          display: grid;
          grid-template-columns: 65px 1fr 1fr;
          grid-column: 1/3;
          margin: 5px 0;

          label {
            font-size: 10px;
            color: $darkgrey;
          }
        }
        .orderInfo {
          border-bottom: 3px solid #0e2c44;

          h4 {
            border-bottom: none;
          }
        }
        .token {
          border-bottom: 3px solid #0e2c44;
          text-align: right;

          h4 {
            border-bottom: none;
          }
        }
      }
    }
    .details {
      grid-column: 1 / span 2;
      display: grid;
      grid-template-columns: 8fr 10fr 9fr;
      grid-gap: 0 15px;
      page-break-after: avoid;

      .deliveryPrint,
      .checkInPrint {
        .grid tbody tr:first-of-type() td {
          border-top: none;
        }
      }
      .deliveryPrint {
        grid-column: 1;
        grid-row: 1 / span 3;
      }
      .checkInPrint {
        grid-column: 2;
        grid-row: 1 / span 3;

        & > div {
          margin-top: 20px;
        }
      }
      .teamMemberPrint,
      .customerPrint,
      .companyPrint {
        grid-column: 3;

        label {
          display: inline-block;
          margin-top: 14px;
          font-weight: $bold;
          color: $blue;
        }

        .caption {
          margin: 0;
          color: $blue;
        }
        .subHeading {
          color: $blue;
          border-bottom: none !important;
        }
        .rentalCustomer,
        .rentalCompany {
          margin-top: 4px;
          margin-bottom: 12px;

          & > div {
            padding: 1px 0;
          }
        }
      }

      .grid {
        td {
          white-space: normal;

          &.medSm {
            width: 105px;
          }

          &:first-of-type {
            width: 50px;
            font-size: 10px;
            color: $blue;

            &.sm {
              width: 70px;
            }

            &.notes {
              font-size: 14px;

              border-top: none;
              color: $darkgrey;
            }
          }

          .caption {
            margin: 0;
          }
        }
      }
    }
    .items,
    .staff,
    .billing {
      h4 {
        color: $blue;
        border-bottom: 3px solid $blue;
      }
      .grid {
        tbody tr.promtArea {
          page-break-inside: avoid;
        }
        tr.noBorder {
          page-break-inside: avoid;
          & > td {
            height: auto;
            padding-bottom: 10px;

            p {
              margin-top: 5px;
            }
          }
        }
        th svg {
          fill: transparent;
          color: $blue;
        }
        td {
          font-size: 12px;

          .grid {
            tbody {
              td.sm {
                width: 50px;
              }
              &:first-of-type tr:first-of-type td {
                border-top: none;
              }
            }
          }

          &.wrap,
          p {
            font-size: 12px;
            line-height: 14px;
            color: $cobalt;
          }
          &.qty {
            font-weight: bold;
          }
          p {
            margin: 0;
          }

          .subRentalContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 17px;
            min-width: 56px;
            max-width: fit-content;
            border-radius: 2.55px;
            padding: 0px 5px;
            margin-bottom: 5px;
            p {
              color: $white;
              font-size: 10.2px;
              line-height: 15px;
              font-weight: bold;
              letter-spacing: 0;
            }
          }
        }
      }
      .bundles {
        & > .grid {
          .rTableHeading {
            tr th.medSm,
            tr th.action {
              width: auto;
            }
          }
          td.indent {
            .grid {
              td.sm {
                width: 90px;
              }
              td.medSm {
                width: 0px;
              }
            }
          }
        }
        //Only on picklist with thumbnails
        & > .thumbnail > tbody > tr.promtArea {
          td:nth-of-type(3) {
            width: 850px;
          }
        }
      }
    }
    .items {
      grid-column: 1/-1;
      grid-row: 4 / span 1;

      &.full {
        grid-column: 1 / span 2;
      }
      &.picklist {
        grid-row: 3 / span 1;

        .grid.thumbnail td {
          height: auto;

          &.image {
            padding: 2px 0;
          }
        }
      }
      .notes {
        color: $blue !important;

        p {
          color: $blue !important;
        }
      }

      & > div {
        margin-bottom: 20px;
      }
      .subGrid {
        td {
          &:nth-of-type(1) {
            width: 36px;
          }
          &:nth-of-type(2) {
            width: 80px;
          }
        }

        &.thumbnail {
          td {
            &:nth-of-type(2) {
              width: 130px;
            }
            &:nth-of-type(3) {
              width: 80px;
            }
          }
        }
      }
    }
    .staff {
      .grid {
        th,
        td {
          text-align: center;
        }

        tr:not(.noBorder) {
          td {
            padding-top: 12px;
            padding-bottom: 12px;
            vertical-align: top;

            &:first-of-type() {
              white-space: normal;
            }
          }
        }
      }
    }
    .notes {
      grid-column: 2;

      div {
        margin-top: 10px;
      }
    }
    .billing {
      grid-column: 1/-1;

      &.printFill {
        grid-column: 1;
      }

      .billing_status {
        margin: 0;
        font-size: 11px;
        line-height: 17px;
      }
      .earnings label span {
        display: none;
      }
    }
    .printSignature {
      grid-column: 1 / span 2;
      grid-row: 5 / span 1;
      margin-top: 40px;

      .signatureImage {
        width: 350px;
        border-bottom: 3px solid $blue;
      }

      .signatureContainer {
        display: inline-grid;
        margin: 40px 15px 0 0;
      }

      .signatureDatetime {
        color: #000;
      }

      .signatureLabels {
        display: inline-flex;
        justify-content: space-between;
        width: 65%;
      }

      .picklistSignaturesGrid {
        display: inline-grid;

        .title {
          grid-column-start: 1;
          padding-bottom: 15px;
          grid-row-start: 1;
          align-self: end;
        }

        .signatureLine {
          grid-column-start: 2;
          align-self: end;
          grid-row-start: 1;
        }

        .signatureDate {
          grid-column-start: 3;
          align-self: end;
          padding-bottom: 6px;
        }
      }

      label {
        position: relative;
        top: -15px;
        display: inline-block;
        width: 90px;
        margin-top: 0;
        font-weight: $bold;
        color: $blue;

        &.line {
          position: static;
          width: 100px;
          margin: 40px 15px 0 0;
          border-top: 3px solid $blue;
          font-weight: $regular;
          line-height: 30px;
          color: $darkblue;

          &.lg {
            width: 350px;
          }
        }
      }
      p {
        width: 350px;
        margin-left: 90px;
        font-size: 9px;
        line-height: 13px;
        color: $darkgrey;
      }
    }

    .maintenanceHeader {
      grid-column: 1 / span 1;
      display: grid;
      grid-gap: 15px;
      page-break-after: avoid;
      width: 540px;

      h4 {
        font-size: 10px;
        color: $blue !important;
        font-weight: normal;
      }

      span:first-child {
        span {
          margin-left: 15px;
          font-size: 12px;
          font-weight: bold;
          color: $darkblue;
        }
      }

      span:nth-child(2) {
        float: right;
        span {
          font-size: 12px;
          margin-left: 18px;
          text-transform: uppercase;
          font-weight: bold;
          color: $darkblue;
        }
      }
    }

    .workOrderHeader {
      h4 {
        font-size: 18px;
      }

      span {
        float: right;
        font-size: 12px;
        font-weight: normal;
      }
    }

    .maintenanceDetails {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-column-gap: 15px;
      height: 275px;

      figure {
        width: 175px;
        height: 99px;
        @include maintain-aspect-ratio(16, 9);
        background-color: $darkblue;

        svg {
          height: 50% !important;
          width: 50% !important;
        }

        img {
          object-fit: contain;
        }
      }

      h2 {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 5px;
      }

      .grid {
        display: grid;
        grid-template-columns: 80px 265px;

        span:nth-child(odd) {
          font-size: 10px;
          color: $blue;
        }

        span:nth-child(even) {
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 10px;
        }
      }
    }

    .documents {
      align-self: end;

      h5 {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 6px;
        font-weight: bold;
      }

      .grid {
        display: grid;
        grid-template-columns: 4fr 2fr;

        .gridHeader {
          color: $blue;
          font-size: 10px;
          line-height: 14px;
          height: auto;
          padding-bottom: 12px;
        }

        .gridRow {
          display: grid;
          grid-template-columns: 4fr 2fr;
          grid-column: 1 / span 2;
        }

        span {
          line-height: 39px;
          height: 39px;
          border-bottom: 1px solid #ced8e3;
        }
      }
    }

    .workHeader {
      margin-bottom: 27px;
      h2 {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 14px;
      }

      h5 {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 5px;
        font-weight: bold;
      }

      p {
        line-height: 18px;
      }
    }

    .workSide {
      display: grid;
      grid-template-columns: 3fr 4fr;
      align-self: end;
      margin-top: 34px;
      margin-bottom: 27px;

      span:nth-child(odd) {
        color: #7194ac;
        font-size: 10px;
        line-height: 14px;
        padding-right: 18px;
        justify-self: end;
        margin-bottom: 12px;
      }

      span:nth-child(even) {
        line-height: 18px;
        font-weight: bold;
      }
    }

    .smallerHeader {
      line-height: 18px;
      padding-bottom: 10px;
      border-bottom: 1px solid $midblue;
      font-weight: bold;
      margin-bottom: 14px;
    }

    .workNotes {
      padding-right: 66px;

      p {
        width: 436px;
        margin-top: 14px;
        line-height: 18px;
      }
    }

    .shipTo {
      h6 {
        margin-top: 12px;
        margin-bottom: 6px;
        line-height: 18px;
        font-weight: bold;
        color: $darkblue;
      }
    }

    .lineItems {
      grid-column: 1 / span 2;
      margin-bottom: 50px;

      .lineItemRow {
        display: grid;
        grid-template-columns: 11fr 2.5fr 8fr 1.2fr;
        margin-bottom: 0;

        .subTotal {
          grid-column: 3 / span 1;
        }
      }

      .lastDataRow {
        border-bottom: 1px solid #ced8e3;
      }

      .dataRow {
        line-height: 39px;
      }

      .center {
        justify-self: center;
      }
    }

    .barcodedItems {
      grid-column: 1 / span 2;

      p {
        margin-bottom: 69px;
      }
    }

    .workOrderNotes {
      padding-right: 66px;

      p {
        width: 436px;
      }
    }

    .total {
      h4 {
        margin-bottom: 10px;
      }

      .grid {
        display: grid;
        grid-template-columns: 4fr 1fr;

        span {
          margin-bottom: 16px;
        }

        span:nth-child(odd) {
          font-weight: bold;
        }

        span:last-child {
          font-weight: bold;
        }
      }
    }
  }

  &.printMaintenanceWrapper {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 191px;
  }

  .withoutGrid {
    display: block !important;
  }

  .tickLabel {
    text-align: center;
  }

  .smTickBox {
    width: 46px;
  }

  .lgTickBox {
    width: 119px;
  }

  .spLftBorder {
    border-left: 1px solid #c1cdd7;
  }

  .spRgtBorder {
    border-right: 1px solid #c1cdd7;
  }

  .promtArea {
    .smptxt,
    span {
      font-weight: inherit;
      font-size: 14px !important;
      font-family: 'Heebo';
    }
  }

  section {
    padding: 0;
    margin: 0;

    .sectionEdit {
      display: none;
    }
    &:hover {
      & > .sectionEdit + h2 {
        margin-left: 0;
      }
    }
  }
  h1,
  h2,
  h3,
  h4 {
    padding: 0;
    margin: 0;
  }
  h1 {
    margin-top: 10px;
    font-size: 18px;
    line-height: 27px;
    font-weight: $bold;
    color: $blue;
  }
  h2 {
    font-size: 14px;
    line-height: 21px;
    font-weight: $bold;
    color: $darkblue;
  }
  h3,
  h4 {
    font-size: 11px;
    line-height: 25px;
    color: $darkblue;
    font-weight: bold;
    border-bottom: 3px solid $darkblue;
  }
  .btnLink {
    display: none;
  }
}

.react-qr {
  width: 60px !important;
  height: 60px !important;
}

tbody.pickListBundleItem, tbody.pickListBundleAccessory, tbody.pickListBundleAddOn {
  tr > td:first-child {
    border-left: 5px solid $blue;
    padding-left: 10px;
  }
}
