

.contentWrapperTwoFactor {
  background-color: $navyblue;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    background-color: $lightgrey;
    border-radius: 2px;
    max-width: 550px;
    padding: 20px;
    margin: 10px;

    .container-two-factor-phone-content {
      display: flex;
      column-gap: 10px;
      align-items: center;

      input {
        width: 50px;
      }
      input:last-child {
        width: 80px;
      }
    }
    .container-header {
      display: flex;
      justify-content: space-between;
    }
    .container-buttons {
      display: flex;
      margin-top: 20px;
    }
  }
}
