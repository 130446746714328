.employeeForm {
  @include breakpoint($tablet) {
    display: grid;
    grid-template-columns: 2fr 3fr;
  }

  .userActions {
    place-self: start end;
    margin: 10px 10px;
    @include breakpoint($tablet) {
      margin: 5px 0 5px 5px;
    }

    .btnIcon {
      width: 100%;
      margin: 0 0 10px;
      padding: 0 40px;
      @include breakpoint($tablet) {
        width: auto;
        margin: 0 20px 0 0;
      }
    }
  }
  .members {
    grid-column: span 2;
    margin-bottom: 20px;
    @include breakpoint($tablet) {
      margin-bottom: 40px;
    }

    & > .btnLink {
      float: none;
      margin-bottom: 10px;

      svg {
        margin: 0 5px 0 0;
      }

      .alert {
        display: block;
        margin: 5px 0;
        font-weight: $regular;
        color: $orange;
        @include breakpoint($tablet) {
          display: inline-block;
          margin: 0 0 0 20px;
        }

        svg {
          height: 15px !important;
          width: 16px !important;
          fill: $orange !important;
        }
      }
    }
  }
  section {
    grid-column: span 2;
    padding: 0 10px 20px;
    margin-bottom: 3px;

    h4 {
      margin: 0;
      line-height: 40px;
    }
    & > div {
      @include breakpoint($tablet) {
        display: flex;
      }
    }
  }
  .accordian {
    grid-column: span 2;

    .header {
      position: relative;

      & > div > svg,
      .imageWrapper {
        display: none !important;
        @include breakpoint($tablet) {
          display: inline-block !important;
          margin: -30px 10px 0 0;
        }
      }
      & > div > svg {
        height: 25px !important;
        width: 26px !important;
        fill: $blue;
        vertical-align: middle;
      }
      .imageWrapper {
        @include clearfix;
        @include maintain-aspect-ratio(1, 1);
        width: 25px;
        background-color: $white;
        @include border-radius(50%);
        vertical-align: middle;

        img {
          height: 100%;
          max-width: 100%;
        }
      }
      label {
        font-weight: $bold;
        color: $blue;
      }
      span {
        display: inline-block;
        width: 220px;
        text-overflow: ellipsis;
        overflow: hidden;

        &:first-of-type {
          width: 150px;
        }
        &:last-of-type {
          width: auto;
        }
      }
      .btnLink {
        position: absolute;
        top: 0;
        right: 35px;
      }
    }
    .content > div {
      @include breakpoint($tablet) {
        display: flex;
      }
    }
  }

  .uploadZone {
    width: 50%;
    margin: 0 25% 10px;
    @include breakpoint($tablet) {
      width: 125px;
      margin: 0 40px;
    }

    .drop {
      @include maintain-aspect-ratio(1, 1);
      @include border-radius(50%);
      overflow: hidden;

      .placeholder {
        svg {
          margin: 45px 0 0;
          @include breakpoint($tablet) {
            margin: 40px 0 0;
          }
        }
      }
    }
  }
  form {
    @include breakpoint($tablet) {
      width: calc(100% - 300px);
    }

    input[type='text'] {
      width: 100%;

      @include breakpoint($tablet) {
        &.med {
          width: 50%;
          &:nth-child(even) {
            width: calc(50% - 5px);
            margin-left: 5px;
          }
        }
      }
    }
    .check {
      width: 100%;
      margin: 15px 0;
    }
    select {
      width: 100%;
      @include breakpoint($tablet) {
        width: 50%;
      }
    }
    .btnIcon {
      width: 100%;
      margin: $innerPadding 0;
      @include breakpoint($tablet) {
        width: 50%;
        margin: 0 0 $innerPadding 0;
      }
    }
    .actions {
      @include breakpoint($tablet) {
        @include span(2 of 3);
        @include post(1 of 3);
      }
    }
  }
}
.defaultDeliverySettings {
  h4 {
    color: $darkblue;
  }
}

.defaultDelivery {
  padding-bottom: 10px;
  margin-bottom: 5px;
  border-bottom: 2px solid $cobalt;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;

    h6 {
      margin-left: 3px;
    }
  }

  select {
    width: 65%;
    margin-left: 10px;
  }
}

.rentalSettings,
.multilocationSettings {
  .videoButton {
    margin-bottom: 15px;
  }
  .displaySettings {
    margin-top: 10px;
  }
  .settingsDetailsForm {
    .details {
      width: span(7 of 8);
      @include breakpoint($tablet) {
        @include push(1 of 13);
        width: span(9 of 13);
        @include post(2 of 13);
      }

      & > div {
        position: relative;
        min-height: $mobileVertical;
        padding: 5px 0 4px;
        border-bottom: 1px solid $meddarkgrey;
        @include breakpoint($tablet) {
          min-height: $vertical;
          padding: 0;
        }

        &:last-of-type() {
          border-bottom: 1px solid transparent;
        }
        .check {
          position: absolute;
          top: 12px;
          left: -30px;

          &.disabled {
            display: none !important;
            top: 11px;
            left: -33px;
            height: 20px;
            width: 20px;
            @include breakpoint($tablet) {
              display: inline-block !important;
            }

            svg {
              height: 20px !important;
              width: 20px !important;
            }

            &.on > svg {
              color: $blue;
              fill: transparent;
            }
            &.off > svg {
              fill: transparent;
            }
          }
        }
        & > input[type='text'] {
          width: span(2 of 5);
          margin: 0;
          @include breakpoint($tablet) {
            width: span(1 of 5);
          }
        }
        .rc-time-picker {
          width: span(2 of 5);
          @include breakpoint($tablet) {
            width: span(1 of 4);
          }

          input[type='text'] {
            width: 100%;
            margin: 0;
          }
        }
      }
      label {
        display: inline-block;
        width: span(1 of 2);
        @include breakpoint($tablet) {
          padding-top: 11px;
        }

        &.caption {
          width: auto;
        }
      }
      .mileage {
        & > input[type='text'] {
          width: span(1 of 4);
          margin: 0;
          @include breakpoint($tablet) {
            width: span(1 of 5);
          }
        }
      }
      .location {
        h6 {
          margin-top: 12px;
        }
        & > div {
          position: relative;
          font-weight: $bold;

          & > svg {
            display: none !important;
            position: absolute;
            top: 4px;
            left: -10%;
            fill: $blue;
            @include breakpoint($tablet) {
              display: inline-block !important;
            }
          }
        }
      }
      .viewToggle {
        margin: 0;
        padding-top: 9px;

        label {
          padding-top: 2px;
        }
      }
    }
    .default-delivery-times {
      padding-bottom: 10px;
      margin-bottom: 5px;
      border-bottom: 2px solid $cobalt;

      .rc-time-picker {
        width: span(3 of 5);
        @include breakpoint($tablet) {
          width: span(1 of 5) !important;
        }
      }
      .long-dash {
        margin-left: 10px;
        margin-right: 10px;
      }
      .inputs {
        white-space: nowrap;
      }
      .icon {
        position: absolute;
        top: 12px;
        left: -33px;

        display: none;
        @include breakpoint($tablet) {
          display: inline-block;
          margin-right: 15px;
        }
      }
      .afternoon-icon {
        top: 7px;
      }
      .evening-icon {
        left: -28px;
      }
    }
    .summary {
      padding-top: 15px;
      border-top: 2px solid $cobalt;
      @include breakpoint($tablet) {
        @include push(1 of 13);
        width: span(9 of 13);
        @include post(2 of 13);
      }

      span {
        font-weight: $bold;
        @include breakpoint($phone $tablet) {
          display: block;
        }
      }
      .caption {
        margin-left: 0;
      }
    }
    .customer-pickup-location {
      margin: 10px 0 18px;
      padding-top: 5px;
      border-top: 2px solid $cobalt;
      @include breakpoint($tablet) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      select {
        width: 65%;
      }
    }
    .actions {
      clear: both;
      margin-top: 10px;
      @include breakpoint($tablet) {
        @include push(1 of 13);
        width: span(9 of 13);
        @include post(2 of 13);
      }
    }

    &.wide .details {
      label {
        width: 100%;
        @include breakpoint($tablet) {
          width: span(3 of 4);
        }

        &.caption {
          width: auto;
        }
      }
    }

    &.expiration .details {
      @include breakpoint($tablet) {
        @include push(1 of 13);
        width: span(11 of 13);
      }

      & > div {
        & > input[type='text'] {
          width: span(3 of 10);
          @include breakpoint($tablet) {
            width: span(2 of 15);
          }
        }
      }
      .check {
        display: inline-block !important;
        position: static;
        height: auto;
        width: auto;
        margin-right: 5px;

        svg {
          height: 14px !important;
          width: 15px !important;
        }

        &.on > svg {
          color: $lightgrey;
          fill: $blue;
        }
        &.off > svg {
          fill: $meddarkgrey;
        }
      }
      label {
        width: auto;
        white-space: normal;
        @include breakpoint($tablet) {
          white-space: nowrap;
        }
      }
    }
  }
  .expirationForm {
    margin-bottom: 20px;
    & > div {
      margin-bottom: 20px;

      & > input[type='text'] {
        margin-left: 23px;
      }
    }
    div[disabled] {
      pointer-events: none;
      opacity: 0.15;
    }
    input[type='text'] {
      width: 50px;
    }
    strong {
      display: block;
    }
    .radioItems {
      margin-left: 8%;
      .radio {
        margin-top: 8px;
        label {
          font-weight: $bold;
          margin-left: -5px;
        }
        & + div,
        & + p {
          margin-left: 23px;
        }
      }
    }
    .borderTop {
      padding-top: 15px;
      border-top: 1px solid $meddarkgrey;
      input[type='text'] {
        width: 50px;
        margin-top: 7px;
        margin-left: 0px;
      }
      margin-bottom: 15px !important;
    }
    .actions {
      margin: 0;
      @include breakpoint($tablet) {
        width: 50%;
      }
    }
  }
  .boldInput {
    input[type='text'] {
      width: 50px;
      font-weight: bold;
      text-align: center;
      font-size: 12px;
    }
  }
  .finalPaymentForm {
    .details {
      margin-top: 10px;
      margin-left: 0px;
      width: 100%;
    }

    .count-label {
      float: left;
      display: initial;
      margin-right: 7px;
      margin-top: 5px;
    }

    #final_payment_days_input {
      width: 8%;
      float: left;
      text-align: center;
      margin-right: 7px;
    }

    #final_payment_type_select_list {
      margin-right: 15px;
      width: 35%;
      float: left;
      &.unselected {
        color: $darkgrey;
      }
    }

    .disabledInput {
      opacity: 0.3;
    }

    .radioList {
      float: left;
      width: 45%;

      label {
        font-weight: bold;
        padding-top: 3px;
        white-space: nowrap !important;
      }
    }

    .actions {
      margin-left: 0px;
    }
  }
  .paymentNotificationsForm .details {
    > div {
      display: inline-block;
      width: 100%;
    }
    select {
      float: left;
      width: 30%;
    }
    .radioList {
      width: 60%;
      float: left;
      margin-left: 20px;

      label {
        padding-top: 3px;
        font-weight: bold;
      }
    }
  }
  #payment_notifications_accordion {
    .option {
      display: inline-block;
      position: relative;
      width: 100%;
      border-bottom: none;
    }
    .check {
      position: absolute;
      left: -22px;
      top: 10px;
    }
    input[type='text'] {
      width: 12%;
      text-align: center;
      margin-right: 10px;
    }
  }
}

.legalForm {
  display: grid;
  padding-bottom: 20px;
  @include breakpoint($tablet) {
    grid-template-columns: 3fr 1fr;
  }

  input[type='text'] {
    width: 100%;
  }
  textarea {
    height: 150px;
    @include breakpoint($tablet) {
      height: 300px;
    }
  }
  .inputWrapper {
    @include breakpoint($tablet) {
      grid-column: 1;
      height: 300px;

      display: flex;
      flex-direction: column;
    }

    .rdw-editor-wrapper {
      @include breakpoint($tablet) {
        margin-bottom: 10px;
        flex: 1;
      }
    }

    .drop {
      @include breakpoint($tablet) {
        margin-bottom: 0;
      }
    }
  }

  .actions {
    position: relative;
    display: block;
    @include breakpoint($tablet) {
      grid-column: 2;
      margin-left: 10px;
    }

    .btn {
      width: 100%;
      margin: 0 0 5px;

      &.tertiary {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 0;
      }
    }
  }
}
.pickListForm {
  textarea {
    width: 100%;
    height: 90px;
  }

  .actions {
    grid-template-columns: repeat(4, 1fr);
    margin-top: 10px;
    @include breakpoint($tablet) {
      grid-template-columns: repeat(8, 1fr);
    }

    .btn {
      &.tertiary {
        grid-column: span 1;
        @include breakpoint($tablet) {
          grid-column: 8 / span 1;
        }
      }
    }
  }
}

.teamHeader {
  color: $blue;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
}

.trNoBorder {
  & td {
    padding: 31px 0 !important;
    border-top: none !important;
  }
}

.autoBookingSettings {
  &.expiration .details {
    margin-left: 0px !important;
    width: 100% !important;

    & > div {
      border-bottom: none !important;

      & > input[type='text'] {
        width: 50px !important;
        font-weight: bold;
      }
    }

    .autobook_setting_type {
      font-size: 12px !important;
      margin-right: 7px;
    }

    .caption {
      margin-left: 43px !important;
    }
  }

  &.expiration .showOverbookingSettings {
    width: 95% !important;

    .caption {
      margin-left: 0px !important;
    }
  }
}

.font-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 60%;
  align-self: center;
}

.font-12{
  font-size: 12px;
}
.font-14{
  font-size: 14px;
}
.font-16{
  font-size: 16px;
}
.font-18{
  font-size: 18px;
}
.font-20{
  font-size: 20px;
}

.scaleWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 40px;
}

.displayDiscountSettings {
  display: flex;
  flex-direction: column;
  border-top: 2px solid $midblue;
  margin-top: 44px;
  padding-top: 10px;

  .displayDiscountInput {
    display: flex;
    flex-direction: row;
    padding: 10px 20px 0 12px;
    gap: 8px;

    &>div {
      width: unset !important;
    }
  }
}
