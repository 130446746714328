html {
  height: 100%;
  background: $lightgrey;
}

body {
  height: 100%;
  overflow-x: auto !important;
  overflow-y: scroll !important;
  overscroll-behavior: none;
}

body,
tr,
td,
input,
textarea,
select,
button {
  font-family: $fontfamily;
  font-size: 16px;
  line-height: 20px;
  color: $darkblue;
  @include breakpoint($tablet) {
    font-size: 12px;
    line-height: 18px;
  }
}

input[type='text'],
input[type='number'],
input[type='email'],
input[type='password'],
textarea,
select {
  height: 35px;
  padding: 6px 8px;
  margin: 0 0 5px 0;
  background-color: $white;
  border: none;
  outline: none;
  @include border-radius(2px);
  @include breakpoint($tablet) {
    height: 30px;
  }

  &:required,
  &:invalid {
    box-shadow: none;
  }

  &.barcode {
    padding-left: 41px;
    background-image: url(svg-encode(barcode-icon($darkblue)));
    background-repeat: no-repeat;
    background-position: left 10px center;
    background-size: 25px 15px;
  }
  &:disabled {
    color: $darkgrey;
  }
}

span.input {
  display: inline-block;
  height: 35px;
  padding: 6px 8px;
  margin: 0 0 5px 0;
  background-color: $white;
  border: none;
  outline: none;
  @include border-radius(2px);
  @include breakpoint($tablet) {
    height: 30px;
  }
}

select {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;

  padding-right: 27px;
  background-image: url(svg-encode(down-arrow($navyblue)));
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 12px 8px;

  &.purchase_order {
    background-image: url(svg-encode(down-arrow($blue)));
  }
}
/* Various browsers treat the select list differently */
/* Internet Explorer */
_:-ms-lang(x),
select {
  padding-top: 7px !important;
  padding-bottom: 6px !important;
}
select::-ms-expand {
  display: none;
}
/* Firefox */
@-moz-document url-prefix() {
  select {
    padding-top: 5px !important;
    padding-bottom: 4px !important;
  }
}
/* Safari */
_::-webkit-:not(:root:root),
select {
  line-height: 24px;
  @include breakpoint($tablet) {
    line-height: 18px;
  }
}
@supports (overflow: -webkit-marquee) and (justify-content: inherit) {
  select {
    line-height: 24px;
    @include breakpoint($tablet) {
      line-height: 18px;
    }
  }
}

/* Override the color of the placeholder text */
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: $darkgrey;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: $darkgrey;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: $darkgrey;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $darkgrey;
}

input[type='checkbox']:disabled {
  cursor: not-allowed !important;
}

input[type="checkbox"]:disabled + svg {
  filter: grayscale(60%);
}

input[type='search'] {
  height: 35px;
  width: 100%;
  padding: 8px 8px 7px 27px;
  margin: 5px 0;
  background-color: #e0e5e9;
  background-image: image('icons/icon_Search.png');
  background-image: url(svg-encode(search-icon(black)));
  background-repeat: no-repeat;
  background-position: left 9px center;
  background-size: 12px 12px;
  color: black;
  border: none;
  outline: none;
  @include border-radius(2px);
  -webkit-appearance: textfield;
  @include breakpoint($tablet) {
    height: 30px;
    width: 235px;
    background-size: 11px 11px;
  }

  /* Override the color of the placeholder text */
  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: $medlightgrey;
    text-transform: uppercase;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $medlightgrey;
    opacity: 1;
    text-transform: uppercase;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $medlightgrey;
    opacity: 1;
    text-transform: uppercase;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $medlightgrey;
    text-transform: uppercase;
  }
}

h1,
h2,
h3,
h4,
h6 {
  padding: 0;
  margin: 0;

  svg {
    height: 20px !important;
    width: 20px !important;
    margin-right: 5px;
    fill: $blue;
    vertical-align: middle;
  }
}
h1 {
  font-size: 28px;
  line-height: 45px;
  font-weight: $regular;
  color: $blue;

  @media (max-width: 769px) {
    margin-top: 10px;
  }

  @include breakpoint($tablet) {
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 40px;
  }

  .info {
    padding: 0;
    margin-left: 0;
  }
  a {
    display: inline-block;
    margin-right: 20px;
    letter-spacing: 0.1px;
    color: $darkgrey;

    &.selected {
      font-weight: $light;
      letter-spacing: 0;
      color: $blue;
    }
    &:last-of-type() {
      margin-right: 0;
    }
  }
}
h2 {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 40px;
  font-weight: $regular;
  color: $darkgrey;
}
h3 {
  margin-bottom: 10px;
  border-bottom: 2px solid $blue;
  font-size: 12px;
  line-height: 18px;
  font-weight: $bold;
  color: $blue;
}
h4 {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 20px;
  font-weight: $bold;
  color: $blue;
}
h6 {
  font-size: 12px;
  line-height: 15px;
  font-weight: $regular;
  color: $darkgrey;
  @include breakpoint($tablet) {
    font-size: 10px;
    line-height: 15px;
  }
}

section {
  position: relative;
  margin-bottom: 20px;
  overflow: visible;

  &:not(.secondary) {
    clear: both;
    padding: 10px;
    background-color: $white;

    @include breakpoint($tablet) {
      padding: 10px 20px;
    }

    .sectionEdit {
      top: 22px;
      left: 10px;
      right: auto;

      @include breakpoint($tablet) {
        left: 20px;
      }
    }

    .accordian {
      margin-bottom: 0;
      box-shadow: none;
      border-top: 1px solid $meddarkgrey;

      &.storefrontCategory {
        border-top: 1px solid $midblue;
      }

      .btnCollapse,
      .btnExpand {
        margin-right: 8px;
      }

      .header {
        height: 39px;
        padding: 0;
      }

      .content {
        padding: 0;
      }

      &.revenue_table_row {
        border-top: 1.5px solid black;
        font-weight: bold;

        .content {
          table {
            tr {

              th:first-child,
              td:first-child {
                padding-left: 30px;
              }

              th:last-child,
              td:last-child {
                padding-right: 45px;
              }
            }

          }
        }
      }

    }

    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"],
    textarea,
    select,
    .toggle,
    .Select-control {
      background-color: $lightgrey !important;
    }

    .toggle:not(.disabled) {
      color: $darkblue;
    }

    .react-datepicker-component .react-datepicker-input {
      background-color: $lightgrey !important;
    }

    .autocomplete {
      .results {
        li {
          background-color: $lightgrey;

          &.highlight {
            background-color: $meddarkgrey;
          }
        }
      }
    }

    &.without-padding {
      padding: 0px;
    }
  }

  .info {
    float: right;
    margin: 0;
  }

  .rTableCell .info {
    margin: 0;
  }

  .sectionEdit {
    position: absolute;
    top: 0;
    right: 0;
    height: 15px;
    width: 16px;
    cursor: pointer;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    @include breakpoint($tablet) {
      opacity: 0;
    }

    svg {
      height: 15px !important;
      width: 16px !important;
      fill: #8fa9be;
    }

    &+h2 {
      margin-left: 20px;
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

      @include breakpoint($tablet) {
        margin-left: 0;
      }
    }
  }

  &:hover {
    &>.sectionEdit {
      opacity: 1;

      &+h2 {
        margin-left: 20px;
      }
    }
  }

  &.disabled {
    .disabledLabel {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 75%;
      font-size: 12px;
      line-height: 18px;
      color: $white;
      white-space: normal;
      text-align: center;
      z-index: 21;
    }

    &:after {
      content: " ";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $blue;
      opacity: 0.85;
      z-index: 20;
    }
  }
}

figure {
  position: relative;
  background-color: $meddarkgrey;
  overflow: hidden;

  svg {
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 25%;
    fill: $white;
  }
  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
fieldset {
  @include container;
  position: relative;
  margin-bottom: 20px;
}

p {
  margin-bottom: 10px;
  word-break: break-word;
}

label {
  white-space: nowrap;

  &.font-bold {
    font-weight: bold;
  }

  &.orange-color {
    color: $orange;
    font-weight: bold;
  }

  &.marquee {
    white-space: break-spaces;
  }
}

a {
  color: $blue;
  outline: none;
  text-decoration: none;
  cursor: pointer;

  * {
    cursor: pointer;
  }
}
strong,
b {
  font-weight: $bold;
}
em {
  font-style: italic;
}
ul.bullets {
  margin-left: 15px;
  list-style: inherit;

  ul.bullets {
    margin: 5px 0 5px 15px;
  }
}
ul.arrows {
  li {
    position: relative;
    margin-left: 15px;

    &:before {
      content: ' ';
      position: absolute;
      top: 5px;
      left: -12px;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 6px solid $blue;
      border-right: none;
    }
  }
}
ul.numbers {
  margin: 0 0 10px 15px;
  list-style: decimal;
}
hr {
  height: 1px;
  margin: 10px 0;
  border: none;
  background-color: $meddarkgrey;
}

.highlight {
  color: $darkgrey;
  .redWarning {
    color: $red !important;
  }
}
.error {
  outline: 2px solid $red !important;
}
.errorText {
  color: $red;
}
.warn {
  font-size: 10px;
  color: $red;
}
.caption {
  margin-left: 5px;
  font-size: 12px;
  font-weight: $regular;
  color: $darkgrey;
  white-space: initial;
  @include breakpoint($tablet) {
    font-size: 10px;
  }
}
.strikeThrough {
  text-decoration: line-through;
}

svg {
  &.blue {
    fill: $blue !important;
  }
  &.orange {
    fill: $orange !important;
  }
  &.medlightgrey {
    fill: $medlightgrey !important;
  }
  &.darkgrey {
    fill: $darkgrey !important;
  }
  &.green {
    fill: $green !important;
  }

  &.circle {
    padding: 5px;
    @include border-radius(50%);
    background-color: $blue;
    fill: $white;
    overflow: visible;
  }
}

.textareaResize {
  resize: vertical !important;
}

.mui-ripple-circle {
  display: none;
}

.pointerEvents {
  pointer-events: all !important;
}

.w40 {
  width: 40% !important;
}

.dNone {
  display: none !important;
}

.textBreak {
  white-space: normal !important;
}

.rTableCell.textBreak {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}

.clearfix {
  overflow: auto;
}

#launcher {
  display: none !important;
  margin: 40px 20px !important;
}

.askUsHow {
  text-decoration: underline;
}

.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  border-radius: 3px;
  color: white;
  font-weight: bold;
  font-size: 10px;
  height: 22px;
  margin-right: 7px;
  width: 40px;

  &.xl {
    font-size: 12px;
    height: 30px;
    width: 60px;
  }
}

.signedContainer {
  margin: 20px 50px;
}

.signedTopicGrid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 7fr;
}

.signedDescGrid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 2fr 5fr;
}

.signedSubTitle {
  font-weight: bold;
  word-break: break-word;
}

.signedBreakWord {
  word-break: break-word;
}

.signedTitle {
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.signedPrint {
  margin-top: 5px;
  cursor: pointer;

  transition: opacity 0.2s;

  &:hover {
    filter: opacity(0.8);
  }
}

.hoverable {
  &:hover {
    filter: opacity(0.9);
    cursor: pointer;
  }
}
