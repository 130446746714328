.systemUpdate {
  color: $meddarkgrey;
  padding-top: 10px;
  .viewToggle {
    line-height: normal;
    align-items: normal;
  }
}

.adminReporting {
  @include container;
  position: relative;

  .import {
    float: right;
    margin-top: 5px;
  }
  .totals {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 10px 40px 0;
    background-color: $darkblue;
    font-weight: $bold;
    color: $blue;

    div {
      width: 150px;
      margin-bottom: 10px;
      text-align: center;

      span {
        display: block;
        font-size: 22px;
        line-height: 24px;
        font-weight: $regular;
        color: $white;
      }
    }
  }
  .reportFilter,
  .callout,
  .chartWrapper {
    @include container;
    margin-bottom: 25px;
  }
  .reportFilter {
    position: relative;

    select {
      width: 100%;
    }
  }
  .callout {
    height: 120px;
    padding-top: 15px;
    background-color: $blue;
    @include border-radius(6px);
    font-size: 12px;
    font-weight: $light;
    line-height: 24px;
    text-align: center;

    label {
      font-size: 16px;

      &.large {
        display: block;
        font-size: 30px;
        font-weight: $thin;
        line-height: 44px;
        color: $white;
      }
    }
  }
  .gridWrapper.scroll .grid {
    @include breakpoint($tablet) {
      width: 1100px;
    }
  }

  &.rentalsReport {
    @include breakpoint($tablet) {
      .reportFilter {
        position: relative;
        @include push(1 of 17);
        @include span(16 of 17);
        padding-left: calc(#{span(3 of 17)} + 10px);
        padding-right: calc(#{span(3 of 17)} + 10px);

        &:before,
        &:after {
          content: ' ';
          position: absolute;
          top: 15px;
          height: 2px;
          width: span(3 of 17);
          background-color: $meddarkgrey;
        }
        &:before {
          left: 0;
        }
        &:after {
          right: 0;
        }
      }
    }
    .linkBack {
      position: absolute;
      right: 0;
      top: 40px;
      @include breakpoint($tablet) {
        left: 0;
        right: auto;
      }
    }
    .detailGrid {
      width: 100%;
      table-layout: fixed;

      .title span:last-of-type() {
        display: inline-block;
        padding-left: 10px;
        font-weight: $bold;
        color: $blue;
      }
      .rTableRow:last-of-type {
        .rTableCell {
          &:nth-of-type(even) {
            @include border-radius(0 0 0 6px);
          }
          &:nth-of-type(odd) {
            @include border-radius(0 0 6px 0);
          }
        }
      }
    }
    .details {
      @include breakpoint($tablet) {
        @include push(-1 of 17);
        @include span(18 of 17);
      }

      .quotedItems,
      .reservedItems,
      .closedItems {
        margin-top: 25px;
        @include breakpoint($tablet) {
          margin-top: 0;
        }

        .rTableHead:not(.title),
        .rTableCell:not(.title) {
          width: span(10 of 24);
        }
      }
      .quotedItems {
        @include breakpoint($tablet) {
          @include span(11 of 27);

          .callout {
            @include push(3 of 11);
          }
          .rTableHead:not(.title),
          .rTableCell:not(.title) {
            width: calc(#{span(4 of 11)} - 5px);
          }
        }
      }
      .reservedItems,
      .closedItems {
        @include breakpoint($tablet) {
          @include span(8 of 27);
          padding-left: 10px;

          .rTableHead:not(.title),
          .rTableCell:not(.title) {
            width: 50%;
          }

          .title {
            display: none;
          }
        }
      }
      .reservedItems {
        .callout {
          background-color: $cobalt;
          color: $meddarkgrey;
        }
        .detailGrid {
          .rTableHead:not(.title) {
            background-color: $cobalt;
            color: $meddarkgrey;
          }
        }
      }
      .closedItems {
        .callout {
          background-color: $darkblue;
          color: $blue;
        }
        .detailGrid {
          .rTableHead:not(.title) {
            background-color: $darkblue;
            color: $blue;
          }
        }
      }
      .pagerWrapper {
        @include span(full);
      }
    }
  }

  &.itemsReport {
    .filter,
    .reportFilter,
    .summary,
    .viewToggle,
    .chartWrapper {
      @include breakpoint($tablet) {
        @include push(1 of 17);
        @include span(10 of 17);
        @include post(6 of 17);
      }
    }
    .summary {
      .callout {
        float: left;
        width: calc(50% - 2px);
        margin: 0 2px 25px 0;

        &:last-of-type {
          margin: 0 0 25px 2px;
          background-color: $purple;
        }
      }
    }
    .viewToggle {
      margin-bottom: 25px;
      text-align: center;
    }
    .detailGrid {
      @include breakpoint($tablet) {
        @include push(-1 of 17);
        @include span(12 of 17);
        @include post(6 of 17);
      }

      .rTableRow,
      tr {
        .rTableHead:not(.title),
        th:not(.title),
        .rTableCell:not(.title),
        td:not(.title) {
          width: span(5 of 12);
        }
        .rTableHead,
        th {
          &:last-of-type() {
            background-color: $purple;
          }
        }
        .rTableCell,
        td {
          &:last-of-type() {
            color: $purple;
          }
        }
      }
    }
    .pagerWrapper {
      @include breakpoint($tablet) {
        @include push(1 of 17);
        @include span(10 of 17);
        @include post(6 of 17);
      }
    }
  }

  &.powerbiReport {
    .header {
      display: flex;
      justify-content: space-between;
    }
  }

  &.subscriptionsReport {
    .tabs {
      display: flex;

      a {
        padding: 0 15px;
        line-height: 30px;
        @include border-radius(2px 2px 0 0);
        background-color: $meddarkgrey;
        color: $darkblue;

        &.active {
          background-color: $darkblue;
          color: $white;
          font-weight: $bold;
        }
      }
    }
    section {
      padding: 0 0 1px;

      header {
        display: flex;
        width: 720px;
        background-color: $darkblue;
        line-height: 40px;
        color: $white;
        font-weight: $bold;
        @include breakpoint($tablet) {
          width: 100%;
        }

        label {
          &:nth-of-type(1) {
            margin-left: 210px;
          }
          &:nth-of-type(2) {
            margin-left: 205px;
          }
          &:last-of-type {
            width: 76px;
            margin-left: auto;
            padding-left: 12px;
            background-color: $cobalt;
            @include breakpoint($tablet) {
              width: 86px;
              padding-left: 17px;
            }
          }
        }
      }

      .gridWrapper .grid {
        width: 700px;
        @include breakpoint($tablet) {
          width: calc(100% - 40px);
        }
      }
      .grid {
        margin: 10px 10px 20px;
        @include breakpoint($tablet) {
          margin: 10px 20px 20px;
        }

        .title {
          font-size: 10px;
          color: $darkgrey;
        }
        .divider {
          width: 2px;
          padding: 0;
          background-color: $darkblue;
        }

        thead,
        tbody {
          tr {
            th,
            td {
              &.sm {
                width: 65px;
              }
            }
          }
        }

        &:last-of-type {
          border-top: 5px solid $blue;

          thead {
            tr {
              th {
                font-size: 12px;
                font-weight: $bold;
              }
            }
          }
          tbody {
            tr {
              td {
                border-top: 1px solid $blue;
              }
            }
          }
        }
      }
    }
  }
}
