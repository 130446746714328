.ticketsToggles {
  .toggles1 {
    display: flex;
    float: right;
    margin-right: 10px;

    .toggles2 {
      width: auto;
      font-size: 10px;
      margin: 8px;
    }
  }
}
