.Dropdown-root {
	position: relative;
	cursor: pointer;

	.Dropdown-control {
		display: flex;
		height: 35px;
		line-height: 35px;
		@include breakpoint($tablet) {
			height: 30px;
			line-height: 30px;
		}

		.Dropdown-placeholder {
			width: calc(100% - 22px);
			padding-left: 8px;
			color: $darkgrey;

			&.is-selected {
        color: $lightgrey;
        margin-right: 25px;
			}
		}
		.Dropdown-arrow-wrapper {
			.Dropdown-arrow {
				display: inline-block;
				height: 100%;
				width: 22px;
				background-image: url(svg-encode(down-arrow($blue)));
				background-repeat: no-repeat;
				background-position: center;
				background-size: 12px 8px;
			}
		}
	}
	.Dropdown-menu {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		@include border-radius(0 0 2px 2px);
		border: 1px solid $lightgrey;
		background-color: $white;
		line-height: 35px;
		z-index: 1;
		margin-bottom: 50px;
		@include breakpoint($tablet) {
			line-height: 30px;
		}

		.Dropdown-option {
			padding: 0 8px;

			&:hover, &.is-selected {
				background-color: $lightgrey;
			}
		}
		.Dropdown-noresults {
			padding: 0 8px;
		}
	}
}
