#CreditTermCompanyTermInfo {
  padding: 15px 20px;
  background: $white;
  margin-bottom: 22px;
  .child-company {
    margin-left: 6px;
    color: $darkgrey;
    font-size: 0.95rem;
  }
  .flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  h2 {
    font-weight: 500;
    color: $darkblue;
    margin-bottom: 0;
  }
  .fill-container-4 {
    display: flex;
    & > div {
      flex: 1 1;
    }
    label {
      display: block;
      color: $blue;
      font-size: 10px;
    }
    .mb-12 {
      margin-bottom: 12px;
    }
    .mb-4 {
      margin-bottom: 4px;
    }
    .large-content {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 12px;
    }
  }
  .no-margin {
    margin: 0;
  }
  .blue {
    color: $blue;
  }
}

select {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;

  padding-right: 27px;
  background-image: url(svg-encode(down-arrow($darkblue)));
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 12px 8px;
  background-color: $medlightgrey;
  margin-left: 10px;
}

#CreditTermCompanyTermRentals,
#StatementTermRentals,
#AccountsReceivable,
#CreditTermMemos {
  .clickable {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .pink {
    color: $pink;
  }
  .flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 5px 5px 20px;
    p {
      margin: 0;
      font-weight: bold;
    }
  }
  .dark {
    color: $white;
    background: $navyblue;
    .btnIcon {
      background: $blue;
      height: 30px;
      width: 234px;
      svg {
        fill: $white !important;
      }
    }
  }
  .table-toggle {
    p {
      font-weight: normal;
      color: #7194ac;
      white-space: nowrap;
      &:hover {
        cursor: pointer;
      }
    }
    p.active {
      font-weight: bold;
      color: $white;
    }
    .ml-17 {
      margin-left: 17px;
    }
  }
  .top-align-table {
    td {
      vertical-align: top;
    }
    .centered-box {
      height: 30px;
      width: 30px;
      border-radius: 2px;
      background-color: $pink;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.5s;
      cursor: pointer;
    }
    .rTableRow:hover {
      .centered-box {
        opacity: 100;
      }
    }
  }
  .summary-row td {
    border-top: 3px solid $midblue !important;
  }

  .newStatementBtn {
    min-width: 12rem;
    bottom: 2.5rem;
    right: 9.7rem;
  }

  label.creditMemos, label.debitMemos { font-weight: bolder };
  label:not(.creditMemos, .debitMemos) {
    color: $darkblue;
    font-weight: normal;
  }
  
}
.top-align-table {
  td {
    vertical-align: top !important;
  }
}
.rentalStatementFeeModal {
  th {
    text-align: center;
    &.left {
      text-align: left;
    }
  }
  .content {
    background: $white !important;
    border-radius: 8px;
    max-width: 350px !important;
    h3 {
      color: $pink !important;
    }
    .button-box {
      display: flex;
      margin-top: 20px;
      .btn-delete-bg {
        width: 195px;
        background: $pink;
      }
      .btn-cancel-bg {
        max-width: 80px;
        padding: 0;
        flex: 0 0 80px;
        background: $darkgrey;
        color: $darkblue;
      }
    }
  }
  input {
    text-align: right;
    font-weight: bold;
    background: $lightgrey;
  }
}

#CreditTermStatements {
  .pink {
    color: $pink;
  }
}
