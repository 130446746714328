.ticketsHeaders {

  display: inline-block;
  width: 145px;
  height: 120px;
  background-color: #278ada;
  margin-top: 20px;
  float: left;

  .span1 {
    display: flex;
    justify-content: center;
    align-content: center;

    .addCircle {
      margin-top: 38px;
      fill: #54c06e;
    }
  }

  .span2 {
    display: flex;
    justify-content: center;
    align-content: center;
    color: #ffffff;
  }
}

.ticketHeadersWithArrows {
  margin-right: 30px;
}
