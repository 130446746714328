.tax-exemption-header {
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  border-bottom: 1px solid #ced8e3;
  margin-top: 10px;
  padding-bottom: 3px;
}


.tax-exempt-checkboxes {
  .tax-exempt-checkbox {
    margin-left: 40px;
    margin-top: 12px;

    label {
      margin-left: 15px;
    }
  }
}

.tax-exempt-number-form {
  margin-top: 15px;

  input {
    width: 250px;
  }

  .actions {
    display: block;
    width: 100%;
    margin-top: 10px;

    .primary {
      width: 165px;
    }
    .cancel {
      width: 80px;
    }
  }
}