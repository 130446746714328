.truckWrapper {
  #orderContents {
    display: flex;
    flex-direction: column;
  }
  .contents {
    display: grid;
    padding: 20px;
    background: white;
    column-gap: 35px;
    @include breakpoint($tablet) {
      grid-template-columns: 1fr 1fr;
    }

    .rental-transport-element{
      
      .row-content{
        label{ 
          display: block; 
          margin-bottom: 2px;
          max-width: 100%;
          white-space: normal;
          min-height: 16px;
        }
      }
    }

    .assigned,
    .unassigned {
      h3 {
        margin: 0;
        border: 0;
      }
      a {
        display: grid;
        // @ToDo span 2nd column text to new line
        grid-template-columns: 25px 165px 1fr 25px;
        padding: 10px 0;
        border-bottom: 1px solid $meddarkgrey;
        color: $darkblue;
        
        &.remove-columns {
          grid-template-columns: none;
        }

        .marker {
          grid-row: span 2;
        }
        strong label {
          margin-left: 5px;
          color: $darkblue;
          .span-margin {
            margin-left: 30px;
          }
        }
      }
      .truck-route {
        a:last-of-type {
          padding-bottom: 0px;
        }
        &.last {
          a:last-of-type {
            border-bottom: none;
          }
        }
      }

      .mt-10 {
        margin-top: 10px;
      }
      .mb-10 {
        margin-bottom: 10px;
      }

      .up-down-Icon {
        padding-right: 10px;

        img {
          vertical-align: middle;
          margin-right: 15px;
        }
      }

      .rentalInfo {
        display: grid;
        grid-template-columns: 1fr 2.08fr 0.2fr;
      }
    }
    .assigned {
      grid-column: 1;
      h4 {
        color: $blue;
        a {
          float: right;
          grid-template-columns: auto;
          padding: 0;
          border: 0;
          text-decoration: underline;
        }
      }
      .section {
        margin-bottom: 40px;

        .teamMembers {
          .teamMemberBadge {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
    
            .icon {
              margin-right: 8px;
            }
          }
  
          &:last-child {
            margin-bottom: 16px;
          }
          
          margin-top: 8px;
          padding: 0;
        }
      }
    }
    .blue-bold-line {
      border-top: 2px solid $blue;
      &.pink {
        border-top-color: #d3347f;
      }
    }
    .orange-bold-line {
      border-top: 2px solid $orange;
    }
    .unassigned {
      grid-column: 2;
      &.orders {
        margin-top: 40px;
      }

      h4 {
        color: $orange;
        margin-bottom: 10px;
        a {
          color: $orange;
          float: right;
          grid-template-columns: auto;
          padding: 0;
          border: 0;
          text-decoration: underline;
        }
      }
    }
  }
}
.chrome-picker.truck-color-picker {
  position: absolute;
  margin-top: 40px;
}
.color-picker-element {
  display: flex;
  flex-direction: column;

  .popover {
    position: absolute;
    z-index: 2;
  }
  label {
    cursor: pointer;
  }
  .cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  .paint-bucket {
    height: 25px;
  }
}
.pick-ups {
  .assigned {
    .up-down-Icon {
      color: #278ADA;
    }
  }
}
.date-picker-arrow {
  @media screen and (max-width: $tablet) {
    display: none;
  }
  margin-left: 10px;
  svg {
    display: inline-block;
    user-select: none;
    height: 12px;
    width: 8px;
    fill: #278ada;
    vertical-align: middle;
  }
}

.toggle-add-truck {
  width: calc(100% - 5px);
  margin-top: 20px;
}

.add-truck-row {
  vertical-align: middle;
  display: flex;
  justify-content: center;

  &> svg {
    cursor: pointer;
  }
}
