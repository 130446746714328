.maintenanceWrapper {
  // display: grid;
  // grid-template-columns: 1.16fr 1fr;
  // grid-template-rows: auto auto;
  display: flex;
  flex-direction: column;

  @include breakpoint($tablet){
    
    display: grid;
    grid-template-columns: 1.16fr 1fr;
    grid-template-rows: auto auto;
  }

  .maintenanceHeader {
    display: flex;
    grid-column: 1 / span 2;

    .status {
        display: flex;
        float: right;
        justify-self: flex-end;
        margin-left: auto;
        align-items: center;
        height: 40px;
        margin-bottom: 20px;
        gap: 12px;
      
      
        h4 {
          font-size: 10px;
          line-height: 14px;
          margin-bottom: 0px;
          font-weight: normal;
        }
      
        select {
          display: inline-block;
          margin: 0px;
          font-weight: bold;
        }

        a {
          display: flex;
          align-items: center;
        }
      }
  }
}