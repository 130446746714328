.rentalConnect {
	grid-template-rows: auto auto 1fr;

	header {
		.tabWrapper a.active {
			background: linear-gradient(135deg, #E8CB66 0%, #25AFBC 50%, #278ADA 100%);
			background-attachment: fixed;
			color: $white;

			svg {
				fill: $white;
			}
		}
	}
	.subNavWrapper {
		display: block;
		height: auto;
		grid-column: 1 / span 8;
		@include breakpoint($tablet) {
			grid-column: 1 / span 14;
			grid-row: 2;
		}

		.subNav {
			position: static;
			background: linear-gradient(135deg, #E8CB66 0%, #25AFBC 50%, #278ADA 100%);
			background-attachment: fixed;

			.subNavMenu {
				a {
					width: calc(50% - 10px);
					margin: 0 10px 0 0;
					color: $midblue;
					@include breakpoint($tablet) {
						width: 110px;
						margin: 0 30px 0 0;
					}

					&.selected, &:hover {
						border-bottom: 5px solid $white;
					}
					&.selected {
						font-weight: $bold;
						color: $white;
					}
					&:last-of-type() {
						margin: 0 0 0 10px;
						@include breakpoint($tablet) {
							margin: 0;
						}
					}
				}
			}
		}
	}
	.content {
		padding-top: 20px;
		grid-row: 3;
	}

	.subRentSelect {
		@include span(full);

		label {
			float: left;
			margin-right: 10px;
			font-weight: $bold;
			line-height: 30px;
			color: $blue;
		}
		.Select {
			@include breakpoint($tablet) {
				@include span(1 of 3);
			}

			.Select-clear-zone {
				display: none;
			}
		}
	}
	.connectCategories {
		@include container;

		.catFilter {
			font-weight: $bold;
			padding: 20px 0;
			margin: 0;
			line-height: 30px;

			input[type="search"] {
				width: 100%;
				margin: 0;
				@include breakpoint($tablet) {
					width: span(5 of 9);
				}
			}
			label {
				display: none;
				width: span(2 of 9);
				padding-right: 10px;
				color: $darkblue;
				text-align: right;
				@include breakpoint($tablet) {
					display: inline-block;
				}
			}
		}
		.primaryCategories {
			display: grid;
			grid-gap: 20px;
			margin-bottom: 20px;
			@include breakpoint($tablet) {
				grid-template-columns: repeat(12, 1fr);
			}

			& > a {
				position: relative;
				height: 200px;
				padding: 0 20px;
				background-color: $blue;
				color: $white;

				@include breakpoint($tablet) {
					&:nth-of-type(-n+3) {
						grid-column: span 4;
					}
					&:nth-of-type(n+4) {
						grid-column: span 3;
					}
				}

				h4 {
					line-height: 40px;
					color: $white;

					svg {
						height: 14px;
						width: 14px;
						margin-right: 5px;
						fill: $white;
						vertical-align: middle;
					}
				}
				img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 120px;
				}
			}
		}
		.allCategories {
			@include breakpoint($tablet) {
				display: grid;
				grid-template-columns: repeat(6, 1fr);
			}

			.categoryList {
				h4 {
					margin-bottom: 0;
					line-height: 40px;

					svg {
						height: 14px;
						width: 14px;
						margin-right: 5px;
						fill: $blue;
						vertical-align: middle;
					}
				}
				ul {
					margin-bottom: 20px;

					li a {
						color: $darkblue;
					}
				}
			}
		}
	}
	.connectFilter {
		@include breakpoint($tablet) {
			@include span(1 of 4);
		}

		input[type="search"] {
			width: 100%;
		}
		h2 {
			margin: 0;
			line-height: 40px;
		}
		.geofence {
			margin: 20px 0 40px;

			input[type="text"] {
				width: 100%;
			}
			label {
				display: inline-block;
				width: span(1 of 4);
				color: $blue;
				line-height: 22px;
			}
			select {
				width: calc(#{span(3 of 4)} - 35px);
				margin-right: 5px;
			}
			.btnIcon {
				width: 30px;
				margin: 0 0 5px;
				background-color: $blue;
				vertical-align: bottom;

				svg {
					height: 15px !important;
					width: 16px !important;
					margin-right: 0;
					fill: $white;
				}

				&:hover {
					background-color: $lightblue;
				}
			}
		}
		.checkList {
			& > div {
				border-bottom: 1px solid #CED8E3;

				&:nth-child(1) {
					border-top: none;
				}
			}

			.checkListItem {
				color: $blue !important;

				.check {
					&.on > svg {
						color: $white;
						fill: $blue;
					}
					&.off > svg {
						fill: $meddarkgrey;
					}
				}

				& > button {
					margin-right: 0 !important;

					svg {
						fill: $meddarkgrey !important;
					}
				}

				& + div {
					padding-left: 1000px !important;
					margin-left: -1000px !important;
					background-color: $meddarkgrey;

					.checkListItem {
						margin-left: 0 !important;

						.check {
							&.off > svg {
								fill: $darkgrey;
							}
						}
					}
				}
			}
		}
		.scrollarea {
			max-height: 500px;
			padding-left: 1000px !important;
			margin-left: -1000px !important;
			margin-right: -5px;

			.scrollarea-content {
				padding-left: 1000px !important;
				margin-left: -1000px !important;
			}
		}

		.mobileFilter {
			left: 0;
		}
	}
	.connectItems {
		margin-bottom: 60px;
		@include breakpoint($tablet) {
			@include push(1 of 24);
			@include span(17 of 24);
		}

		.grid {
			tbody .rTableRow {
				td {
					@include breakpoint($tablet) {
						padding-top: 20px;
						padding-bottom: 20px;
						vertical-align: top;
					}

					h6 {
						color: $blue;
						@include breakpoint($tablet) {
							margin-top: -15px;
						}
					}
					.pricingInfo {
						display: inline-block;

						label {
							display: block;
						}
					}
					label {
						display: block;
						margin-right: 15px;
						@include breakpoint($tablet) {
							display: inline-block;
							vertical-align: top;
						}

						&.caption {
							position: static;
							display: block;
							margin: 10px 0 0;
							font-weight: $regular;
							line-height: 12px;
							white-space: normal;
						}
					}

					input[type="text"] {
						width: 50px;
						margin-right: 5px;
						cursor: initial;
					}

					&:nth-of-type(3) {
						font-weight: $bold;
					}
					&:last-of-type() {
						@include breakpoint($tablet) {
							width: 170px;
						}

						input[type="text"] {
							width: 100%;
							margin: 10px 0;
							@include breakpoint($tablet) {
								width: 50px;
								margin: -8px 5px -7px 0;
							}
						}
						.btn {
							display: block;
							width: 100%;
							padding: 0 10px;
							@include breakpoint($tablet) {
								display: inline-block;
								width: auto;
								margin: -8px 0 -7px;
								visibility: hidden;
							}
						}
						&.highlight .btn {
							visibility: visible;
						}
					}
				}
			}

			&:hover {
				td {
					&:last-of-type .btn {
						visibility: visible;
					}
				}
			}
		}
	}
}

.connectProduct {
	clear: both;

	.product {
		@include breakpoint($tablet) {
			grid-template-rows: auto;
		}

		.summaryPricing {
			font-size: 12px !important;
			font-weight: $regular !important;
			line-height: 18px !important;

			label.caption {
				margin: 10px 0 0;
				color: $darkgrey;
				white-space: normal;
			}
			.quantitySelect {
				display: block;
				margin-top: 10px;

				input[type="text"] {
					width: 100%;
					background-color: $lightgrey;
				}
				.btn {
					width: 100%;
					padding: 0;
				}
			}
		}
	}

	.connectCompany {
		figure {
			margin-bottom: 10px;
			@include maintain-aspect-ratio(16, 9);
			@include breakpoint($tablet) {
				@include span(2 of 12);
			}
		}
		.details {
			@include breakpoint($tablet) {
				@include span(3 of 12);
				@include post(7 of 12);
				padding-left: 20px;
			}

			.actions {
				margin-top: 10px;

				.btnChat {
					place-self: center start;

					svg {
						height: 21px;
						width: 22px;
						fill: $blue;
						vertical-align: middle;
					}
				}
			}
		}
	}
}

.eventSubRentals {
	.noData {
		position: relative;
		height: 440px;
		margin: 0;
		background: transparent url("../../../images/bkgd-Connect.png") no-repeat center center;

		a {
			position: absolute;
			top: 50px;
			left: 50%;
			transform: translate(-50%, 0);
			height: 200px;
			width: 200px;
			padding: 80px 10px 0;
			@include border-radius(50%);
			background-color: $darkblue;
			font-size: 22px;
			font-weight: $light;
			line-height: 24px;
			color: $blue;
			text-align: center;

			svg {
				position: absolute;
				top: 40px;
				left: 50%;
				transform: translate(-50%, 0);
				height: 25px;
				width: 25px;
				fill: $green;
			}
		}
	}
	h1 {
		margin: 40px 0 20px;
	}
	.subRental {
		min-height: 250px;
		margin-bottom: 20px;
		@include breakpoint($tablet) {
			display: flex;
		}

		section {
			margin: 0;
			@include breakpoint($tablet) {
				width: span(2 of 3);
			}

			.rTableCell, td {
				label, span {
					display: block;
				}
			}
			&.summary {
				background-color: $navyblue;
				@include breakpoint($tablet) {
					width: span(1 of 3);
				}

				.btnChat {
					line-height: 40px;

					svg {
						height: 21px;
						width: 22px;
						fill: $white;
						vertical-align: middle;
					}
				}
				& > div {
					border-top: 1px solid $darkblueHover;
					line-height: 39px;
					color: $white;

					label {
						font-size: 14px;
						font-weight: $bold;
						color: $blue;
					}
					span {
						float: right;

						span {
							float: none;
						}
					}
				}
				.btn {
					width: 100%;
					margin: 10px 0 0;
				}
				p {
					margin: 10px 0 0;
					color: $darkgrey;
				}
			}
		}
	}
}

.supplierRental {
	display: block;
	position: relative;

	.topAction {
		text-align: right;
		@include breakpoint($tablet) {
			position: absolute;
			top: 0;
			right: 0;
			width: span(1 of 4);
		}

		.btn {
			width: 100%;
			margin: 0;
			padding: 0;
		}
	}
	.actions {
		span {
			display: inline-block;
			width: calc(#{span(1 of 4)} - 5px);
			margin-right: 5px;
		}
	}
	.details {
		clear: both;
		display: grid;
		grid-gap: 10px;
		@include breakpoint($tablet) {
			grid-template-columns: 9fr 9fr 6fr;
		}

		& > * {
			margin: 0;
		}
		.rentalSchedule, .rentalDelivery {
			.subHeading {
				display: block;
				font-weight: $bold;
				color: $blue;
			}
			input[type="text"], input[type="password"], select, textarea {
				width: calc(#{span(full)} - 5px);
				margin: 0 5px 5px 0;

				&.lg {
					width: calc(#{span(3 of 4)} - 5px);
				}
				&.med {
					width: calc(#{span(2 of 4)} - 5px);
				}
				&.sm {
					width: calc(#{span(1 of 4)} - 5px);
				}
			}
			.check {
				margin: 10px 0;
			}
			.react-datepicker-component {
				width: calc(#{span(2 of 4)} - 5px);
				margin-right: 5px;
				@include breakpoint($tablet) {
					width: calc(#{span(2 of 5)} - 5px);
				}
			}
			.rc-time-picker {
				display: inline-block;
				width: calc(#{span(2 of 5)});
				margin-right: 5px;
				@include breakpoint($tablet) {
					width: calc(#{span(1 of 4)} - 5px);
				}
			}
		}
		.rentalSchedule {
			label {
				display: block;
				@include breakpoint($tablet) {
					display: inline-block;
					width: 80px;
				}
			}
		}
		.rentalItems {
			@include breakpoint($tablet) {
				grid-column: 1 / span 2;
			}
		}
		.payment {
			@include breakpoint($tablet) {
				grid-column: 3;
				grid-row: 1;
				align-self: start;
			}

			.summary {
				border-bottom: 1px solid $meddarkgrey;
				font-weight: $bold;
				margin-bottom: 5px;
				line-height: 40px;

				span {
					float: right;
					font-weight: $regular;
				}
			}
			.selection {
				@include container;
				position: relative;

				input[type="text"] {
					position: absolute;
					right: 0;
					bottom: -8px;
					z-index: 10;
				}
			}
			input[type="text"] {
				@include span(full);

				&.med {
					@include span(1 of 2);
				}
			}
			select {
				@include span(full);

				&.med {
					@include span(1 of 2);

					&:nth-of-type(2) {
						width: calc(50% - 5px);
						margin-left: 5px;
					}
				}
			}
			p {
				margin: 10px 0;
			}
			form {
				@include container;
			}
			.check {
				margin-bottom: 15px;
			}
			.actions {
				a {
					display: block;
					line-height: 30px;
					text-align: center;
					text-decoration: underline;
				}
			}
		}
		.billing {
			@include breakpoint($tablet) {
				grid-row: 2 / span 2;
				grid-column: 3;
				align-self: start;
			}
		}
	}
	.itemDelete {

		.btnLink {
			width: auto;
			padding: 0;
			color: $red;

			svg {
				position: static;
				transform: translate(0, 0);
				margin-right: 5px;
			}
		}
	}
}

svg.changed {
	float: right;
	height: 12px;
	width: 15px;
	margin: 14px 0 0 5px;
	fill: $orange;
}
