.locations {
  .content {
    grid-column: 1 / span 2;
  }
  h1 {
    margin-bottom: 0;
  }

  .right {
    margin: 0 0 10px;
    @include breakpoint($tablet) {
      grid-column: 2;
      place-self: start end;
      margin: 5px 0 0;
    }

    .btnIcon {
      padding: 0 20px;
    }
  }
  section {
    @include breakpoint($tablet) {
      grid-column: 1 / span 2;
      display: grid;
      grid-template-columns: 4fr minmax(160px, 30%) 5fr;
      grid-column-gap: 40px;
    }

    & > div {
      margin-bottom: 20px;

      figure {
        @include maintain-aspect-ratio(16, 9);
        margin-bottom: 20px;
      }
      h2 {
        color: $darkblue;
        line-height: 24px;
      }
      .btn {
        width: 100%;
        padding: 0;
        margin: 0;
      }

      p {
        overflow-wrap: break-word;
      }
    }

    & > div:last-of-type {
      word-break: break-word;
    }

    h6 {
      margin-bottom: 5px;
    }
  }
}

.btnLocations {
  svg {
    fill: $green;
    height: 15px !important;
    width: 15px !important;
    margin-right: 5px !important;
    margin-bottom: -3px !important;
  }
}

.smallLocations {
  grid-column: span 2;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3.5%;

  .btn {
    margin-right: 0;
    margin-top: 10px;
  }

  section {
    grid-column: span 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    & div:nth-of-type(2) {
      margin-bottom: 0;
    }
  }
}

.teamLocation {
  section {
    padding-top: 30px !important;

    h2 {
      margin-top: 5px;
    }

    .remit-to-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;

      @include breakpoint($tablet) {
      }
    }
  }

  .editBusinessLink {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}

.businessHeader {
  padding-left: 20px;
}
