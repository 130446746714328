.public-DraftStyleDefault-block {
    margin: 0
}

.public-DraftEditor-content {
    margin-top: 0;
}

.editedText {
  div {
    margin: 0 !important;
    min-height: 5px;
    display: block;
  }

  p{
    margin-bottom: 0;
  }
}
