@keyframes highlightFade {
  from {
    background-color: $lightbluegrey;
  }
  to {
    background-color: transparent;
  }
}

.headerNav {
  display: block;
  font-size: 12px;
  line-height: 39px;
  vertical-align: middle;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    margin-top: 25px;
    line-height: 32px;
  }

  @include breakpoint($tablet) {
    display: inline-flex;
    align-items: center;
  }

  a {
    display: inline-block;
    margin-right: 20px;
    font-weight: $regular;
    letter-spacing: 0.1px;
    color: $darkblue;

    &.selected,
    &.active {
      font-weight: $bold;
      letter-spacing: 0;
      color: $blue;
    }

    &:last-of-type() {
      margin-right: 0;
    }

    &.withTooltip {
      margin-right: 0;
    }

    &.info {
      margin-left: 10px;
    }
  }

  a:not(.selected) {
    opacity: 0.7;
  }
}

#routingHeaderNav {
  padding-left: 0;
  margin-left: 0;
  border-left: 0;

  a {
    &.selected {
      border-bottom: 5px solid #278ada;
      height: 38px;
    }
  }
}

h1 .headerNav {
  @include breakpoint($tablet) {
    min-height: 30px;
    padding-left: 25px;
    margin-left: 25px;
    border-left: 1px solid $meddarkgrey;
  }

  .lesser-serial-numbers {
    margin: 0 0 3px 5px;
    height: 16px !important;
    width: 16px !important;

    circle {
      fill: $orange;
    }

    path {
      fill: white;
    }
  }
}

.tabbedNav {
  a {
    display: inline-block;
    margin-left: 20px;
    letter-spacing: 0.1px;
    color: $darkgrey;

    &.selected {
      font-weight: $light;
      letter-spacing: 0;
      color: $blue;
    }

    &:first-of-type() {
      margin-left: 0;
    }
  }
}

.check {
  position: relative;
  display: inline-block;
  white-space: nowrap;

  input[type='checkbox'] {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }

  svg {
    @include breakpoint($tablet) {
      vertical-align: sub;
    }
  }

  label {
    display: inline-block;
    margin-left: 5px;
    vertical-align: top;
  }

  &.on > svg {
    color: $lightgrey;
    fill: $blue;
  }

  &.off > svg {
    fill: $meddarkgrey;
  }

  &.disabled {
    cursor: not-allowed;

    label {
      color: $darkgrey;
    }
  }

  .wrap {
    white-space: normal;
  }
}

.radio {
  & > div {
    & > div {
      height: 16px !important;
      width: 17px !important;
      margin: 3px 12px 0 0 !important;
      transition: none !important;
    }
  }

  svg {
    left: 0;
    height: 100% !important;
    width: 100% !important;
    margin: 0 !important;
    transition: none !important;

    &:nth-of-type(odd) {
      fill: $darkgrey !important;
    }
  }

  span {
    display: none !important;
  }

  label {
    padding: 2px 0;
    font-size: 16px;
    line-height: 20px !important;
    white-space: normal;

    @include breakpoint($tablet) {
      padding: 4px 0;
      font-size: 12px;
      line-height: 16px !important;
    }

    span {
      display: inline !important;
    }
  }

  &.hidden {
    display: none !important;
  }

  .verticalTop {
    vertical-align: top;
    width: 40%;
  }
}

.pricingDescription {
  grid-column: span 4;
  color: #7194ac;
  font-family: Heebo;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 14px;
  text-wrap: wrap;
}

.bold {
  font-weight: bold;
  margin-bottom: 0px;
}

.contact_support {
  font-size: 10px;
  font-weight: 500;
  margin-left: 2px;
}

.radioList {
  margin-bottom: 10px;
}

.align-vertical-items {
  padding-left: 15px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.radioGroup {
  display: grid;

  @include breakpoint($tablet) {
    grid-template-columns: 1fr 1fr !important;
  }

  .radio label {
    font-weight: $bold;
  }

  p {
    padding: 0 40px 0 22px;
    line-height: initial;
  }
}

.radio-toggle {
  display: flex;
  margin-right: 0.5rem;
  height: 1.5rem;
  align-items: end;

  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    margin-right: 0.2rem;
    font: inherit;
    color: $darkgrey;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid $darkgrey;
    border-radius: 50%;
    transform: translateY(-0.23em);
    display: grid;
    place-content: center;
  }

  input[type='radio']:checked {
    border: 0.15em solid $blue;
  }

  input[type='radio']::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em $blue;
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }
}

.levelList {
  @include container;
  margin-bottom: 10px;

  .level {
    float: left;
    width: span(1 of 3) !important;
    text-align: center;

    & > div {
      display: block !important;

      & > div {
        position: static !important;
        float: none !important;
        height: 30px !important;
        width: 100% !important;
        margin: 0 !important;
        transition: none !important;
        text-align: center;

        & > div:first-of-type() {
          position: relative;
          display: inline-block;
          height: 30px;
          width: 30px;
        }
      }
    }

    svg {
      left: 0;
      height: 100% !important;
      width: 100% !important;
      margin: 0 !important;
      background-color: $white;
      fill: $green !important;
      transition: none !important;
      z-index: 2;

      &:nth-of-type(odd) {
        padding: 8px;
        background-color: transparent;
        fill: $meddarkgrey !important;
      }
    }

    input {
      z-index: 3 !important;
    }

    span {
      display: none !important;
    }

    label {
      float: none !important;
      width: 100% !important;
      padding: 10px 0 0;
      font-size: 12px;
      line-height: 16px !important;
      white-space: normal;
    }

    &:after {
      content: ' ';
      position: absolute;
      top: 14px;
      left: calc(50% + 5px);
      height: 2px;
      width: calc(100% - 10px);
      background-color: $meddarkgrey;
      z-index: 1;
    }

    &:last-of-type():after {
      display: none;
    }

    &.on {
      label {
        font-weight: $bold;
      }
    }
  }
}

.levelList.smallLevelList {
  width: 60% !important;

  .level.smallLevel {
    width: 20% !important;
  }
}

.autocomplete {
  position: relative;
  margin-bottom: 5px;

  input[type='text'] {
    width: 100%;
    margin: 0;
  }

  .results {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    max-height: 400px;
    border-top: 1px solid $meddarkgrey;
    overflow: auto;
    z-index: 50;

    li {
      padding: 0 8px;
      background-color: $white;
      line-height: 35px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;

      @include breakpoint($tablet) {
        line-height: 30px;
      }

      &.highlight {
        color: $darkblue;
        background-color: $meddarkgrey;
      }
    }
  }

  &.search {
    input[type='text'] {
      padding-left: 27px;
      background-image: url(svg-encode(search-icon($blue)));
      background-repeat: no-repeat;
      background-position: left 9px center;
      background-size: 12px 12px;

      @include breakpoint($tablet) {
        background-size: 11px 11px;
      }
    }
  }
}

.monthPicker {
  display: inline-block;
  margin: 0 5px 5px 0;
  font-size: 16px;
  line-height: 20px;

  @include breakpoint($tablet) {
    font-size: 12px;
    line-height: 15px;
  }

  & > div:first-child() {
    label {
      position: relative;
      display: inline-block;
      height: 35px;
      width: calc(100% - 48px);
      padding: 8px 8px 7px 43px;
      background-color: $white;
      background-repeat: no-repeat;
      background-position: left center;
      border-radius: 3px 2px 2px 3px;
      cursor: pointer;
      z-index: 0;

      @include breakpoint($tablet) {
        height: 30px;
        padding: 8px 8px 7px 38px;
      }

      &:before,
      &:after {
        content: ' ';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 35px;
        z-index: 0;

        @include breakpoint($tablet) {
          width: 30px;
        }
      }

      &:before {
        background-color: $blue;
        border-radius: 2px;
      }

      &:after {
        background: transparent url(svg-encode(calendar-icon($white))) no-repeat
          center center;
        background-size: 14px 14px;
      }
    }

    a {
      display: inline-block;
      padding: 0 8px;
      cursor: pointer;

      svg {
        fill: $blue;
        vertical-align: middle;
      }
    }
  }
}

.colorPicker {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 4px;

  @include breakpoint($tablet) {
    grid-gap: 5px;
  }

  li {
    @include maintain-aspect-ratio(1, 1);
    padding: 5px 4px;

    @include breakpoint($tablet) {
      padding: 3px 2px;
    }

    .check {
      height: 100%;
      width: 100%;
      margin: 0 !important;

      svg {
        height: 100% !important;
        width: 100% !important;
      }

      &.on > svg {
        color: #000;
        fill: transparent;
      }

      &.off > svg {
        fill: transparent;
      }
    }

    &.red {
      background-color: $red;
    }

    &.orange {
      background-color: $orange;
    }

    &.yellow {
      background-color: #ffdf6d;
    }

    &.green {
      background-color: $green;
    }

    &.blue {
      background-color: $blue;
    }

    &.purple {
      background-color: $purple;
    }

    &.pink {
      background-color: $pink;
    }

    &.white {
      padding: 4px 3px;
      border: 1px solid $darkgrey;
      background-color: $white;

      @include breakpoint($tablet) {
        padding: 2px 1px;
      }
    }

    &.brown {
      background-color: #6d584c;

      .check.on > svg {
        color: $lightgrey;
      }
    }

    &.black {
      background-color: #000;

      .check.on > svg {
        color: $lightgrey;
      }
    }

    &.grey {
      background-color: $meddarkgrey;
    }

    &.silver {
      background: linear-gradient(
        225deg,
        #e0e7ef 0%,
        #8897a2 50%,
        #a7c5d3 100%
      );
    }

    &.gold {
      background: linear-gradient(
        224.91deg,
        #f0da86 0%,
        #af9037 50%,
        #eba422 100%
      );
    }

    &.clear {
      padding: 4px 3px;
      border: 1px solid $darkgrey;
      background: linear-gradient(
        to top right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) calc(50% - 0.8px),
        rgba(143, 169, 190, 1) 50%,
        rgba(0, 0, 0, 0) calc(50% + 0.8px),
        rgba(0, 0, 0, 0) 100%
      );

      @include breakpoint($tablet) {
        padding: 2px 1px;
      }
    }
  }
}

.blue-text {
  color: $blue;
}

.colorSelector {
  position: absolute;
  top: 36px;
  left: 16px;
  border: 4px solid $lightgrey;
  @include border-radius(2px);

  .swatch {
    height: 20px;
    width: 100px;
    background-color: white;
    @include border-radius(2px);
    cursor: pointer;

    label {
      position: absolute;
      top: 0;
      left: 10px;
      font-size: 10px;
      line-height: 20px;
      cursor: inherit;
    }

    & > div {
      height: 100%;
      width: 100%;
    }
  }
}

.uploadZone {
  font-size: 12px;

  .drop {
    position: relative;
    background-color: $darkblue;
    color: $meddarkgrey;
    text-align: center;
    text-transform: uppercase;

    .placeholder {
      line-height: initial;
      cursor: pointer;

      svg {
        height: 25px;
        width: 100%;
        margin-bottom: 5px;
        fill: $blue;
      }
    }

    &.toggle {
      width: 100%;
      background-color: $white;
      color: $blue;
      text-align: left;
      text-transform: none;
    }
  }

  .draggable {
    cursor: move;

    &.isOver {
      outline: 2px solid $blue;
    }
  }

  img {
    height: 100%;
    max-width: 100%;

    &.isDragging {
      outline: 2px dashed $blue;
    }

    &:hover {
      & + .delete {
        visibility: visible;
      }
    }
  }

  .btnLink {
    svg {
      margin: 0;
    }

    .svgMarginOR {
      svg {
        margin: 0 5px;
      }
    }

    &.delete {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      visibility: hidden;

      &:hover {
        visibility: visible;
      }
    }
  }

  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 5px;

    li {
      position: relative;
      background-color: $darkblue;

      .add {
        height: 100%;
        width: 100%;
        background-color: $darkblue;

        svg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          fill: $cobalt;
        }

        &:hover {
          svg {
            fill: $cobaltHover;
          }
        }
      }
    }
  }
}

.linkInput {
  grid-column: span 4;
  display: grid;
  grid-template-columns: 1fr 30px;
  grid-column-gap: 5px;

  input[type='text'] {
    grid-column: 1 !important;
  }

  .btnIcon {
    width: 100%;
    margin: 0;
    background-color: $blue;
    vertical-align: top;

    svg {
      margin: 0;
      fill: $white;
    }
  }
}

.cropper {
  overflow: hidden;
  height: 200px;
  width: 100%;

  @include breakpoint($tablet) {
    height: 315px;
    width: 560px;
  }

  .cropper-container {
    .cropper-modal {
      background-color: $navyblue;
    }

    .cropper-crop-box {
      .cropper-point {
        height: 5px;
        width: 5px;
        opacity: 0.75;
      }
    }
  }
}

.open-mobile-left-pane-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0e2b44;
  border-radius: 50%;
  z-index: 1000;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);


  svg {
    fill: #278ada;
    scale: 1.2;
  }
}

.close-mobile-left-pane-container {
  position: fixed;
  left: 4px;
  width: 30px;
  height: 30px;
  bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: #278ada;
    scale: 1.7;
  }
}

.itemList {
  clear: both;
  padding: 0;
  margin: 40px 0 0;

  &.m-0 {
    margin: 0;
  }

  &.hasHeader {
    margin-top: 0;
  }

  &.noActions {
    margin-top: 80px;
  }

  li {
    position: relative;
    font-size: 12px;
    line-height: 39px;
    font-weight: $light;
    color: $meddarkgrey;
    /*border-bottom: 1px solid $navyblue;*/
    border-bottom: 1px solid #0c263b; //rgba(7,24,39,0.3);

    &:nth-child(1) {
      border-top: 1px solid #0c263b; //rgba(7,24,39,0.3);
    }

    &.selected.subItem {
      margin-left: 24px;

      a {
        color: $lightgrey;
        font-weight: $regular;
      }
    }

    &.selected {
      background-color: $navyblue;
      font-weight: $bold;
      color: $white;

      a {
        color: $white;
      }

      li {
        background-color: $darkblue;
        color: $meddarkgrey;

        a {
          color: $meddarkgrey;
        }
      }

      &:nth-child(1) {
        border-top: 1px solid $navyblue;
      }
    }

    a {
      display: block;
      line-height: 39px;
      color: $meddarkgrey;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;

      * {
        cursor: inherit;
      }

      &.expandToggle {
        position: absolute;
        top: 0;
        right: 0;

        svg {
          height: 8px !important;
          width: 12px !important;
        }
      }
    }

    & > div {
      line-height: 39px;

      .check {
        position: absolute;
        top: 15px;
        left: 15px;
        height: 14px;
        width: 14px;
        padding: 0;
        margin: 0;

        @include breakpoint($tablet) {
          top: 12px;
        }

        svg {
          height: 14px !important;
          width: 14px !important;
        }

        &.on > svg {
          color: $darkblue;
          fill: $lightgrey;
        }

        &.off > svg {
          fill: $cobalt;
        }
      }

      label {
        display: inline-block;
        height: auto;
        width: 200px !important;
        padding: 0;
        margin: 0 10px 0 40px;
        color: $white;
        overflow: hidden;
        vertical-align: middle;
        /* overflow re-orients the vertical alignment */

        &.caption {
          width: auto !important;
          margin: 0 0 0 5px;
        }

        &.marquee:hover {
          animation: marquee 4s linear infinite;
        }
      }

      input[type='text'] {
        width: 45px;
        margin: -7px 0;
        background-color: $darkblue;
        color: $white;
        text-align: center;
      }
    }

    ul {
      padding-left: 25px;

      li:last-of-type {
        border-bottom: none;
      }

      &.subList {
        padding-left: 0;

        li {
          background-color: $navyblue;
          border-color: transparent;

          .active {
            font-weight: $bold;
            color: $white;
          }
        }
      }
    }

    .callouts {
      position: absolute;
      top: 0;
      right: 20px;
      text-align: right;

      .alertCallout,
      .readyCallout {
        position: static;
        margin-right: 5px;
      }
    }
  }
}

.iconItemList {
  li {
    img {
      height: 15px !important;
      width: 16px !important;
      margin-right: 10px;
      vertical-align: middle;
    }

    svg {
      height: 15px !important;
      width: 16px !important;
      margin: -2px 10px 0 0;
      fill: $white;
      vertical-align: middle;
    }

    &.selected {
      svg {
        fill: $blue;
      }

      li svg {
        fill: $white;
      }
    }
  }

  .key {
    text-align: left !important;
    margin-top: 20px !important;

    svg {
      height: 15px !important;
      width: 16px !important;
      fill: #ffffff;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}

.iconItemListBigger {
  li {
    margin-bottom: 3.5px;

    .iconBorder {
      padding: 9px;
      border-radius: 50%;
      border: 1px solid $meddarkgrey;
      margin-right: 9px;
    }

    .iconLabel {
      font-size: 16px;
    }

    img {
      width: 18px !important;
      height: 18px !important;
      vertical-align: middle;
    }

    svg {
      width: 18px !important;
      height: 18px !important;
      margin: -2px 0 0 0;
      fill: $white;
      vertical-align: middle;
    }

    &.selected {
      .iconBorder {
        border: 1px solid $blue;
        background-color: $blue;
      }

      li svg {
        fill: $white;
      }
    }
  }
}

.checkList {
  clear: both;
  padding: 0 !important;

  & > div {
    border-bottom: 1px solid #254A67; //rgba(7,24,39,0.3);

    &:nth-child(1) {
      border-top: 1px solid #254A67; //rgba(7,24,39,0.3);
    }
  }

  .checkListItem {
    float: none !important;
    height: auto !important;
    width: auto !important;
    padding: 12px 0 11px 25px !important;
    margin: 0;
    font-size: 12px !important;
    font-weight: $light;
    color: $white !important;
    transition: none !important;

    button {
      div {
        svg {
          fill: $blue !important;
        }
      }
    }

    &.checkEmptyCheckbox {
      padding: 0 !important;

      button {
        width: 100% !important;

        svg {
          fill: $cobalt !important;
          position: absolute !important;
          right: 20px !important;
          top: 15px !important;
        }
      }
    }

    .check {
      position: absolute;
      top: 12px;
      left: 0;
      height: 14px !important;
      width: 15px !important;
      padding: 0;
      margin: 0;

      svg {
        height: 14px !important;
        width: 15px !important;
      }

      &.off > svg {
        fill: $cobalt;
      }
    }

    & > button {
      top: 8px !important;
      height: 24px !important;
      width: 24px !important;
      padding: 0 !important;
      margin-right: 10px !important;

      svg {
        fill: $cobalt !important;
      }
    }

    span {
      display: none !important;
    }

    & + div {
      padding: 0 !important;

      & > div {
        background-color: $darkGray;
      }

      .checkListItem {
        margin-left: 25px !important;
        color: $meddarkgrey !important;

        & > span {
          display: block !important;
        }
      }
    }
  }

  /* Without Checkbox */
  span.itemListItem {
    padding: 0 !important;

    & > div > div {
      a,
      div {
        padding: 12px 0 11px !important;
      }
    }
  }
}

.iconItemList {
  .itemListItem {
    svg {
      height: 15px !important;
      width: 16px !important;
      margin: -2px 10px 0 0;
      fill: $white !important;
      vertical-align: middle;
    }

    &.selected svg {
      fill: $blue !important;
    }
  }
}

.steps {
  counter-reset: section;

  .step {
    counter-increment: section;

    &:before {
      content: counter(section);
    }
  }
}

.maintenanceTabs {
  a {
    padding-right: 20px;
    color: $bluegrey;

    span {
      &.active {
        color: $blue;
        border-bottom: 5px solid $blue;
        font-weight: bold;
        padding-bottom: 11px;
      }
    }
  }
}

.noteContent {
  color: $bluegrey;
}

.scheduled-maintenance-row:hover
  .scheduled-maintenance-settings-icon-container {
  opacity: 1;
  transition: opacity 0.5s ease-in-out, width 0.5s ease-in-out,
    height 0.5s ease-in-out, margin-right 0.5s ease-in-out,
    transform 0.2s ease-in-out;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.scheduled-maintenance-settings-icon-container {
  opacity: 0;
  transition: opacity 0.5s ease-in-out, width 0.5s ease-in-out,
    height 0.5s ease-in-out, margin-right 0.5s ease-in-out,
    transform 0.2s ease-in-out;
  width: 0;
  height: 0;
  margin-right: 0;

  svg path {
    fill: $bluegrey;
  }
}

.scheduled-maintenance-settings-icon-container:hover {
  cursor: pointer;

  svg path {
    fill: $lightbluegrey;
  }
}

.bluegrey {
  color: $bluegrey;
}

.maintenanceHeader {
  padding-bottom: 15px;
  border-bottom: 1px solid $mediumgrey;
}

.mt-15 {
  margin-top: 15px;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  margin-left: 0px !important;
}

.productHeader {
  p {
    color: $blue;
    margin-bottom: 0px;
  }

  strong {
    color: #0e2c44;
    font-family: Heebo;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
  }
}

.fs-48 {
  font-size: 48px !important;
}

.toggle {
  position: relative;
  display: inline-block;
  padding: 0 8px 0 43px;
  margin-bottom: 5px;
  color: $blue;
  @include border-radius(3px 2px 2px 3px);
  background-color: $white;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 35px 35px;
  line-height: 35px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 0;

  @include breakpoint($tablet) {
    padding: 0 8px 0 38px;
    background-size: 30px 30px;
    line-height: 30px;
  }

  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 35px;
    background-color: $blue;
    border-radius: 2px;
    z-index: -1;

    @include breakpoint($tablet) {
      width: 30px;
    }
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 35px !important;
    width: 35px !important;
    padding: 11px;
    fill: $white;

    @include breakpoint($tablet) {
      height: 30px !important;
      width: 30px !important;
      padding: 8px;
    }
  }

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.secondary:after {
    background-color: $cobalt;
  }

  &.add {
    &:after {
      background-color: $green;
    }

    svg {
      path:last-of-type() {
        fill: $green !important;
      }
    }
  }

  &.disabled {
    color: $darkgrey;
    cursor: inherit;
  }

  &.grayToggle {
    background-color: #e5e8e9;
  }
}

.drawer {
  padding-top: 80px;
  background-color: $navyblue !important;
  box-shadow: none !important;
  overflow: hidden !important;
  z-index: 1000000 !important; // Needs to be in front of the Zendesk widget
  overscroll-behavior: contain;
  display: flex;
  flex-direction: column;

  &.filter-drawer {
    @media screen and (max-width: 768px) {
      &.open {
        width: 100% !important;
      }
    }

    .top-container {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      justify-content: space-between;

      .back-button-container {
        height: 25px;
      }

      .linkAction {
        margin: 0 !important;
        font-size: 12px;
        font-weight: 400;
        text-transform: none;
        text-decoration: none;
      }
    }

    .dateSelect {
      margin-top: 15px;
      display: flex;
      flex-direction: column;

      h4 {
        font-size: 16px;
      }

      div {
        width: 100%;
      }

      .dateSelectContent {
        gap: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        label {
          font-weight: 400;
          font-size: 12px;
          text-transform: none;
        }
      }
    }

    background-color: #0e2c44 !important;
    padding: 20px 15px;

    h2 {
      color: white;
      margin-left: 0;
      font-size: 28px;
      font-weight: 500;
      text-transform: none;
    }

    .dateSelect {
      padding: 0;
    }
  }

  &.purchaseOrderDrawer {
    background-color: #1e3e58 !important;
  }

  &.laborDrawer {
    background-color: #1e3e58 !important;
  }

  .occurrenceModalWrapper {
    padding: 0 50px 0 20px;

    & label {
      color: $bluegrey;
    }
  }

  .sampleDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mt5 {
    margin-top: 5px;
  }

  .mb20 {
    margin-bottom: 20px;
  }

  .w30 {
    width: 30%;
  }

  .w35 {
    width: 35%;
  }

  .w45 {
    width: 45%;
  }

  .w50 {
    width: 50%;
  }

  .fields {
    display: flex;
    flex-direction: column;
  }

  .localeFields {
    display: flex;
    flex-direction: row;
    grid-column: span 2;
    grid-gap: 5px;
  }

  .w100 {
    width: 100%;
  }

  .noMargin {
    margin: 0px !important;
  }

  .serviceFields {
    display: flex;
    flex-direction: column;
  }

  .locationWrapper {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .blueBrakeLine {
    border-top: 1px solid #278ada;
  }

  .greyBrakeLine {
    border-top: 1px solid #1b4160;
    padding: 10px 0;
  }

  .hourInput {
    border: transparent;
    background-color: #e5e8e9 !important;
    margin-left: 5px;
    height: 30px;
    border-radius: 3px 2px 2px 3px;
  }

  .timeInOut {
    color: #7194ac;
    margin-bottom: 15px;

    div {
      color: #278ada;
      font-weight: bold;
    }

    span {
      margin-left: 5px;
    }
  }

  .showTimeInOut {
    margin-top: 10px;
  }

  .staffingRecurring {
    color: #c1cdd7;
  }

  &.withForm.w-400 {
    width: 400px !important;
    transform: translate(400px, 0px) !important;

    @include breakpoint($tablet) {
      width: 800px !important;
      transform: translate(800px, 0px) !important;
    }

    &.open {
      transform: translate(0px, 0px) !important;

      @include breakpoint($tablet) {
        transform: translate(400px, 0px) !important;
      }
    }

    .drawerForm {
      @include breakpoint($tablet) {
        left: 400px;

        .drawer {
          width: 400px !important;
        }
      }
    }

    &.isMobile {
      width: 100% !important;
    }

    &.isMobileAndOpen {
      width: 100% !important;
    }
  }

  &.withForm {
    width: 340px !important;
    transform: translate(340px, 0px) !important;

    @include breakpoint($tablet) {
      width: 680px !important;
      transform: translate(680px, 0px) !important;

      &.smallDrawer {
        width: 625px !important;
        transform: translate(625px, 0px) !important;

        .drawerContent {
          width: 54% !important;
        }

        &.open {
          transform: translate(280px, 0px) !important;

          @media screen and (max-width: $tablet) {
            width: 100% !important;
          }
        }

        .drawerForm {
          width: 45% !important;
        }
      }
    }

    &.open {
      transform: translate(0px, 0px) !important;

      @include breakpoint($tablet) {
        transform: translate(340px, 0px) !important;

        @media screen and (max-width: $tablet) {
          width: 100% !important;
        }
      }

      @include breakpoint($phone, $tablet) {
        .drawerForm {
          display: none;
        }
      }

      &.formOpen {
        transform: translate(0px, 0px) !important;

        @include breakpoint($phone, $tablet) {
          .drawerForm {
            display: block;
          }
        }

        &.customerDrawer {
          @include breakpoint($phone, $tablet) {
            .drawerForm {
              overflow-y: hidden;
              display: block;
            }
          }
        }
      }
    }

    .occurrenceModalWrapper {
      padding: 0 50px 0 20px;

      & label {
        color: #cae0f2;
      }
    }

    .drawerContent {
      @include breakpoint($tablet) {
        @include span(4 of 8);
      }
    }

    .drawerForm {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      padding-top: 80px;
      background-color: $darkblue;

      @include breakpoint($phone, $tablet) {
        overflow-y: auto;
      }

      @include breakpoint($tablet) {
        bottom: 0;
        left: 340px;
        @include span(4 of 8);

        .drawer {
          width: 340px !important;
        }
      }

      .linkBack {
        @include breakpoint($tablet) {
          display: none;
        }
      }

      label,
      p {
        color: $blue !important;
      }

      .usageUnitFormText p {
        color: $cobalt !important;
      }
    }

    .actions.pad {
      padding: 0 15px;
    }
  }

  h2 {
    margin: 5px 0 15px 15px;
    color: $cobalt;
    line-height: 22px;
    text-transform: uppercase;
  }

  .linkBack {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 15px;
  }

  .drawerToggle {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    cursor: pointer;

    svg {
      height: 24px;
      width: 24px;
      fill: $blue;
    }
  }

  .btnLink,
  .linkAction {
    float: right;
    margin: 13px 10px 0 0 !important;
  }

  .spinner {
    display: block !important;
  }

  input[type='search'] {
    width: 315px !important;
    margin: 0 0 10px 15px !important;
  }

  .dateSelect,
  .periodSelect {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5px;
    padding: 0 15px;
    margin-bottom: 20px;

    h4 {
      grid-column: 1 / span 2;
    }

    label {
      display: block;
      font-size: 10px;
      line-height: 14px;
      font-weight: $bold;
      color: $darkgrey;
      text-transform: uppercase;

      @include breakpoint($tablet) {
        margin-right: 5px;
      }
    }

    select,
    .react-datepicker-component {
      width: 100%;
    }
  }

  .dateSelect {
    .clear {
      svg {
        height: 15px !important;
        width: 16px !important;
        fill: $darkgrey;
        vertical-align: middle;
        cursor: pointer;
      }

      &:hover {
        svg {
          fill: $darkgreyHover;
        }
      }
    }

    .react-datepicker {
      .react-datepicker-body {
        background-color: $lightgrey;
      }
    }
  }

  .customerSelect,
  .itemSelect {
    padding: 0 15px;
    margin-bottom: 20px;

    .autocomplete,
    select {
      width: 100%;
    }
  }

  .heading {
    margin: 30px 0 15px 15px;
    color: #fff;
    display: inline-block;
  }

  .scrollarea {
    @include breakpoint($tablet) {
      margin-top: 0;
    }

    .itemList {
      margin-top: 0;
    }
  }

  .infinite {
    -webkit-overflow-scrolling: touch;

    & > div > div {
      height: 0 !important;
    }
  }

  .itemList {
    &.footerVisible {
      height: calc(100vh - 300px);

      @include breakpoint($tablet) {
        height: calc(100vh - 280px);
      }
    }

    li {
      border-color: $darkblue;

      a {
        padding-left: 15px;
      }

      &.selected {
        a {
          padding-left: 10px;
          border-left: 5px solid $blue;
        }

        &:nth-child(1) {
          border-top: 1px solid $darkblue;
        }
      }
    }
  }

  .checkList {
    .checkListItem {
      margin-left: 15px !important;

      &.checkEmptyCheckbox {
        margin-left: 0 !important;
      }

      & + div {
        .checkListItem {
          margin-left: 40px !important;

          &.storefrontCategoryFilter {
            margin-left: 10px !important;
          }
        }
      }
    }
  }

  #filterBarTop {
    height: calc(100% - 40px);
    overflow-y: scroll;
  }
}

.rentalFilter__filterBadge {
  display: flex;
  align-items: center;
  height: 35px;
}

.rentalFilter__filterBadgeLabel {
  margin: 0;
  line-height: 12px;
  height: 12px;
  font-size: 12px;
}

.rentalFilter__filterBadgeContent {
  margin: 0;
  line-height: 12px;
  height: 12px;
  font-size: 12px;
}

.deliveryDate {
  margin-top: 10px !important;
}

.noteFooter {
  transform: translate(0, 0);
  opacity: 1;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  z-index: 1400;

  @include breakpoint($tablet) {
    height: 40px;
  }

  &.documentActions {
    display: grid;

    .btn {
      grid-column: span 3;

      &.cancel {
        grid-column: span 1;
      }
    }

    grid-template-columns: repeat(4, 1fr);
  }

  .actions {
    display: block;
  }

  &.noteFooterActions {
    position: static;
    display: grid;

    .btn {
      grid-column: span 3;

      &.cancel {
        grid-column: span 1;
      }
    }

    grid-template-columns: repeat(4, 1fr);
  }

  &.hidden {
    transform: translate(0, 55px);
    opacity: 0;

    @include breakpoint($tablet) {
      transform: translate(0, 40px);
    }
  }
}

.drawerFooter {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 55px;
  width: 340px;
  padding: 5px;
  overflow: auto;
  transform: translate(0, 0);
  opacity: 1;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  z-index: 1400;

  @include breakpoint($tablet) {
    height: 40px;
  }

  &.documentActions {
    display: grid;

    .btn {
      grid-column: span 2;

      &.cancel {
        grid-column: span 1;
      }
    }

    grid-template-columns: repeat(3, 1fr);
  }

  .actions {
    display: block;
  }

  &.footerActions {
    position: static;
    display: grid;

    .btn {
      grid-column: span 2;

      &.cancel {
        grid-column: span 1;
      }
    }

    grid-template-columns: repeat(3, 1fr);
  }

  &.hidden {
    transform: translate(0, 55px);
    opacity: 0;

    @include breakpoint($tablet) {
      transform: translate(0, 40px);
    }
  }
}

.overrideWidth {
  width: 100% !important;
}

.drawerMargin {
  margin: 0 10px;

  label {
    color: $lightgrey !important;
    font-size: 12px !important;
  }
}

.mRXl {
  margin-right: 50px !important;
}

.marginRight {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-top-7 {
  margin-top: 7px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.ptOff {
  padding-top: 0px !important;
}

.pbOff {
  padding-bottom: 0px !important;
}

.mBOff {
  margin-bottom: 0px !important;
}

.mROff {
  margin-right: 0px !important;
}

.mbSm {
  margin-bottom: 5px;
}

.mbMd {
  margin-bottom: 10px;
}

.mbMd-Lg {
  margin-bottom: 15px;
}

.mlSm {
  margin-left: 5px !important;
}

.mtSm {
  margin-top: 5px !important;
}

.mtLg {
  margin-top: 30px !important;
}

.svgMLSm {
  svg {
    margin-left: 5px;
  }
}

.rotated {
  transform: rotate(45deg);
}

.flexAlignCenter {
  display: flex;
  align-items: center;
}

.psidesSm {
  padding: 0 25px !important;
}

.transitionAll {
  transition: all 0.3s ease;
}

.userSelectNone {
  user-select: none;
}

.noteList {
  border-bottom: 1px solid $meddarkgrey;
  margin: 5px 0;
}

.expenseList {
  border-top: 1px solid $meddarkgrey;
  margin: 5px 0;
}

.expenseLineItem {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 5px 0;

  & > div {
    margin-right: 5px;
  }
}

.flexDrawer {
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  background-color: $navyblue !important;
  box-shadow: none !important;
  overflow: hidden !important;
  z-index: 1000000 !important; // Needs to be in front of the Zendesk widget

  h2 {
    height: 44px;
    margin: 5px 0 31px 15px;
    color: $darkgrey;
    line-height: 22px;
    text-transform: uppercase;
    overflow: visible;
  }

  .linkBack {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 15px;
  }

  .drawerToggle {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    cursor: pointer;

    svg {
      height: 24px;
      width: 24px;
      fill: $blue;
    }
  }

  .btnLink,
  .linkAction {
    float: right;
    margin: 5px 10px 0 0 !important;
  }

  .spinner {
    display: block !important;
  }

  input[type='search'] {
    width: 315px !important;
    margin: 0 0 10px 15px !important;
  }

  .dateSelect,
  .periodSelect {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5px;
    padding: 0 15px;
    margin-bottom: 20px;
    width: 100%;

    h4 {
      grid-column: 1 / span 2;
    }

    label {
      display: block;
      font-size: 10px;
      line-height: 14px;
      font-weight: $bold;
      color: $darkgrey;
      text-transform: uppercase;

      @include breakpoint($tablet) {
        margin-right: 5px;
      }
    }

    select,
    .react-datepicker-component {
      width: 100%;
    }
  }

  .dateSelect {
    .clear {
      svg {
        height: 15px !important;
        width: 16px !important;
        fill: $darkgrey;
        vertical-align: middle;
        cursor: pointer;
      }

      &:hover {
        svg {
          fill: $darkgreyHover;
        }
      }
    }
  }

  .customerSelect,
  .itemSelect {
    padding: 0 15px;
    margin-bottom: 20px;

    .autocomplete,
    select {
      width: 100%;
    }
  }

  .scrollarea {
    flex: 1;

    @include breakpoint($tablet) {
      margin-top: 20px;
    }

    .itemList {
      margin-top: 0;
    }
  }

  .infinite {
    margin-right: -20px;
  }

  .itemList {
    &.footerVisible {
      height: calc(100vh - 300px);

      @include breakpoint($tablet) {
        height: calc(100vh - 280px);
      }
    }

    li {
      border-color: $darkblue;

      a {
        padding-left: 15px;
      }

      &.selected {
        a {
          padding-left: 10px;
          border-left: 5px solid $blue;
        }

        &:nth-child(1) {
          border-top: 1px solid $darkblue;
        }
      }
    }
  }

  .checkList {
    .checkListItem {
      margin-left: 15px !important;

      & + div {
        .checkListItem {
          margin-left: 40px !important;
        }
      }
    }
  }

  .drawerFooter {
    position: static;
    padding: 0 15px;
  }
}

.centerToggle {
  display: flex;
  justify-content: center;
  margin-bottom: 10px !important;
}

.viewToggle {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  line-height: 30px;

  & > div {
    width: auto !important;
    margin-right: 8px;
  }

  & > label {
    line-height: initial;
    white-space: normal;
  }

  select {
    margin-left: auto;
  }

  &.rateCalculationsToggle {
    margin-bottom: 24px;

    & > label {
      color: white;

      & > span {
        color: $darkgrey;
        font-size: 10px;
      }
    }
  }
}

.hiddenOnMobile {
  @media only screen and (max-width: $tablet) {
    display: none !important;
  }
}

.filtering {
  background-color: $blue;
  color: $white !important;

  path,
  svg {
    fill: $white !important;
  }
}

.filter {
  @include container;
  width: 100%;
  padding: 5px 5px 0;
  background-color: $meddarkgrey;

  @include breakpoint($tablet) {
    padding: 5px 0 5px 5px;
  }

  .filter-icon-container {
    height: 30px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;

    span {
      margin-left: 3px;
    }
  }

  .right {
    @include breakpoint($tablet) {
      float: right;
    }

    .filterIcon {
      float: right;

      @media only screen and (max-width: $tablet) {
        order: 2;
      }
    }

    .filtering {
      background-color: $blue;
      color: $white !important;

      path,
      svg {
        fill: $white !important;
      }
    }

    .routing-filter-icon {
      path {
        fill: $darkblue;
      }
    }

    .linkAction {
      float: none;
    }

    .info {
      margin: 0 5px 0 0;
    }

    span {
      .radio-toggle {
        padding-right: 1rem;
      }
    }
  }

  & > span,
  input[type='search'],
  select,
  .react-datepicker-component,
  .monthPicker,
  .btn,
  .btnIcon {
    margin: 0 5px 5px 0;
    vertical-align: middle;

    @include breakpoint($tablet) {
      margin: 0 5px 0 0;
    }
  }

  & > span {
    display: inline-block;
    line-height: 35px;

    @include breakpoint($tablet) {
      line-height: 30px;
    }
  }

  input[type='search'] {
    width: 100%;
    background-color: $medlightgrey;
    background-image: url(svg-encode(search-icon($navyblue)));
    color: $darkblue;

    @include breakpoint($tablet) {
      width: 255px;
      transition: width 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

      &:placeholder-shown {
        width: 30px;
        padding-right: 3px;
      }

      &:focus {
        width: 255px;
        padding-right: 8px;
      }

      &.bulkActionsLeft {
        margin-left: 16%;
      }
    }

    /* Override the color of the placeholder text */
    &::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: $lightgrey;
    }

    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: $lightgrey;
    }

    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: $lightgrey;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $lightgrey;
    }
  }

  select {
    background-color: $medlightgrey;
  }

  .toggle {
    width: 100%;
    vertical-align: middle;

    @include breakpoint($tablet) {
      width: 180px;
      margin: 0;
    }
  }

  .monthPicker {
    width: span(3 of 5);
    vertical-align: middle;

    @include breakpoint($tablet) {
      width: 180px;
    }

    & > div:first-child() {
      label {
        width: 100%;
      }
    }
  }

  .linkAction {
    display: inline-block;
    height: 30px;
    margin: 0 5px 0 0;
    line-height: 30px;
    vertical-align: middle;

    svg {
      margin: 0;
    }
  }

  .sortOrder {
    display: inline-block;
    height: 35px;
    margin-right: 10px;
    vertical-align: middle;

    @include breakpoint($tablet) {
      height: 30px;
      margin-right: 5px;
    }

    a {
      display: inline-block;
      margin-right: 10px;
      line-height: 35px;
      cursor: pointer;

      @include breakpoint($tablet) {
        margin-right: 5px;
        line-height: 30px;
      }

      svg {
        height: 20px !important;
        width: 20px !important;
        fill: $darkgrey;

        @include breakpoint($tablet) {
          height: 11px !important;
          width: 11px !important;
        }
      }

      &.active {
        color: $blue;

        svg {
          fill: $blue;
        }
      }
    }
  }

  .viewMode {
    display: inline-block;
    height: 35px;
    vertical-align: middle;

    @include breakpoint($tablet) {
      height: 30px;
      margin-right: 5px;
    }

    a {
      display: inline-block;
      margin-right: 10px;
      font-size: 22px;
      line-height: 35px;
      color: $darkgrey;
      cursor: pointer;

      @include breakpoint($tablet) {
        margin-right: 5px;
        font-size: 16px;
        line-height: 30px;
      }

      &.active {
        color: $blue;
      }
    }
  }

  .viewToggle {
    display: inline-flex;
    place-items: center;
    margin: 0 5px 0 0;
    text-transform: uppercase;
  }

  .btn,
  .btnIcon {
    padding: 0 10px;

    svg {
      height: 15px !important;
      width: 15px !important;

      &.width-20 {
        width: 20px !important;
      }

      &.heigth-20 {
        height: 20px !important;
      }
    }

    img {
      vertical-align: middle;
      margin-right: 3px;
    }
  }

  .up-down-Icon {
    padding: 0 5px;

    img,
    svg {
      vertical-align: middle;
      margin-right: 3px;
    }
  }

  .filter-header-icon {
    @media only screen and (max-width: $tablet) {
      display: none;
    }

    svg {
      margin-right: 5px;
    }

    span {
      vertical-align: bottom;
      margin-right: 10px;
    }
  }

  .btnIcon {
    background-color: $medlightgrey;
    color: $darkblue;
    text-transform: none;

    &:hover,
    &.active {
      background-color: $blue;
      color: $white;

      svg {
        fill: $white;
      }
    }

    &.exportPdf {
      color: $blue;
      background-color: black;
      font-weight: bold;
      width: 120px;
      height: 30px;
    }

    &.openInMaps {
      height: 30px;
      margin-left: 5px;
      width: 140px;
    }

    &.newService {
      color: $white;
      width: 135px;
      height: 30px;
    }
  }

  .eventTiming {
    display: inline-block;

    @include breakpoint($phone $tablet) {
      line-height: 35px;
    }

    a {
      margin: 0 20px 0 0;
      color: $darkblue;

      @include breakpoint($tablet) {
        margin: 0 0 0 20px;
      }

      &.selected {
        font-weight: $bold;
      }

      &.active {
        font-weight: bold;
      }
    }
  }

  .filterTokens {
    clear: both;
    margin: 5px 5px 0 0;
    overflow: auto;

    @media screen and (max-width: $tablet) {
      display: none;
    }

    & > div {
      span {
        display: inline-flex;
        align-items: center;
        margin-left: 20px;
      }

      float: right;
      margin-left: 20px;
      line-height: 35px;

      .tag {
        display: inline-block;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .btnLink {
        svg {
          height: 12px !important;
          width: 13px !important;
        }
      }
    }
  }

  .radioGroupSpan {
    .radio {
      margin-right: 10px;

      & > div > div {
        margin: 3px 3px 0 0 !important;
      }
    }
  }
}

.bulkAction {
  display: none;
  padding-left: 5px;
  background-color: $meddarkgrey;
  padding-top: 5px;

  @include breakpoint($tablet) {
    margin-top: 5px;
    padding-top: 0px;
    position: absolute;
  }

  .check {
    margin: 0 5px 0 10px;

    @include breakpoint($tablet) {
      margin: 0 5px 0 20px;
    }

    &.off svg {
      fill: $medlightgrey;
    }
  }

  select {
    width: calc(100% - 35px);
    background-color: $medlightgrey;

    @include breakpoint($tablet) {
      width: auto;
    }
  }

  &.selected {
    display: block;
  }

  input[type='search'] {
    margin-left: 5px;
    width: 100%;
    background-color: $medlightgrey;
    background-image: url(svg-encode(search-icon($navyblue)));
    color: $darkblue;

    @include breakpoint($tablet) {
      width: 255px;
      transition: width 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

      &:placeholder-shown {
        width: 30px;
        padding-right: 3px;
      }

      &:focus {
        width: 255px;
        padding-right: 8px;
      }
    }

    /* Override the color of the placeholder text */
    &::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: $lightgrey;
    }

    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: $lightgrey;
    }

    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: $lightgrey;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $lightgrey;
    }
  }
}

.rTable {
  display: table;
}

.rTableHeading {
  display: table-header-group;
}

.rTableBody {
  display: table-row-group;
}

.rTableFoot {
  display: table-footer-group;
}

.rTableRow {
  display: table-row;
}

.rTableCell,
.rTableHead {
  display: table-cell;
  vertical-align: middle;
}

.widthFull {
  width: 100% !important;
}

.widthAuto {
  width: auto !important;
}

.rdw-text-align-wrapper {
  width: 100%;
}

.marginBottom {
  margin-bottom: 20px !important;
}

.grid {
  width: 100%;
  table-layout: auto;
  border-collapse: separate;
  border-spacing: 0;

  &.documentsGrid {
    table-layout: auto !important;

    tr {
      td:first-child() {
        width: 40%;
      }
    }
  }

  .tdActionSmall {
    width: 10%;
  }

  .rTableHeading,
  thead {
    .rTableRow,
    tr {
      .rTableHead,
      th {
        height: 40px;
        padding: 0;
        font-size: 12px;
        line-height: 18px;
        color: $blue;

        @include breakpoint($tablet) {
          font-size: 10px;
          line-height: 14px;
        }

        svg {
          height: 15px !important;
          width: 16px !important;
          fill: $darkgrey;
          vertical-align: middle;
        }

        svg.checkIndeterminate {
          height: 17px !important;
          width: 17px !important;
        }

        svg.XL {
          height: 25px !important;
          width: 26px !important;
          fill: #8fa9be;
          vertical-align: middle;
          padding-left: 5px;
          padding-top: 5px;
        }

        &.expandToggle {
          position: relative;
          width: 2px;
          background-color: $darkblue;

          a {
            position: absolute;
            top: 50%;
            left: 1px;
            transform: translate(-50%, -50%);

            &:hover svg {
              fill: $darkgreyHover;
            }
          }
        }

        &.expanded {
          background-color: $darkblue;
        }

        .legend {
          position: relative;

          svg {
            height: 15px !important;
            width: 16px !important;
            vertical-align: middle;
          }

          a {
            display: inline-block;
            height: 15px;
            width: 16px;

            svg {
              color: $white;
              fill: $blue;
            }
          }

          & > div {
            position: absolute;
            top: -20px;
            right: -20px;
            padding: 0 40px 20px 20px;
            background-color: $darkblue;
            text-align: left;
            white-space: nowrap;
            z-index: 1;

            a {
              position: absolute;
              top: 13px;
              right: 22px;

              svg {
                color: $darkblue;
              }
            }

            h4 {
              margin: 0;
              line-height: 40px;
            }

            li {
              font-size: 12px;
              line-height: 30px;
              color: $darkgrey;

              svg {
                margin-right: 5px;
                fill: $orange;
              }
            }
          }
        }
      }
    }
  }

  .rTableBody,
  tbody {
    .rTableRow,
    tr {
      &.bundlesTr {
        td,
        .rTableCell,
        .action {
          vertical-align: top !important;
          padding-top: 10px !important;

          &.image {
            figure {
              height: 110px !important;
              width: 150px !important;
            }
          }
        }
      }

      .rTableCell,
      td {
        position: relative;
        height: 40px;
        padding-right: 5px;
        border-top: 1px solid $meddarkgrey;
        color: $darkblue;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;

        &:last-of-type() {
          padding-right: 0;
        }

        svg {
          height: 15px;
          width: 16px;
          margin-right: 5px;
          vertical-align: middle;
        }

        & > input[type='text'],
        & > textarea,
        & > select {
          width: 100%;
          margin: 0;
          background-color: $lightgrey;
        }

        .btnExpand,
        .btnCollapse {
          margin: 0;
        }

        .spinner {
          padding: 0;
          cursor: default;
        }

        &.wrap {
          white-space: normal;
        }

        &.overflow {
          overflow: visible;
          white-space: normal;
        }

        &.expandToggle,
        &.expanded {
          background-color: $darkblue;
          border-top: 1px solid $darkblueHover;
          color: $darkgrey;
        }
      }

      &.top {
        .rTableCell,
        td {
          vertical-align: top;
          padding: 10px 0 11px;
        }
      }

      &.totalRow {
        background-color: $meddarkgrey;

        .rTableCell,
        td {
          font-weight: $bold;

          &.expandToggle,
          &.expanded {
            background-color: $navyblue;
            color: $white;
          }

          &:first-of-type() {
            padding-left: 5px;
          }

          &:last-of-type() {
            padding-right: 5px !important;
          }
        }
      }

      &.noBorder {
        & > .rTableCell,
        & > td {
          border-top: none;
        }
      }

      &.separator td {
        height: 20px !important;
        padding: 0 5px;
        font-size: 10px;
        background-color: $lightgrey;
        border-top: none !important;
        color: $blue;
      }

      .rlargeTableCell {
        padding: 10px 0;
        border-top: 1px solid $meddarkgrey;
      }
    }

    tr.noBorderTop {
      .rTableCell,
      td {
        border-top: none;
      }
    }

    a.rTableRow {
      cursor: pointer;
    }

    .highlight {
      color: $blue !important;
    }
  }

  .btnLink {
    float: none;

    svg {
      margin: 0;
    }
  }

  &.thumbnail {
    .rTableBody,
    tbody {
      .rTableRow,
      tr {
        .rTableCell,
        td {
          position: relative;
          height: 120px;
          padding-top: 5px;
          padding-bottom: 5px;
          vertical-align: top;

          @include breakpoint($tablet) {
            height: 80px;
            padding-top: 0;
            padding-bottom: 0;
            vertical-align: middle;
          }

          &.image {
            padding: 5px 5px 5px 0;
            text-align: center;

            figure {
              height: 110px;
              width: 140px;
              vertical-align: middle;

              @include breakpoint($tablet) {
                height: 50px;
                width: 90px;
              }
            }
          }

          .highlight {
            @include breakpoint($phone $tablet) {
              margin-bottom: 5px;
            }
          }

          .caption {
            position: absolute;
            bottom: 7px;
            margin: 0;
          }

          .hasShortage,
          .hasBufferWarning,
          .hasUnreturned,
          .hasChange,
          .hasSoftHold {
            position: relative;
            padding: 0 25px 0 5px;
            margin-bottom: 5px;
            line-height: 25px;
            cursor: pointer;

            svg {
              position: absolute;
              top: 50%;
              right: 0;
              transform: translate(0, -50%);
            }
          }

          .hasShortage,
          .hasChange {
            background-color: $orange;

            svg {
              fill: $white;
            }
          }

          .hasBufferWarning,
          .hasSoftHold {
            svg {
              fill: $meddarkgrey;
            }
          }

          .hasChange {
            cursor: initial;
          }
        }
      }
    }
  }

  &.subGrid {
    .rTableHeading .rTableRow .rTableHead {
      height: 20px;
      border-top: none;
    }
  }

  &.double {
    .rTableBody,
    tbody {
      .rTableRow,
      tr {
        .rTableCell,
        td {
          height: 30px;
          border-top: none;
          vertical-align: top;

          &.right {
            padding-right: 10px !important;
          }
        }

        &:first-of-type {
          .rTableCell,
          td {
            border-top: 1px solid $meddarkgrey;
            vertical-align: bottom;

            &.middle {
              vertical-align: middle;
            }
          }
        }

        &.includeTopLine:last-of-type {
          td {
            padding: 10px 0px 30px !important;
          }
        }
      }
    }

    .CheckingInRentalTableRow {
      .qty {
        vertical-align: top !important;
        padding: 15px 5px;
      }

      .alertQty {
        color: $orange;
        font-weight: bold;
      }

      .image {
        vertical-align: middle !important;
      }

      .invtName {
        height: 50px;
        padding: 15px 5px;

        .rentalRowName {
          display: flex;

          &:first-of-type {
            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 150px;
            }
          }
        }
      }

      .department {
        height: 50px;
        padding: 15px 5px;
      }

      .location {
        height: 50px;
        padding: 15px 5px;
      }

      .highlight {
        height: 50px;
        padding: 15px 5px;
        white-space: nowrap;

        span {
          svg {
            height: 15px !important;
            width: 16px !important;
          }
        }
      }
    }
  }

  &.ship {
    .rTableBody,
    tbody {
      .rTableRow,
      tr {
        &:first-of-type {
          .rTableCell,
          td {
            border-top: 1px solid $meddarkgrey;
            vertical-align: bottom;

            a {
              color: $darkblue;
              text-decoration: none;
            }
          }
        }

        .rTableCell,
        td {
          height: 30px;
          border-top: none;

          &.center {
            text-align: center;
          }

          a {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &.hover {
    .rTableBody .rTableRow:hover {
      background-color: $lightgrey;
    }
  }

  .sortable {
    position: relative;
    display: inline-block;
    padding-right: 12px;
    margin-right: -12px;
    cursor: pointer;

    &.asc:before,
    &.desc:before {
      content: ' ';
      position: absolute;
      top: 7px;
      right: 0;
      display: inline-block;
      height: 4px;
      width: 8px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;

      @include breakpoint($tablet) {
        top: 5px;
      }
    }

    &.asc:before {
      border-top: 4px solid $blue;
      border-bottom: none;
    }

    &.desc:before {
      border-top: none;
      border-bottom: 4px solid $blue;
    }

    &.padding-bottom-10 {
      padding-bottom: 10px;
    }
  }

  .check {
    &.on > svg {
      margin: 0 !important;
      fill: $blue;
    }

    &.off > svg {
      margin: 0 !important;
      fill: $meddarkgrey;
    }
  }

  .contact-info {
    line-height: 1.5rem;
  }

  .contact-label {
    color: $blue;
    font-size: 0.6rem;
    margin-right: 1.4rem;
  }

  .contact-value {
    position: absolute;
    left: 5rem;
  }
}

.dragGrid {
  display: grid;
  grid-template-columns: 20px 1fr;
  width: calc(100% + 20px);
  margin-left: -20px;

  &.rentalFormV2 {
    margin-bottom: 0px !important;
  }

  &.rentalSection {
    display: block !important;
    width: 100% !important;
    margin-left: 0px !important;
  }

  .dragHandle {
    text-align: center;
    cursor: grab;

    svg {
      margin: 12px 0 0;
      fill: transparent;
    }
  }

  .margin-left-12 {
    margin-left: 12px;
  }

  .dragPlaceholder {
    margin: 5px 0;
    padding: 0 40px;
    border: 1px dashed $darkgrey;
    font-size: 10px;
    line-height: 40px;
    color: $darkgrey;
  }

  &.isOver {
    .rTableCell,
    .rentalTransHeaderWrapper,
    .dragAndDropBox,
    td {
      background-color: $lightgrey;
    }
  }

  &.right {
  }

  &:hover {
    & > .dragHandle {
      svg {
        fill: $meddarkgrey;
      }

      &:hover svg {
        fill: $blue;
      }
    }
  }
}

.bundleHeaderLink {
  color: black;
  font-weight: bold;
}

.bundleLinkedProducts {
  padding-left: 15px;
  color: $darkblue;

  > div {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.alignDragGrid {
  display: flex;
  align-items: center;
}

.gridWrapper {
  width: 100%;
  max-width: calc(100vw - 60px);

  &.max-full-width {
    max-width: 100%;

    .grid {
      width: 100%;
      min-width: 700px;
    }
  }

  padding-bottom: 10px;
  overflow-x: auto;

  @include breakpoint($tablet) {
    padding-bottom: 0;
    overflow-x: inherit;
  }

  &.scroll {
    overflow-x: auto;
  }

  .grid,
  .detailGrid {
    width: 700px;

    @include breakpoint($tablet) {
      width: 100%;
    }
  }

  .checkingInOutInvtByDepartment {
    h1 {
      padding-top: 35px;
      color: #000000;
      font-weight: bold;
      border-top: 1px solid $meddarkgrey !important;
      margin-bottom: 0px;
    }

    &:first-of-type {
      h1 {
        padding-top: 3px;
        border-top: none !important;
      }
    }

    .rTableRow {
      .qty {
        vertical-align: text-top !important;
        padding: 15px 10px !important;
      }

      .alertQty {
        color: $orange;
        font-weight: bold;
      }

      .image {
        vertical-align: middle !important;
      }

      .invtName {
        height: 50px;
        padding: 15px 5px;

        .rentalRowName {
          display: flex;

          &:first-of-type {
            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 150px;
            }
          }
        }
      }

      .location {
        height: 50px !important;
        padding: 15px 5px !important;
      }

      .date {
        height: 50px !important;
        padding: 15px 5px !important;
        white-space: nowrap !important;

        span {
          svg {
            height: 15px !important;
            width: 15px !important;
          }
        }
      }
    }
  }
}

.blackLink {
  color: #0e2c44 !important;
}

.marginBottom0 {
  margin-bottom: 0px;
}

.linkWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.marginLeft6 {
  margin-left: 6px;
}

.paidRentalTable {
  .sortable {
    width: 100%;

    &.asc:before,
    &.desc:before {
      right: 5px;
    }
  }
}

.grid {
  .rTableHeading,
  thead,
  .rTableBody,
  tbody {
    .rTableRow,
    tr {
      .rTableHead,
      th,
      .rTableCell,
      td {
        &.indent {
          padding-left: 20px !important;

          @include breakpoint($tablet) {
            padding-left: 30px !important;
          }
        }

        &.bold {
          font-weight: $bold;
          padding: 10px 0;
        }

        &.valignTop {
          vertical-align: top !important;
        }

        &.padded {
          padding: 10px 0;
        }

        svg.infoHeader {
          width: 27px !important;
          height: 25px !important;
        }

        &.strikeThrough {
          color: $darkgrey;
        }

        &.nameSection {
          width: 380px;
        }

        &.action {
          width: 36px;
          padding-left: 0;
          padding-right: 0;
          text-align: center;
          overflow: visible;
          vertical-align: middle !important;
        }

        &.actionTop {
          width: 36px;
          padding-left: 0;
          padding-right: 0;
          text-align: center;
          overflow: visible;
          vertical-align: top;
          padding-top: 4rem;
        }

        &.actionSm {
          width: 22px;
          padding-left: 0;
          padding-right: 0;
          text-align: center;
          overflow: visible;
          vertical-align: middle !important;
        }

        &.exSm {
          width: 50px;
        }

        &.sm {
          width: 80px;
        }

        &.medSm {
          width: 120px;

          @include breakpoint($tablet) {
            width: 100px;
          }
        }

        &.colorGrey {
          color: #7194ac;
        }

        &.med {
          width: 140px;

          @include breakpoint($tablet) {
            width: 120px;
          }
        }

        &.medLg {
          width: 120px;

          @include breakpoint($tablet) {
            width: 200px;
          }
        }

        &.lg {
          width: 120px;

          @include breakpoint($tablet) {
            width: 275px;
          }
        }

        &.lgXl {
          width: 120px;

          @include breakpoint($tablet) {
            width: 435px;
          }
        }

        &.lgXXl {
          width: 120px;

          @include breakpoint($tablet) {
            width: 600px;
          }
        }

        &.image {
          width: 160px;

          @include breakpoint($tablet) {
            width: 160px;
          }
        }

        &.right {
          text-align: right;
          padding-right: 10px;

          &:last-of-type() {
            padding-right: 0;
          }
        }

        &.center {
          text-align: center;
        }

        &.vertical-center {
          vertical-align: middle !important;
        }

        &.alert {
          vertical-align: middle !important;

          svg {
            fill: $orange;
          }
        }

        &.hasShortage,
        &.hasBufferWarning,
        &.hasUnreturned,
        &.hasChange,
        &.hasSoftHold {
          position: relative;
          padding-right: 25px;
          cursor: pointer;

          svg {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
          }
        }

        &.hasShortage,
        &.hasChange {
          background-color: $orange;

          svg {
            fill: $white;

            &:hover {
              fill: $white !important;
            }
          }
        }

        &.hasBufferWarning {
          cursor: default;

          svg {
            fill: $meddarkgrey;
          }
        }

        &.hasChange {
          cursor: initial;
        }

        &.hasSoftHold {
          svg {
            fill: $meddarkgrey;
          }
        }

        &.pickUp {
          svg {
            height: 17px;
            width: 17px;
            margin-left: 5px;
            fill: $darkgrey;
            vertical-align: middle;
          }
        }

        &.hasCheck {
          svg {
            height: 20px !important;
            width: 20px !important;
            color: $blue;
            fill: transparent;
          }
        }

        &.contact {
          padding: 10px 0;
        }

        &.creditStatus {
          color: $pink;
        }

        .fixWidth {
          width: 150px !important;
          max-width: 150px !important;
          display: inline-block !important;
        }
      }

      .rTableCell,
      td {
        .strikeThrough {
          color: $darkgrey;
        }

        .action {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }

  tbody {
    .rTableRow,
    tr {
      td {
        &.vmiddle {
          vertical-align: middle !important;
          padding: 10px 0px;
        }

        &.gearColumn {
          vertical-align: middle !important;
          text-align: center;
          width: 100px;
        }

        &.checkColumn {
          text-align: center;
          width: 100px;
        }
      }

      th {
        &.textCenter {
          text-align: center;
        }
      }

      svg {
        &.recurrenceGear {
          fill: $lightGray;
        }

        &:hover {
          fill: $darkGray !important;
        }
      }

      .optionsServiceTicket {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 10px;
      }

      .clockIconStaffRoleRow {
        height: 19px;
        width: 19px;
        margin-left: 2px;
      }

      .morningIconStaffRoleRow {
        height: 13px !important;
        width: 22px !important;
      }

      svg {
        &.recurrenceGear {
          fill: $lightGray;
        }

        &:hover {
          fill: $darkGray !important;
        }

        &.staffRoleGear {
          fill: $lightbluegrey;
        }

        &.staffRoleGear:hover {
          fill: $darkblue;
        }

        &.cancelRentalServiceTicket {
          fill: #c1cdd7;
        }

        &.cancelRentalServiceTicket:hover {
          fill: #ca3a3a;
        }

        &.recurrenceGear {
          fill: $lightGray;
        }

        &:hover {
          fill: $darkGray !important;
        }
      }
    }

    .serializedRowStatusCell {
      text-align: right;
    }

    .highlight-row {
      animation: highlightFade 1.5s ease-out;
    }

  }
}

.inline {
  white-space: nowrap;
}

.focusHeader {
  & > ul {
    @include clearfix;

    li {
      float: left;
      padding: 3px 10px;
      font-size: 12px;
      color: $darkgrey;

      @include breakpoint($tablet) {
        font-size: 10px;
      }

      &.sm {
        width: 80px;
      }

      &.med {
        width: 140px;

        @include breakpoint($tablet) {
          width: 120px;
        }
      }

      &.right {
        text-align: right;
        padding-right: 0;
      }
    }
  }
}

.detailGrid {
  margin-bottom: 20px;
  table-layout: fixed;

  .rTableRow,
  tr {
    .rTableHead,
    th,
    .rTableCell,
    td {
      height: 40px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;

      &.title {
        width: 85px;
        padding-right: 5px;
        background-color: transparent !important;
        font-size: 10px;
        font-weight: $light;
        text-align: right;
      }
    }

    .rTableHead,
    th {
      background-color: $blue;
      color: $white;

      &.title {
        color: $blue;
        font-weight: $bold;
        text-transform: uppercase;
      }
    }

    .rTableCell,
    td {
      height: 39px;
      border-bottom: 1px solid $lightgrey;
      background-color: $white;
      color: $blue;
    }

    &.totalRow {
      .rTableHead,
      .rTableCell {
        border-bottom: none;
        background-color: transparent;
        color: $darkblue;
      }
    }

    &:last-of-type {
      .rTableCell {
        height: 40px;
        border: none;

        &:nth-of-type(2) {
          @include border-radius(0 0 0 6px);
        }

        &:last-of-type() {
          @include border-radius(0 0 6px 0);
        }
      }
    }
  }

  a.rTableRow {
    cursor: pointer;
  }
}

.pagerWrapper {
  margin-top: 15px;
  font-size: 12px;
  line-height: 15px;

  .pager {
    width: 150px;
    margin: 0 auto;
    text-align: center;
    @include clearfix;

    li {
      float: left;
      height: 12px;
      width: 10px;
      margin: 6px 5px 4px;

      a {
        display: inline-block;
        height: 100%;
        width: 100%;
        cursor: pointer;

        svg {
          fill: $darkblue;
        }
      }

      &.disabled {
        a {
          cursor: auto;

          svg {
            fill: $darkgrey;
          }
        }
      }
    }

    div {
      float: left;
      margin: 0 4px;
      color: $meddarkgrey;
    }

    input[type='text'] {
      height: 25px;
      width: 25px !important;
      padding: 6px 0 4px;
      margin: 0 5px 0 0;
      background-color: $darkblue;
      color: $white;
      text-align: center;
    }

    span {
      display: inline-block;
      height: 25px;
      width: 15px;
      padding: 5px 0;
      color: $darkblue;
    }
  }
}

.mapsFirstChild {
  @media (max-width: $tablet) {
    margin-top: 45px;
  }

  padding-top: 10px;
}

.padding-10-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.accordian {
  clear: both;
  margin-bottom: 3px;
  background-color: $white;

  input[type='text'],
  input[type='password'],
  textarea,
  select,
  span.input,
  .toggle {
    background-color: $lightgrey;
  }

  .header {
    min-width: 37px;
    line-height: 37px;
    padding: 0 10px;

    & > div {
      width: calc(100% - 30px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;

      & > label {
        display: inline-block;
        font-weight: $bold;
        color: $blue;

        @include breakpoint($tablet) {
          width: 120px;
        }
      }

      span {
        display: inline;

        @include breakpoint($tablet) {
          display: inline;
        }
      }
    }
  }

  .content {
    @include container;
    padding: 5px 10px $innerPadding;

    .radioList {
      .radio {
        padding: 4px 0;
      }
    }

    .toggle {
      width: 100%;
    }

    .grid {
      margin-bottom: $innerPadding;
    }

    .form {
      .actions {
        width: 50%;
      }
    }
  }
}

.dropbtn {
  border: none;
  cursor: pointer;
  display: flex;
  font-weight: 700;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9;
}

.box {
  float: left;
  height: 20px;
  width: 20px;
  border: 1px solid black;
  clear: both;
  margin-right: 10px;
}

.dropdown-content a {
  color: black;
  padding: 5px 12px;
  text-decoration: none;
  display: block;

  span {
    vertical-align: sub;
  }
}

.fullWidth {
  width: 100% !important;
}

.info {
  display: inline-block;
  height: 16px;
  width: 17px;
  padding: 0;
  margin-left: 5px;
  cursor: help;
  z-index: 25;

  svg {
    height: 16px !important;
    width: 17px !important;
    color: white;
    fill: $darkgrey;
    vertical-align: top;

    &:hover {
      fill: $darkgreyHover;
    }
  }
}

.actionMenu {
  position: relative;
  float: right;
  width: 30px;
  height: 40px;
  overflow: hidden;

  @include breakpoint($tablet) {
    float: none;
    width: auto;
  }

  .menuToggle {
    position: absolute;
    top: 12px;
    right: 10px;
    cursor: pointer;
    z-index: 25;

    svg {
      width: 11px;
      fill: $blue;

      @include breakpoint($tablet) {
        fill: $white;
      }
    }
  }

  & > div {
    position: absolute;
    top: 0;
    right: 0;
    width: 255px;
    padding: 0 30px 0 10px;
    z-index: 24;

    @include breakpoint($tablet) {
      width: 100%;
    }

    .btn,
    .btnIcon,
    .btnLink {
      width: 100%;
    }

    .btn,
    .btnIcon {
      margin: 5px 0;
    }

    .btnLink {
      height: 40px;
      line-height: 40px;
      text-align: right;
    }
  }

  &.open {
    overflow: visible;

    .menuToggle svg {
      fill: $white;
    }

    & > div {
      background-color: $navyblue;
    }
  }
}

.documentSelectList {
  position: relative;
  margin: 0 0 5px;

  .toggle {
    margin-bottom: 0 !important;
  }

  ul {
    position: absolute;
    top: 100%;
    left: 0;
    right: 5px;
    background-color: $white;
    z-index: 1;

    li {
      width: 100% !important;
      padding: 15px;
      margin: 0 !important;
      line-height: 18px !important;
      cursor: pointer;

      &:hover {
        background-color: $darkblue;
        color: $white;
      }

      .caption {
        display: block;
        margin: 0;
        color: $blue;
        cursor: pointer;
      }
    }
  }
}

.itemDelete {
  @include span(full);
  margin: $vertical 0;
  text-align: center;

  .deleteLink {
    color: $red;
    text-decoration: underline;
  }

  .btnLink {
    position: relative;
    float: none;
    width: 100px;
    padding: 30px 0 0 0;
    margin: 0;
    line-height: 13px;

    svg {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      height: 25px;
      width: 26px;
      padding: 0;
      margin: 0;
    }
  }
}

.rentalAgreement {
  h1 {
    color: $blue;
    margin-bottom: 6px;
  }

  h2 {
    color: $darkgrey;
  }

  strong {
    font-weight: $bold;
  }

  em {
    font-style: italic;
  }

  ins {
    text-decoration: underline;
  }

  ol,
  ul {
    list-style-position: outside;
    margin-bottom: 10px;

    li {
      margin-left: 18px;
    }
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }
}

.textCenter {
  text-align: center;
}

.signatureDatetime {
  color: #000;
}

.signatureHeader {
  width: 95%;
  display: inline-flex;
  justify-content: space-between;
}

.signaturePad {
  position: relative;
  display: block;
  height: 90px;
  padding: 0 0 20px;
  background-color: $white;
  @include border-radius(2px);
  text-align: center;
  cursor: pointer;

  img {
    height: 100%;
  }

  label {
    position: absolute;
    bottom: 0;
    left: 7px;
    font-size: 10px;
    color: $blue;
    text-align: left;
  }

  &:after {
    content: ' ';
    position: absolute;
    bottom: 20px;
    left: 7px;
    right: 7px;
    height: 20px;
    border-bottom: 1px solid $darkblue;
    background-image: url(svg-encode(close-icon($blue)));
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 8px 8px;
  }
}


.one-million-ten-z-index {
  z-index: 1000010 !important;
}

.dialog {
  text-align: center;

  .content {
    & > div {
      background-color: transparent !important;
      box-shadow: none !important;
      border-radius: 0 !important;
    }

    .body {
      padding: 0 !important;
      color: $lightgrey !important;
      font-size: 22px !important;
      line-height: 25px !important;

      svg {
        height: 30px;
        width: 30px;
        fill: $blue;
        margin-bottom: 30px;
      }

      textarea {
        height: 90px;
        width: 100%;

        @include breakpoint($tablet) {
          width: 400px;
        }
      }

      label {
        white-space: normal;
      }

      .caption {
        font-size: 12px;
        color: $lightgrey;
      }

      & > div {
        margin: 20px auto 0;
        text-align: left;

        @include breakpoint($tablet) {
          width: 400px;
        }

        .check {
          font-size: 12px !important;
          line-height: 18px !important;

          svg {
            margin: 0;
          }

          &.on > svg {
            color: $lightgrey;
            fill: $blue;
          }

          &.off > svg {
            fill: $meddarkgrey;
          }
        }
      }
    }

    .actions {
      display: block;
      padding: 0 !important;
      margin-top: 30px !important;
      text-align: inherit !important;

      a,
      button {
        width: 25%;
        padding-left: 20px !important;
        padding-right: 20px !important;
        margin-bottom: 5px;

        @include breakpoint($tablet) {
          width: auto;
        }

        &:not(.cancel) {
          width: 100%;
          margin-bottom: 80px;

          @include breakpoint($tablet) {
            width: auto;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

.modal {
  padding-top: 0 !important;

  .content {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    width: 100% !important;
    padding: 0 10px 20px;
    background-color: $lightgrey;
    @include border-radius(3px);
    overscroll-behavior: contain;

    @include breakpoint($tablet) {
      width: 425px !important;
      padding: 0 20px 20px;
    }

    & > div {
      background-color: transparent !important;
      border-radius: 0 !important;
      box-shadow: none !important;
    }

    h3 {
      padding: 0 !important;
      margin: 0;
      border-bottom: none;
      font-size: 12px !important;
      line-height: 40px !important;
      font-weight: $bold !important;
      color: $blue !important;

      svg {
        height: 20px !important;
        width: 20px !important;
        margin-right: 5px;
        fill: $orange;
        vertical-align: middle;
      }

      .headerNav {
        padding-left: 20px;
        margin-left: 20px;
        border-left: 1px solid $meddarkgrey;
        line-height: 20px;
        cursor: pointer;
      }
    }

    .body {
      max-height: inherit !important;
      padding: 0 !important;
      font-size: 12px !important;
      line-height: 18px !important;
      color: $darkblue !important;
      overflow: inherit !important;

      .btnLink.add {
        position: absolute;
        top: 2px;
        right: 40px;
      }

      input[type='text'],
      select,
      .toggle {
        width: 100%;

        &.sm {
          width: auto;
        }
      }

      textarea {
        width: 100%;
        height: 80px;
      }

      .infinite,
      .noItems {
        clear: both;
        height: 350px !important;

        @media screen and (max-width: 768px) {
          &.inventory-list-container {
            height: 450px !important;
          }
        }

        @include breakpoint($tablet) {
          clear: none;
          height: 400px !important;
        }
      }

      .noItems {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $white;
        text-align: center;

        svg {
          height: 60px;
          width: 60px;
          margin-bottom: 10px;
          fill: $darkgrey;
        }

        a {
          text-decoration: underline;
        }
      }

      .scrollarea {
        clear: both;
        height: 100%;

        section {
          margin: 0;
        }
      }

      .signaturePad {
        width: 100%;
        margin: 20px auto;
      }

      .example {
        display: block;
        margin: 10px 0;
        text-align: center;
        text-decoration: underline;

        & + p {
          margin: 0;
        }
      }

      .feeLineItem {
        overflow: auto;
        margin-top: 20px;
        line-height: 30px;

        input[type='text'] {
          float: right;
        }
      }

      .tabSelect {
        margin-bottom: 20px;

        a {
          margin-right: 25px;
          color: $darkblue;

          &.active {
            font-weight: $bold;
            color: $blue;
          }
        }
      }

      .rTableCell,
      td {
        input[type='text'],
        input[type='number'] {
          width: 60px;
        }
      }

      .fullForm {
        padding: 20px;
        margin: 0 -20px 20px;
        background-color: $darkblue;
        color: $white;
      }
    }

    .actions {
      padding: 0 !important;
      margin: 10px 0 !important;
      text-align: inherit !important;
    }

    .itemFilter {
      display: flex;
      z-index: 5;
      flex-direction: column;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 25%;

      @media screen and (max-width: 768px) {
        left: 0;

        .mobileFilter {
          width: 0;
        }
      }

      right: 0;
      padding: 15px;
      background-color: $darkGray;

      .checkListItem {
        background-color: $darkGray;

        .checklist {
          div {
            background-color: $darkGray;
          }
        }
      }

      .linkAction {
        font-size: 14px;
      }

      .back-button-container {
        width: 60px;
        margin-bottom: 15px;
      }

      h2 {
        color: white;
        font-size: 28px;
        font-weight: 500;
      }

      .actions {
        display: flex;
        flex-direction: row;
        margin-top: auto;

        .btn {
          font-size: 16px;
          font-weight: 400;
          width: 100%;
          color: white;
        }
      }

      @include breakpoint($tablet) {
        left: 50%;
      }

      .linkBack {
        position: absolute;
        top: 0;
      }

      .infinite {
        -webkit-overflow-scrolling: touch;
        margin: 0 -20px;
      }

      .checkList {
        .checkListItem {
          margin-left: 20px !important;

          & + div .checkListItem {
            margin-left: 45px !important;
          }
        }
      }
    }
  }

  .modalParent {
    top: 468px !important;
    left: 526px !important;
  }

  .dupContent {
    top: 136.5% !important;
    left: 52% !important;
    background-color: $darkblue;
    width: 340px !important;
    padding: 0 10px;

    .triangle-right {
      position: absolute;
      top: 31%;
      right: -14px;
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-left: 15px solid $darkblue;
      border-bottom: 15px solid transparent;
    }

    .actions {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .actions-pop {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
    background-color: $darkblue;

    .btn {
      grid-column: 1 / span 2;
      margin: 5px;
    }

    .btn:last-of-type {
      margin-top: 0;
    }
  }

  .triangle-right {
    position: absolute;
    top: 31%;
    right: -14px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 15px solid $darkblue;
    border-bottom: 15px solid transparent;
  }

  &.large {
    .content {
      width: 100% !important;

      @include breakpoint($tablet) {
        width: 600px !important;
      }

      .body {
        .scrollarea {
          height: auto;
          max-height: 300px;

          @include breakpoint($tablet) {
            max-height: 400px;
          }
        }
      }
    }
  }

  &.very-large {
    .content {
      width: 100% !important;
      max-width: 1024px !important;

      @include breakpoint($tablet) {
        width: 1024px !important;
      }

      .body {
        .scrollarea {
          height: auto;
          max-height: 300px;

          @include breakpoint($tablet) {
            max-height: 400px;
          }
        }
      }
    }
  }

  &.full {
    .content {
      & > div {
        padding-left: 10px;
        padding-right: 10px;

        @include breakpoint($tablet) {
          padding-left: 20px;
          padding-right: 20px;
        }
      }

      .actions {
        @include breakpoint($phone $tablet) {
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }
  }

  &.warn {
    h3 {
      color: $red !important;
    }

    .btnClose svg {
      fill: $red;
    }

    .body {
      .fullForm {
        background-color: $red;

        .btn:not(.cancel) {
          background-color: $darkblue;
          color: $red;
        }
      }
    }
  }
}

.overlay {
  background-color: $navyblue !important;
  opacity: 0.85 !important;
}

.drawerOverlay {
  background-color: $navyblue !important;
  opacity: 0.2 !important;
}

.noOverlay {
  display: none;
}

.mobileDialog {
  padding-top: 0 !important;

  & > div {
    height: 100%;
  }

  .content {
    height: 100% !important;
    width: 100% !important;
    transform: translate(0px, 0px) !important;

    & > div {
      height: 100% !important;
      box-shadow: none !important;
      border-radius: 0 !important;
    }

    .body {
      height: calc(100% - 45px);
      padding: $mobileVertical 0 !important;
    }

    .actions {
      text-align: inherit !important;

      .linkBack {
        position: absolute;
        top: 40px;
        left: 10px;
        background-color: transparent !important;
      }
    }
  }

  .overlay {
    display: none;
  }
}

.tooltip {
  padding: 20px !important;
  background-color: $blue !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  color: $white !important;
  opacity: 1 !important;
  white-space: normal;
  text-align: left;

  @include breakpoint($phone $tablet) {
    width: 100% !important;
    left: 0 !important;
  }

  @include breakpoint($tablet) {
    width: 300px !important;
  }

  h2 {
    margin-bottom: 5px;
    color: $white !important;
  }

  p {
    font-size: 12px;
    line-height: 18px;
    font-weight: $light;
    word-break: break-word;
  }

  &.top {
    &:after {
      border-top-color: $blue !important;
    }
  }

  &.bottom {
    &:after {
      border-bottom-color: $blue !important;
    }
  }

  &.left {
    &:after {
      background-color: $blue !important;
    }
  }

  &.right {
    &:after {
      border-right-color: $blue !important;
    }
  }

  &:after {
    @include breakpoint($phone $tablet) {
      border: none !important;
    }
  }
}

.validationPopup,
.successPopup {
  max-width: 500px;

  & > div {
    padding: 0 0 0 20px !important;

    & > div {
      color: $white !important;

      & > span {
        display: inline-block;
        width: calc(100% - 36px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      button {
        width: 36px;
        min-width: auto !important;
        margin: 6px 0 0 !important;

        span {
          padding: 0 !important;
        }

        svg {
          fill: $white;
        }
      }
    }
  }
}

.validationPopup {
  & > div {
    background-color: $red !important;
  }
}

.successPopup {
  & > div {
    background-color: $blue !important;
  }
}

.alertCallout,
.readyCallout {
  position: absolute;
  height: auto;
  width: auto;
  display: inline-block;
  padding: 0 6px;
  @include border-radius(8px);
  font-size: 10px;
  font-weight: $light;
  line-height: 16px;
  color: $white;
  text-transform: uppercase;
}

.alertCallout {
  background-color: $pink;
}

.readyCallout {
  background-color: $green;
}

.unlimitedUsers {
  padding: 6px 10px;
}

.inlineAlert {
  display: inline-block;
  margin: -6px 0 -5px;
  padding: 6px 10px;
  background-color: $orange;

  svg {
    height: 15px;
    width: 16px;
    margin-left: 5px;
    fill: $white;
    vertical-align: middle;
  }
}

.warehouse-legend {
  .paddings {
    padding-bottom: 20px;
  }

  .key {
    margin: 25px 20px 0 0 !important;
    text-align: left !important;

    .alertCallout {
      background-color: $orange;
      padding: 3px 58px 3px 58px;
      text-align: center;
      border-radius: 13px;
    }

    svg {
      height: 17px !important;
      width: 18px !important;
      margin: -2px 10px 0 0;
      fill: #ffffff;
      vertical-align: middle;
    }

    &.none svg {
      color: $lightgrey;
      fill: $lightgrey;
    }

    &.partial svg {
      color: $lightgrey;
      fill: $purple;
    }

    &.full svg {
      color: $green;
      fill: $green;
    }
  }
}

.spinner {
  clear: both;
  padding: $vertical 0;
  width: 100% !important;
  text-align: center;

  svg {
    height: 40px;
    width: 40px;
    fill: none;
    stroke: $blue;
    stroke-width: 2px;

    circle {
      stroke-opacity: 0.15;
    }
  }

  p {
    margin-top: $innerPadding;
  }

  &.spinner-left {
    display: flex;
    justify-content: start;
    margin: 0px 25px 0px 25px;
    height: 50px;
    padding: 0;
  }

  &.small-loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 31px;
  }
}

.modalSpinner {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: $meddarkgrey;
  z-index: 1000001; // Needs to be in front of the side drawer and Zendesk widget;

  @include breakpoint($tablet) {
    white-space: nowrap;
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.videoButton {
  position: relative;
  display: inline-block;
  height: 20px;
  padding: 0 7px 0 15px;
  @include border-radius(2px);
  background-color: $blue;
  color: $white;
  font-size: 10px;
  line-height: 22px;

  &:before {
    content: ' ';
    position: absolute;
    top: 6px;
    left: 5px;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 6px solid $white;
    border-right: none;
  }
}

.systemUpdateWidget {
  position: fixed;
  bottom: 100px;
  left: 0;
  display: grid;
  grid-template: 1fr 1fr / 30px 255px;
  place-items: end center;
  background-color: $pink;
  color: $white;
  z-index: 100;

  svg {
    grid-row: span 2;
    width: 57px;
    height: 60px;
    margin-left: -30px;
    fill: $white;
  }

  a {
    align-self: start;
    font-weight: $bold;
    color: $white;
  }
}

.counted {
  position: relative;

  input[type='text'],
  textarea {
    padding-right: 30px;

    @include breakpoint($tablet) {
      padding-right: 25px;
    }
  }

  .count {
    position: absolute;
    top: 0;
    right: 5px;
    color: $blue;
  }
}

.flexRow {
  display: flex;
  justify-content: space-between;

  h1 {
    margin: 0;
  }

  select {
    max-width: 40%;
  }
}

.flexToggle {
  display: flex;
  margin-top: 10px;

  span {
    padding-right: 25px;
  }
}

.ctaMargin {
  margin: 10px 5px !important;
}

.taxExemptCheck {
  fill: $white;
}

.cancelLocation {
  grid-column: 1 / span 3 !important;
  text-align: center;
  margin-top: 20px;
}

.fullTW {
  grid-column: 1 / span 4;
}

.w50 {
  width: 50%;
}

.w100 {
  width: 100% !important;
}

.h2FilterColor {
  color: $cobalt !important;
}

.labelBreakText {
  line-height: 18px !important;

  label {
    white-space: normal !important;
  }
}

.no-wrap {
  white-space: normal !important;

  .idForm {
    margin: 4px 0;

    input[type='text'] {
      width: 100% !important;
      margin: 0 0 5px 0 !important;
    }
  }
}

select[name='usage_unit'] {
  width: 90%;
}

.drawerForm {
  .btn.cancel {
    margin-right: 0 !important;
  }
}

.modal.check-in-out-modal {
  .btn-100 {
    grid-column: span 3 !important;
  }

  .btn-error {
    background-color: #ff886d !important;
    border-radius: 3px;
    grid-column: span 3 !important;
  }

  h3.error-color {
    color: #ff886d !important;
  }

  .title {
    margin-top: 10px !important;
  }

  .subtitle {
    margin-top: 15px;
    margin-bottom: 25px;
    line-height: 0.6rem;
    white-space: pre-line;
  }

  .bulk-container {
    background: white;
    padding: 20px;
    padding-top: 5px;
  }
}

/* width */
.bulk-container ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.bulk-container ::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.bulk-container ::-webkit-scrollbar-thumb {
  background: #278ada;
  border-radius: 15px;
}

/* Handle on hover */
.bulk-container ::-webkit-scrollbar-thumb:hover {
  background: #278ada;
}

.commissioned-customer-add {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 5rem;
}

.disclaimerActions {
  display: flex;
  justify-content: space-evenly;
}

.hideAction {
  visibility: hidden;
}

.displayActions:hover .hideAction {
  visibility: visible;
}

.py-10 {
  padding: 10px 0;
}

.serializedHeaders {
  th {
    color: $blue !important;
    font-size: 10px !important;
    padding: 10px 0 !important;
  }
}

.serializedRows {
  td {
    padding-top: 5px;
    vertical-align: top;
    color: $white !important;
    border-top: 1px solid $midblue !important;
  }
}

.serializedFilter {
  margin: 0 10px !important;
  width: 95% !important;
}

.serializedTitle {
  h2 {
    color: $darkgrey !important;
  }

  .serializedQty {
    color: $white !important;
    float: right;
    margin-right: 35px;
  }
}

.serializedUsage {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .usageUnit {
    text-align: end;
    padding-right: 10px;
  }
}

.serializedInMaintenance {
  color: $orange;
}

.serializedTextCenter {
  text-align: center;
}

.serializedTruncate {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.w540 {
  width: 540px;
}

.h350 {
  max-height: 350px;
  overflow-y: scroll;
}

.padding-left-10 {
  padding-left: 10px;
}

.margin-left-30 {
  margin-left: 40px;
}

.width-40 {
  width: 40px;
}

.width-100 {
  width: 100px;
}

.nowrap {
  white-space: nowrap;
}

.bundleDetailsTable {
  table p {
    line-height: 1rem;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.serializedStickyTop {
  background-color: $navyblue;
  position: sticky;
  top: 0;
  z-index: 1;
}

table .flatTable {
  width: 100%;

  tr {
    td {
      border: none;
      height: 30px;
    }
  }
}

.serializedTableBody td {
  vertical-align: top !important;
}

.grayToggle {
  background-color: $lightgrey;
  color: $black;
}

.maintenanceHistoryContainer {
  .drawer {
    display: block !important;
  }
}

.radioButtonList {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #0c263b;
  border-bottom: 1px solid #0c263b;

  .radioList {
    padding-left: 15px;
  }

  label {
    color: #ffffff !important;
  }

  input {
    width: 40%;
    margin-right: 15px;
  }

  &.align-content {
    justify-content: end;
    align-items: center;
  }

  &.width-90 {
    input {
      width: 90%;
    }
  }

  &.opacity {
    filter: opacity(0.2);
  }
}

.radio {
  color: white;
}

.input-range {
  &.width-100 {
    input[type='range'] {
      width: 100%;
    }
  }
}

.input-range {
  input[type='range'] {
    -webkit-appearance: none;
    height: 15px;
    border-radius: 2px;
    width: 143px !important;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  input[type='range']::-webkit-slider-thumb {
    height: 32px;
    width: 25px;
    border-radius: 2px;
    background: url('../../images/range-input.png') center center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    -webkit-appearance: none;
    z-index: 999999 !important;
    position: relative;
  }
}

.range0 {
  input[type='range'] {
    background: linear-gradient(
      to right,
      #278ada 0%,
      #278ada 0%,
      #e5e8e9 0%,
      #e5e8e9 100%
    );
  }
}

.range25 {
  input[type='range'] {
    background: linear-gradient(
      to right,
      #278ada 0%,
      #278ada 25%,
      #e5e8e9 25%,
      #e5e8e9 100%
    );
  }
}

.range50 {
  input[type='range'] {
    background: linear-gradient(
      to right,
      #278ada 0%,
      #278ada 50%,
      #e5e8e9 50%,
      #e5e8e9 100%
    );
  }
}

.range75 {
  input[type='range'] {
    background: linear-gradient(
      to right,
      #278ada 0%,
      #278ada 75%,
      #e5e8e9 75%,
      #e5e8e9 100%
    );
  }
}

.range100 {
  input[type='range'] {
    background: linear-gradient(
      to right,
      #278ada 0%,
      #278ada 100%,
      #e5e8e9 100%,
      #e5e8e9 100%
    );
  }
}

.search-input {
  margin: 35px 0px 10px -2px;
}

.serial-number-error {
  background-color: $red !important;
}

.serial-number-success {
  background-color: $green !important;
}

.serial-number-product-title {
  font-size: 12px;
  color: $white;
  font-weight: bold;
  margin: 15px;
}

.input-container {
  height: 40px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.number-input-container {
  background-color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 143px;
  border-radius: 2px;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
    width: 30%;
    margin: 0px;
    text-align: center;
  }

  button {
    background-color: $white;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.usage-unit-input-container {
  input[type='text'] {
    margin: 0px;
    text-align: center;
  }
}

.usage-name-container {
  margin: 30px 18px 30px 20px;

  input[type='text'] {
    width: 100%;
  }
}

.usage-type {
  font-weight: $bold;
  color: $bluegrey;
  padding-left: 20px;
  margin-bottom: 10px;
}

.usage-type-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;

  span {
    font-weight: $bold;
    color: $bluegrey;
  }
}

.additiveRight {
  background: #e5e8e9 !important;
  height: 30px;
  border-radius: 0 2px 2px 0;
}

.additiveLeft {
  background: #e5e8e9 !important;
  height: 30px;
  border-radius: 2px 0px 0px 2px;
}

.noBorderRadius {
  border-radius: 0px !important;
}

.range-slider-bar {
  display: flex;
  justify-content: space-around;
  width: 143px;
  position: absolute;
}

.sliderLeft {
  color: #ffffff;
}

.sliderSelected {
  z-index: 0 !important;
  color: transparent;
}

.wAuto {
  width: auto !important;
}

.cumulativeText {
  text-align: center;
  margin: 0;
}

.deleteLink {
  height: 30px;
  justify-self: center;
  align-self: center;
  color: $red;
  font-family: Heebo;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-decoration: underline;
}

.span1 {
  grid-column: span 1 !important;
}

.noPaddingTop {
  padding-top: 0px !important;
}

.noMargin {
  margin: 0 !important;
}

.cumulativeContainer {
  height: 40px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &.flex-start {
    justify-content: start;
  }
}

.grayToggle {
  background-color: $lightgrey;
  color: $black;
}

.noBorder {
  border: none !important;
}

.modalAccions {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-around;
}

.noPadding {
  padding: 0px !important;
}

.breadcrumb {
  display: inline-flex;
  color: $darkgrey;
  line-height: 20px;
  font-size: 12px;

  .separator {
    padding: 0px 4px 0px !important;
  }
}

.accordionBorderBottom {
  border-bottom: 1px solid $meddarkgrey;
  padding-bottom: 10px !important;
}
