.drawer {

  overflow-y: auto !important;


  .deliveryTicketSlideOutForm {

    margin: 0 10px 0 20px;


    hr {
      background-color: #1B4160 !important;
    }

    .topHeading {
      display: flex;
      justify-content: space-between;

      margin: 0 0 43px 0;

      .lftTxt {
        color: #8FA9BE;
        font-size: 18px;
      }

      .rgtTxt {
        color: #8FA9BE;
        font-size: 12px;
      }

      b {
        color: white !important;
      }
    }


    .bodySection {

      margin-right: 20px;

      .fields {

        input {
          width: 100%;
        }

      }

      .deliverySection {

        .topHeading {
          color: #278ADA;
          font-weight: $bold;
          font-size: 12px;
          margin: 0 0 19px 0 !important;
        }

        section {
          background-color: transparent !important;
          padding: 0px !important;
        }

        .delivery {

          h4, .info {
            display: none;
          }

        }

        select {
          width: 100%;
        }

        .shipping {

          .radioList {

            span {
              color: #7194AC !important;
              font-size: 12px !important;
            }
          }
          .fields {

            margin-top: 5px !important;

            input[name="delivery_address_street_address_1"] {
              width: 49% !important;
              margin-right: 2px !important;
            }

            input[name="delivery_address_street_address_2"] {
              width: 50% !important;
            }

            input[name="delivery_address_city"] {
              width: 49% !important;
              margin-right: 2px !important;
            }

            select[name="delivery_address_locale"] {
              width: 50% !important;
            }

            input[name="pickup_address_street_address_1"] {
              width: 49% !important;
              margin-right: 2px !important;
            }

            input[name="pickup_address_street_address_2"] {
              width: 50% !important;
            }

            input[name="pickup_address_city"] {
              width: 49% !important;
              margin-right: 2px !important;
            }

            select[name="pickup_address_locale"] {
              width: 50% !important;
            }

            .deliverReturn {
              color: #7194AC !important;
              font-size: 12px !important;


            }

          }

          .actions {
            margin: 15px 0 5px 0 !important;

            span {
              color: #7194AC !important;
              font-size: 12px !important;
            }

          }

          .check,  .off {
            margin: 12px 0 12px 0 !important;

            label {
              color: #7194AC !important;
              font-size: 12px !important;
            }
          }

        }

      }

      .flipTimeSection, .setByDateTimeSection {

        margin: 5px 0 0 0;


        label {

          color: #278ADA;
          font-weight: bold;

        }

        textarea {
          width: 100%;
          height: 90px;
        }

        .startDateTimeSection {
          margin-top: 15px;
        }
      }

      .drawerFooter {
        position: unset !important;
        width: auto !important;
        padding: 5px 0 0 0 !important;

      }

      .startDateTimeSection, .endDateTimeSection {

        display: flex;

        .dateRange {
          width: 175px;
        }

        .timeRange {

          width: 85px;
          margin-left: 5px;
        }

        .timeRange2 {
          width: 85px;
          margin-left: 5px;
          margin-top: 18px;
        }
      }

      .deliverySpecificDeliveryTime, .returnSpecificDeliveryTime {

        .topHeading {
          color: #278ADA;
          font-weight: $bold;
          font-size: 12px;
          margin: 0 0 19px 0 !important;
        }

        margin: 0 0 10px 0;

        .selectDropdown {

          margin: 0 0 10px 0;
          display: flex;
          justify-content: space-between;

          select {
            width: 100%;
          }
          .marginImg {
            margin-left: 13px !important;
          }
        }

        .dateFields {

          display: flex;

          .dateRange {
            width: 175px;
          }

          .timeRange {

            width: 85px;
            margin-left: 5px;
          }

          .singleDateRange {

            .react-datepicker-input {
              width: 255px !important;
            }

          }

        }

      }

    }
  }
}
.rc-time-picker-panel {
  z-index: 9999999 !important;
}
