.ticketsTab {
  .scrollMenu {
    z-index: -1;
  }

  .ticketsMenu {
    color: #ffffff;
  }

  .tableRow {
    height: 59px;

    .div9 {
      width: 275px;
    }

    .div7 {
      width: 126px;
    }

    .div8 {
      width: 162px;
    }

    .truckPickUp {
      fill: #c1cdd7;
    }

    .truckDropOff {
      fill: #278ada;
    }

    .progressBar {
      padding: 80px 10px 20px;
      margin: 0 -10px;
      background-color: #ffffff;
      @include breakpoint($tablet) {
        position: relative;
        padding: 0 0 0 15px;
        margin: 0;
      }

      .stepContainer {
        margin-top: 10px;
        @include container;
        @include breakpoint($tablet) {
          display: inline-block;
        }

        & > div {
          float: left;
          &.connector {
            height: 4px;
            width: $connector_width_mobile;
            margin-top: 13px;
            /* This is where we set the progress bar colors */
            background: transparent url('../../../images/icons/dotted-line.svg');
            @include breakpoint($tablet) {
              width: 60px;
              margin-top: 11px;
            }

            &.complete,
            &.active {
              background: $blue;
            }
            &.showAnts {
              background: transparent
                url('../../../images/icons/bkgd_progress.gif') no-repeat
                no-repeat left top;
            }
            &.invisible {
              visibility: hidden;
            }
          }
          &.connectorOrange {
            height: 4px;
            width: $connector_width_mobile;
            margin-top: 13px;
            /* This is where we set the progress bar colors */
            background: transparent
              url('../../../images/icons/dotted-line-orange.svg');
            @include breakpoint($tablet) {
              width: 60px;
              margin-top: 11px;
            }

            &.complete,
            &.active {
              background: $blue;
            }
            &.showAnts {
              background: transparent
                url('../../../images/icons/bkgd_progress.gif') no-repeat
                no-repeat left top;
            }
            &.invisible {
              visibility: hidden;
            }
          }
          &.connectorOrangeSolid {
            height: 4px;
            width: $connector_width_mobile;
            margin-top: 13px;
            /* This is where we set the progress bar colors */
            background: #ff886d;
            @include breakpoint($tablet) {
              width: 60px;
              margin-top: 11px;
            }

            &.complete,
            &.active {
              background: #ff886d;
            }
            &.showAnts {
              background: transparent
                url('../../../images/icons/bkgd_progress.gif') no-repeat
                no-repeat left top;
            }
            &.invisible {
              visibility: hidden;
            }
          }
          &.connectorGrey {
            height: 4px;
            width: $connector_width_mobile;
            margin-top: 13px;
            /* This is where we set the progress bar colors */
            background: #c1cdd7;
            @include breakpoint($tablet) {
              width: 60px;
              margin-top: 11px;
            }

            &.complete,
            &.active {
              background: $blue;
            }
            &.showAnts {
              background: transparent
                url('../../../images/icons/bkgd_progress.gif') no-repeat
                no-repeat left top;
            }
            &.invisible {
              visibility: hidden;
            }
          }
          &.step {
            position: relative;
            width: $icon_size_mobile;
            text-align: center;
            @include breakpoint($tablet) {
              width: $icon_size;
            }

            &.complete,
            &.active {
              .icon {
                background-color: $blue;
                svg {
                  fill: $white;
                }
              }
            }
            &.complete1,
            &.active1 {
              .icon {
                background-color: #ff886d;
                svg {
                  fill: $white;
                }
              }
            }
            &.active {
              label {
                color: $white;
              }
              &.alert {
                .icon {
                  background-color: $red;
                }
              }
            }
            .icon {
              height: $icon_size_mobile;
              width: $icon_size_mobile;
              background-color: #c1cdd7;
              @include border-radius(100%);
              @include breakpoint($tablet) {
                height: $icon_size;
                width: $icon_size;
                margin-bottom: 5px;
              }

              .checkedOut {
                fill: #ffffff;
              }

              .checkedIn {
                fill: #ffffff;
              }

              .closed {
                fill: #ffffff;
              }

              svg {
                height: 15px !important;
                width: 16px !important;
                margin: 7px auto;
                fill: $cobalt;
                @include breakpoint($tablet) {
                  margin: 5px auto;
                }
              }
            }
            label {
              position: absolute;
              display: block;
              padding-left: 35px;
              padding-top: $label_centering_padding_mobile;
              font-size: $step_label_font_size;
              color: $darkgrey;
              transform: rotate(-90deg);
              transform-origin: left top 0;
              white-space: nowrap;

              @include breakpoint($tablet) {
                position: static;
                padding-left: 0;
                padding-top: 0;
                transform: none;

                &:before {
                  content: '';
                  display: inline-block;
                  margin-right: -900%;
                }
                &:after {
                  content: '';
                  display: inline-block;
                  margin-left: -900%;
                }
              }

              svg {
                position: absolute;
                right: -28px;
                height: 14px !important;
                width: 16px !important;
                fill: $orange;
              }
              span {
                svg {
                  left: -20px !important;
                }
              }
            }
          }
        }
      }

      &.serviceTier {
        .stepContainer .connector {
          width: $connector_width_mobile_service;
          @include breakpoint($tablet) {
            width: 82px;
          }
        }
      }
      &.change {
        .stepContainer {
          & > div {
            &.connector {
              &.complete,
              &.active {
                background: $orange;
              }
              &.showAnts {
                background: transparent
                  url('../../../images/icons/bkgd_progress_orange.gif')
                  no-repeat no-repeat left top;
              }
            }
            &.step {
              &.complete,
              &.active {
                .icon {
                  background-color: $orange;
                }
              }
              &.active {
                &.alert {
                  .icon {
                    background-color: $red;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
