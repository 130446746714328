.dashboard-at-a-glance {
  .chart-row {
    margin-bottom: 10px;
  }

  .nav-links {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    line-height: 24px;

    a.selected {
      font-weight: bold;
      color: $white;
    }

    @include breakpoint($tablet) {
      display: none;
    }
  }

  .underlined {
    text-decoration: underline;
  }

  .react-datepicker-component .react-datepicker-input input {
    font-size: 12px;
  }

  .margin-0 {
    margin: 0px !important;
  }

  .flex-display {
    display: flex;
    flex-flow: column;

    @include breakpoint($tablet) {
      flex-flow: row;
    }
  }

  .payment-stats-row {
    .payment-stats-col {
      //margin: auto;
      width: 100%;

      @include breakpoint($tablet) {
        width: 50%;
      }

      margin-bottom: 16px;

      @include breakpoint($tablet) {
        margin-bottom: inherit;
      }

      p {
        display: flex;

        margin-bottom: 0px;

        @include breakpoint($tablet) {
          margin-bottom: 10px;
        }

        &.payments-received {
          color: #278ada;

          a {
            color: #278ada !important;
          }
        }

        &.balance-due,
        &.payments-remaining,
        &.revenue {
          a {
            color: #0e2c44 !important;
          }
        }

        span {
          margin: auto;
          margin-left: 23px;

          &.amount-total {
          }

          &.icon {
            color: $white;
            fill: $blue;
            margin-left: 20px;
            margin-right: 0px;
          }

          &.label {
            line-height: 14px;
            font-weight: bold;
            margin-left: 4px;
          }

          &.count {
            font-size: 18px;
            font-weight: 900;
            line-height: 24px;
            margin-right: 25px;
          }
        }
      }
    }
  }

  p {
    &.datepicker-container {
      margin-left: auto;
      margin-right: auto;

      margin-top: 5px;
      display: flex;
      color: white;
      align-items: baseline;
      margin-bottom: 30px;

      @include breakpoint($tablet) {
        margin-left: 0px;
      }
    }
  }

  p {
    color: #0e2c44;

    &.payments {
    }

    &.stats {
      margin-bottom: 0px;
      position: relative;

      .bottom-pipe {
        height: 10px;
        border: 2px solid;
        position: absolute;
        left: 33px;

        @include breakpoint($tablet) {
          height: 5px;
        }
      }

      .content-stats {
        display: flex;
        padding-top: 10px;
        @include breakpoint($tablet) {
          padding-top: 5px;
        }

        .icon {
          height: 25px;
          width: 25px;
          border-radius: 50%;
          display: inline-block;
          margin-left: 23px;
        }

        svg {
          height: 15px !important;
          width: 16px !important;
          margin: -2px 10px 0 0;
          fill: #ffffff;
          vertical-align: middle;
          margin-left: 4px;
          margin-top: 5px;
        }

        span.label {
          text-decoration: underline;
          margin-left: 12px;
          font-weight: bold;
          color: #0e2c44;
          margin-top: 2px;

          a {
            color: #0e2c44;
          }
        }

        span.count {
          margin-top: 1px;
          font-weight: 900;
          font-size: 18px;
          margin-right: 20px;
          margin-left: auto;
          line-height: 24px;
        }
      }
    }

    &.heading {
      padding-left: 10px;
      font-weight: bold;
      margin-bottom: 16px;
      font-size: 20px;
      color: #0e2c44;

      @include breakpoint($tablet) {
        margin-bottom: 12px;
        font-size: 12px;
      }
    }
  }

  .chart {
    margin-bottom: 10px;

    @include breakpoint($tablet) {
      margin-bottom: 0px;
    }

    .chart-container {
      position: relative;
      width: 65%;
      margin: 0 auto;
      @include breakpoint($desktop) {
        width: 100%;
      }
    }

    @include breakpoint($desktop) {
      height: 465px;
      width: 245px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    padding-top: 20px;
    padding-bottom: 20px;

    &.chart-payment {
      background-color: white;
      .topSection {
        display: flex;
        justify-content: space-between;
      }

      @include breakpoint($tablet) {
        flex-grow: 1;
      }
      //width:100%;

      select {
        background-color: #e5e8e9;
        float: right;
        right: 0;
        top: 0;
        margin-right: 10px;
        margin-bottom: 13px;
        width: 200px;
      }
    }

    .total {
      position: absolute;
      font-size: 42px;
      line-height: 42px;
      font-weight: bold;
      /* margin-top: 70px; */
      z-index: 1;
      /* margin-left: 85px; */
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .donut-center-label {
      font-size: 10px;
      position: absolute;
      margin: 0;
      padding: 0;
      display: inline;
      top: 64%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: normal;
      color: #90acb5;
    }
  }

  .verticalChart {
    //margin-top: 20px !important;
    padding: 20px 10px 25px 0px;
    width: 100%;
    margin: auto;
    background-color: $white;
    margin-bottom: 10px;

    @include breakpoint($tablet) {
      height: 160px;
    }

    .singleCardContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @include breakpoint($tablet) {
        flex-direction: row;
        flex-wrap: initial;
      }
    }

    .singleCard {
      margin-bottom: 18px;

      @include breakpoint($phone) {
        width: 50%;
      }

      &:nth-child(odd) {
        border-right: 1px solid #c1cdd7;
      }

      @include breakpoint($tablet) {
        margin-bottom: 10px;
        width: 100%;
        a {
          span {
            br {
              display: none;
            }
          }
        }

        border-right: 1px solid #c1cdd7;
      }

      padding: 0 24px;
      text-align: center;

      a {
        max-width: 44px;
        text-align: center;
        color: #0e2c44;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        text-decoration: underline;
      }

      p {
        color: #278ada;
        font-size: 42px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 42px;
        margin-bottom: 0;
        margin-top: 8px;

        @include breakpoint($tablet) {
          margin-top: 12px;
        }
      }
    }

    .withoutBorder {
      border-right: none !important;
    }
  }

  .smartChartContainer {
    margintop: 10px;
    display: flex;
    flex-direction: column;

    @include breakpoint($tablet) {
      flex-direction: row;
    }

    .smartChart {
      padding-top: 20px;
      padding-bottom: 10px;
      //height: 200px;
      //margin: auto;
      width: 100%;
      margin-bottom: 10px;

      @include breakpoint($tablet) {
        width: 333px;
        &:not(:last-child) {
          margin-right: 10px;
        }
      }

      &:last-child {
        margin-bottom: 35px;
      }

      @include breakpoint($tablet) {
        &:last-child {
          margin-bottom: 10px;
        }
      }

      @include breakpoint($tablet) {
        height: 200px;
      }

      .deliveryTypeDonut {
        align-items: center;
        display: flex;
        justify-content: center;
        //flex-wrap: wrap;
        //width: 50%;

        .donut-graph:first-child {
          border-right: 1px solid #c1cdd7;
        }

        .donut-graph {
          height: 140px !important;
          position: relative;
        }

        .checkoutTotalArea {
          text-align: center;
          position: absolute;
          top: 48%;
          left: 50%;
          transform: translate(-50%, -50%);

          .checkOutTotal {
            font-size: 32px;
            font-weight: bold;
            color: #0e2c44;
          }

          .checkOutState {
            margin-top: 2px;
            font-size: 10px;
            color: #7194ac;
          }
        }
      }
    }
  }
}

.separator {
  padding: 0px 10px 0px;
}

.withoutIcon {
  margin-left: 20px !important;
}

.chartHeaderWithLoadingDots {
  display: flex;

  .loadingDots {
    margin-top: 7px;
    margin-left: 9px;
  }
}
.checkingStatusIcon {
  width: 15px;
  margin-bottom: 5px;
  height: 14px;
}

.noChartPayment {
  padding-right: 10px;
  width: 100%;
  height: 165px;
  padding-left: 10px;
}

.apexcharts-legend,
.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon,
.apexcharts-pan-icon,
.apexcharts-zoom-icon,
.apexcharts-reset-icon {
  display: none !important;
}
