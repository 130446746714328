.calendarWrapper {
	display: grid;
	padding: 0 !important;
	@include breakpoint($tablet) {
		grid-template-columns: 1fr 1fr;
	}
	
	.calendarList {
		background-color: $darkblue;
		@include breakpoint($tablet) {
			padding-top: 40px;
			@include bleed-x(100% 0);
			padding-right: 40px;
		}
		
		h1 {
			height: 120px;
			padding: 0 20px;
			color: $darkgrey;
			@include breakpoint($tablet) {
				height: auto;
				padding: 0;
			}
			
			.headerNav {
				@include breakpoint($tablet) {
					border-left: 1px solid $cobalt;
				}
				
				a:not(.selected) {
					color: $cobalt;
				}
			}
		}
		
		@include breakpoint($phone $tablet) {
			.r-calendar {
				@include container;
				padding: 0 0 15px;
				
				.r-head, .r-weekdays, .r-dates{
					padding: 0 20px;
				}
			}
		}
	}
	.rentals, .mobileRentals {
		h1 {
			height: 80px;
			line-height: 28px;
		}
	}
	.rentals {
		padding: 40px 0 40px span(1 of 12);
	}
	.mobileRentals {
		padding: 0 10px;
		background-color: $darkblue;
		
		.navigateBack {
			height: 45px;
			margin: 0;
		
			a {
				display: inline-block;
				padding: 14px 0 0 5px;
				font-size: 10px;
				font-weight: $bold;
				color: $darkgrey;
				text-transform: uppercase;
		
				svg {
					margin: 2px 10px -2px 0;
					fill: $blue;
				}
			}
		}
		h1 {
			color: $blue;
		}
		.grid {
			a {
				color: $darkblue;
				cursor: pointer;
			}
		}
		.pager {
			li {
				svg {
					fill: $blue;
				}
				&.disabled {
					svg {
						fill: $cobalt;
					}
				}
			}
			div {
				color: $darkgrey;
			}
			input[type="text"] {
				background-color: $blue;
			}
			span {
				color: $white;
			}
		}
	}
	
	.r-calendar {
		.r-head, .r-weekdays {
			color: $blue;
			
			svg {
				fill: $blue;
			}
		}
		.r-weekdays {
			margin: 20px 0 10px;
		}
	}
	
	&.alerts {
		h1 a.selected {
			color: $orange;
		}
		.r-calendar {
			.r-head, .r-weekdays {
				color: $orange;
			
				svg {
					fill: $orange;
				}
			}
			.r-cell {
				&.r-date {
					&:not(.r-past):hover {
						background-color: $orange;
					}
					&.r-selected {
						background-color: $orange;
					}
				}
			}
		}
	}
	&.in-out {
		h1 a.selected {
			color: $purple;
		}
		.r-calendar {
			.r-head, .r-weekdays {
				color: $purple;
			
				svg {
					fill: $purple;
				}
			}
			.r-cell {
				&.r-date {
					&:not(.r-past):hover {
						background-color: $purple;
					}
					&.r-selected {
						background-color: $purple;
					}
				}
			}
		}
	}
}

@media print {
	.calendarWrapper {
		display: block;
		
		.calendarList {
			display: none;
		}
		.rentals {
			padding: 0 40px;
			margin: 0;
		}
	}
}