.adminBusinessForm {
  margin-bottom: $vertical;

  .client,
  .business {
    .uploadZone {
      margin-bottom: 20px;
    }
  }
  .client {
    .uploadZone {
      @include push(3 of 16);
      width: span(10 of 16);
      @include post(3 of 16);
      @include breakpoint($tablet) {
        @include push(0 of 13);
        width: span(4 of 13);
        @include post(9 of 13);
      }

      .drop {
        @include maintain-aspect-ratio(1, 1);
        @include border-radius(50%);

        .placeholder svg {
          margin-top: 55px;
          @include breakpoint($tablet) {
            margin-top: 60px;
          }
        }
      }
    }
  }
  .business {
    .uploadZone {
      @include breakpoint($tablet) {
        width: span(7 of 13);
      }

      .drop {
        @include maintain-aspect-ratio(16, 9);

        .placeholder svg {
          margin-top: 55px;
          @include breakpoint($tablet) {
            margin-top: 50px;
          }
        }
      }
    }
    @include breakpoint($phone $tablet) {
      .fields {
        label,
        span,
        .react-datepicker-component {
          grid-column: span 2;
        }
      }
    }
  }
  .taxInfo {
    li {
      label {
        display: inline-block;
        width: span(1 of 2);
        line-height: 30px;

        &:last-of-type() {
          width: span(1 of 10);
          text-align: center;
        }
      }
      input[type='text'] {
        width: calc(#{span(1 of 5)} - 5px) !important;
      }
    }
  }
  .actions {
    @include breakpoint($tablet) {
      width: span(7 of 13);
    }
  }

  .businessHeader {
    display: flex;
    justify-content: space-between;
  }
}
