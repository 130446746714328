.helpDrawer {
	transform: translate(340px, 0) !important;
	padding: 0;
	background: linear-gradient(90deg, #6668C6 0%, $blue 100%);
	overflow: visible !important;
	z-index: 1299 !important;

	& > .btn {
		display: none;
		position: absolute;
		top: 50%;
		left: 0;
		transform: rotate(90deg) translate(-50%, 0);
		transform-origin: left top;
		padding: 0 20px;
		margin: 0;
		@include border-radius(0 0 2px 2px);
		background-color: #6668C6;
		@include breakpoint($tablet) {
			display: inline-block;
		}
		
		svg {
			height: 15px !important;
			width: 15px !important;
			margin-right: 5px;
			fill: $white;
			vertical-align: middle;
		}
		&:before {
			content: "?";
			display: inline-block;
			height: 15px !important;
			width: 15px !important;
			margin-right: 5px;
			transform: rotate(-90deg);
			@include border-radius(50%);
			background-color: $white;
			font-size: 12px;
			line-height: 17px;
			color: #6668C6;
			text-transform: initial;
		}
	}
	.tabs {
		padding: 0 20px;
	}
	
	&.open {
		transform: translate(0, 0) !important;
		
		& > .btn {
			display: inline-block;
			
			&:before {
				content: "x";
			}
		}
	}
	
	.headerTabs {
		background: linear-gradient(90deg, #6668C6 0%, $blue 100%);
		
		li {
			display: inline-block;
			width: 50%;
			
			a {
				display: block;
				line-height: 40px;
				background-color: $white;
				color: $purple;
				text-align: center;
				cursor: pointer;
				
				&.active {
					background-color: transparent;
					color: $white;
				}
			}
		}
	}
	.scrollarea {
		margin-right: -10px;
		padding-right: 15px;
		
		.scrollbar-container {
			background-color: transparent;
			opacity: 0;
			
			.scrollbar {
			}
		}

		&:hover .scrollbar-container{
			opacity: 1;
		}
	}
	
	.index {
		padding-top: 70px;
		color: $white;
		
		h1 {
			display: block;
			margin-bottom: 0;
			color: $white;
		}
		.scrollarea {
			height: calc(100vh - 320px);
		}
		.itemList {
			li {
				padding: 10px 40px 10px 0;
				border-color: rgba(206, 216, 227, 0.15); // white 15%
				color: $white;
				cursor: pointer;
				
				& > * {
					cursor: pointer;
				}
				span {
					display: inline-block;
					margin-right: 5px;
					font-size: 10px;
					color: $darkblue;
					font-weight: $bold;
					text-transform: uppercase;
					vertical-align: top;
				}
				label {
					display: inline-block;
					width: calc(100% - 45px);
					white-space: normal;
				}
					
				svg {
					position: absolute;
					top: 50%;
					right: 0;
					transform: translate(0, -50%);
					height: 21px;
					width: 21px;
					padding: 0;
					fill: $white;
				}
			}
		}
		.legend {
			position: absolute;
			bottom: 20px;
			left: 0;
			right: 0;
			text-align: center;
			
			svg {
				height: 21px;
				width: 21px;
				margin-right: 5px;
				fill: $white;
				vertical-align: middle;
			}
		}
	}
	
	.topic {
		color: $white;
		counter-reset: topic;
		
		.linkBack {
			position: static;
			padding: 0;
			line-height: 40px;
			color: $darkblue;
			
			svg {
				fill: $darkblue;
			}
		}
		h1 {
			height: 80px;
			margin: 0;
			line-height: 24px;
			color: $white;
		}
		.tutorialLink {
			display: block;
			font-weight: $bold;
			line-height: 40px;
			color: $white;
			text-align: center;
			
			svg {
				height: 21px;
				width: 21px;
				margin-right: 5px;
				fill: $white;
				vertical-align: middle;
			}
			
			& + .scrollarea {
				height: calc(100vh - 260px);
			}
		}
		.scrollarea {
			height: calc(100vh - 220px);
			
			.scrollarea-content {
				padding-top: 5px;
			}
		}
		p {
			position: relative;
			margin: 0 0 20px 25px;
			
			a {
				color: $white;
				text-decoration: underline;
			}
			
			&:before {
				counter-increment: topic;
				content: counter(topic);
				position: absolute;
				top: -3px;
				left: -25px;
				height: 20px;
				width: 20px;
				@include border-radius(50%);
				background-color: $darkblue;
				font-size: 10px;
				line-height: 20px;
				text-align: center;
			}
		}
	}
	
	.faq {
		padding-top: 40px;
		color: $white;
		
		h2 {
			margin: 0;
			line-height: 40px;
			color: $white;
			text-transform: none;
		}
		.scrollarea {
			height: calc(100vh - 100px);
		}
		strong {
			display: block;
			margin-top: 40px;
			line-height: 40px;
			border-top: 1px solid rgba(206, 216, 227, 0.15); // white 15%
		}
	}
}