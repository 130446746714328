.rdt {
  position: relative;
  background: $white;

  .rdtPicker {
    display: none;
    position: absolute;
    width: 186%;
    margin-top: 1px;
    z-index: 99999 !important;
    background: $white;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.6);
    border: 1px solid #f9f9f9;

    span {
      font-weight: bold;
      font-size: 24px;
      color: $blue;
    }

    .rdtTimeToggle {
      text-align: center;
    }

    table {
      width: 100%;

      &:nth-child(2) {
        margin: 0 0 16px 0;
      }
    }
    td,
    th {
      text-align: center;
      height: 28px;
    }
    td {
      cursor: pointer;
    }
    td.rdtDay:hover,
    td.rdtHour:hover,
    td.rdtMinute:hover,
    td.rdtSecond:hover,
    .rdtTimeToggle:hover {
      background: $lightgrey;
      cursor: pointer;
    }
    td.rdtOld,
    td.rdtNew {
      color: $darkgrey;
    }
    td.rdtToday {
      position: relative;

      &:before {
        content: '';
        display: inline-block;
        border-left: 7px solid transparent;
        border-bottom: 7px solid $blue;
        border-top-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        bottom: 4px;
        right: 4px;
      }
    }
    td.rdtActive {
      background-color: $blue !important;
      color: $white;

      &:hover {
        background-color: $darkblue !important;
      }
    }
    td.rdtActive.rdtToday:before {
      border-bottom-color: $white;
    }
    td.rdtDisabled {
      background: none;
      color: $darkgrey;
      cursor: not-allowed;
    }

    td span.rdtOld {
      color: $darkgrey;
    }
    td span.rdtDisabled {
      background: none;
      color: $darkgrey;
      cursor: not-allowed;
    }
    .dow {
      width: 14.2857%;
      border-bottom: none;
      cursor: default;
    }
    th.rdtSwitch {
      width: 100px;
    }
    th.rdtNext,
    th.rdtPrev {
      padding: 0 5px;
      font-size: 21px;
      vertical-align: middle;

      span {
        display: block;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Chrome/Safari/Opera */
        -khtml-user-select: none; /* Konqueror */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
      }
    }

    th.rdtDisabled {
      background: none;
      color: $darkgrey;
      cursor: not-allowed;
    }
    thead tr:first-child {
      background-color: $white;
      th {
        cursor: pointer;
        height: 40px;
        margin-bottom: 8px;

        &:hover {
          background-color: rgba(0, 0, 0, 0.075);
        }
      }
    }

    tfoot {
      border-top: 1px solid #f9f9f9;
    }

    button {
      border: none;
      background: none;
      cursor: pointer;

      &:hover {
        background-color: $lightgrey;
      }
    }

    thead button {
      width: 100%;
      height: 100%;
    }

    td.rdtMonth,
    td.rdtYear {
      height: 42px;
      width: 25%;
      padding: 8px;
      cursor: pointer;
      background-color: #c1cdd7;
      border: 16px solid $white;
      -webkit-border-radius: 32px;
      -moz-border-radius: 32px;
      border-radius: 32px;
      box-shadow: inset 0 0 0 -40px $white;

      &:hover {
        background: $lightgrey;
      }
    }
  }

  &.rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
  }
  &.rdtOpen .rdtPicker {
    display: block;
  }
}

.rdtCounters {
  display: inline-block;

  & > div {
    float: left;
  }
}

.rdtCounter {
  height: 100px;
  width: 40px;

  .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    &:hover {
      background: $lightgrey;
    }
  }
  .rdtCount {
    height: 20%;
    font-size: 1.2em;
  }
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;

  input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
  }
}

.rdtTime td {
  cursor: default;
}
