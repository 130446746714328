.member {
  .details {
    .iconView {
      clear: both;
      @include breakpoint($tablet) {
        @include span(7 of 13);
        @include post(6 of 13);
      }
    }
    & > .btnExpand, & > .btnCollapse {
      margin-bottom: 10px;
      color: $blue;
      svg {
        margin-left: 5px;
        fill: $blue;
      }
    }
  }
  .iconForm {
    @include breakpoint($tablet) {
      @include span(9 of 13);
      @include post(4 of 13);
      textarea {
        grid-column: 2 / span 4;
      }
    }
  }
  &.noData > div {
    width: 100%;
    padding-left: span(9 of 26);
    padding-right: span(9 of 26);
    border-right: none;
    .logo {
      background-image: url('../../../images/icons/noData_Company.png');
    }
  }
}
.role {
  h1 {
    @include breakpoint($tablet) {
      width: span(10 of 13);
    }
  }
  section {
    @include breakpoint($tablet) {
      @include span(6 of 13);
      @include post(7 of 13);
    }
    .check {
      line-height: 30px;
    }
  }
  &.noData > div {
    width: 100%;
    padding-left: span(9 of 26);
    padding-right: span(9 of 26);
    border-right: none;
    .logo {
      background-image: url('../../../images/icons/noData_Venue.png');
    }
  }
}
.editRole {
  .form, .itemDelete {
    @include breakpoint($tablet) {
      @include span(6 of 13);
      @include post(7 of 13);
    }
  }
}
.w100 {
  width: 100%;
}
.w90 {
  width: 90%;
}
.w97 {
  width: 97%;
}
.noMarginLeft {
  margin-left: 0px !important;
}
.marginTop20 {
  margin-top: 20px !important;
}
.marginBottom20 {
  margin-bottom: 20px !important;
}
.serviceSection {
  padding: 28px !important;
}
.monthDaysAlertModal {
  .alertContainer {
    display: flex;
    align-items: baseline;
    padding-left: 0px !important;
    padding-top: 10px !important;

    .confirmationAlert {
      height: 15px;
      color: $orange;
    }
    span.title {
      color: $blue;
      font-size: 14px;
      padding-left: 5px;
    }
  }
  .mb-75 {
    margin-bottom: 75px;
  }
}
.serviceWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.serviceColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  &.left {
    margin-left: 10px;
    & h4 {
      margin-top: 50px;
    }
  }
  &.right {
    margin-right: 10px;
  }
  & select {
    margin-left: 0px !important;
  }
}
.roleList {
  li a {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.staffCalendar {
  padding-top: 45px;
  @include breakpoint($tablet) {
    padding-top: 80px !important;
  }
  .scrollarea {
    max-height: calc(100vh - 120px);
    padding-right: 10px;
    margin-right: -10px;
  }
  .r-calendar {
    padding-top: 15px;
    @include breakpoint($tablet) {
      padding-top: 0;
    }
    .r-weekdays {
      top: 45px;
      height: 45px;
      width: 100%;
      background-color: $lightgrey;
      line-height: 45px;
      @include breakpoint($tablet) {
        top: 80px;
        height: auto;
        width: 235px;
        background-color: transparent;
        line-height: 9px;
      }
    }
    .r-dates .r-cell {
      color: $darkblue;
      @include breakpoint($tablet) {
        color: $darkgrey;
      }
      &.r-selected {
        color: $white;
      }
    }
  }
  @include breakpoint($tablet) {
    &.hasSubNav {
      .r-calendar .r-weekdays {
        top: 120px;
      }
    }
  }
  .r-calendar {
    min-width: auto;
    .r-head, .r-weekdays {
      color: $blue;
    }
    .r-weekdays {
      position: fixed;
      z-index: 2;
    }
    .r-dates {
      font-size: 10px;
      line-height: 10px;
    }
    .r-cell {
      width: span(1 of 9);
      margin-left: span(1 of 54);
      margin-right: span(1 of 54);
    }
  }
  .mobileRentals {
    margin-top: -45px;
  }
}