.storefrontNav {
  .locked {
    float: right !important;
  }

	.subList li {
		svg {
			height: 20px !important;
			width: 20px !important;
			margin: -2px 8px 0 -2px;
			fill: transparent !important;
			color: $darkblue !important;
		}

		&.active svg {
			color: $green !important;
		}

		.newPage {
			text-align: center;
			border: 2px solid $green;
			height: 33px;
			line-height: 31px;
			margin: 20px 30px 10px 0;
			border-radius: 2px;
			font-weight: 400;

			&:hover {
				color: $green;
			}

			svg {
				display: inline-block;
				user-select: none;
				background-color: $green;
				border-radius: 16px;
			}
		}

		hr {
			display: block;
			margin: 0;
			margin-left: -1000px;
			background-color: $darkblue;
		}
	}
}

.unlock-storefront {
    color: $blue;
    font-weight: bold;
    margin-bottom: 0px;
}

.storefront {
	@include breakpoint($tablet) {
		& > div {
			display: grid;
			grid-template-columns: 11fr 6fr;

			h1 {
				grid-column: 1 / span 2;
			}

			aside {
				margin-left: 20px;
			}

			&.full {
				grid-template-columns: 1fr;

				h1 {
					grid-column: 1;
				}
			}
		}
		& > .spinner {
			display: block !important;
		}
	}

	h1 a {
		font-size: 12px;
		line-height: 18px;
		color: $blue;
		vertical-align: middle;
		text-decoration: underline;
	}
	.form {
		align-self: start;
	}
	section {
		align-self: start;

		.accordian {
			.header label {
				color: $darkblue;
			}
		}
		h3 {
			display: flex;
			margin: 0;
			line-height: 24px;

			.viewToggle {
				height: auto;
				margin: 0 -8px 0 auto !important;
				line-height: 18px;

				label {
					font-size: 10px !important;
					font-weight: $regular;
					color: $blue !important;

					& + div {
						margin-top: 2px;

						& > div {
							/* track */
							&:first-of-type() {
								height: 9px !important;
								width: 22px !important;
							}
							/* thumb */
							&:last-of-type() {
								top: 2px !important;
								height: 13px !important;
								width: 13px !important;
							}
						}
					}
				}
				& > div {
					margin: 0;
				}
			}
			.btnLink {
				margin-left: auto;
				color: $darkblue;
				white-space: nowrap;
			}

			& + .accordian {
				border: none;
			}
		}
		.aside {
			font-size: 10px;
			line-height: 14px;
			@include breakpoint($tablet) {
				position: absolute;
				top: 10px;
				left: 100%;
				width: 255px;
				margin: 0;
				padding-left: 20px;

				&:before {
					content: " ";
					position: absolute;
					top: 0;
					left: 0;
					height: 20px;
					width: 3px;
					background-color: $darkblue;
				}
			}
		}
		.itemDelete {
			float: none;
			margin: 20px 0;

			.btnLink {
				width: auto;
				padding: 0;
				font-weight: $regular;
				color: $darkblue;

				svg {
					position: static;
					transform: translate(0, 0);
					margin-right: 5px;
				}
			}
		}

		&.metaDataForm {
			.seoTitleInput {
				display: contents;

				p {
					margin-bottom: 0;
					grid-column: span 4;
				}
			}
		}
	}
	aside {
		align-self: start;
		@include breakpoint($phone $tablet) {
			sticky {
				position: relative !important;
				width: initial !important;
			}
		}

		.sticky {
			position: fixed;
			width: 15rem;
		}

		header {
			padding: 5px 20px;
			background-color: $navyblue;
			line-height: 30px;
			color: $white;

			.viewToggle {
				margin: 0;

				label {
					margin-right: auto;
					color: $darkgrey;
				}
				& > div {
					margin: 0;
				}

				&.active label {
					color: $white;
				}
			}
			.btnIcon {
				width: 100%;
				margin: 10px 0;
			}
		}
		.actions {
			grid-template-columns: 1fr;
			padding: 20px 20px 10px;
			margin-bottom: 20px;
			background-color: $navyblue;

			hr {
				grid-row-start: none;
				background-color: $blue;
			}

			.btn {
				margin: 0 0 10px;
			}

			.btnLink {
				grid-column: span 2;
				justify-content: center;
				align-content: center;
				display: grid;
				grid-auto-flow: column;
				grid-gap: 7px;
				font-weight: normal;
				color: white;
				margin: 4px 0 7px;
				padding-right: 10px;
			}
		}
		p {
			font-size: 10px;
			line-height: 14px;
		}
		p.innerText {
			font-size: 12px;
			line-height: 18px;
			color: white;
		}
		& > a {
			font-size: 12px;
			line-height: 14px;
			text-decoration: underline;
		}
	}
	.actions {
		grid-template-columns: repeat(2, 1fr);
		@include breakpoint($tablet) {
			grid-template-columns: repeat(4, 1fr);
		}

		.btn.outline {
			grid-column: span 1;
		}
	}

	.domainSettings {
		.instructions {
			margin-bottom: 20px;
			@include breakpoint($tablet) {
				width: span(3 of 4);
			}

			ol {
				margin-left: 23px;
				list-style: decimal;

				li {
					padding-left: 10px;
				}
			}
		}
		.form {
			p {
				margin-left: 23px;

				&:first-of-type {
					margin-left: 0;
				}
				& > label {
					display: block;
					margin: 5px 0;
					font-size: 10px;
					line-height: 14px;
					color: $blue;
					white-space: normal;
					@include breakpoint($tablet) {
						display: inline;
						margin: 0 0 0 10px;
						white-space: nowrap;
					}
				}
				input[type="text"] {
					margin: 5px 0;
					@include breakpoint($tablet) {
						margin: 0 0 0 10px;
					}
				}
			}
			& > a {
				margin-left: 23px;
			}
			a:not(.btn) {
				text-decoration: underline;
			}
			.check {
				margin-left: 23px;
			}
			.domains {
				margin: 0 0 20px 23px;

				input[type="text"] {
          width: 50%;
          margin-top: 5px;
				}
				.btnLink {
					float: none;
				}
			}
		}
		.actions {
			margin-top: 20px;
		}
	}

	.themeSettings {
		@include breakpoint($tablet) {
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 120px;
		}

		& > div {
			position: relative;
            width: 100%;
			padding: 220px 0 0;
			margin-bottom: 40px;
			background-size: 297px 178px;
			background-position: 11px 12px;
			background-repeat: no-repeat;
			@include breakpoint($tablet) {
				padding: 220px 20px 0;
				width: 320px;
			}

			.btn {
				width: 100%;
				margin: 0 0 10px;
			}
			.viewToggle {
				label {
					width: calc((100% - 52px) / 2);
					color: $blue;

					&.selected {
						font-weight: $bold;
					}
					&:last-of-type {
						text-align: right;
					}
				}
			}

			&.chic {
				background-image: url("../../../images/marketing/Harper_Light.png");

				&.dark {
					background-image: url("../../../images/marketing/Harper_Dark.png");
				}
			}
			&.fun {
				background-image: url("../../../images/marketing/Emmit_Light.png");

				&.dark {
					background-image: url("../../../images/marketing/Emmit_Dark.png");
				}
			}
			&.catalina {
				background-image: url("../../../images/marketing/Catalina_Light.png");

				&.dark {
					background-image: url("../../../images/marketing/Catalina_Dark.png");
				}
			}
			&.wisteria {
				background-image: url("../../../images/marketing/Wisteria_Light.png");

				&.dark {
					background-image: url("../../../images/marketing/Wisteria_Dark.png");
				}
      }
      &.magnolia {
        background-image: url("../../../images/marketing/Magnolia_Light.png");
        &.dark {
          background-image: url("../../../images/marketing/Magnolia_Dark.png")
        }
	  }

	  &.preScott {
			background-image: url("../../../images/marketing/Prescott_Light.png");
		}

		&.elaina {
			background-image: url("../../../images/marketing/Elaina_Light.png");
		}

		.singleTheme {
			justify-content: center;
			h4 {
				margin-bottom: 0;
			}
		}

			&:before {
				content: " ";
				position: absolute;
				top: 0;
				left: 0;
				height: 200px;
				width: 320px;
				border: 5px solid transparent;
			}

			&.selected:before {
				border: 5px solid $blue;
			}
		}
	}

	.styleSettings {
		a:not(.btn) {
			grid-column: 1 / span 4;
			text-decoration: underline;
		}
		.colors {
			grid-template-columns: repeat(2, 1fr);
			@include breakpoint($tablet) {
				grid-template-columns: repeat(2, 1fr);
				grid-gap: 0 10px;
			}

			h3 {
				grid-column: span 2;
			}
			& > label {
				grid-row: 2;
				font-size: 10px;
				color: $blue;
			}
			.colorSelector {
				grid-row: 3;
				position: static;
				border: 1px solid $navyblue;
				@include border-radius(2px);

				.swatch {
					position: relative;
					height: 28px;
					width: 100%;

					label {
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						font-size: 11px;
						line-height: 28px;
						text-align: center;
					}
				}

				&.primary {
					color: $white;
				}
				&.darkSecondary {
					color: $white;
				}
			}
		}
		.buttons {
			.radioGroup {
				grid-row-gap: 5px;
				@include breakpoint($tablet) {
					grid-row-gap: 0;
				}
			}
			.radio {
				& > div > div {
					margin-top: 13px !important;
					@include breakpoint($tablet) {
						margin-top: 7px !important;
					}
				}
				label {
					display: inline-block;
					height: $mobileVertical;
					padding: 0;
					background-color: $lightgrey;
					@include border-radius(2px);
					font-size: 11px;
					font-weight: $bold;
					line-height: 45px !important;
					text-align: center;
					text-transform: uppercase;
					white-space: nowrap;
					vertical-align: middle;
					@include breakpoint($tablet) {
						height: 30px;
						font-weight: $regular;
						line-height: 30px !important;
					}
				}

				&:last-of-type() label {
					@include border-radius(23px);
					@include breakpoint($tablet) {
						@include border-radius(15px);
					}
				}
			}
		}
		.fonts {
			.radio {
				margin-top: 10px;

				label {
					width: calc(100% - 30px) !important;
					padding: 26px 0 0;
					background-position: left 2px;
					background-repeat: no-repeat;
					background-size: 391px 23px;
					font-size: 10px;
					color: $darkgrey !important;
				}

				&.heebo label {
					background-image: url("../../../images/marketing/font_Heebo.png");
				}
				&.lora label {
					background-image: url("../../../images/marketing/font_Lora.png");
				}
				&.teko label {
					background-image: url("../../../images/marketing/font_Oswald.png");
				}
				&.arvo label {
					background-image: url("../../../images/marketing/font_Arvo.png");
				}
				&.nunito label {
					background-image: url("../../../images/marketing/font_Nunito.png");
				}
				&.roboto_slab label {
					background-image: url("../../../images/marketing/font_RobotoSlab.png");
				}
				&.questrial label {
					background-image: url("../../../images/marketing/font_Questrial.png")
				}
				&.gupter label {
					background-image: url("../../../images/marketing/font_Gupter.png")
				}
				&.goudy label {
					background-image: url("../../../images/marketing/font_Goudy.png")
				}
			}
		}
	}

	.pageSettings {
		@include breakpoint($phone $tablet) {
			h1 {
				display: none;
			}
		}
		@include breakpoint($tablet) {
			& > div:first-of-type {
				grid-column: 1 / span 2;
				display: grid;
				grid-template-columns: 11fr 6fr;
			}
			& > .spinner {
				display: block !important;
			}
		}

		& > select {
			width: 100%;
			margin: 10px 0;
		}
		.revisionHistory {
			margin-top: 20px;
			@include breakpoint($phone $tablet) {
				display: none;
			}

			.btnExpand, .btnCollapse {
				margin: 7px 0;
				color: $blue;

				svg {
					fill: $blue;
				}
			}

			h4 {
				margin-bottom: 0;
				padding-bottom: 5px;
				border-bottom: 2px solid $blue;
			}
		}

		.homeSettings {
			@include breakpoint($phone $tablet) {
				.form .fields label.full {
					grid-column: span 4;
					white-space: normal;
				}
			}
			.accordian {
				grid-column: 1 / span 4;
				margin-bottom: -5px;

				&:last-of-type {
					margin-bottom: 0;
				}

				.indent {
					margin-left: 30px;
				}
			}
		}
		.aboutSettings {
			.radioList {
				display: grid;
				grid-template-columns: repeat(5, 1fr);
				margin-top: 10px;
			}
			.accordian {
				grid-column: 1 / span 4;
				margin-bottom: -5px;

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
		.gallerySettings {
			.images {
				grid-column: 1 / span 4;
				display: grid;
				@include breakpoint($tablet) {
					grid-template-columns: repeat(3, 1fr);
					grid-gap: 10px;
				}

				div {
					@include maintain-aspect-ratio(1, 1);

					img {
						display: block;
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
					.btnLink {
						display: none;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(20%, -50%);
						height: 18px;
						width: 19px;
						z-index: 100;

						svg {
							margin: 0;
							vertical-align: initial;
						}

						&.delete {
							transform: translate(-120%, -50%);
						}
					}
					&.hasCredit:after {
						content: "*";
						position: absolute;
						top: 5px;
						bottom: auto;
						left: auto;
						right: 5px;
						height: 20px;
						width: 20px;
						@include border-radius(50%);
						background-color: $white;
						font-family: Arial;
						font-size: 25px;
						font-weight: $bold;
						line-height: 30px;
						text-align: center;
						color: $cobalt;
						z-index: 99;
					}

					&:hover {
						.btnLink {
							display: block;
						}
						&:after {
							content: " ";
							position: absolute;
							top: 0;
							bottom: 0;
							left: 0;
							right: 0;
							height: auto;
							width: auto;
							@include border-radius(0);
							background-color: $white;
							opacity: 0.85;
							z-index: 99;
						}
					}
				}
			}
		}
		.shopSettings {
			h2 {
				font-size: 18px;
				line-height: 24px;
				color: $darkblue;
			}
			.upgrade {
				margin-bottom: 40px;

				h3 {
					margin-bottom: 20px;

					.right {
						margin-left: auto;
						color: $orange;

						svg {
							fill: $orange;
						}
					}
				}
				ul {
					margin: 10px 0;

					li {
						line-height: 30px;

						svg {
							height: 20px !important;
							width: 20px !important;
							margin-right: 5px;
							fill: transparent;
							color: $green;
							vertical-align: middle;
						}
					}
				}
			}
			.plan {
				h2 {
					color: $blue;
				}

				& + .actions {
					margin-top: 20px;

					.btn {
						grid-column: span 5;
					}
				}
			}
			.fields, .showcase {
				.btn {
					grid-column: span 4;
					width: 100%;
					margin: 10px 0;
				}
			}
			.showcase {
				margin-bottom: 20px;

				.dragGrid {
					grid-auto-rows: 40px;
				}
				.grid td {
					border-top: none;
				}
			}
		}
		.eventSettings {
		}
		.categoryShowcaseSettings {
		}
		.contactSettings {
			.contact {
				grid-template-columns: 60px repeat(4, 1fr);

				h3 {
					grid-column: span 5;
				}
				label {
					align-self: start;
				}
				& > div {
					grid-column: span 4;
					display: grid;
					grid-template-columns: repeat(4, 1fr);
					grid-gap: 5px;
				}
			}
		}
		.faqSettings {
			.accordian {
				grid-column: 1 / span 4;
				margin-bottom: -5px;

				&:first-of-type {
					border: none;
				}
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
		.customPageSettings {
			.accordian {
				.header label {
					color: $blue;
				}

				&:first-of-type {
					border: none;
				}
			}
			.showcase {
				margin-bottom: 20px;

				.btn {
					width: 100%;
					margin: 10px 0;
				}
				.dragGrid {
					grid-auto-rows: 40px;
				}
				.grid td {
					border-top: none;
				}
			}
			.toggle {
				width: 100%;
			}
		}
		.menuSettings {
			section > .btn {
				width: 100%;
				margin: 0 0 20px;
			}
			.dragGrid {
				&.isOver {
					position: relative;

					&:after {
						content: " ";
						position: absolute;
						bottom: -1px;
						left: 20px;
						right: 0;
						height: 3px;
						background-color: $blue;
					}

					&.right:after {
						left: 60px;
					}
				}
				&.indent {
					width: calc(100% - 20px);
					margin-left: 20px;

					.accordian .header > div > label {
						font-weight: $regular;
					}

					&.isOver.right:after {
						left: 20px;
					}
				}

				& + .actions {
					margin-top: 20px;
				}
			}
			.accordian {
				.header {
					.btnExpand svg {
						height: 17px !important;
						width: 18px !important;
						margin-top: -2px;
					}
				}
				.content {
					.fields {
						margin-bottom: 0;
					}
					.actions {
						grid-template-columns: repeat(4, 1fr);
						width: 100%;
						margin-bottom: 20px;

						.btnLink {
							justify-self: end;
						}
					}
				}
			}
		}
	}

  .upgradeStorefrontSettings {
    display:block;
    grid-template-columns: unset;

    .sf-plans-upgrade {
      display: grid;

      .plan-details {
        margin-bottom: 30px;

        &:not(:nth-last-child(3)) {
          border-right: 1px solid #E0E5E9;
        }

        .plan-name {
          width: 90%;
          margin: auto;
        }
      }

      .upgrade-subscription, .learn-more, .warning-header, .contact-msg {
        grid-column: 1 / span 3;
        margin: 10px 0;

        &.learn-more{
          margin-top: 5px;
          text-align: center;
          text-decoration: underline;
        }
		
		&.contact-msg{
		  margin: 10px;
		  vertical-align: top;
		  border-right: none;
		}

        &.warning-header{
          color: $orange;
          font-weight: bold;
          margin: 10px;
          vertical-align: top;
          border-right: none;

          svg {
            fill: $orange;
            height: 14px;
            width: 15px;
            margin-right: 4px;
          }
        }
      }
    }
  }

	.getTheLook {
		grid-template-columns: 11fr 6fr !important;
		h1 {
			@include breakpoint($tablet) {
				grid-column: 1 / span 2 !important;
				grid-row: 1;
			}
		}
		.btnIcon {
			@include breakpoint($tablet) {
				grid-column: 2;
				grid-row: 1;
			}
			display: flex;
			align-items: center;
			justify-content: center;
			text-decoration: none;
		}
		.form div .btn {
			width: 100%;
		}
		.noItems {
			figure {
				background: transparent url("../../../images/marketing/Storefront.png") no-repeat top left;
				background-size: cover;
			}
		}

		.customPageStatus {
			text-transform: capitalize;
		}
	}

	.testimonialSettings {
		.accordian {
			grid-column: 1 / span 4;
			margin-bottom: -5px;

			&:first-of-type {
				border: none;
			}
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.socialSettings {
		@include breakpoint($phone $tablet) {
			input[type="text"].lg {
				grid-column: span 4;
			}
		}
		@include breakpoint($tablet) {
			.check {
				grid-column: span 1;
			}
		}

        .btnInstagram {
			display: flex;
			background-color: $blue;
			height: 32px;
			width: 290px;
			color: $white;
			align-items: center;
			justify-content: center;
			border: none;
			border-radius: 1px;
			cursor: pointer;

			&:hover {
				background-color: $lightblue;
			}

			svg {
				height: 20px;
				fill: $white;
				margin-right: 5px;
			}
		}
		.instagramInfo {
			grid-column: span 4;
			display: grid;
			grid-template-columns: 30px 50px 1fr 1fr;
			grid-column-gap: 5px;
			align-items: center;
			margin-top: 10px;
			line-height: 18px;

			.btnLink {
				grid-row: span 2;
				grid-column: 1;
				float: none;

				svg {
					margin: 0;
				}
			}
			figure {
				grid-row: span 2;
				grid-column: 2;
			}
			strong {
				grid-column: 3;
			}
			label {
				grid-column: 3;
			}
			.check {
				padding-top: 10px;
				@include breakpoint($tablet) {
					padding-top: 0;
					grid-row: 1 / span 2;
					grid-column: 4;
				}
			}
			.token {
				grid-column: span 4;
				display: grid;
				grid-template-columns: 1fr 3fr;
				margin-top: 10px;

				label {
					grid-column: 1;
				}
				input[type="text"] {
					grid-column: 2;
				}
			}
		}
	}

	.footerSettings {
		.address {
			grid-column: 1 / span 4;
			display: grid;
			grid-template-columns: 80px 1fr;
			grid-row-gap: 10px;
			line-height: 18px;

			label {
				align-self: start;
			}
			.btn {
				grid-column: 1 / span 2;
				width: 170px;
				padding: 0;
			}
		}
	}

	.seoSettings {
		.redWarning {
			color: $red;
			span {
              color: $red !important;
			}
		}
		label, a:not(.btn) {
			grid-column: 1 / span 4;
			white-space: normal;

			&.highlight {
				font-size: 10px;

				span {
					color: $blue;
				}
			}
		}
		a:not(.btn) {
			text-decoration: underline;
		}
	}
}

.linkDrawer {
	.linkAction {
		margin-top: 0 !important;
		line-height: 22px;
	}
	h2 {
		height: auto;
		margin-left: 15px !important;
	}
	input[type="text"], .btn, .radioGroup {
		width: 315px;
	}
	input[type="text"] {
		margin: 20px 0 5px 15px !important;
	}
	.btn {
		margin: 0 0 20px 15px !important;
	}
	.radioGroup {
		margin-left: 15px !important;

		.radio {
			label {
				color: $darkgrey !important;
				white-space: nowrap;
			}

			&.active label {
				color: $white !important;
			}
		}
	}
	h4 {
		margin-left: 15px !important;
		line-height: 40px;
	}
	.radioList {
		.radio {
			padding-left: 15px !important;
			border-top: 1px solid #0C263B; //rgba(7,24,39,0.3);

			& > div > div {
				margin-top: 15px !important;
			}
			label {
				line-height: 39px !important;
				color: $white !important;
			}
			&.radioSmall{
				height: 40px !important;
				display: flex !important;
				input{
					height: 40px !important;
				}
				& > div{
					align-items: center !important;
				}
				& > div > div {
					margin: 0 !important;
					margin-right: 5px !important;
				}

			}
		}
	}
}

.galleryModal {
	figure {
		@include maintain-aspect-ratio(1, 1);
		margin-bottom: 20px;
	}
	.credits {
		display: grid;
		grid-template-columns: 4fr 6fr 1fr;
		grid-column-gap: 5px;
		margin: 10px 0;

		input[type="text"] {
			margin: 0;
		}
		.btnLink {
			place-self: center end;
		}
	}
}

.newStorefrontPageModal.modal {
	.content {
		width: 70vw !important;
		max-width: 900px !important;
	}

	@include breakpoint($tablet) {
		.content {
			width: 70vw !important;
			max-width: 900px !important;
		}
	}

	.sfPageTypes {
		-moz-column-width: 19em;
		-webkit-column-width: 19em;
		column-width: 19em;
		-moz-column-gap: 1em;
		-webkit-column-gap:1em;
		column-gap:1em;

		> div {
			display: inline-block;
			margin-bottom: 1rem;
			width:  100%;
			position: relative;
			cursor: pointer;

			&:hover, &.selected {
				box-shadow: 1px 1px 8px 1px #a2a2a2;

				label {
					display: flex;
				}
			}

			img {
				width: 100%;
			}
			label {
				display: none;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				font-size: 16px;
				font-weight: 600;
				color: $blue;
				// display: flex;
				align-items: center;
				justify-content: center;
				background-color: rgba(255, 255, 255, 0.7);
				cursor: pointer;
			}
		}
	}
}

.upgradeSfModal {
  .title {
    color: $blue;
    padding-left: 20px;
    padding-top: 11px;
  }
  .content {
    padding: 0px;
    height: fit-content;
    padding-bottom: 10px;
    margin-bottom: 0px;
    background-color: white;
    overflow: scroll;

    .actions {
      padding: 0px 20px!important;
    }
  }

  .sf-plan {
    margin-bottom: 28px;
  }

  footer {
    padding-left: 20px;
    margin-bottom: 20px;
    .sfPrice {
        font-weight: bold;
     }
  }
}

.upgrade-sf-modal{
  .pricing-details{
    margin: 15px 0;

    div {
      margin-bottom: 5px;
    }

    .sfPrice {
      margin-right: 10px;
      font-weight: bold;
    }
  }
}
