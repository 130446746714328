.taxtypelist {

      > div {
        margin-bottom: 10px;
      }
  
      h4 {
        color: $white;
        margin-left: 15px;
        display: inline-block;
      }     
  }
  .taxfields {
    position: relative;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px;
    margin-bottom: 20px;
    line-height: 30px;

    > a {
        width: 100%;
    }
    ul{
        border-top: 1px solid #C1CDD7;
        padding-top: 6px;

        > li{
             display: flex;
             align-items: center;
             justify-content: space-between;
             margin-bottom: 10px;
         }
    }

    & > h4 {
        grid-column: span 4;
        margin: 0;
        margin-bottom: 5px;
    }
    & > h1, & > h2, & > h3, & > p {
        grid-column: span 4;
        margin: 0;
    }
    & > p {
        margin: 6px 0;
        line-height: 18px;
      }
  .info-text {
    font-size: 11px;
    color: $darkgrey;
    margin-top: 5px;
  }
}
